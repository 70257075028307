import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { PlusOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Row from '~/components/Row';
import RFIForm from './form';
import Filter from './filter';
import { paginationSpecBookSubmittials, deleteSpecBookSubmittial } from '~/services/hooks/specBookSubmittials';
import { downloadFile } from '~/services/hooks/files';
import { useSelector } from 'react-redux';
import ItemRFI from './itemRFI';
import './styles.css';

export default function RFIs({ estimate }) {
  const project = estimate?.project;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const user = useSelector(state => state.user.profile);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationSpecBookSubmittials({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [
        ...filters,
        { columnJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' },
        { field: 'type', value: 'RFI', restriction: 'EQUAL' },
      ],
    });
    const content = data.content;
    setTableData2(content);
    const originalData = content.filter(i => i.reply === null);
    originalData.map(item => {
      if (item.replied === true) {
        item.replies = content.filter(i => i.reply?.id === item.id);
      }
    });
    setTableData(originalData);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (project.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy, project]);

  const handleDeleteReplies = async item => {
    if (item?.replies?.length === 0) return;
    item.replies.map(async i => {
      await deleteSpecBookSubmittial(i.id);
    });
  };

  const handleDelete = async id => {
    const item = tableData.find(i => i.id === id);
    if (item?.replies?.length > 0) {
      handleDeleteReplies(item).then(async () => {
        deleteSpecBookSubmittial(id).then(() => {
          fetchData(meta.pageNumber + 1);
        });
      });
    } else {
      deleteSpecBookSubmittial(id).then(() => {
        fetchData(meta.pageNumber + 1);
      });
    }
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setIsReply(false);
    setShowForm(true);
  };

  const handleReply = record => {
    setSelectedRecord(record);
    setIsReply(true);
    setShowForm(true);
  };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:RFI.btnNew')}
            </Button>
          </Col>
        </Row>
        <div>
          {tableData2
            .filter(i => i.reply === null)
            .map(record => {
              return (
                <ItemRFI
                  key={record.id}
                  userLogged={user}
                  record={record}
                  tableData={tableData2}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  handleReply={handleReply}
                  downloadFile={downloadFile}
                />
              );
            })}
        </div>
        <RFIForm
          project={project}
          isReply={isReply}
          user={user}
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => {
            setShowForm(false);
            fetchData(1);
          }}
        />
      </Box>
    </>
  );
}
