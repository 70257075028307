
import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'specBookSubmittials';

// SEND NOTIFICATION OF RFI (REQUEST FOR INFORMATION)
export const sendNoticeOfRfi = async values => {
  try {
    const { data } = await api.post(`/mail/sendNoticeOfRfi`, values);
    message.success(i18next.t(`messages:${endPoint}.sentNotification`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// SEND NOTIFICATION OF SUBMITTIAL
export const sendNoticeOfSubmittial = async values => {
  try {
    const { data } = await api.post(`/mail/sendNoticeOfSubmittial`, values);
    message.success(i18next.t(`messages:${endPoint}.sentNotification`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAGINATION
export const paginationSpecBookSubmittials = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllSpecBookSubmittials = async (filters) => {
  try {
    const { data } = await api.post(`/${endPoint}/findAll`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createSpecBookSubmittial = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateSpecBookSubmittial = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteSpecBookSubmittial = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

sendNoticeOfRfi.propTypes = {
  values: PropTypes.object.isRequired,
};

sendNoticeOfSubmittial.propTypes = {
  values: PropTypes.object.isRequired,
};

paginationSpecBookSubmittials.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllSpecBookSubmittials.propTypes = {
  filters: PropTypes.object.isRequired,
};

createSpecBookSubmittial.propTypes = {
  values: PropTypes.object.isRequired,
};

updateSpecBookSubmittial.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteSpecBookSubmittial.propTypes = {
  id: PropTypes.string.isRequired,
};
