import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

const initialValues = {
    website: '',
    faxNumber: '',
    other: '',
};

export default function CustomerContactForm({ visible, onClose, onChange, customerContact }) {
    const { t } = useTranslation();
    const [recordData, setRecordData] = useState(initialValues);
    const [loading, setLoading] = useState(false);

    const fetchRecords = async () => {
        setLoading(true);
        if (customerContact === null) {
            setRecordData(initialValues);
        } else {
            setRecordData({
                website: customerContact.website,
                faxNumber: customerContact.faxNumber,
                other: customerContact.other
            });
        }
        setLoading(false);
    }

    const handleSave = async (values, { setErrors }) => {
        setLoading(true);
        let c = customerContact;
        c.website = values.website;
        c.faxNumber = values.faxNumber;
        c.other = values.other;
        onChange(c);
        setLoading(false);
    };

    useEffect(() => {
        if (visible) {
            fetchRecords();
        }
    }, [visible]);

    return (
        <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave}>
            {({ isSubmitting, submitForm, resetForm, touched }) => (
                <Modal
                    title={t('screens:customerContact.btnEdit')}
                    onCancel={onClose}
                    afterClose={resetForm}
                    open={visible}
                    loading={loading || isSubmitting}
                    footer={
                        <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
                    }
                >
                    <Spin spinning={loading || isSubmitting}>
                        <Form>
                            <Row>
                                <FormControl cols={{ xs: 24 }} field="website" label={t('screens:customers.data.website')}>
                                    <Input name="website" autoComplete="off" />
                                </FormControl>
                                <FormControl cols={{ xs: 24 }} field="faxNumber" label={t('screens:customers.data.faxNumber')}>
                                    <Input name="faxNumber" autoComplete="off" />
                                </FormControl>
                                <FormControl cols={{ xs: 24 }} field="other" label={t('screens:customers.data.other')}>
                                    <Input name="other" autoComplete="off" />
                                </FormControl>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            )}
        </Formik>
    );
}