import { Card, Col, DatePicker, Input, Row, Select, Spin, Modal, message, Typography } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { Table } from '~/components/Table';
import { formatPrice } from '~/Utils';
import { Container, ImageCustom, TextMd, InputCustom, TextAreaCustom, PCustom, DividerCustom } from './style';

export default function JmcContract({ contractTypes, contractType, project, record, onClose, onChangeType, visible }) {
    const logo = "/assets/images/logo-light.png";
    const currentDate = moment().format('DD') + 'th day of ' + moment().format('MMMM') + ' in the year ' + moment().format('YYYY');
    const { t } = useTranslation();
    const { Option } = Select;
    const { Text } = Typography;
    const [loading, setLoading] = useState(false);
    const [subVendor, setSubVendor] = useState(null);
    const [items, setItems] = useState([]);
    const [subContractors, setSubContractors] = useState([]);
    const [subContractorOptions, setSubContractorOptions] = useState([]);
    const [projectNumber, setProjectNumber] = useState('');
    const [dateDescription, setDateDescription] = useState(currentDate);
    const [subcontractor, setSubcontractor] = useState(null);
    const [subcontractorLocale, setSubcontractorLocale] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectAddress, setProjectAddress] = useState('');
    const [cityStateZipCode, setCityStateZipCode] = useState('');
    const [mediationLocale, setMediationLocale] = useState('');
    const [construction, setConstruction] = useState('');
    const [subcontractValue, setSubcontractValue] = useState('0.00');
    const [paymentCondition, setPaymentCondition] = useState('');
    const [date, setDate] = useState(moment());

    const fetchRecords = () => {
        if (record !== null) {
            setSubVendor(record.subVendor);
            setProjectNumber(record.projectNumber);
            setDateDescription(record.dateDescription);
            setSubcontractor(record.subcontractor);
            setSubcontractorLocale(record.subcontractorLocale);
            setProjectName(record.projectName);
            setProjectAddress(record.projectAddress);
            setCityStateZipCode(record.cityStateZipCode);
            setMediationLocale(record.mediationLocale);
            setConstruction(record.construction);
            setSubcontractValue(record.subcontractValue);
            setPaymentCondition(record.paymentCondition);
            setDate(moment(record.date));
            fetchItems(record.project, record.subVendor);
        } else {
            setSubVendor(null);
            setItems([]);
            setSubContractors([]);
            setSubContractorOptions([]);
            setProjectNumber('');
            setDateDescription(currentDate);
            setSubcontractor(null);
            setSubcontractorLocale('');
            setProjectName('');
            setProjectAddress('');
            setCityStateZipCode('');
            setMediationLocale('');
            setConstruction('');
            setSubcontractValue('0.00');
            setPaymentCondition('');
            setDate(moment());

            if (project) {
                setProjectNumber(project.id);
                setProjectName(project.description);

                if (project.property && project.property.address) {
                    const address = project.property.address;
                    setProjectAddress(address.addressLineOne);
                    setCityStateZipCode(address.city + ", " + address.state + address.zipCode);
                }
            }
            fetchSubContractors();
        }
    };

    const fetchItems = async (project, subVendor) => {
        try {
            setLoading(true);
            var bigDecimal = require('js-big-decimal');

            if (record !== null) {
                var total = new bigDecimal('0.00');
                record.items.forEach((item) => {
                    total = total.add(new bigDecimal(item.total));
                });
                
                setItems(record.items);
                setSubcontractValue(total.round(2).getValue());
            } else {
                const { data } = await api.get('/contracts/generateContractItems', {
                    params: { projectId: project.id, subVendorId: subVendor.id }
                });

                setItems(data.items);
                var total = new bigDecimal(data.total);
                setSubcontractValue(total.round(2).getValue());
            }
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const fetchSubContractors = async () => {
        setLoading(true);
        try {
            const filterList = [{ field: 'type', value: 'SUBCONTRACTOR', restriction: 'EQUAL' }];
            const { data } = await api.post('/subVendors/findAllPost', filterList);
            let options = [];
            data.forEach((item) => {
                options.push({ key: item.id, value: item.companyName, label: item.companyName });
            });
            setSubContractors(data);
            setSubContractorOptions(options);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleChange = (value) => {
        onChangeType(value);
    };

    const handleChangeSubcontractor = (value) => {
        setSubcontractor(value);

        subContractors.some((item) => {
            if (item.companyName === value) {
                setSubVendor(item);
                setSubcontractorLocale(item.address);
                let p = record !== null ? record.project : project;
                fetchItems(p, item);
                return true;
            }
        });
    };

    const handleSave = async () => {
        setLoading(true);

        try {
            let newContract = {
                id: record !== null ? record.id : null,
                project: record !== null ? record.project : project,
                subVendor: record !== null ? record.subVendor : subVendor,
                type: contractType,
                projectNumber: projectNumber,
                dateDescription: dateDescription,
                subcontractor: subcontractor,
                subcontractorLocale: subcontractorLocale,
                projectName: projectName,
                projectAddress: projectAddress,
                cityStateZipCode: cityStateZipCode,
                mediationLocale: mediationLocale,
                construction: construction,
                subcontractValue: subcontractValue,
                paymentCondition: paymentCondition,
                date: moment(date).format('YYYY-MM-DD HH:mm:s'),
                sentDate: record !== null ? record.sentDate : null,
                arquive: record !== null ? record.arquive : null,
                contractSignature: record !== null ? record.contractSignature : null,
                items: items,
            };

            if (record !== null) {
                await api.put('/contracts/update', newContract);
                message.success(t('messages:contractSuccessEdit'));
            } else {
                await api.post('/contracts/create', newContract);
                message.success(t('messages:contractSuccess'));
            }
            onClose();
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const tableColumns = [
        {
            title: t('screens:estimateSubVendors.data.code'),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t('screens:estimateSubVendors.data.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('screens:changeOrders.item.data.quantity'),
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            //title: t('screens:changeOrders.item.data.unit_cost'),
            title: t('screens:changeOrders.item.data.unitCost'),
            dataIndex: 'value',
            key: 'value',
            render: value => formatPrice(value)
        },
        {
            title: t('screens:estimateSubVendors.data.scheduledValue'),
            dataIndex: 'total',
            key: 'total',
            render: total => formatPrice(total)
        },
    ];

    useEffect(() => {
        fetchRecords();
    }, [record]);

    return (
        <Modal
            width="95%"
            title={record !== null ? t('screens:contracts.btnEdit') : t('screens:contracts.btnNew')}
            onCancel={onClose}
            open={visible}
            loading={loading}
            footer={
                <ModalFooter onOk={handleSave} loading={loading} onCancel={onClose} cancelColor="default" />
            }
        >
            <Spin spinning={loading}>
                <Row>
                    <FormControl
                        field="contractType"
                        label={t('screens:subcontractor.data.contractType')}
                    >
                        <Select
                            style={{ width: 370 }}
                            name="contractType"
                            placeholder="Select"
                            disabled={record !== null}
                            defaultValue={contractType}
                            onChange={handleChange}
                        >
                            {contractTypes?.map(item => {
                                return (
                                    <Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Row>
                <Card>
                    <Row><ImageCustom src={logo} /></Row>
                    <Container><TextMd strong>JMC QUALITY BUILDERS CORP. SUBCONTRACT</TextMd></Container>
                    <Container><TextMd strong>AGREEMENT</TextMd></Container>
                    <br />
                    <Row>
                        <Input.Group compact>
                            PROJECT NO.
                            <Text strong style={{ paddingLeft: '5px' }}>{projectNumber}</Text>
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>
                        <Input.Group compact>
                            This Agreement, made as of the
                            <InputCustom size="small" value={dateDescription} style={{ width: '260px' }}
                                onChange={(e) => { setDateDescription(e.target.value); }}
                            />
                            , by and between JMC QUALITY BUILDERS CORP., (Providing Construction Managing), a Florida profit corporation (hereinafter “JMC”), located at 11501 Lake Underhill Road Orlando FL 32825, and
                            {record === null ?
                                <Select size="small" showSearch value={subcontractor} placeholder="Select subcontractor"
                                    options={subContractorOptions}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={handleChangeSubcontractor}

                                />
                                :
                                <InputCustom size="small" disabled={true} value={subcontractor} style={{ width: '200px' }}
                                    onChange={(e) => { setSubcontractor(e.target.value); }}
                                />
                            }
                            .(hereinafter called the Subcontractor) located at
                            <InputCustom size="small" value={subcontractorLocale} style={{ width: '250px' }}
                                onChange={(e) => { setSubcontractorLocale(e.target.value); }}
                            />
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>For the Following Project:</Row>
                    <br />
                    <Row>
                        <InputCustom size="small" value={projectName}
                            style={{ width: '250px', fontWeight: '600' }}
                            onChange={(e) => { setProjectName(e.target.value); }} />
                    </Row>
                    <Row>
                        <TextAreaCustom autoSize="true" size="small" value={projectAddress}
                            style={{ width: '100%', fontWeight: '600' }}
                            placeholder="Insert the project address"
                            onChange={(e) => { setProjectAddress(e.target.value); }} />
                    </Row>
                    <Row>
                        <InputCustom size="small" value={cityStateZipCode}
                            style={{ width: '250px', fontWeight: '600' }}
                            placeholder="Insert the city, state and zip code"
                            onChange={(e) => { setCityStateZipCode(e.target.value); }} />
                    </Row>
                    <br />
                    <Row>Subcontractor and JMC agree as follows:</Row>
                    <br />
                    <Row>Section 1. Governing Contracts</Row>
                    <br />
                    <Row>JMC and Subcontractor agree to enter into a Subcontractor Agreement and Notice to Proceed with Construction. The Subcontractor Agreement will contain all terms regarding the description of work, the scope of work, and the parties' agreed price for the described work.
                    </Row>
                    <br />
                    <Row>Section 2. Contract Documents</Row>
                    <br />
                    <Row>2.1. The Contract Documents hereinabove mentioned, as well as the Plans, Specifications, General Conditions, Special Conditions, and any Addenda, available for examination by Subcontractor at all reasonable times at the office of JMC. These documents, including this Agreement, are hereinafter sometimes referred to as the Contract Documents.</Row>
                    <br />
                    <Row>2.2. Subcontractor represents and agrees that it has carefully examined and understands the Contract Documents, has investigated the nature, locality and site of the Work and the conditions and difficulties under which said Work is to be performed and that it enters into this Agreement on the basis of its own examination, investigation, and evaluation of all such matters, and not in reliance upon any opinions or representations of JMC, Owner, or any of their respective officers, agents, servants, or employees.
                    </Row>
                    <br />
                    <Row>2.3.With respect to the Work to be performed and furnished by Subcontractor, Subcontractor agrees to be bound to JMC by each and all of the terms and provisions of the Contract Documents, and to assume toward JMC all of the duties, obligations, and responsibilities that JMC by those Contract Documents assumes toward Owner and others, and Subcontractor agrees further that JMC shall have the same rights and remedies against Subcontractor as Owner under the terms and provisions of the Contract Documents has against JMC with the same force and effect as though every such duty, obligation, responsibility, right or remedy were set forth herein in full.
                    </Row>
                    <br />
                    <Row>2.4. The Work by Subcontractor shall be performed in a good and workmanlike manner and in strict compliance with the Contract Documents (as described above) and applicable codes and ordinances.
                    </Row>
                    <br />
                    <Row>Section 3. Contractor</Row>
                    <br />
                    <Row>3.1. Contractors Rights and Responsibilities</Row>
                    <br />
                    <Row>The Contractor shall cooperate with the Subcontractor in scheduling and perform the Contractor's Work to avoid conflicts or interference in the Subcontractor's Work and shall expedite written responses to submittals made by the Subcontractor.</Row>
                    <Row>The Contractor shall provide the Subcontractor copies of the Contractor's construction schedule and schedule of submittals, together with such additional scheduling details as will enable the Subcontractor to plan and perform the Subcontractor's Work properly. The Contractor shall promptly notify the Subcontractor of subsequent changes in the construction and submittal schedules and additional scheduling details.</Row>
                    <Row>The Contractor shall designate the areas within and around the Project where the Work is to be performed, provide suitable access thereto and provide an adequate area or areas in the vicinity of the Site sufficient for Subcontractor and/or Vendor storage, deliveries, lay-down areas and employee parking.</Row>
                    <br />
                    <Row>3.2. CONTRACTOR'S OPERATIONS</Row>
                    <br />
                    <Row>Subcontractor and/or Vendor and its Sub-subcontractors, Vendors or Suppliers, shall schedule and conduct its operations in a manner that minimizes interference with Contractor use and occupancy of its premises.</Row>
                    <br />
                    <Row>3.3. Communications</Row>
                    <br />
                    <Row>All notices relating to termination, cancellation for convenience, suspension or material breach shall be sent to the parties at the addresses stated next to the parties' signatures below, by registered or certified mail. All other notices may be sent by regular mail. Notice by facsimile transmissions shall be effective upon receipt, but must be confirmed by registered, certified or regular mail, as is appropriate to the type of notice. Either party hereto may specify to the other party a different address for the giving of notices upon receipt of notice of the change in writing.</Row>
                    <br />
                    <Row>3.4. Hazardous Materials</Row>
                    <br />
                    <Row>Subcontractor and its Subcontractors of any tier shall have no responsibility for the discovery, presence, handling, removal, or disposal of hazardous materials on Contractor's premises, including asbestos, asbestos products, polychlorinated biphenyl (PCB) or other toxic substances. Subcontractor shall, however, promptly report to Contractor the presence and location of any hazardous material, which it discovers at the Site.</Row>
                    <br />
                    <Row>Section 4. Subcontractors Rights and Responsibilities</Row>
                    <br />
                    <Row>4.1 Assignment of Subcontract</Row>
                    <br />
                    <Row>In the event of an assignment of this Subcontract by JMC to another contractor, Subcontractor agrees to be bound by and continue to perform under the terms and conditions of this Subcontract. Subcontractor specifically acknowledges that its rights, duties, and obligations under this Subcontract shall transfer to any assignee of this Subcontract.  JMC shall provide Subcontractor with timely written notice of any such assignment. The subcontractor may not assign this Subcontract to any other subcontractor without first obtaining the express written consent of the assignment and the express approval of said assignee from JMC.</Row>
                    <br />
                    <Row>4.2 Notices to Owner – Prior Notice to JMC</Row>
                    <br />
                    <Row>JMC and Subcontractor recognize that the law provides Subcontractor the right to serve a Notice to Owner/ Notice to Contractor to protect its rights on certain projects as set forth in the Subcontractor Agreement. Irrespective of those rights, Subcontractor agrees that before sending a Notice to Owner to the property owner of the property on which the contract will be performed, Subcontractor agrees to provide JMC with seven (7) days advance notice of Subcontractor's intent to file the Notice to Owner.</Row>
                    <br />
                    <Row>4.3 Covenant Not to Compete</Row>
                    <br />
                    <Row>4.3.1 This subcontract agreement indicates the subcontractor covenants and agrees that the subcontractor will not, either directly or indirectly, enter into a contract with End Client (owner/owner rep.). This limitation will include the case if the end client approaches or offers a new job to the subcontractor.</Row>
                    <br />
                    <Row>4.3.2 Limited Time and Duration. The undersigned Subcontractor hereby agrees that during the course of the Agreement and for a period of 12 months immediately following the expiration or termination of the Agreement for any reason, whether with or without good cause or for any or no cause, at the option either of the JMC QUALITY BUILDERS CORP. or the Subcontractor, with or without notice, the Subcontractor will not compete with the JMC and its successors and assigns, without the prior written consent of the JMC.</Row>
                    <br />
                    <Row>4.3.3 Limited Scope of Prohibited Activities. The term “not compete” as used herein shall mean that the Subcontractor shall not, without the prior written consent of the JMC, (i) serve as a contractor or subcontractor, (ii) directly or indirectly, build, operate, manage, work or consult for or otherwise affiliate with, any business in competition with or otherwise similar to the JMC.'s business.</Row>
                    <br />
                    <Row>Section 5. Guarantee</Row>
                    <br />
                    <Row>5.1.1. Subcontractor hereby guarantees the Work to the full extent provided in the Plans, Specifications, General Conditions, Special Conditions and Contract Documents.</Row>
                    <br />
                    <Row>5.1.2. Subcontractor shall remove, replace and/or repair at its own expense and at the convenience of Owner and/or JMC any faulty, defective or improper Work, materials or equipment discovered within one (1) year from the date of the acceptance of the Project as a whole by Architect and Owner or for such longer period as may be provided in the Plans, Specifications, General Conditions, Special Conditions or Contract Documents.</Row>
                    <br />
                    <Row>5.1.3. Without limiting the generality of the foregoing, Subcontractor warrants to Owner, Architect and JMC, and each of them, that all materials and equipment furnished under this Agreement will be of first class quality and new, unless otherwise required or permitted by the Contract Documents, that the Work performed pursuant to this Agreement will be free from defects and that the Work will strictly conform to the requirements of the Contract Documents and applicable code requirements. Work not conforming to such requirements, including substitutions not properly approved and authorized, shall be considered defective. All warranties contained in this Agreement and in the Contract documents shall be in addition to and not in limitation of all other warranties or remedies required and/or arising pursuant to applicable law.</Row>
                    <br />
                    <Row>5.2 Clean Up</Row>
                    <br />
                    <Row>5.2.1. Subcontractor shall, at its own cost and expense, (a) keep the Premises free at all times from all waste materials, packaging materials and other rubbish accumulated in connection with the execution of its Work by collecting and depositing said materials and rubbish in locations or containers as JMC designates from which it shall be removed by JMC from the Premises without charge, (b) clean and remove from its own Work and from all contiguous work of others any soiling, staining, mortar, plaster, concrete or dirt caused by the execution of its Work and make good all defects resulting therefrom, (c) at the completion of its Work in each area, perform such cleaning as may be required to leave the area "broom clean", and (d) at the entire completion of its Work, remove all of its tools, equipment, scaffolds, shanties and surplus materials.</Row>
                    <br />
                    <Row>5.2.2. Should Subcontractor fail to perform any of the foregoing to the satisfaction of Owner, Architect or JMC, and upon receipt of a formal 24 hour clean up notice from JMC, JMC shall have the right to perform and complete such work itself or through others and charge the cost thereof, via a deductive change order, to Subcontractor.</Row>
                    <br />
                    <Row>Section 6. Change Orders/Additions and Omissions</Row>
                    <br />
                    <Row>JMC reserves the right, from time to time, whether the Work or any part thereof shall or shall not have been completed, to make changes, additions and/or omissions in the Work as it may deem necessary, upon written Change Order to Subcontractor. The value of the work to be changed, added, or omitted shall be stated in said written order and shall be added to or deducted from the Price. All Change Orders must be fully executed before submission for payment.</Row>
                    <Row>By accepting to perform work for JMC, Subcontractor acknowledges that any and all work performed and submitted for payment must be a part of the original contract scope. All work performed outside the scope of the original contract must be approved by the JMC principal: Marco Garavelo and be a part of a fully executed change order in advance of performing the work. Work approved by anyone other than the project manager and not included on a fully executed change order will not be accepted for payment. By definition, the JMC principal is not the project superintendent or project owner, neither of whom has the authority to approve work outside the subcontractor's scope of work.</Row>
                    <Row>Subcontractors performing work for JMC are expected to perform work as identified on the permitted and approved construction drawings. Work performed that deviates from the approved work without written approval by the JMC principal will be subject to non-payment and subsequent rework if required. JMC will not be responsible for the cost of demolition of non-approved work and any necessary rework. Any associated demolition and rework costs will be borne by the Subcontractor.</Row>
                    <br />
                    <Row>Section 7. Dispute Resolution</Row>
                    <br />
                    <Row>7.1. The parties recognize that problems and disputes between them may occur and that it is preferable for them to reach an amicable resolution of such disputes without the need to resort to formal dispute resolution procedures. However, in the event that such disputes are not resolved voluntarily as JMC and Subcontractor may agree, then such disputes shall first be mediated unless otherwise dictated by the terms of the General Contract between Owner and JMC.</Row>
                    <br />
                    <Row>7.2. Mediation shall be initiated by either party giving the other party written a notice of its demand for mediation, which written notice shall include a description of any issues in dispute. Subcontractor agrees that it shall waive its right to pursue any claim for which it fails to make a written demand for mediation within thirty (30) days of the date Subcontractor first became aware of the issue in dispute.</Row>
                    <br />
                    <Row>
                        <Input.Group compact>
                            7.3. Mediation shall be held in
                            <InputCustom size="small" value={mediationLocale} style={{ width: '260px' }}
                                onChange={(e) => { setMediationLocale(e.target.value); }}
                            />
                            , and the parties, individually or through their respective legal counsel, shall mutually agree upon a certified mediator. The parties' specifically agree that their good faith attendance at mediation is a condition precedent to the filing of any action in a court of law or other legal or administrative action.  In the event any unresolved dispute cannot be amicably settled and the parties' good faith mediation efforts fail within a reasonable period of time, then Subcontractor expressly agrees to waive its right to trial by jury in any action at law, and agrees that proper venue for any legal action shall be limited to either the county wherein the property in dispute is located, or
                            <InputCustom size="small" value={mediationLocale} style={{ width: '260px' }}
                                onChange={(e) => { setMediationLocale(e.target.value); }}
                            />
                            . At its sole option, Contractor reserves the right to demand a trial by jury.
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>7.4. Unless otherwise agreed in writing, Subcontractor shall remain obligated to continue to prosecute its work and maintain the progress schedule during any dispute resolution proceedings, regardless of whether it has received payment for the work in dispute.  Provided Subcontractor continues to perform, JMC shall continue to make payments in accordance with this Agreement for all work which is not the subject of the dispute resolution proceeding.</Row>
                    <br />
                    <Row>7.5. Indemnification</Row>
                    <br />
                    <Row>Subcontractor's indemnity, to the fullest extent permitted by applicable law, subcontractor and/or vendor will indemnify and defend the contractor parties against all claims arising, or alleged to arise, from any subcontractor party's  performance of the work, breach of this agreement which does not constitute a contractor's insurable injury, violation of or a failure to comply with applicable law or failure to promptly remove or release any stop notices, mechanic's liens or other similar claims relating to labor, goods, materials, equipment or services whether provided directly or indirectly by the subcontractor parties or any other breach or claim under the Contract Documents.
                    </Row>
                    <br />
                    <Row>7.6 Construction Liens or Claims</Row>
                    <br />
                    <Row>
                        <Input.Group compact>
                            7.6.1. Subcontractor acknowledges that its rights to remedies pursuant to
                            <InputCustom size="small" value={construction} style={{ width: '260px' }}
                                onChange={(e) => { setConstruction(e.target.value); }}
                            />
                            Construction Lien Law shall be governed, to the fullest extent permitted by law, by this clause. Subcontractor agrees that its rights to pursue a lien shall be limited to that portion of its subcontract price which is unpaid and due at the time of filing a lien claim and specifically agrees and covenants that it will not file liens, or make any claims against any applicable bonds, for any sums not within the contract price or written modifications thereto and shall not file liens, or make any claims against any applicable bonds, for such sums as it may contend are due as damages by reason of delays or other such claims unless such sums are agreed due to Subcontractor by written contract modification.   Subcontractor agrees that its lien rights, whatever they may be are reduced by each payment made to Subcontractor by JMC or any other party on behalf of JMC.
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>7.6.2. Subcontractor acknowledges that a claim of lien or notice of nonpayment filed by it may be disruptive of Project finances and could have an adverse impact on JMC relationships with Owner and with other Subcontractors.  Therefore, Subcontractor further agrees that if it files a lien claim or makes any claim against any applicable bonds which is not permitted by law, or which contains claims which are not permissible hereunder or pursuant to law, or which is negligently or purposefully overstated, JMC shall be entitled to receive from Subcontractor all its damages arising therefrom, and shall further be held harmless and indemnified by Subcontractor from all claims of Owner and other Subcontractors arising therefrom.  The foregoing shall be in addition to all rights and remedies provided by law.
                    </Row>
                    <br />
                    <Row>7.6.3. If any Subcontractor, laborer, material man or supplier of Subcontractor or any other person directly or indirectly acting for, through or under it or any of them files or maintains a claim, whether a construction lien, notice of nonpayment or an attested account or otherwise, against the Project or Premises or any part thereof or any interests therein or any improvements thereon or against any monies due or to become due from the Owner to JMC or from JMC to Subcontractor, for or on account of any work, labor, services, materials, supplies, equipment or other items performed or furnished for or in connection with the Work or under any change order or supplemental agreement for extra or additional work in connection with the Project, Subcontractor agrees to cause such liens and claims to be satisfied, removed or discharged at its own expense by bond, payment or otherwise within ten (10) days from the date of the filing thereof, and upon its failure to do so JMC shall have the right, in addition to all other rights and remedies provided under this Agreement and the Contract Documents or by law, to cause such liens or claims to be satisfied, removed or discharged by whatever means JMC chooses, at the entire cost and expense of Subcontractor (such cost and expense to include legal fees and disbursements).  Subcontractor agrees to indemnify, protect and save harmless JMC and Owner from and against any and all such liens and claims and actions brought or judgments rendered thereon, and from and against any and all loss, damages, liability, costs and expenses, including legal fees and disbursements, which JMC and/or Owner may sustain or incur in connection therewith.
                    </Row>
                    <br />
                    <Row>Section 8. Termination</Row>
                    <br />
                    <Row>If Subcontractor defaults, neglects to carry out the Work diligently and in accordance with this Agreement or in any other way breaches this Agreement, including but not limited to failing to pay its employees, subcontractors, suppliers or other creditors, and fails with 24 hours after receipt of written notice from Owner to cure, or diligently commence curing, such deficiencies, then JMC shall have the right to terminate Subcontractor's performance and/or to take whatever steps it seems necessary to correct said deficiencies and breaches and charge the cost thereof to Subcontractor, who shall be liable for the full cost of JMC's corrective action, including reasonable overhead and profit. Within 24 hours of receipt of JMC's written notice of termination, Subcontractor shall vacate the project and remove from the project site all tools, materials and equipment not intended for inclusion in the work. Further, JMC may at any time and for any reason terminate Subcontractor's performance hereunder for JMC's convenience; in this case, Subcontractor also has 24 hours to vacate the property. In the event of termination for cause, Subcontractor shall recover only the actual cost of Work completed in accordance with the Contract Documents to the date of termination, plus applicable overhead and profit, less the cost to cure or remedy any deficiencies in the work or any damages resulting from Subcontractor's breach of this Agreement. Subcontractor shall not be entitled to any other compensation or consequential damages in the event of a termination for cause or convenience, including without limitation lost profits, lost opportunity costs, home office overhead, unabsorbed corporate overhead, Impact damages or other similar remuneration. Subcontractor waives any claim for loss of anticipated profits in the event JMC exercises this clause.</Row>
                    <br />
                    <Row>Section 9. The Work of this Subcontract</Row>
                    <br />
                    <Row>Unless otherwise noted, Subcontractor is to furnish all labor and equipment required to complete the specified scope of work.</Row>
                    <br />
                    <Row>Section 10. Date of Commencement and Time of Completion</Row>
                    <br />
                    <Row>10.1. Subcontractor shall commence the Work when notified to do so by JMC and shall diligently and continuously prosecute and complete the Work and coordinate the Work with the other work being performed on the Project, in accordance with those project schedules as may be issued from time to time during the performance of the Work, and any other scheduling requirements listed in this Agreement, including Exhibits, so as not to delay, impede, obstruct, hinder or interfere with the commencement, progress or completion of the whole or any part of the Work or other work on the Project.</Row>
                    <br />
                    <Row>10.2. Subcontractor shall: (a) participate and cooperate in the development of schedules and other efforts to achieve timely completion of the Work; (b) provide information for the scheduling of the times and sequence of operations required for its Work to meet JMC's overall scheduling requirements; (c) shall continuously monitor the project schedule so as to be fully familiar with the timing, phasing and sequence of operations of the Work and of other work on the Project; and (d) shall execute the Work in accordance with the requirements of the project schedule including any revisions thereto.</Row>
                    <br />
                    <Row>10.3. Should the progress of the Work or the Project be delayed, disrupted, hindered, obstructed or interfered with, by any fault or neglect or act or failure to act of Subcontractor or any of its officers, agents, servants, employees, subcontractors or suppliers so as to cause any additional cost, expense, liability or damage to JMC or to Owner, including legal fees and disbursements incurred by JMC (whether incurred in defending claims arising from such delay or in seeking reimbursement and indemnity from Subcontractor and its surety hereunder or otherwise), Subcontractor and its surety shall and do hereby agree to compensate JMC and/or Owner for, and indemnify them against, all such costs, expenses, damages and liability incurred or to be incurred. JMC shall be entitled to seek liquidated damages in the sum of $1,000.00 for each day of delay beyond the substantial completion date.  This indemnity is a distinctly negotiated contract term, separate and apart from any terms and specifications listed in the Subcontractor Agreement or Contract Documents.</Row>
                    <br />
                    <Row>10.4. JMC, if it deems necessary, may direct Subcontractor to work overtime and, if so directed, Subcontractor shall work said overtime.  Provided that Subcontractor is not in default under any of the terms or provisions of this Agreement or of any of the Contract Documents, JMC will pay Subcontractor for such actual additional wages paid, if any, at rates that JMC has approved, plus taxes imposed by law on such wages.</Row>
                    <br />
                    <Row>10.5. If the progress of the Work or the Project is delayed by any fault or neglect or act or failure to act of Subcontractor or any of its officers, agents, servants, employees, subcontractors, or suppliers, then Subcontractor shall, in addition to all of the other obligations imposed by this Agreement upon Subcontractor, and at its own cost and expense, work such overtime as may be necessary to make up all time lost in the completion of the Work and of the Project due to such delay.  Should Subcontractor fail to make up the time lost by reason of such delay, JMC shall have the right to cause other Subcontractors to work overtime and to take whatever other action it deems necessary to avoid delay in completion of the Work and of the Project. Subcontractor agrees to bear the cost and expense of such overtime and/or such other action as JMC requires.</Row>
                    <br />
                    <Row>Section 11. Subcontract Sum</Row>
                    <br />
                    <Row>
                        <Input.Group compact>
                            11.1 The Contractor shall pay the Subcontractor in current funds for the performance of the Subcontract the amount of
                            <Text strong style={{ paddingLeft: '5px' }}>{formatPrice(subcontractValue)}</Text>.
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>
                        <Table
                            size="small"
                            style={{ width: '100%' }}
                            bordered
                            rowKey="id"
                            pagination={false}
                            loading={loading}
                            columns={tableColumns}
                            dataSource={items}
                        />
                    </Row>
                    <br />
                    <Row>Any issues extending beyond the specifications and/or scope of work will immediately be brought to the attention of JMC representation </Row>
                    <br />
                    <Row>Payment As per Section 12.</Row>
                    <br />
                    <Row>
                        <TextAreaCustom autoSize="true" size="small" value={paymentCondition} style={{ width: '100%' }} onChange={(e) => { setPaymentCondition(e.target.value); }} />
                        Payment will take place on the subsequent Friday of the requested pay application given pay application is received by Friday end of business day of the week prior, otherwise payment will occur on next week Friday.
                    </Row>
                    <br />
                    <Row>Invoice must contain notarized Lien Waiver with description of work performed and amount.</Row>
                    <br />
                    <Row>11.2. Schedule of Values: N/A</Row>
                    <br />
                    <Row>11.3. The sum to be paid by JMC, out of funds received by JMC for work on the Project to Subcontractor for the satisfactory performance and completion of the Work and of all of the duties, obligations and responsibilities of Subcontractor under this Agreement and the Contract Documents shall be specifically set forth in the Subcontractor Agreement, subject to additions and deductions, via executed change orders, as herein provided.</Row>
                    <br />
                    <Row>11.4. In addition to the indemnity as described in detail above and below, the price includes all Federal, State, County, Municipal and other taxes imposed by law and based on labor, services, materials, equipment, or other items acquired, performed, furnished or used for and in connection with the Work, including but not limited to sales, use and personal property taxes payable by or levied or assessed against Owner, JMC, or Subcontractor.  Where the law requires any such taxes to be stated and charged separately, the total price of all items included in the Work plus the amount of such taxes shall not exceed the Price.</Row>
                    <br />
                    <Row>Section 12. Progress Payments</Row>
                    <br />
                    <Row>12.1. As per the Notice to Proceed with Construction Subcontractor shall submit to JMC, in the form and quantities required by JMC, a written requisition for payment showing the proportionate value of the work completed as specified in your contract, including any executed change orders, from which shall be deducted a retainage of ten percent (10%); all previous payments; and, all charges for services, materials, equipment and other items furnished by JMC to or chargeable to Subcontractor.  The Subcontractor shall not bill in advance for the value of any Work not reasonably expected to be in place on or before the appropriate bill by date per the Notice to Proceed.  Subcontractor agrees that JMC shall have the right to withhold any monthly draw for which Subcontractor has attempted to improperly bill for Work in advance and that such draw as properly adjusted will not become due until the following month thereafter. The balance of the amount of such requisition, as approved by JMC, Owner Representative and Owner, and for which payment has been received by JMC, shall be due and paid to Subcontractor within seven (7) days of receipt of said funds, and upon receipt of all insurance, waivers and documents as set forth in this agreement.</Row>
                    <br />
                    <Row>12.2. The obligation of JMC to make a payment under this Agreement, whether a progress or final payment or for extras or change orders or delays to the Work, is not due and owing to Subcontractor unless and until JMC received payment from Owner or Owner Representative. Subcontractor hereby acknowledges the pay when paid provision and assumes the risk of not- payment by Owner or Owner Representative to JMC.  If JMC has provided payment or performance bonds or a combination payment and performance bond, the obligation of JMC and its surety under any of those bonds to make any payment (whether a progress payment or final payment) to a claimant on that bond is similarly subject to the express condition precedent of payment therefore by Owner or Owner Representative. In any event, no payment shall be due to Subcontractor from JMC unless and until JMC receives payment from Owner.</Row>
                    <br />
                    <Row>12.3. Subcontractor shall submit before its first requisition for payment a detailed schedule of values showing the breakdown of the Price into its various parts for use only as a basis of checking Subcontractor's monthly requisitions.</Row>
                    <br />
                    <Row>12.4. JMC reserves the right to advance the date of any payment (including the final payment) under this Agreement if, in JMC's sole judgment, it becomes desirable to do so.</Row>
                    <br />
                    <Row>12.5. If a joint check is requested by either Subcontractor or a supplier of Subcontractor, Subcontractor shall be charged $50.00 per check and said charge shall be deducted from the Contract amount.  In addition, if a joint check between parties becomes necessary to protect Owner's interest, this charge shall also apply.  This option shall not release any party from the standard contract requirements regarding furnishing a Release of Lien upon payment.</Row>
                    <br />
                    <Row>12.6. Subcontractor agrees that, if and when requested to do so by JMC, it shall furnish such information and evidence, including but not limited to affidavits, releases, and/or waivers, as JMC may require, with respect to the obligations incurred by Subcontractor for or in connection with the Work, including all payments made by Subcontractor thereon, and the amounts remaining unpaid, to whom and the reasons therefore.</Row>
                    <br />
                    <Row>12.7. Despite the requirements set forth above, JMC will forward absolutely no payments to Subcontractor unless and until Subcontractor has furnished to JMC all proof of insurance in the amounts set forth in Section 14. Subcontractor specifically acknowledges that JMC's actual receipt of the required insurance paperwork and proof of valid insurance is a condition precedent to the receipt of any payment.  If Subcontractor fails to provide JMC with the required insurance documentation, then Subcontractor also agrees to waive any claims against JMC in any forum based on JMC's refusal to pay Subcontractor.</Row>
                    <br />
                    <Row>Section 13. Final Payment</Row>
                    <br />
                    <Row>13.1. Final payment to Subcontractor shall be made only with funds JMC receives from Owner, Construction Lender or Owner's Agent as final payment for Work under the General Contract.   Final payment by JMC is not due and owing to Subcontractor unless and until JMC received final payment from Owner or Owner Representative. Subcontractor hereby acknowledges the pay when paid provision and assumes the risk of non-payment by Owner or Owner Representative to JMC.</Row>
                    <br />
                    <Row>13.2. In addition, final payment by JMC to Subcontractor shall not become due and payable until the following other express conditions precedent have been met: (a) completion and acceptance of the Work by JMC, Architect, and Owner, including all closeout documents, warranties, and guarantees in their approved forms; (b) evidence from Subcontractor satisfactory to JMC that there are no claims, obligations, notices of nonpayment or liens outstanding or unsatisfied for labor, services, materials, equipment, taxes or other items performed, furnished, or incurred for or in connection with the Work; and (c) execution and delivery by Subcontractor, in a form satisfactory to JMC and/or Owner a general release running in favor of JMC and Owner.</Row>
                    <br />
                    <Row>13.3. Should there prove to be any such claim, obligation or lien after final payment is made, Subcontractor shall refund to JMC and/or Owner all monies that JMC and/or Owner shall pay in satisfying, discharging or defending against any such claim, obligation or lien, or any action brought, or judgment recovered thereon, and all costs and expenses, including legal fees and costs incurred in connection therewith.</Row>
                    <br />
                    <Row>Section 14. Insurances</Row>
                    <br />
                    <Row>14.1 Insurance</Row>
                    <Row>14.1.1. Despite the requirements set forth above, JMC will forward absolutely no payments to Subcontractor unless and until Subcontractor has furnished to JMC all insurance paperwork in the forms JMC requires.  Subcontractor specifically acknowledges that JMC's actual receipt of the required insurance paperwork and proof of valid insurance is a condition precedent to the receipt of any payment.    If Subcontractor fails to provide JMC with the required insurance documentation, then Subcontractor also agrees to waive any claims against JMC in any forum based on JMC's refusal to pay Subcontractor.
                    </Row>
                    <br />
                    <Row>14.1.2. Unless a given project requires greater levels of insurance coverage than specified below, Subcontractor shall purchase and maintain insurance, during the progress of a project and for two years following the final completion of that project, with the following minimum limits of coverage. All insurance companies must be acceptable to JMC:</Row>
                    <br />
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Worker's Compensation:</Col>
                        <Col flex="auto">Statutory for State of job location with a Broad Form All Other States Endorsement</Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Employer's Liability:</Col>
                        <Col flex="auto">$500,000/500,000/500,000</Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Automobile Liability:</Col>
                        <Col flex="auto">
                            <PCustom>$1,000,000 Combined Single Limit (each accident).</PCustom>
                            <PCustom>Coverage must include: All Owned, Non-Owned, and Hired Vehicles</PCustom>
                        </Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Commercial General Liability:</Col>
                        <Col flex="auto">
                            <PCustom>Occurrence Based Policy. Explosion, Collapse, and Underground coverage cannot be deleted</PCustom>
                            <PCustom>Must have Severability of Interest Clause</PCustom>
                            <PCustom>$1,000,000 Per Occurrence</PCustom>
                            <PCustom>$1,000,000 Personal & Advertising Injury</PCustom>
                            <PCustom>$2,000,000 General Aggregate (per project endorsement)</PCustom>
                            <PCustom>$2,000,000 Products-Completed Operations Aggregate</PCustom>
                        </Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Umbrella/Excess Liability:</Col>
                        <Col flex="auto">Maybe dictated by project</Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Maritime Insurance:</Col>
                        <Col flex="auto">Statutory (when applicable)</Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Waivers of Subrogation:</Col>
                        <Col flex="auto">
                            <PCustom>In favor of:</PCustom>
                            <PCustom>• JMC Quality Builders Corp.</PCustom>
                            <PCustom>• Owner</PCustom>
                            <PCustom>Applicable to:</PCustom>
                            <PCustom>Commercial General Liability</PCustom>
                            <PCustom>Worker's Compensation</PCustom>
                            <PCustom>Commercial Automobile Liability</PCustom>
                            <PCustom>All Commercial Property or Inland Marine Policies insuring the Work and/or the Subcontractor's property and equipment associated with performing the Subcontractor's Work.</PCustom>
                        </Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex="none" style={{ width: '230px' }}>Additional Insured Requirements:</Col>
                        <Col flex="auto">
                            <PCustom>In favor of:</PCustom>
                            <PCustom>• JMC Quality Builders Corp.</PCustom>
                            <PCustom>• Owner</PCustom>
                            <PCustom>Primary and Non-Contributory Additional Insured</PCustom>
                            <PCustom style={{ textIndent: '30px' }}>General Liability</PCustom>
                            <PCustom style={{ textIndent: '30px' }}>Umbrella/Excess Liability (Must be specifically endorsed onto the policy)</PCustom>
                            <PCustom style={{ textIndent: '30px' }}>Additional Insured</PCustom>
                            <PCustom style={{ textIndent: '30px' }}>Commercial Automobile Liability</PCustom>
                        </Col>
                    </Row>
                    <br />
                    <Row>14.1.3. Before any Work is commenced on the project, Subcontractor shall provide JMC with a Certificate of Insurance indicating that the Subcontractor has satisfied all of the insurance coverage and limit requirements.  Such certificate shall provide for a minimum of thirty (30) days prior written notice to JMC before cancellation, termination, or non-renewal of such insurance (unless the Contract Documents contain a longer advance notice requirement). In the event a deficiency in the Subcontractor's insurance coverage is discovered after the Subcontractor has commenced its work, JMC may withhold payments until Subcontractor complies with the required insurance coverage. The failure of JMC to reject the Subcontractor's Certificate of Insurance shall not be deemed to constitute an acceptance by JMC of a deficient insurance certificate.  In addition to the Certificate of Insurance, which shall show JMC as a Certificate Holder, Subcontractor shall provide to JMC, prior to commencing any Work, a copy of the endorsement to Subcontractor's insurance policy, showing JMC, the Owner and Architect/Engineer as named additional insured.</Row>
                    <br />
                    <Row>14.1.4. Each of the Subcontractor's contractors, sub-subcontractors, and suppliers who perform services on the project shall be required to provide equivalent insurance coverage, including the additional Insured and Waiver of Subrogation requirements, before such suppliers or subcontractors come onto the job site.  Subcontractor shall provide to JMC, prior to any of Subcontractor's contractors, sub-subcontractors or suppliers commencing any work, a copy of the endorsement to their policy, showing JMC, the Owner and Architect/Engineer as named additional insured.</Row>
                    <br />
                    <Row>14.1.5. The Subcontractor shall have the right, at its sole expense, to employ sub-subcontractors or persons in his sole discretion, provided all requirements above were met and accepted by JMC. Subcontractor agrees as follows:
                    </Row>
                    <Row style={{ textIndent: '30px' }}>i. That the Subcontractor shall, immediately prior to such employment or hiring, notify such sub-subcontractor or person that he, she or they are employees of the Subcontractor and not employees of JMC.</Row>
                    <Row style={{ textIndent: '30px' }}>ii. That the Subcontractor shall withhold and remit to the appropriate governmental body, all city, state, and federal withholding taxes relation to the employment of such person/sub-subcontractor.</Row>
                    <Row style={{ textIndent: '30px' }}>iii. That the Subcontractor shall pay all social security taxes to be paid by the Subcontractor relating to such persons;</Row>
                    <Row style={{ textIndent: '30px' }}>iv. That the Subcontractor shall at all times comply with the state laws relating to workers' compensation'</Row>
                    <Row style={{ textIndent: '30px' }}>v. That the Subcontractor shall at all times verify the eligibility of the employees or sub-subcontractors that it uses to work in the United States;</Row>
                    <Row style={{ textIndent: '30px' }}>vi. That the Subcontractor shall at all times comply with the state laws relating to unemployment compensation.
                    </Row>
                    <br />
                    <Row>Section 15. Miscellaneous</Row>
                    <br />
                    <Row>15.1. Unless otherwise provided in the project specific subcontract, Subcontractor shall obtain and pay for all necessary permits and licenses and comply with all statutes, ordinances, rules, regulations and orders of any governmental or quasi-governmental authority applicable to the performance of the Work and be responsible for and correct any violations thereof.</Row>
                    <br />
                    <Row>15.2. Subcontractor hereby expressly warrants that it shall examine the work installed by others that affects Subcontractor's work and that if any defects exist, shall immediately notify JMC in writing and the Subcontractor shall not proceed until such defects are corrected or Subcontractor is given written authorization to proceed signed by an officer of JMC.  In the event Subcontractor fails to give notice as required herein, Subcontractor shall be deemed to have waived any claim with respect to such defects.</Row>
                    <br />
                    <Row>15.3. No waiver by JMC of any breach by Subcontractor of a provision of this Subcontractor Agreement or Contract Documents shall be deemed a waiver of any other provision hereof or of any subsequent breach by Subcontractor of such provision.</Row>
                    <br />
                    <Row>15.4. The Subcontractor shall at its own expense and cost, protect its employees and other persons from the risk of death, injury or bodily harm arising out of or in any way connected with the Subcontractor's Work.  In addition, the Subcontractor shall comply with all safety policies and procedures of JMC and the Owner.</Row>
                    <br />
                    <Row>15.4.1 Safety: Subcontractor shall comply with the JMC safety program, a written, site specific, safety plan must be submitted to JMC a minimum of 1 week prior to commencement of work. This safety plan shall include a jobsite specific safety program, safety training and equipment, tool operator, and welder certifications, licenses, insurance certificates, and emergency contact information.  Subcontractor shall perform all work safely in accordance with OSHA and JMC safety program, and conduct a weekly safety inspection of his operations and submit to JMC a copy of the inspection notes and attendance record.  Enforcement of safety for his activities is this Subcontractor's responsibility. Subcontractor must provide all safety signs and notices as required for the work. Subcontractor shall daily complete Pre-Task Plans (PTP) for all scopes of work and shall submit electronic copies to the JMC project manager/superintendent at least weekly.</Row>
                    <br />
                    <Row>15.5. Subcontractor agrees that all Subcontractor's contractors or sub-subcontractors shall be subject to approval in writing by JMC. The Subcontractor shall require each of its contractors or sub-subcontractors to assume toward the Subcontractor all the obligations and responsibilities which the Subcontractor, by Subcontractor Agreement or Contract Documents assumes towards JMC. The Subcontractor shall require its contractors or sub- subcontractors to indemnify JMC and the Owner and their agents and employees according to the indemnity provision herein. If requested by JMC, the Subcontractor shall submit to JMC for approval a list of the Sub-subcontractors and suppliers with whom the Subcontractor intends to engage with respect to Subcontractor's Work.</Row>
                    <br />
                    <Row>The said parties, for themselves, their heirs, executors, administrators, successors and assigns, do hereby agree to the full performance of all of the terms and provisions herein contained.</Row>
                    <br />
                    <Row>IN WITNESS WHEREOF the parties to these presents have hereunto set their hands as of the day and year first above written.</Row>
                    <br />
                    <Row>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <PCustom style={{ fontWeight: '600', textDecoration: 'underline' }}>JMC QUALITY BUILDERS CORP.</PCustom>
                            <br />
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="none" style={{ width: '50px' }}>
                                    <PCustom>By:</PCustom>
                                    <PCustom>Phone:</PCustom>
                                    <PCustom>Email:</PCustom>
                                    <PCustom>As:</PCustom>
                                    <PCustom style={{ paddingTop: '5px' }}>Date:</PCustom>
                                </Col>
                                <Col flex="auto">
                                    <PCustom>Marco Garavelo</PCustom>
                                    <PCustom>407 704 7834</PCustom>
                                    <PCustom>marco@jmcquality.com</PCustom>
                                    <PCustom>Principal – JMC Quality Builders</PCustom>
                                    <PCustom>
                                        <DatePicker
                                            format={"YYYY/MM/DD"}
                                            placeholder={t('messages:select')}
                                            value={date}
                                            onChange={(date) => setDate(date)}
                                        />
                                    </PCustom>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            {subcontractor !== '' ? <PCustom style={{ fontWeight: '600', textDecoration: 'underline' }}>{subcontractor}</PCustom> : <br />}
                            <br />
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="none" style={{ width: '50px' }}>
                                    <PCustom>By:</PCustom>
                                    <PCustom>Phone:</PCustom>
                                    <PCustom>Email:</PCustom>
                                    <PCustom>As:</PCustom>
                                    <PCustom>Date:</PCustom>
                                </Col>
                                <Col flex="auto">
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </Modal>
    );
}