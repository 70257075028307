import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { Input, Modal, Row, Space, Spin, Typography } from 'antd';
import { Footer, Img } from './styles';
import Button from 'components/Button';
import FormControl from '../Form/FormControl';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function ModalSignatureCanvas({ visible, loading, onSave, onClose, onLoad, canvasProps, jcmSignature }) {
    const { t } = useTranslation();
    const profile = useSelector(state => state.user.profile);
    const { Paragraph } = Typography;
    const [name, setName] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [signatureResult, setSignatureResult] = useState('');
    const [rubricResult, setRubricResult] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ name: '', jobRole: '', signatureResult: '', rubricResult: '', password: '' });
    let sigCanvas = useRef({});
    let rubCanvas = useRef({});

    const setNameOnChange = (event) => {
        setName(event.target.value)
    }

    const setJobRoleOnChange = (event) => {
        setJobRole(event.target.value)
    }

    const setSignatureOnChange = () => {
        const dataURL = sigCanvas.getTrimmedCanvas().toDataURL('image/png');
        setSignatureResult(dataURL);
    }

    const setRubricOnChange = () => {
        const dataURL = rubCanvas.getTrimmedCanvas().toDataURL('image/png');
        setRubricResult(dataURL);
    }

    const setPasswordOnChange = (event) => {
        setPassword(event.target.value)
    }

    function initData() {
        sigCanvas.clear();
        rubCanvas.clear();
        setName('');
        setJobRole('');
        setSignatureResult('');
        setRubricResult('');
        setPassword('');
        setErrors({ name: '', jobRole: '', signatureResult: '', rubricResult: '', password: '' });
    }

    const clear = () => {
        sigCanvas.clear();
        rubCanvas.clear();
        setName('');
        setJobRole('');
        setSignatureResult('');
        setRubricResult('');
        setPassword('');
        setErrors({ name: '', jobRole: '', signatureResult: '', rubricResult: '', password: '' });
    }

    const saveInput = () => {
        //onLoad();
        let pwd = password;
        if (jcmSignature) {
            pwd = profile.password;
        }

        const signatureValidate = { name: name, jobRole: jobRole, signatureResult: signatureResult, rubricResult: rubricResult, password: pwd };
        schema.validate(signatureValidate)
            .then(() => {
                onSave({ name: name, jobRole: jobRole, signatureResult: signatureResult, rubricResult: rubricResult, password: password })
            })
            .catch(error => {
                let nameError = '';
                let jobRoleError = '';
                let signatureResultError = '';
                let rubricResultError = '';
                let passwordError = '';

                switch (error.path) {
                    case 'name':
                        nameError = error.message;
                        break;
                    case 'jobRole':
                        jobRoleError = error.message;
                        break;
                    case 'signatureResult':
                        signatureResultError = error.message;
                        break;
                    case 'rubricResult':
                        rubricResultError = error.message;
                        break;
                    case 'password':
                        passwordError = error.message;
                        break;
                }
                setErrors({
                    name: nameError,
                    jobRole: jobRoleError,
                    signatureResult: signatureResultError,
                    rubricResult: rubricResultError,
                    password: passwordError
                });
            })
    };

    const schema = Yup.object({
        password: Yup.string('Insert your password')
            .typeError('Insert your password')
            .required('Insert your password'),
        rubricResult: Yup.string().required(t('messages:signature.initialsRequired')),
        signatureResult: Yup.string().required(t('messages:signature.signatureRequired')),
        jobRole: Yup.string().required(t('messages:signature.jobRoleRequired')),
        name: Yup.string().required(t('messages:signature.nameRequired')),
    });

    return (
        <Modal width={canvasProps.width + 40}
            title={t('screens:contracts.data.approve')}
            open={visible}
            onCancel={onClose}
            loading={loading}
            footer={
                <Footer>
                    <Space>
                        <Button color="default" onClick={clear}>Clear</Button>
                        <Button loading={loading} color="primary" onClick={saveInput}>Save</Button>
                    </Space>
                </Footer>
            }
        >
            <Spin spinning={loading}>
                <Row>
                    <FormControl
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="name"
                        label={'Name of person entering signature'}
                        error={errors.name}
                        required
                    >
                        <Input name="name" value={name} onChange={setNameOnChange} />
                    </FormControl>
                    <FormControl
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="jobRole"
                        label={'Job Role'}
                        error={errors.jobRole}
                        required
                    >
                        <Input name="jobRole" value={jobRole} onChange={setJobRoleOnChange} />
                    </FormControl>
                    <FormControl
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="signatureResult"
                        label={'Enter your signature'}
                        error={errors.signatureResult}
                        required
                    >
                        <div name="signatureResult" style={{ border: '1px solid #d9d9d9', borderRadius: '2px' }}>
                            <SignatureCanvas maxWidth={2.0} canvasProps={canvasProps} ref={(ref) => { sigCanvas = ref }} onEnd={setSignatureOnChange} />
                        </div>
                    </FormControl>

                    {signatureResult ?
                        <FormControl
                            cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                            field="signaturePreview"
                            label={'Signature preview'}
                        >
                            <Img alt='signature' src={signatureResult} />
                        </FormControl>
                        : null}

                    <FormControl
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="rubricResult"
                        label={t('screens:signature.enterYourInitials')}
                        error={errors.rubricResult}
                        required
                    >
                        <div name="rubricResult" style={{ border: '1px solid #d9d9d9', borderRadius: '2px' }}>
                            <SignatureCanvas maxWidth={2.0} canvasProps={canvasProps} ref={(ref) => { rubCanvas = ref }} onEnd={setRubricOnChange} />
                        </div>
                    </FormControl>

                    {rubricResult ?
                        <FormControl
                            cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                            field="rubricPreview"
                            label={t('screens:signature.initialsPreview')}
                        >
                            <Img alt='rubric' src={rubricResult} />
                        </FormControl>
                        : null}
                </Row>

                {jcmSignature ? null :
                    <Row>
                        <Paragraph strong>{t('screens:signature.title')}</Paragraph>
                        <FormControl
                            field="password"
                            label="Password"
                            error={errors.password}
                            required
                        >
                            <Input.Password
                                name="password"
                                placeholder={t('fields:login.password.placeholder')}
                                value={password}
                                onChange={setPasswordOnChange}
                            />
                        </FormControl>
                    </Row>
                }
            </Spin>
        </Modal>
    );
}

ModalSignatureCanvas.propTypes = {
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    onLoad: PropTypes.func,
    canvasProps: PropTypes.object,
    jcmSignature: PropTypes.bool,
}

ModalSignatureCanvas.defaultProps = {
    visible: false,
    loading: false,
    jcmSignature: false,
}