import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import EstimateDescriptionForm from './form';
import Filter from './filter';
import { formatPrice } from '~/Utils';
import { paginationEstimateDescriptions, deleteEstimateDescription } from '~/services/hooks/estimateDescriptions';
import { findAllLaborTypes } from '~/services/hooks/laborTypes';

export default function EstimateDescription() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [sortBy, setSortBy] = useState('description');
  const [sortDirection, setSortDirection] = useState('ASC');
  // const defaultFilter = { columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' };
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationEstimateDescriptions({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: filters, // [...filters, defaultFilter],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  useEffect(() => {
    findAllLaborTypes([]).then(data => {
      setLaborTypes(data);
    });
  }, []);

  const handleDelete = async id => {
    await deleteEstimateDescription(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:estimateDescription.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      // sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:estimateDescription.laborType'),
      dataIndex: 'laborType',
      key: 'laborType',
      align: 'left',
      // sorter: true,
      width: 250,
      ellipsis: {
        showTitle: true,
      },
      render : laborType => `${laborType.code} - ${laborType.name}`,
    },
    {
      title: t('screens:estimateDescription.unit'),
      dataIndex: 'unit',
      key: 'unit',
      align: 'left',
      // sorter: true,
      width: 150,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:estimateDescription.materialValue'),
      dataIndex: 'materialValue',
      key: 'materialValue',
      // sorter: true,
      align: 'left',
      width: 150,
      ellipsis: {
        showTitle: true,
      },
      render: materialValue => formatPrice(materialValue),
    },
    {
      title: t('screens:estimateDescription.laborValue'),
      dataIndex: 'laborValue',
      key: 'laborValue',
      // sorter: true,
      align: 'left',
      width: 150,
      ellipsis: {
        showTitle: true,
      },
      render: laborValue => formatPrice(laborValue),
    },
    {
      title: t('screens:estimateDescription.actions'),
      key: 'actions',
      align: 'center',
      width: 150,
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/">{t('menus:settings')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:estimateDescription')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  // const handleTableChange = (sorter) => {
  //   const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
  //   if (sorter.field != sortBy || sortOrder != sortDirection) {
  //     setSortBy(sorter.field);
  //     setSortDirection(sortOrder);
  //     fetchData(1);
  //   }
  // };

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:estimateDescription')} />
      <Filter laborTypes={laborTypes} isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:estimateDescription.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          // onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />

        <EstimateDescriptionForm
          selectedRecord={selectedRecord}
          laborTypes={laborTypes}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </DefaultLayout>
  );
}
