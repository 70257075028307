import React, { useState } from 'react';
import {
  DeleteOutlined,
  PaperClipOutlined,
  UploadOutlined,
  FilePdfOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Col, Divider, message, Modal, Select, Spin, Typography, Upload } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input } from 'formik-antd';
import FormTxt from '~/components/Form/FormTxt';
import { useTranslation } from 'react-i18next';
import errorHandler from 'Utils/errorHandler';
import * as Yup from 'yup';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import Items from './Items';
import { createChangeOrderClient, updateChangeOrderClient } from '~/services/hooks/changeOrdersClient';
import { uploadFiles, downloadFile } from '~/services/hooks/files';
import moment from 'moment';
import { format } from 'date-fns';

const { Text } = Typography;

const style = {
  divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px` },
};

export default function ChangeOrdersClientForm({
  selectedRecord,
  visible,
  approvalStatusList,
  project,
  customer,
  onCancel,
  onClose,
}) {
  const initialValues = {
    id: null,
    project: project,
    title: '',
    approvalStatus: 'PENDING_APPROVAL',
    approvalDeadline: moment().format('YYYY-MM-DD HH:mm:ss'),
    internalNotes: '',
    ownerNotes: '',
    description: '',
    priceInfo: {
      id: null,
      type: 'LINE_ITEMS',
      builderCost: 0,
      ownerPrice: 0,
      items: [],
    },
    attachments: [],
    customer: customer,
    // subcontractor: null,
  };

  // get initial values and replace with values that exist in selectedRecord
  const changeOrderClient = { ...initialValues, ...selectedRecord };
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadList, setUploadList] = useState([]);

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    const archivesArray = [];
    values.approvalDeadline = format(new Date(values.approvalDeadline), 'yyyy-MM-dd\'T\'HH:mm:ss');
    try {
      if (uploadList.length !== 0) {
        const data = await uploadFiles(uploadList);
        data.forEach(element => {
          const dataAttachment = {
            id: null,
            arquive: element,
          };
          archivesArray.push(dataAttachment);
        });
        values.attachments = archivesArray;
      }

      if (values.priceInfo.items.length === 0) {
        values.priceInfo.type = 'FLAT_FEE';
      } else {
        values.priceInfo.type = 'LINE_ITEMS';
      }

      if (values.id !== null) {
        await updateChangeOrderClient(values);
      } else {
        await createChangeOrderClient(values);
      }
      onClose();
    } catch (error) {
      const { response } = error;
      if (response && response.status === 422) {
        setErrors(response.data.errors);
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  // file upload functions
  const handleUpload = async event => {
    event.fileList.map(file => {
      var extension = '';
      var name = '';
      if (file.name.substring(file.name.length - 4) === 'jpeg') {
        extension = file.name.substring(file.name.length - 5);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 4);
      } else {
        extension = file.name.substring(file.name.length - 4);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 3);
      }
      file.name = name + extension;
    });
    setUploadList(event.fileList);
  };

  const beforeUpload = file => {
    const isValid =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
      setTimeout(function() {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 10MB!');
      setTimeout(function() {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    return isValid && isLt2M;
  };

  const handleRemoveUploadListItem = uid => {
    const data = uploadList.filter(item => item.uid !== uid);
    setUploadList(data);
  };
  // end file upload functions

  const changeOrderClientSchema = Yup.object().shape({
    title: Yup.string().required(),
    project: Yup.object().required(),
    approvalStatus: Yup.string().required(),
    approvalDeadline: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={changeOrderClient}
      onSubmit={handleSave}
      validationSchema={changeOrderClientSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          width="900px"
          title={values.id !== null ? t('screens:changeOrders.btnEdit') : t('screens:changeOrders.btnNew')}
          onCancel={onCancel}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onCancel}
              cancelColor="default"
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Text style={style.divider}>{t('screens:changeOrders.data.information')}</Text>
              </Row>
              <Row>
                <Col span={18}>
                  <FormControl
                    field="title"
                    label={t('screens:changeOrders.data.title')}
                    required
                    error={(touched.title && errors.title) || errors.title}
                  >
                    <Input name="title" />
                  </FormControl>
                </Col>
                <Col span={6}>
                  <FormControl
                    field="approvalDeadline"
                    label={t('screens:changeOrders.data.approvalDeadline')}
                    error={errors.approvalDeadline}
                  >
                    <DatePicker name="approvalDeadline" format="MM/DD/Y" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:changeOrders.data.description')}
                    error={(touched.description && errors.description) || errors.description}
                  >
                    <Input.TextArea showCount maxLength={250} name="description" />
                  </FormControl>
                </Col>
                {/* <Col span={6}>
                  <FormControl
                    error={errors.approvalStatus}
                    field="approvalStatus"
                    label={t('screens:changeOrders.data.approvalStatus')}
                  >
                    <Select
                      name="approvalStatus"
                      id="approvalStatus"
                      placeholder="Select"
                      value={approvalStatusList?.find(item => item.value === values.approvalStatus)?.value}
                      onChange={value =>
                        setFieldValue('approvalStatus', approvalStatusList.find(item => item.value === value).value)
                      }
                    >
                      {approvalStatusList?.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col> */}
              </Row>
              <Row>
                <Divider orientation="left" orientationMargin="0">
                  <Text>{t('screens:changeOrders.data.priceInformation')}</Text>
                </Divider>
              </Row>
              <Row>
                <Col span={24}>
                  <Items
                    items={values.priceInfo.items}
                    onHandleItems={e => {
                      setFieldValue('priceInfo.items', e); // setRecordData({ ...recordData, priceInfo: { ...recordData.priceInfo, items: e } });
                    }}
                  />
                </Col>
              </Row>
              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:changeOrders.data.attachments')}</Text>
              </Divider>
              <Row>
                <Col span={6}>
                  <FormControl field="file">
                    <Upload
                      name="file"
                      accept=".pdf"
                      multiple={true}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleUpload}
                      fileList={uploadList}
                      maxCount="5"
                    >
                      <Button color="default">
                        {'Upload file'}
                        <UploadOutlined />
                      </Button>
                    </Upload>
                  </FormControl>
                </Col>
              </Row>
              {uploadList.length > 0 ? (
                <Row>
                  <Col span={24}>
                    <FormControl>
                      {uploadList?.map(item => {
                        return (
                          <span key={item.uid}>
                            <PaperClipOutlined />
                            <Text>{item.name}</Text>
                            <DeleteOutlined
                              style={{ paddingLeft: '5px' }}
                              onClick={() => handleRemoveUploadListItem(item.uid)}
                            />
                          </span>
                        );
                      })}
                    </FormControl>
                  </Col>
                </Row>
              ) : null}

              {values.attachments.length > 0 ? (
                <>
                  <Divider orientation="left" orientationMargin="0" />
                  <Row>
                    <Col span={24}>
                      <FormTxt label={t('screens:changeOrders.lastFileSent')}>
                        <p>
                          {values.attachments.map(item => {
                            return (
                              <p key={item.arquive.id}>
                                <FilePdfOutlined /> {item.arquive.originalName}{' '}
                                <DownloadOutlined onClick={async () => await downloadFile(item)} />
                              </p>
                            );
                          })}
                        </p>
                      </FormTxt>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:changeOrders.data.notes')}</Text>
              </Divider>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="internalNotes"
                    label={t('screens:changeOrders.data.internalNotes')}
                    error={(touched.internalNotes && errors.internalNotes) || errors.internalNotes}
                  >
                    <Input.TextArea showCount maxLength={250} name="internalNotes" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="ownerNotes"
                    label={t('screens:changeOrders.data.ownerNotes')}
                    error={(touched.ownerNotes && errors.ownerNotes) || errors.ownerNotes}
                  >
                    <Input.TextArea showCount maxLength={250} name="ownerNotes" />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
