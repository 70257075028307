import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col, DatePicker } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();

  const handleSearch = values => {
    if (values.descriptionFilter === '0') {
      values.descriptionFilter = '';
    }
    const filter = [];
    try {
      if (
        values.descriptionFilter !== undefined &&
        values.descriptionFilter !== null &&
        values.descriptionFilter !== ''
      ) {
        filter.push({
          columnJoin: 'project',
          field: 'description',
          value: values.descriptionFilter,
          restriction: 'LIKE',
        });
      }

      if (values.sendDataInitial !== undefined && values.sendDataInitial !== null && values.sendDataInitial !== '') {
        filter.push({
          field: 'date',
          value: moment(values.sendDataInitial).format(dateFormat),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        });
      }
      if (values.sendDataFinal !== undefined && values.sendDataFinal !== null && values.sendDataFinal !== '') {
        filter.push({
          field: 'date',
          value: moment(values.sendDataFinal).format(dateFormat),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        });
      }

      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    <Box>
      <Formik
        initialValues={{
          descriptionFilter: '',
          sendDataInitial: null, // moment(new Date(), dateFormat).subtract(15, 'days'),
          sendDataFinal: null, // moment(new Date(), dateFormat),
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm, values, setFieldValue, submitForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span="6">
                  <FormControl
                    error={errors.descriptionFilter}
                    field="descriptionFilter"
                    label={t('screens:contracts.data.project')}
                  >
                    <Input name="descriptionFilter" />
                  </FormControl>
                </Col>
                <Col span="6">
                  <FormControl field="sendDateFilter" label={t('screens:contracts.data.date')}>
                    <RangePicker
                      defaultValue={[
                        values.sendDataInitial,
                        values.sendDataFinal,
                      ]}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        setFieldValue('sendDataInitial', dateString[0]);
                        setFieldValue('sendDataFinal', dateString[1]);
                      }}
                    />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
