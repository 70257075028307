import React from 'react';
import {
  CameraOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  ContainerOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentFilled,
  Loading3QuartersOutlined,
  MailFilled,
  PhoneFilled,
  SettingOutlined,
  ShopOutlined,
  TagOutlined,
  ToolOutlined,
  DeleteOutlined,
  UpOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Button as AntButton, Checkbox, Col, Divider, List, Switch, Tag, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import CommentsForm from './commentsForm';
import TaskForm from './form';
import PhotosForm from './photosForm';
import moment from 'moment';
import { priorities } from '~/Utils/data/jsonData';
import { TableTask } from './styles';
import Pagination from '~/components/Pagination';
import { paginationTasks, deleteTask, updateTask } from '~/services/hooks/tasks';
import { findAllUsers } from '~/services/hooks/users';
import { findAllTags } from '~/services/hooks/tags';
import Filter from './filter';
import UsersInfo from '~/components/UsersInfo';

export default function Tasks({ project, customer }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [showCommentsForm, setShowCommentsForm] = useState(false);
  const [showPhotosForm, setShowPhotosForm] = useState(false);
  const sortBy = 'date';
  const sortDirection = 'ASC';
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState();
  // const [completedTasks, setCompletedTasks] = useState(0);
  const [filters, setFilters] = useState([
    { field: 'complete', value: false, restriction: 'EQUAL' },
  ]);
  const [showItemsDetails, setShowItemsDetails] = useState([]);
  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    findAllUsers().then(response => {
      setUsers(response);
    });
    findAllTags().then(response => {
      setTags(response);
    });
  }, []);

  const fetchRecords = async page => {
    setLoading(true);
    const params = {
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [...filters, { columnJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' }],
    };

    const data = await paginationTasks(params);
    setTableData(data.content);
    setMeta(data.content.meta);
    setLoading(false);
  };

  const handleItemsDetails = id => {
    const newShowItemsDetails = [...showItemsDetails];
    if (newShowItemsDetails.includes(id)) {
      const index = newShowItemsDetails.indexOf(id);
      if (index > -1) {
        newShowItemsDetails.splice(index, 1);
      }
    } else {
      newShowItemsDetails.push(id);
    }
    setShowItemsDetails(newShowItemsDetails);
  };

  const handleEdit = item => {
    setSelectedRecord(item);
    setShowForm(true);
  };

  const handlePhotos = item => {
    setSelectedRecord(item);
    setShowPhotosForm(true);
  };

  const handleShowComments = item => {
    setSelectedRecord(item);
    setShowCommentsForm(true);
  };

  const handleDelete = async id => {
    await deleteTask(id).then(() => {
      fetchRecords(1);
    });
  };

  useEffect(() => {
    fetchRecords(1);
  }, [filters]);

  const avatarsInfo = users => {
    if (users !== undefined && users !== null && users.length > 0) {
      return <UsersInfo users={users} />;
    }
    return <>Not assigned</>;
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const handleSave = async values => {
    setLoading(true);
    try {
      await updateTask(values);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Filter
        // completedTasks={completedTasks}
        users={users}
        priorities={priorities}
        tags={tags}
        setSelectedRecord={setSelectedRecord}
        setShowForm={setShowForm}
        isLoading={loading}
        setFilters={setFilters}
      />
      <Box>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={tableData}
          // START LIST OF TASKS ITEMS
          renderItem={item => (
            <List.Item
              align="end"
              actions={[
                <div style={{ width: '100%', textAlign: 'start' }}>
                  {showItemsDetails.filter(x => x === item.id).length > 0 ? (
                    <AntButton type="text" icon={<UpOutlined />} onClick={() => handleItemsDetails(item.id)}>
                      SHOW LESS DETAILS
                    </AntButton>
                  ) : (
                    <AntButton type="text" icon={<DownOutlined />} onClick={() => handleItemsDetails(item.id)}>
                      SHOW MORE DETAILS{' '}
                    </AntButton>
                  )}
                </div>,
                <AntButton type="default" icon={<CameraOutlined />} onClick={() => handlePhotos(item)} />,
                <AntButton type="default" icon={<EditOutlined />} onClick={() => handleEdit(item)} />,
                <Popconfirm
                  icon={false}
                  title={t('messages:confirmDelete')}
                  onConfirm={() => handleDelete(item.id)}
                  okText={t('messages:yes')}
                  cancelText={t('messages:no')}
                >
                  <AntButton type="default" title={t('messages:delete')} icon={<DeleteOutlined />} />
                </Popconfirm>,
              ]}
            >
              <div
                style={
                  item.complete
                    ? { backgroundColor: '#FA8C16', paddingLeft: 3 }
                    : { backgroundColor: 'gray', paddingLeft: 3 }
                }
              >
                <div style={{ paddingLeft: 15, backgroundColor: 'white' }}>
                  <Row>
                    <Col span={24}>
                      <div style={{ whiteSpace: 'nowrap', padding: 0, margin: 0 }}>
                        {/* TASK AVATAR */}
                        <div style={{ display: 'inline-block', width: '64px', verticalAlign: 'top' }}>
                          {item.complete ? (
                            <Avatar
                              style={{ backgroundColor: '#f56a00' }}
                              shape="square"
                              alt="task complete"
                              size={64}
                              icon={<ClockCircleOutlined />}
                            />
                          ) : (
                            <Avatar
                              style={
                                item.priority === 'HIGH'
                                  ? { backgroundColor: '#f56a6a' }
                                  : { backgroundColor: '#666666' }
                              }
                              shape="square"
                              alt="task complete"
                              size={64}
                              icon={
                                item.priority === 'HIGH' ? <ExclamationCircleOutlined /> : <Loading3QuartersOutlined />
                              }
                            />
                          )}
                        </div>
                        {/* TASK DATE, TASK START TIME, PRIORITY, COMPLETE */}
                        <div style={{ display: 'inline-block', width: 'calc(100% - 64px)' }}>
                          <TableTask>
                            <tbody>
                              <tr>
                                <td style={{ width: '14%' }} className="title">
                                  TASK DATE
                                </td>
                                <td style={{ width: '14%' }} className="title">
                                  TASK START TIME
                                </td>
                                <td style={{ width: '14%' }} className="title">
                                  PRIORITY
                                </td>
                                <td style={{ textAlign: 'end', width: '58%' }}>
                                  <Checkbox
                                    checked={item.complete}
                                    onChange={
                                      // COMPLETE TASK
                                      e => {
                                        const newTableData = tableData.map(x => {
                                          if (x.id === item.id) {
                                            return {
                                              ...x,
                                              complete: e.target.checked,
                                            };
                                          }
                                          return x;
                                        });
                                        handleSave(newTableData.find(x => x.id === item.id));
                                        // const count = completedTasks + (e.target.checked ? 1 : -1);
                                        // setCompletedTasks(count);
                                        setTableData(newTableData);
                                      }
                                    }
                                  >
                                    Complete
                                  </Checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td className="principal">
                                  {moment(item.startDate).format('MMM DD') // FORMAT DATE TO MONTH DAY
                                  }
                                </td>
                                <td className="principal">
                                  {moment(item.startDate).format('hh:mm A') // FORMAT DATE TO HOUR MINUTE AM/PM
                                  }
                                </td>
                                <td>{item.priority}</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </TableTask>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row justify="start" align="middle">
                    <Col span={24} align="left" style={{ height: '40px', padding: '10px 10px' }}>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {item.complete ? <SettingOutlined /> : <ToolOutlined />}&nbsp;{item.title}
                      </span>
                    </Col>
                  </Row>
                  {/* TASK DETAILS: PROJECT, CONTACT, TASK DAY */}
                  <Row>
                    <Col span={24}>
                      <TableTask>
                        <thead>
                          <tr>
                            <th colSpan="5" className="title">
                              PROJECT
                            </th>
                            <th colSpan="5" className="title">
                              CONTACT
                            </th>
                            <th className="title">TASK DAY</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="icon">
                              <UserOutlined />
                            </td>
                            <td className="icon-text">{`${item?.project?.ownership?.name}`}</td>
                            <td className="icon">
                              <TagOutlined />
                            </td>
                            <td className="icon-text">Referral</td>
                            <td></td>
                            <td className="icon">
                              <PhoneFilled style={{ color: '#ffbf00' }} />
                            </td>
                            <td className="icon-text">{item?.project?.ownership?.representativePhone}</td>
                            <td className="icon">
                              <MailFilled style={{ color: '#ffbf00' }} />
                            </td>
                            <td className="icon-text">{item?.project?.ownership?.representativeEmail}</td>
                            <td></td>
                            <td>{item.day}</td>
                          </tr>
                          <tr>
                            <td className="icon">
                              <ShopOutlined />
                            </td>
                            <td className="icon-text">{item.project.description}</td>
                            <td className="icon">
                              <ContainerOutlined />
                            </td>
                            <td className="icon-text">Active</td>
                            <td></td>
                            <td className="icon">
                              <EnvironmentFilled style={{ color: '#ffbf00' }} />
                            </td>
                            <td
                              colSpan="3"
                              className="icon-text"
                            >{`${item?.project?.ownership?.address?.addressLineOne}, ${item?.project?.ownership?.address?.city}, ${item?.project?.ownership?.address?.state}, ${item?.project?.ownership?.address?.zipCode}, ${item?.project?.ownership?.address?.country}`}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </TableTask>
                    </Col>
                  </Row>
                  {/* TASK MORE DETAILS: DURATION, PROJECT MANAGERS, ESTIMATORS, ASSIGNED WORKERS, COMMENTS, STEPS */}
                  {showItemsDetails.filter(x => x === item.id).length > 0 ? (
                    <Row>
                      <Divider />
                      <Col span={24}>
                        <TableTask style={{ width: '100%', border: 1 }}>
                          <thead>
                            <tr>
                              <th className="title">DURATION</th>
                              <th className="title">PROJECT MANAGERS</th>
                              <th className="title">ESTIMATORS</th>
                              <th className="title">ASSIGINED WORKERS</th>
                              <th className="title">COMMENTS</th>
                              <th className="title">STEPS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Tag>{item.duration}</Tag>
                              </td>
                              <td>
                                {avatarsInfo(
                                  item.assignments.filter(user => user.roles.includes('ROLE_PROJECT_MANAGER'))
                                )}
                              </td>
                              <td>
                                {avatarsInfo(
                                  item.assignments.filter(user => user.roles.includes('ROLE_PROJECT_ESTIMATOR'))
                                )}
                              </td>
                              <td>
                                {avatarsInfo(
                                  item.assignments.filter(
                                    user =>
                                      !user.roles.includes('ROLE_PROJECT_MANAGER') &&
                                      !user.roles.includes('ROLE_PROJECT_ESTIMATOR')
                                  )
                                )}
                              </td>
                              <td>
                                <span
                                  style={{ whiteSpace: 'nowrap', color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => handleShowComments(item)}
                                >
                                  <CommentOutlined />{' '}
                                  {item.comments.length > 0 ? `See ${item.comments.length} comments` : `No comments`}
                                </span>
                              </td>
                              <td rowSpan="4">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Checkbox
                                          checked={item.takeBeforePicture}
                                          onChange={e => {
                                            const newTableData = tableData.map(x => {
                                              if (x.id === item.id) {
                                                return { ...x, takeBeforePicture: e.target.checked };
                                              }
                                              return x;
                                            });
                                            handleSave(newTableData.find(x => x.id === item.id));
                                            setTableData(newTableData);
                                          }}
                                        ></Checkbox>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                          &nbsp;
                                          {t('screens:task.data.takeBeforePicture')}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Checkbox
                                          checked={item.completeWork}
                                          onChange={e => {
                                            const newTableData = tableData.map(x => {
                                              if (x.id === item.id) {
                                                return { ...x, completeWork: e.target.checked };
                                              }
                                              return x;
                                            });
                                            handleSave(newTableData.find(x => x.id === item.id));
                                            setTableData(newTableData);
                                          }}
                                        ></Checkbox>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                          &nbsp;{t('screens:task.data.completeWork')}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Checkbox
                                          checked={item.takeAfterPicture}
                                          onChange={e => {
                                            const newTableData = tableData.map(x => {
                                              if (x.id === item.id) {
                                                return { ...x, takeAfterPicture: e.target.checked };
                                              }
                                              return x;
                                            });
                                            handleSave(newTableData.find(x => x.id === item.id));
                                            setTableData(newTableData);
                                          }}
                                        ></Checkbox>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                          &nbsp;
                                          {t('screens:task.data.takeAfterPicture')}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                          {t('screens:task.data.allSteps')}&nbsp;
                                        </span>
                                        <Switch
                                          onChange={checked => {
                                            const newTableData = tableData.map(x => {
                                              if (x.id === item.id) {
                                                return {
                                                  ...x,
                                                  takeAfterPicture: checked,
                                                  takeBeforePicture: checked,
                                                  completeWork: checked,
                                                };
                                              }
                                              return x;
                                            });
                                            handleSave(newTableData.find(x => x.id === item.id));
                                            setTableData(newTableData);
                                          }}
                                          checked={item.takeBeforePicture && item.completeWork && item.takeAfterPicture}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="5" rowSpan="3" style={{ padding: 0, margin: 0 }}>
                                <TableTask>
                                  <thead>
                                    <tr>
                                      <th className="title">NOTES</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="notes">{item.notes}</td>
                                    </tr>
                                  </tbody>
                                </TableTask>
                              </td>
                            </tr>
                          </tbody>
                        </TableTask>
                      </Col>
                    </Row>
                  ) : null}
                </div>{' '}
              </div>
            </List.Item>
          )}
          Pagination={false}
        />
        <Pagination
          onChange={value => {
            fetchRecords(value);
          }}
          meta={meta}
        />
        {selectedRecord && (
          <>
            <CommentsForm
              item={selectedRecord}
              visible={showCommentsForm}
              onClose={() => setShowCommentsForm(false)}
              onUpdateItem={item => onUpdateItem(item)}
            />
            <PhotosForm
              item={selectedRecord}
              visible={showPhotosForm}
              onClose={() => setShowPhotosForm(false)}
              onUpdateItem={item => onUpdateItem(item)}
            />
          </>
        )}
        <TaskForm
          item={selectedRecord}
          visible={showForm}
          priorities={priorities}
          users={users}
          tags={tags}
          project={project}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </>
  );
}
