import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

// GET ALL COUNTRIES
export const findAllCountries = async () => {
  try {
    const { data } = await api.get('/addresses/findAllCountries');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};