import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { message, Modal, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { primaryContactSchema } from '../validationSchemas';

const initialValues = {
  id: null,
  firstName: '',
  lastName: '',
  jobTitle: '',
  mobilePhone: '',
  mainPhone: '',
  workPhone: '',
  mainEmail: '',
  ccEmail: '',
};

export default function ContactForm({ visible, onClose, onChange, contact }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);
    setRecordData(contact === null ? initialValues : contact);
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    try {
      const c = {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        mobilePhone: values.mobilePhone,
        mainPhone: values.mainPhone,
        workPhone: values.workPhone,
        mainEmail: values.mainEmail,
        ccEmail: values.ccEmail,
      };

      if (c.id === null) {
        const { data } = await api.post('/contacts/create', c);
        message.success(t('messages:contactSuccess'));
        onChange(data);
      } else {
        const { data } = await api.put('/contacts/update', c);
        message.success(t('messages:contactSuccessEdit'));
        onChange(data);
      }

      onClose();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const name = Yup.string()
    .min(2, 'Too Short!')
    .max(150, 'Too Long!')
    .required();

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={primaryContactSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Modal
          title={contact !== null ? t('screens:contacts.btnEdit') : t('screens:contacts.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={12}>
                  <FormControl
                    field="firstName"
                    label={t('screens:leads.data.firstName')}
                    error={touched.firstName && errors.firstName}
                    required
                  >
                    <Input name="firstName" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="lastName"
                    label={t('screens:leads.data.lastName')}
                    error={touched.lastName && errors.lastName}
                    required
                  >
                    <Input name="lastName" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="jobTitle"
                    label={t('screens:leads.data.jobTitle')}
                    error={touched.jobTitle && errors.jobTitle}
                    required
                  >
                    <Input name="jobTitle" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="mobilePhone"
                    label={t('screens:leads.data.mobilePhone')}
                    error={touched.mobilePhone && errors.mobilePhone}
                  >
                    <Input name="mobilePhone" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="mainPhone"
                    label={t('screens:leads.data.mainPhone')}
                    error={touched.mainPhone && errors.mainPhone}
                  >
                    <Input name="mainPhone" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="workPhone"
                    label={t('screens:leads.data.workPhone')}
                    error={touched.workPhone && errors.workPhone}
                  >
                    <Input name="workPhone" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="mainEmail"
                    label={t('screens:leads.data.mainEmail')}
                    error={touched.mainEmail && errors.mainEmail}
                  >
                    <Input type="email" name="mainEmail" autoComplete="off" />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    field="ccEmail"
                    label={t('screens:leads.data.ccEmail')}
                    error={touched.ccEmail && errors.ccEmail}
                  >
                    <Input type="email" name="ccEmail" />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
