import styled from 'styled-components';
import { Divider, Typography, Input } from "antd";

const { Text } = Typography;
const { TextArea } = Input;

export const ImageCustom = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    width: 40%;
`;

export const TextSm = styled(Text)`
    font-size: 11px;
    font-family: Calibri, sans-serif;
`;

export const TextMd = styled(Text)`
    font-size: 16px;
    font-family: Corbel, sans-serif;
`;

export const PCustom = styled.p`
    margin-bottom: 0px;
`;

export const Container = styled.div`
    text-align: center;
`;

export const InputCustom = styled(Input)`
    background-color: #c0c0c0;
`;

export const InputCustomMd = styled(Input)`
    background-color: #c0c0c0;
    font-weight: 600;
    font-size: 16px;
    font-family: Corbel, sans-serif;
`;

export const TextAreaCustom = styled(TextArea)`
    background-color: #c0c0c0;
`;

export const DividerCustom = styled(Divider)`
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.85);
    margin: 20px 0px 0px 0px;
`;

export const Scope = styled(TextArea)`
    width: 100%;
    font-Weight: 600;
    font-Style: italic;
    border: 0px;
`;