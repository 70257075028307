import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Switch, Input, Select } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import Button from '~/components/Button';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { addressSchema } from '../validationSchemas';

export default function AddressForm({ visible, onClose, onAddNewAddress, onUpdateAddress, address, type, addresses }) {
  const { t } = useTranslation();
  const initialValues = {
    id: null,
    type: type,
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    country: 'UNITED_STATES_AMERICA',
    zipCode: '',
    main: false,
    note: ''
  };
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const { Option } = Select;

  // verify if addresses is not null and if has a billing main address if exists return billing main address
  const receivedBillingMainAddress = addresses.find(address => address.type === 'BILLING' && address.main === true) || null;
  const [billingMainAddress, setBillingMainAddress] = useState(receivedBillingMainAddress);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/addresses/findAllCountries');
      setCountries(data);

      if (address === null) {
        setRecordData(initialValues);
      } else {
        setRecordData(address);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  // fill form with billing main address data
  const handleCopyBillingAddress = () => {
    setRecordData({
      ...recordData,
      addressLineOne: billingMainAddress.addressLineOne,
      addressLineTwo: billingMainAddress.addressLineTwo,
      city: billingMainAddress.city,
      state: billingMainAddress.state,
      country: billingMainAddress.country,
      zipCode: billingMainAddress.zipCode,
    });
  };


  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const newAddress = {
        id: values.id,
        type: values.type,
        addressLineOne: values.addressLineOne,
        addressLineTwo: values.addressLineTwo,
        city: values.city,
        state: values.state,
        country: values.country,
        zipCode: values.zipCode,
        main: values.main,
        note: values.note
      };

      if (address === null) {
        onAddNewAddress(newAddress);
        message.success(t('messages:addressSuccess'));
      } else {
        onUpdateAddress(address, newAddress);
        message.success(t('messages:addressSuccessEdit'));
      }
      onClose();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const addressTypes = [
    {
      id: 1,
      code: 'BILLING',
      description: t('screens:addresses.data.types.billing'),
    },
    {
      id: 2,
      code: 'SHIPPING',
      description: t('screens:addresses.data.types.shipping'),
    },
  ];

  const formatTitle = (isNew, type, main) => {
    return `${isNew ? 'New ' : 'Edit'} ${type === 'BILLING' ? t('screens:addresses.data.types.billing') : t('screens:addresses.data.types.shipping')} Address ${main ? '(Main)' : ''}`;
  };

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={addressSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values }) => (
        <Modal
          title= {formatTitle(address === null, values.type, values.main)} // {address !== null ? t('screens:addresses.btnEdit') : t('screens:addresses.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                {/* <FormControl
                  cols={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                  error={errors.type}
                  field="type"
                  label={t('screens:addresses.data.type')}
                  required
                >
                  <Select name="type" disabled="true" placeholder="Select">
                    {addressTypes.map(item => {
                      return (
                        <Option key={item.id} value={item.code}>{item.description}</Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  field="main"
                  label={t('screens:addresses.data.main')}
                  style={{ margin: 'auto' }}
                >
                  <Switch
                    name="main"
                    disabled="true"
                    checkedChildren={t('messages:yes')}
                    unCheckedChildren={t('messages:no')}
                    style={{ margin: 'auto' }}
                  />


                </FormControl> */}

                {values.type === 'SHIPPING' && receivedBillingMainAddress && (
                  <FormControl
                    cols={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                    field="copy"
                    label=""
                    style={{ margin: 'auto' }}
                  >
                    {/* make the button size the same as the label text */}
                    <Button onClick={handleCopyBillingAddress} style={{ width: '200 px' }}>
                      {t('screens:addresses.data.copyBillingAddress')}</Button>
                  </FormControl>
                )}

                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.addressLineOne && errors.addressLineOne}
                  field="addressLineOne"
                  label={t('screens:addresses.data.addressLineOne')}
                  required
                >
                  <Input name="addressLineOne" autoComplete="off" maxLength={40} />
                </FormControl>
                <FormControl
                  cols={{ xs: 24 }}
                  field="addressLineTwo"
                  label={t('screens:addresses.data.addressLineTwo')}
                >
                  <Input name="addressLineTwo" autoComplete="off" maxLength={40} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  error={touched.city && errors.city}
                  field="city"
                  label={t('screens:addresses.data.city')}
                  required
                >
                  <Input name="city" autoComplete="off" maxLength={16} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  error={touched.state && errors.state}
                  field="state"
                  label={t('screens:addresses.data.state')}
                  required
                >
                  <Input name="state" autoComplete="off" maxLength={21} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  field="zipCode"
                  label={t('screens:addresses.data.zipCode')}
                >
                  <Input name="zipCode" autoComplete="off" maxLength={13} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  error={touched.country && errors.country}
                  field="country"
                  label={t('screens:addresses.data.country')}
                  required
                >
                  <Select name="country" placeholder="Select">
                    {countries.map(item => {
                      return (
                        <Option key={item.name} value={item.name}>{item.description}</Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                  field="note"
                  error={(touched.note && errors.note) || errors.note}
                  label={t('screens:addresses.data.note')}
                >
                  <Input name="note" autoComplete="off" maxLength={41} />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
