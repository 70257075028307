import React from 'react';
import { SearchOutlined, TrophyOutlined } from '@ant-design/icons';
import { Spin, Col, DatePicker, Radio, Switch } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import moment from 'moment';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';


export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();

  const handleSearch = values => {
    if (values.descriptionFilter === '0') {
      values.descriptionFilter = '';
    }
    const filter = [];
    try {
      if (
        values.descriptionFilter !== undefined &&
        values.descriptionFilter !== null &&
        values.descriptionFilter !== ''
      ) {
        filter.push({
          columnJoin: 'project',
          field: 'description',
          value: values.descriptionFilter,
          restriction: 'LIKE',
        });
      }
      if (values.sendDataInitial !== undefined && values.sendDataInitial !== null && values.sendDataInitial !== '') {
        filter.push({
          field: 'sent',
          value: moment(values.sendDataInitial).format(dateFormat),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        });
      }
      if (values.sendDataFinal !== undefined && values.sendDataFinal !== null && values.sendDataFinal !== '') {
        filter.push({
          field: 'sent',
          value: moment(values.sendDataFinal).format(dateFormat),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        });
      }
      if (
        values.winnerFilter !== undefined &&
        values.winnerFilter !== null &&
        values.winnerFilter !== '' &&
        values.winnerFilter !== 'All'
      ) {
        filter.push({
          field: 'winner',
          value: [values.winnerFilter === 'winner'],
          restriction: 'IN',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    <Box>
      <Formik
        initialValues={{
          descriptionFilter: '',
          sendDataInitial: null, // moment(new Date(), dateFormat).subtract(15, 'days'),
          sendDataFinal: null, // moment(new Date(), dateFormat),
          winnerFilter: 'All',
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm, values, setFieldValue, submitForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span="6">
                  <FormControl
                    error={errors.descriptionFilter}
                    field="descriptionFilter"
                    label={t('screens:estimatesQuotation.projectDescription')}
                  >
                    <Input name="descriptionFilter" placeholder={t('fields:estimatesQuotation.placeholder')} />
                  </FormControl>
                </Col>
                <Col span="4">
                  <FormControl field="sendDateFilter" label={t('screens:estimatesQuotation.dateRange')}>
                    <RangePicker
                      // set default value equal to today date minus 15 days and today date
                      defaultValue={[
                        values.sendDataInitial, // moment(new Date(), dateFormat).subtract(15, 'days'),
                        values.sendDataFinal, // moment(new Date(), dateFormat),
                      ]}
                      disabledDate={current => current && current > moment().endOf('day')}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        setFieldValue('sendDataInitial', dateString[0]);
                        setFieldValue('sendDataFinal', dateString[1]);
                      }}
                    />
                  </FormControl>
                </Col>
                <Col span="4">
                  {/* radio button for "All" (expired and active) and "Active" (not expired) */}
                  <FormControl field="winnerFilter" label={t('screens:estimatesQuotation.winnerOrAll')}>
                    <Switch
                      name="winnerFilter"
                      checked={values.winnerFilter === 'winner'}
                      style={{backgroundColor: values.winnerFilter === 'winner' ? 'green' : '#cccccc'}}
                      checkedChildren={<><TrophyOutlined/> {t('screens:estimatesQuotation.winner')}</>}
                      unCheckedChildren={t('screens:estimatesQuotation.all')}
                      onChange={checked => {
                        setFieldValue('winnerFilter', checked ? 'winner' : 'All');
                        submitForm();
                      }}
                    />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
