import { Menu } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const AntMenu = styled(Menu)`
  display: block;
  height: 100%;
  border-right: 0;
  text-align: right;
  line-height: 60px;
`;

export const CustomAvatar = styled.ul`
  li {
    display: inline-block;
  }
`;

export const MenuGlobalStyle = createGlobalStyle`style={{marginLeft: '0px'}}
  
  .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    margin-right: 10px;
  }

  #btnLogout,
  #btnProfile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;

    &:hover {
      background-color: transparent;
    }

    svg {
      &:first-child {
        margin-left: 0px;
      }
    }

    span.btn-text  {
      color: rgba(0,0,0,.85);
  
      span {
        &:first-child {
          margin-left: 0px;
          margin-right: 14px;
        }
      }
    }
  }

  #headerMenu {
    background: white;
  }

  .ant-menu-item > button  {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;

    &:hover {
      background-color: transparent;
    }

    svg {
      &:first-child {
        margin-left: 0px;
      }
    }
  }
`;