import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';
import Login from '~/pages/Login';
import Remember from '~/pages/Password/Remember.js';
import Reset from '~/pages/Password/Reset.js';
import Dashboard from '~/pages/Dashboard';
import ProjectsList from '~/pages/Dashboard/ProjectsList';

import Users from '~/pages/Users';
import Customers from '~/pages/Customers';

import Clients from '~/pages/Clients';
import ClientView from '~/pages/Clients/ClientView/view';
import ProjectListNew from '~/pages/ProjectsList';
import ClientProjects from '~/pages/ClientProjects';
import ClientProjectDetails from '~/pages/ClientProjects/ProjectDetails';

import Bids from '~/pages/Bids';
import BidView from '~/pages/Bids/BidView/view';
import BidsList from '~/pages/BidsList';

import SubContractors from '~/pages/SubContractors';
import SubContractorQuotations from '~/pages/SubContractorQuotations';
import SubContractorContracts from '~/pages/SubContractorContracts';
import SubContractorCloseOuts from '~/pages/SubContractorCloseOuts';

import PurchaseOrders from '~/pages/PurchaseOrders';
import Selections from '~/pages/Selections';
import Estimate from '~/pages/Estimate';
import PoPayments from '~/pages/PoPayments';
import Budget from '~/pages/Budget';
import OwnerInvoices from '~/pages/OwnerInvoices';
import Review from '~/pages/Review';
import EntireReview from '~/pages/EntireReview';
import Settings from '~/pages/SystemConfig';
import Notifications from '~/pages/Notifications';
import AuditLog from '~/pages/AuditLog';
import e404 from '~/pages/Errors/e404.js';
import CustomerView from '~/pages/Customers/CustomerView/view';
import ProjectDetails from '~/pages/ProjectDetails';
import Costs from '~/pages/ProjectDetails/Accounting/Workbook/Costs';
import BidDetails from '~/pages/BidDetails';
import RequestForQuotation from '~/pages/SubContractorQuotations/RequestForQuotation';
import EstimateDescriptions from '~/pages/EstimateDescriptions';
import LaborTypes from '~/pages/LaborTypes';
import Areas from '~/pages/Areas';
import Confirmation from '~/pages/BidDetails/Confirmation';


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/password/link" component={Remember} />
      <Route exact path="/password/reset/:token" component={Reset} />

      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/project-list" component={ProjectsList} isPrivate />
      <Route exact path="/users" component={Users} isPrivate />
      <Route exact path="/customers" component={Customers} isPrivate />
      <Route exact path="/customers/:id" component={CustomerView} isPrivate />

      {/* CLIENTS */}
      <Route exact path="/clients" component={Clients} isPrivate />
      <Route exact path="/clients/:id" component={ClientView} isPrivate />
      <Route exact path="/projects-list" component={ProjectListNew} isPrivate />
      <Route exact path="/project-details" component={ProjectDetails} isPrivate />
      <Route exact path="/project-costs" component={Costs} isPrivate />

      {/* BIDS */}
      <Route exact path="/bids" component={Bids} isPrivate />
      <Route exact path="/bids/:id" component={BidView} isPrivate />
      <Route exact path="/bids-list" component={BidsList} isPrivate />
      <Route exact path="/bid-details" component={BidDetails} isPrivate />
      <Route exact path="/bid-confirmation" component={Confirmation} isPrivate />

      {/* SUBCONTRACTORS AND VENDORS QUOTATIONS */}
      <Route exact path="/request-for-quotation" component={RequestForQuotation} isPrivate />
      <Route exact path="/subcontractor-quotations" component={SubContractorQuotations} isPrivate />
      <Route exact path="/estimate-descriptions" component={EstimateDescriptions} isPrivate />
      <Route exact path="/labor-types" component={LaborTypes} isPrivate />
      <Route exact path="/areas" component={Areas} isPrivate />
      <Route exact path="/subcontractor-contracts" component={SubContractorContracts} isPrivate />

      {/* SCREEN OF CLIENT */}
      <Route exact path="/client-projects" component={ClientProjects} isPrivate />
      <Route exact path="/client-project-details" component={ClientProjectDetails} isPrivate />
      <Route exact path="/subcontractor-closeouts" component={SubContractorCloseOuts} isPrivate />

      <Route exact path="/subcontractor" component={SubContractors} isPrivate />
      <Route exact path="/purchase-orders" component={PurchaseOrders} isPrivate />
      <Route exact path='/selections' component={Selections} isPrivate />
      <Route exact path="/estimate" component={Estimate} isPrivate />

      {/* <Route exact path="/bills-pos" component={BillsPOs} isPrivate /> */}
      <Route exact path="/po-payments" component={PoPayments} isPrivate />
      <Route exact path="/budget" component={Budget} isPrivate />
      <Route exact path="/owner-invoices" component={OwnerInvoices} isPrivate />
      <Route exact path="/payment-review" component={Review} isPrivate />
      <Route exact path="/release-review" component={EntireReview} isPrivate />
      <Route exact path="/settings" component={Settings} isPrivate />
      <Route exact path="/notifications" component={Notifications} isPrivate />
      <Route exact path="/audit-log" component={AuditLog} isPrivate />

      {/* ERROR */}
      <Route exact path="/404" component={e404} isFree />
      <Redirect to="/404" />
    </Switch>
  );
}
