import React, { useEffect, useState } from 'react';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Divider, message, Modal, Select, Spin, Typography, Upload } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import errorHandler from 'Utils/errorHandler';
import * as Yup from 'yup';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import moment from 'moment';
import { updateAllInvoice, updateInvoice } from '~/services/hooks/invoices';

export default function PaymentForm({ selectedInvoices, visible, onClose, onUpdateItem }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const recordData = {paymentDate: null};

  const handleSave = async (values) => {
    setLoading(true);

    let date = moment(values.paymentDate).format('YYYY-MM-DD HH:mm:ss');
    selectedInvoices.map(invoice => {
      invoice.paymentDate = date;
    });

    const data = await updateAllInvoice(selectedInvoices);
    onUpdateItem(data);
    onClose();
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {

    }
  }, [visible]);

  const itemSchema = Yup.object().shape({
    paymentDate: Yup.date().required(),
  });

  return (
    <Formik enableReinitialize initialValues={recordData} onSubmit={handleSave} validationSchema={itemSchema}>
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={t('screens:workbook.subcontractor.payments.title')}
          width="600px"
          description={
            selectedInvoices !== null
              ? t('screens:workbook.subcontractor.payments.btnEdit')
              : t('screens:workbook.subcontractor.payments.btnNew')
          }
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  field="paymentDate"
                  label={t('screens:workbook.receipts.data.paymentDate')}
                  error={errors.paymentDate}
                >
                  <DatePicker name="paymentDate" format="YYYY-MM-DD 00:00:00" placeholder={t('messages:select')} />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
