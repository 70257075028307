import React from 'react';
import DefaultLayout from '~/pages/_layouts/full';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Badge, Button, Result } from 'antd';
import BoxFill from '~/components/BoxFill';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import history from '~/services/history';

export default function Confirmation(data) {

  const customer = data.location.state?.customer;
  const project = data.location.state?.data;
  const flag = data.location.state?.flag;
  const { t } = useTranslation();

  const style = {
    pageTitle: { color: '#0f4c81', fontSize: '20px' },
    divider: { margin: '0px' },
    status: {
      new: { backgroundColor: '#1890FF' },
      open: { backgroundColor: '#13C2C2' },
      inProgress: { backgroundColor: '#A0D911' },
      completed: { backgroundColor: '#FAAD14' },
      onHold: { backgroundColor: '#F5222D' },
    },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/bids">{t('menus:bids')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:bidList')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:bidDetails')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  function getStyle(value) {
    switch (value) {
      case 'open':
        return style.status.open;
      case 'in_progress':
        return style.status.inProgress;
      case 'completed':
        return style.status.completed;
      case 'on_hold':
        return style.status.onHold;
      default:
        return style.status.new;
    }
  }

  const getBadge = status => {
    return (
      <Badge
        count={t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase()}
        style={getStyle(status.toLowerCase())}
      />
    );
  };

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <BoxFill>
        <PageTitle title={project.description} tags={getBadge(project.status)} />
        {flag === 'PROJECT' && (
          <Result
            status="success"
            title="The procedure to change the bid in a new project was successfully executed"
            subTitle="Now you can continue to administrate the project in the project details page."
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() =>
                  history.push({
                    pathname: '/project-details',
                    state: { data: project, customer: customer },
                  })
                }
              >
                Go To Project Details
              </Button>,
              <Button key="buy" onClick={() => history.push('/bids')}>
                Back to Bids
              </Button>,
            ]}
          />
        )}
        {flag === 'REJECTED' && (
          <Result
            title="Your operation has been executed"
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() =>
                  history.push({
                    pathname: '/bids-list',
                    state: { data: customer },
                  })
                }
              >
                Back to Bids Customer List
              </Button>
            }
          />
        )}
        {flag !== 'PROJECT' && flag !== 'REJECTED' && (
          <Result
            status="warning"
            title="There are some problems with your operation."
            extra={
              <Button type="primary" key="console" onClick={() => history.goBack()}>
                Back To Previous Page
              </Button>
            }
          />
        )}
      </BoxFill>
    </DefaultLayout>
  );
}
