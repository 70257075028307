import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker, Checkbox } from 'formik-antd';
import { Modal, message, Spin, Typography, Divider, Tabs, Col, List, Upload, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import Button from '~/components/Button';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { FaLessThanEqual } from 'react-icons/fa';
import { TabProject } from './styles';
import Box from '~/components/Box';

const initialValues = {
    id: null,
    description: '',
    flag: 'BID',
    type: 'NEW_CONSTRUCTION',
    status: 'NEW',
    startDate: '',
    endDate: '',
    actualEndDate: '',
    tenantsContractInPlace: '',
    tenantsInsuranceOnFile: '',
    tenantsInsuranceExpirationDate: '',
}

export default function ProjectForm({ visible, onClose, id }) {
    const { t } = useTranslation();
    const noData = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('screens:noListData')} />;
    const { Option } = Select;
    const { Text } = Typography;

    const [recordData, setRecordData] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [projectTypes, setProjectTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [uploadList, setUploadList] = useState([]);
    const [showList, setShowList] = useState('none');

    const fetchCountries = async () => {
        setLoading(true);
        try {
            const { data } = await api.get('/addresses/findAllCountries');
            setCountries(data);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const fetchProjectTypes = async () => {
        setLoading(true);
        try {
            const { data } = await api.get('/projects/findAllProjectTypes');
            setProjectTypes(data);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const fetchRecords = async () => {
        setLoading(true);

        try {
            if (id === null) {

            } else {

            }
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    }

    const handleSave = async (values, { setErrors }) => {
        setLoading(true);

        if (uploadList.length < 1) {
            message.error('Você deve anexar pelo menos um comprovante!');
        } else {
            try {
                let files = new FormData();
                uploadList.map(file => files.append('files', file.originFileObj, file.name));

                const { data } = await api.post('/uploadMultipleArchives', files, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                onClose();
            } catch (error) {
                const { response } = error;
                if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
                    message.error(t('messages:duplicatedEmail'));
                } else {
                    setErrors(errorHandler(error));
                }
            }
            setLoading(false);
        }
    };

    const handleUpload = event => {
        event.fileList.map(file => {
            var extension = '';
            var name = '';
            if (file.name.substring(file.name.length - 4) === 'jpeg') {
                extension = file.name.substring(file.name.length - 5);
                name = file.name.replaceAll('.', '');
                name = name.substring(0, name.length - 4);
            } else {
                extension = file.name.substring(file.name.length - 4);
                name = file.name.replaceAll('.', '');
                name = name.substring(0, name.length - 3);
            }
            file.name = name + extension;
        });
        setUploadList(event.fileList);
        setShowList('block');
    };

    const beforeUpload = file => {
        const isValid =
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png' ||
            file.type === 'application/pdf';
        if (!isValid) {
            message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
            setTimeout(function () {
                handleRemoveUploadListItem(file.uid);
            }, 100);
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('O arquivo deve ter menos de 10MB!');
            setTimeout(function () {
                handleRemoveUploadListItem(file.uid);
            }, 100);
        }
        return isValid && isLt2M;
    };

    const handleRemoveUploadListItem = uid => {
        const data = uploadList.filter(item => item.uid !== uid);
        setUploadList(data);
        setShowList('none');
    };

    useEffect(() => { }, [uploadList]);

    useEffect(() => {
        if (visible) {
            fetchCountries();
            fetchProjectTypes();
            fetchRecords();
        }
    }, [visible]);

    const projectStatus = [
        {
            id: 1,
            code: 'NEW',
            description: t('screens:projects.data.status.new'),
        },
        {
            id: 2,
            code: 'OPEN',
            description: t('screens:projects.data.status.open'),
        },
        {
            id: 3,
            code: 'IN_PROGRESS',
            color: '',
            description: t('screens:projects.data.status.in_progress'),
        },
        {
            id: 4,
            code: 'COMPLETED',
            description: t('screens:projects.data.status.completed'),
        },
        {
            id: 5,
            code: 'ON_HOLD',
            description: t('screens:projects.data.status.on_hold'),
        },
    ];

    const jobSchema = Yup.object().shape({
        description: Yup.string().required(),
        type: Yup.string().required(),
        status: Yup.string().required(),
        propertyPhoneNumber: Yup.string().max(27, 'Field must have at most 27 characters'),
    });

    return (
        <Formik
            initialValues={recordData}
            enableReinitialize
            onSubmit={handleSave}
            validationSchema={jobSchema}
        >
            {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values }) => (
                <Modal
                    width="580px"
                    title={id !== null ? t('screens:projects.btnEdit') : t('screens:projects.btnNew')}
                    onCancel={onClose}
                    afterClose={resetForm}
                    open={visible}
                    loading={loading || isSubmitting}
                    footer={
                        <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
                    }
                >
                    <Spin spinning={loading || isSubmitting}>
                        <Form>
                            <TabProject
                                defaultActiveKey="1"
                                type="card"
                                size={'small'}
                                items={[
                                    {
                                        label: t('screens:projects.data.project'),
                                        key: 1,
                                        children:
                                            <Box>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                                                        field="description"
                                                        error={(touched.description && errors.description) || errors.description}
                                                        label={t('screens:projects.data.description')}
                                                        required
                                                    >
                                                        <Input.TextArea name="description" autoSize maxLength={100} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                                                        field="type"
                                                        label={t('screens:projects.status')}
                                                        required
                                                        error={(touched.status && errors.status) || errors.status}
                                                    >
                                                        <Select showSearch name="status" label={t('screens:projects.data.status')}>
                                                            {projectStatus.map(item => {
                                                                return (
                                                                    <Option key={item.id} value={item.code}>{item.description}</Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 15, sm: 14, md: 14, lg: 14, xl: 14 }}
                                                        field="type"
                                                        label={t('screens:projects.data.type')}
                                                        required
                                                        error={(touched.type && errors.type) || errors.type}
                                                    >
                                                        <Select showSearch name="type" label={t('screens:projects.data.type')}>
                                                            {projectTypes.map(item => {
                                                                return (
                                                                    <Option key={item.name} value={item.name}>{item.description}</Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Row>
                                                <Divider orientation="left" orientationMargin="0">
                                                    <Text>{t('screens:projects.data.schedule')}</Text>
                                                </Divider>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 8, sm: 7, md: 7, lg: 7, xl: 7 }}
                                                        field="startDate"
                                                        label={t('screens:projects.data.startDate')}
                                                    >
                                                        <DatePicker name="startDate" format="DD/MM/Y" placeholder={t('messages:select')} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 8, sm: 7, md: 7, lg: 7, xl: 7 }}
                                                        field="endDate"
                                                        label={t('screens:projects.data.projectedEndDate')}
                                                    >
                                                        <DatePicker name="endDate" format="DD/MM/Y" placeholder={t('messages:select')} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 8, sm: 7, md: 7, lg: 7, xl: 7 }}
                                                        field="actualEndDate"
                                                        label={t('screens:projects.data.actualEndDate')}
                                                    >
                                                        <DatePicker name="actualEndDate" format="DD/MM/Y" placeholder={t('messages:select')} />
                                                    </FormControl>
                                                </Row>
                                                <Divider orientation="left" orientationMargin="0">
                                                    <Text>{t('screens:projects.data.tenantsInfo')}</Text>
                                                </Divider>
                                                <Row>
                                                    <FormControl field="tenantsContractInPlace" cols={{ xs: 24 }}>
                                                        <div style={{ paddingTop: 0 }}>
                                                            <Checkbox value="tenantsContractInPlace" name="tenantsContractInPlace" />
                                                            <span style={{ marginLeft: 10 }}>{t('screens:projects.data.tenantsContractInPlace')}</span>
                                                        </div>
                                                    </FormControl>
                                                    {values.tenantsContractInPlace ?
                                                        <>
                                                            <FormControl
                                                                cols={{ xs: 7, sm: 6, md: 6, lg: 6, xl: 6 }}
                                                                field="payment_receipt">
                                                                <Upload
                                                                    name={'file'}
                                                                    accept=".pdf"
                                                                    multiple={false}
                                                                    showUploadList={false}
                                                                    beforeUpload={beforeUpload}
                                                                    onChange={handleUpload}
                                                                    fileList={uploadList}
                                                                    maxCount="1"
                                                                >
                                                                    <Button color="default">{'Upload file'}<UploadOutlined /></Button>
                                                                </Upload>
                                                            </FormControl>
                                                            <FormControl
                                                                cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                                                                {uploadList.map(item => {
                                                                    return (
                                                                        <span key={item.uid}>
                                                                            <PaperClipOutlined />
                                                                            <Text>{item.name}</Text>
                                                                            <DeleteOutlined style={{ paddingLeft: '5px' }} onClick={() => handleRemoveUploadListItem(item.uid)} />
                                                                        </span>);
                                                                })}
                                                            </FormControl>
                                                        </> : null}
                                                    <FormControl field="tenantsInsuranceOnFile" cols={{ xs: 24 }}>
                                                        <div style={{ paddingTop: 0 }}>
                                                            <Checkbox value="tenantsInsuranceOnFile" name="tenantsInsuranceOnFile" />
                                                            <span style={{ marginLeft: 10 }}>{t('screens:projects.data.tenantsInsuranceOnFile')}</span>
                                                        </div>
                                                    </FormControl>
                                                    {values.tenantsInsuranceOnFile ?
                                                        <>
                                                            <FormControl
                                                                cols={{ xs: 7, sm: 6, md: 6, lg: 6, xl: 6 }}
                                                                field="payment_receipt2">
                                                                <Upload
                                                                    name={'file'}
                                                                    accept=".pdf"
                                                                    multiple={false}
                                                                    showUploadList={false}
                                                                    beforeUpload={beforeUpload}
                                                                    onChange={handleUpload}
                                                                    fileList={uploadList}
                                                                    maxCount="1"
                                                                >
                                                                    <Button color="default">{'Upload file'}<UploadOutlined /></Button>
                                                                </Upload>
                                                            </FormControl>
                                                            <FormControl
                                                                cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                                                                {uploadList.map(item => {
                                                                    return (
                                                                        <span key={item.uid}>
                                                                            <PaperClipOutlined />
                                                                            <Text>{item.name}</Text>
                                                                            <DeleteOutlined style={{ paddingLeft: '5px' }} onClick={() => handleRemoveUploadListItem(item.uid)} />
                                                                        </span>);
                                                                })}
                                                            </FormControl>
                                                        </> : null}
                                                    <FormControl
                                                        cols={{ xs: 10, sm: 9, md: 9, lg: 9, xl: 9 }}
                                                        field="tenantsInsuranceExpirationDate"
                                                        label={t('screens:projects.data.tenantsInsuranceExpirationDate')}
                                                    >
                                                        <DatePicker name="tenantsInsuranceExpirationDate" format="DD/MM/Y" placeholder={t('messages:select')} />
                                                    </FormControl>
                                                </Row>
                                            </Box>,
                                    },
                                    {
                                        label: t('screens:projects.data.property'),
                                        key: 2,
                                        children:
                                            <Box>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="propertyName"
                                                        error={(touched.propertyName && errors.propertyName) || errors.propertyName}
                                                        label={t('screens:projects.data.name')}
                                                    >
                                                        <Input name="propertyName" autoComplete="off" />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="propertyPhoneNumber"
                                                        error={(touched.propertyPhoneNumber && errors.propertyPhoneNumber) || errors.propertyPhoneNumber}
                                                        label={t('screens:projects.data.phoneNumber')}
                                                    >
                                                        <Input name="propertyPhoneNumber" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="propertyWebSite"
                                                        error={(touched.propertyWebSite && errors.propertyWebSite) || errors.propertyWebSite}
                                                        label={t('screens:projects.data.website')}
                                                    >
                                                        <Input name="propertyWebSite" autoComplete="off" />
                                                    </FormControl>
                                                </Row>
                                                <Divider orientation="left" orientationMargin="0">
                                                    <Text>{t('screens:projects.data.address')}</Text>
                                                </Divider>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 24 }}
                                                        error={touched.addressLineOne && errors.addressLineOne}
                                                        field="addressLineOne"
                                                        label={t('screens:addresses.data.addressLineOne')}
                                                        required
                                                    >
                                                        <Input name="addressLineOne" autoComplete="off" maxLength={40} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.city && errors.city}
                                                        field="city"
                                                        label={t('screens:addresses.data.city')}
                                                        required
                                                    >
                                                        <Input name="city" autoComplete="off" maxLength={16} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.state && errors.state}
                                                        field="state"
                                                        label={t('screens:addresses.data.state')}
                                                        required
                                                    >
                                                        <Input name="state" autoComplete="off" maxLength={21} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        field="zipCode"
                                                        label={t('screens:addresses.data.zipCode')}
                                                    >
                                                        <Input name="zipCode" autoComplete="off" maxLength={13} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.country && errors.country}
                                                        field="country"
                                                        label={t('screens:addresses.data.country')}
                                                        required
                                                    >
                                                        <Select name="country" placeholder="Select">
                                                            {countries.map(item => {
                                                                return (
                                                                    <Option key={item.name} value={item.name}>{item.description}</Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Row>
                                            </Box>,
                                    },
                                    {
                                        label: t('screens:projects.data.ownership'),
                                        key: 3,
                                        children:
                                            <Box>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="ownershipName"
                                                        error={(touched.ownershipName && errors.ownershipName) || errors.ownershipName}
                                                        label={t('screens:projects.data.ownershipName')}
                                                    >
                                                        <Input name="ownershipName" autoComplete="off" />
                                                    </FormControl>
                                                </Row>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="ownershipRepresentativeCompany"
                                                        error={(touched.ownershipRepresentativeCompany && errors.ownershipRepresentativeCompany) || errors.ownershipRepresentativeCompany}
                                                        label={t('screens:projects.data.ownershipRepresentativeCompany')}
                                                    >
                                                        <Input name="ownershipRepresentativeCompany" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="ownershipRepresentativeName"
                                                        error={(touched.ownershipRepresentativeName && errors.ownershipRepresentativeName) || errors.ownershipRepresentativeName}
                                                        label={t('screens:projects.data.ownershipRepresentativeName')}
                                                    >
                                                        <Input name="ownershipRepresentativeName" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="ownershipRepresentativePhone"
                                                        error={(touched.ownershipRepresentativePhone && errors.ownershipRepresentativePhone) || errors.ownershipRepresentativePhone}
                                                        label={t('screens:projects.data.ownershipRepresentativePhone')}
                                                    >
                                                        <Input name="ownershipRepresentativePhone" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="ownershipRepresentativeEmail"
                                                        error={(touched.ownershipRepresentativeEmail && errors.ownershipRepresentativeEmail) || errors.ownershipRepresentativeEmail}
                                                        label={t('screens:projects.data.ownershipRepresentativeEmail')}
                                                    >
                                                        <Input name="ownershipRepresentativeEmail" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                </Row>
                                                <Divider orientation="left" orientationMargin="0">
                                                    <Text>{t('screens:projects.data.address')}</Text>
                                                </Divider>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 24 }}
                                                        error={touched.addressLineOne && errors.addressLineOne}
                                                        field="addressLineOne"
                                                        label={t('screens:addresses.data.addressLineOne')}
                                                    >
                                                        <Input name="addressLineOne" autoComplete="off" maxLength={40} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.city && errors.city}
                                                        field="city"
                                                        label={t('screens:addresses.data.city')}
                                                    >
                                                        <Input name="city" autoComplete="off" maxLength={16} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.state && errors.state}
                                                        field="state"
                                                        label={t('screens:addresses.data.state')}
                                                    >
                                                        <Input name="state" autoComplete="off" maxLength={21} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        field="zipCode"
                                                        label={t('screens:addresses.data.zipCode')}
                                                    >
                                                        <Input name="zipCode" autoComplete="off" maxLength={13} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.country && errors.country}
                                                        field="country"
                                                        label={t('screens:addresses.data.country')}
                                                    >
                                                        <Select name="country" placeholder="Select">
                                                            {countries.map(item => {
                                                                return (
                                                                    <Option key={item.name} value={item.name}>{item.description}</Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Row>
                                            </Box>,
                                    },
                                    {
                                        label: t('screens:projects.data.architect'),
                                        key: 4,
                                        children:
                                            <Box>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="architectCompanyName"
                                                        error={(touched.architectCompanyName && errors.architectCompanyName) || errors.architectCompanyName}
                                                        label={t('screens:projects.data.architectCompanyName')}
                                                    >
                                                        <Input name="architectCompanyName" autoComplete="off" />
                                                    </FormControl>
                                                </Row>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="architectRepresentative"
                                                        error={(touched.architectRepresentative && errors.architectRepresentative) || errors.architectRepresentative}
                                                        label={t('screens:projects.data.architectRepresentative')}
                                                    >
                                                        <Input name="architectRepresentative" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        field="architectRepresentativePhone"
                                                        error={(touched.architectRepresentativePhone && errors.architectRepresentativePhone) || errors.architectRepresentativePhone}
                                                        label={t('screens:projects.data.architectRepresentativePhone')}
                                                    >
                                                        <Input name="architectRepresentativePhone" autoComplete="off" maxLength={27} />
                                                    </FormControl>
                                                </Row>
                                                <Divider orientation="left" orientationMargin="0">
                                                    <Text>{t('screens:projects.data.address')}</Text>
                                                </Divider>
                                                <Row>
                                                    <FormControl
                                                        cols={{ xs: 24 }}
                                                        error={touched.addressLineOne && errors.addressLineOne}
                                                        field="addressLineOne"
                                                        label={t('screens:addresses.data.addressLineOne')}
                                                    >
                                                        <Input name="addressLineOne" autoComplete="off" maxLength={40} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.city && errors.city}
                                                        field="city"
                                                        label={t('screens:addresses.data.city')}
                                                    >
                                                        <Input name="city" autoComplete="off" maxLength={16} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.state && errors.state}
                                                        field="state"
                                                        label={t('screens:addresses.data.state')}
                                                    >
                                                        <Input name="state" autoComplete="off" maxLength={21} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        field="zipCode"
                                                        label={t('screens:addresses.data.zipCode')}
                                                    >
                                                        <Input name="zipCode" autoComplete="off" maxLength={13} />
                                                    </FormControl>
                                                    <FormControl
                                                        cols={{ xs: 12 }}
                                                        error={touched.country && errors.country}
                                                        field="country"
                                                        label={t('screens:addresses.data.country')}
                                                    >
                                                        <Select name="country" placeholder="Select">
                                                            {countries.map(item => {
                                                                return (
                                                                    <Option key={item.name} value={item.name}>{item.description}</Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Row>
                                            </Box>,
                                    },
                                ]}
                            />
                        </Form>
                    </Spin>
                </Modal>
            )}
        </Formik>
    );
}