import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Checkbox, Layout } from 'antd';
import * as Yup from 'yup';
import { rememberCredentials, signInRequest } from '~/store/modules/auth/actions';
import { Container, Box, Copyright } from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import { CopyrightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import FooterCustom from '~/components/Footer';

const schema = Yup.object().shape({
  username: Yup.string()
    .typeError('Insert your e-mail')
    .email('Insert a valid email')
    .required('Insert your e-mail'),
  password: Yup.string('Insert your password')
    .typeError('Insert your password')
    .required('Insert your password'),
});

export default function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  var username = useSelector(state => state.auth.rememberUsername);
  var password = useSelector(state => state.auth.rememberPassword);
  var checked = useSelector(state => state.auth.checked);

  function handleRemember({ target }) {
    var checked = target.checked;
    checked ? (username = document.getElementsByName('username')['0'].value) : (username = '');
    checked ? (password = document.getElementsByName('password')['0'].value) : (password = '');
    dispatch(rememberCredentials(username, password, checked));
  }

  function handleSubmit({ username, password }) {
    dispatch(signInRequest(username, password));
    setTimeout(function() {
      setLoading(false);
    }, 1000);
  }

  const currentYear = new Date().getFullYear();
  const myStyleLayout = {
    height: '100%',
    background: 'linear-gradient(90deg, #071d3d 0%, #0152a2 50%, #012a53 100%)'
  }

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik
          initialValues={{ username, password }}
          onSubmit={values => {
            handleSubmit(values);
            setLoading(true);
          }}
          validationSchema={schema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Container>
              <Logo title={false} width="100%" height="100%" margin="auto" marginTop="10px" marginBottom="30px" />
              <FormControl field="username" error={touched.username && errors.username}>
                <Input 
                  type="email" 
                  name="username"
                  size="small"
                  prefix={<UserOutlined />}
                  placeholder={t('fields:login.username.placeholder')} />
              </FormControl>
              <FormControl field="password" error={touched.password && errors.password}>
                <Input 
                  type="password"
                  name="password"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('fields:login.password.placeholder')} />
              </FormControl>
              <div style={{textAlign: 'left'}}>
                  <Checkbox style={{fontSize: '12px'}} checked={checked} onChange={handleRemember}>{t('fields:login.rememberMe')} </Checkbox>
                  <span style={{ float: 'right', marginBottom: '10px' }}>
                    <Link to="/password/link">{t('fields:login.forgotPassword')}</Link>
                  </span>
              </div>
      
              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('fields:login.submit')}
              </Button>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
          {` `}
          <span>{t('fields:login.copyright.copyright')}</span>
          {` `}<CopyrightOutlined />{` ${currentYear} | `}
          <span>{t('fields:login.copyright.produced')}</span>
      </Copyright>
      <FooterCustom/>
    </Layout>
  );
}
