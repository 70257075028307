export const projectStatus = [
  {
    id: 0,
    code: 'ALL',
    description: 'All', // `${i18next.t('screens:status.all')}`,
  },
  {
    id: 1,
    code: 'NEW',
    description: 'New', // `${i18next.t('screens:bids.status.new')}`,
  },
  {
    id: 2,
    code: 'OPEN',
    description: 'Open', // `${i18next.t('screens:bids.status.open')}`,
  },
  {
    id: 3,
    code: 'IN_PROGRESS',
    color: '',
    description: 'In Progress', // `${i18next.t('screens:bids.status.in_progress')}`,
  },
  {
    id: 4,
    code: 'COMPLETED',
    description: 'Completed', // `${i18next.t('screens:bids.status.completed')}`,
  },
  {
    id: 5,
    code: 'ON_HOLD',
    description: 'On hold', // `${i18next.t('screens:bids.status.on_hold')}`,
  },
  // {
  //   id: 6,
  //   code: 'REJECTED',
  //   description: 'Rejected', // `${i18next.t('screens:bids.status.rejected')}`,
  // }
];