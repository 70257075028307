import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col, DatePicker } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import moment from 'moment';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function Filter({ approvalStatusList, isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    if (values.titleFilter === '0') {
      values.titleFilter = '';
    }
    const filter = [];

    try {
      // TITLE FILTER
      if (values.titleFilter !== undefined && values.titleFilter !== null && values.titleFilter !== '') {
        filter.push({
          field: 'title',
          value: values.titleFilter,
          restriction: 'LIKE',
        });
      }
      // APPROVAL DEADLINE FILTER
      if (values.sendDataInitial !== undefined && values.sendDataInitial !== null && values.sendDataInitial !== '') {
        filter.push({
          field: 'approvalDeadline',
          value: moment(values.sendDataInitial).format(dateFormat),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        });
      }
      if (values.sendDataFinal !== undefined && values.sendDataFinal !== null && values.sendDataFinal !== '') {
        filter.push({
          field: 'approvalDeadline',
          value: moment(values.sendDataFinal).format(dateFormat),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        });
      }
      // STATUS FILTER
      if (
        values.statusFilter !== undefined &&
        values.statusFilter !== null &&
        values.statusFilter !== '' &&
        values.statusFilter !== 'All'
      ) {
        filter.push({
          field: 'approvalStatus',
          value: values.statusFilter,
          restriction: 'EQUAL',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          titleFilter: '',
          projectsFilter: 'All',
          sendDataInitial: null, // moment(new Date(), dateFormat).subtract(15, 'days'),
          sendDataFinal: null, // moment(new Date(), dateFormat),
          // statusFilter: 'All',
          // approvalStatusList: approvalStatusList,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm, values, setFieldValue }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span={10}>
                  <FormControl
                    error={errors.titleFilter}
                    field="titleFilter"
                    label={t('screens:changeOrders.data.title')}
                  >
                    <Input name="titleFilter" placeholder={t('fields:claims.title.placeholder')} />
                  </FormControl>
                </Col>
                {/* <Col span={3}>
                  <FormControl
                    error={errors.statusFilter}
                    field="statusFilter"
                    label={t('screens:changeOrders.data.approvalStatus')}
                  >
                    <Select name="statusFilter" placeholder="Select">
                      <Option value="All">All</Option>
                      {approvalStatusList?.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col> */}
                <Col span="4">
                  <FormControl field="sendDataFilter" label={t('screens:changeOrders.data.approvalDeadline')}>
                    <RangePicker
                      // set default value equal to today date minus 15 days and today date
                      defaultValue={[
                        values.sendDataInitial, // moment(new Date(), dateFormat).subtract(15, 'days'),
                        values.sendDataFinal, // moment(new Date(), dateFormat),
                      ]}
                      // disabledDate={current => current && current > moment().endOf('day')}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        setFieldValue('sendDataInitial', dateString[0]);
                        setFieldValue('sendDataFinal', dateString[1]);
                      }}
                    />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
