import { Badge, Button, Card, Col, Divider, Row, Statistic, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from '~/Utils/errorHandler';
import { ColCustom, DividerCustom, LoadingMore } from '../styles';
import { Table } from '~/components/Table';
import Pagination from '~/components/Pagination';
import { formatPrice } from '~/Utils';

export default function Projects({ visible, modeView, onCount }) {
    const { t } = useTranslation();
    const { Text } = Typography;
    const prefixDollar = '$';
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [listData, setListData] = useState([]);
    const [initLoading, setInitLoading] = useState(true);
    const [page, setPage] = useState(0);

    const fetchRecords = async () => {
        try {
            /*const pagePaginationDTO = { page: 0, sortBy: 'name', filters: [] };
            const { data } = await api.post('/projects/pagination', pagePaginationDTO);

            const meta = {
              first: data.first,
              last: data.last,
              numberOfElements: data.numberOfElements,
              totalElements: data.totalElements,
              pageNumber: data.pageable.pageNumber,
              pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/
            const meta = {
                first: true,
                last: true,
                numberOfElements: 6,
                totalElements: 8,
                pageNumber: 1,
                pageSize: 6,
            };

            let content = [
                {
                    id: 1, description: 'Project A', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'NEW',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
                {
                    id: 2, description: 'Project B', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'OPEN',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
                {
                    id: 3, description: 'Project C', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'IN_PROGRESS',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
                {
                    id: 4, description: 'Project D', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'COMPLETED',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
                {
                    id: 5, description: 'Project E', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'ON_HOLD',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
                {
                    id: 6, description: 'Project F', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'OPEN',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
            ];

            setMeta(meta);
            setTableData(content);
            setListData(content);
            onCount(meta.totalElements);
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleChangePage = async page => {
        setLoading(true);
        try {
            /*const paginationDTO = { page: page - 1, sortBy: 'name', filters: [] };
            const { data } = await api.post('/projects/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/
            const meta = {
                first: true,
                last: true,
                numberOfElements: 2,
                totalElements: 8,
                pageNumber: 2,
                pageSize: 6,
            };

            const res = [
                {
                    id: 7, description: 'Project G', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'IN_PROGRESS',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
                {
                    id: 8, description: 'Project H', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'COMPLETED',
                    basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
                },
            ];
            setMeta(meta);
            setTableData(res);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const onLoadMore = () => {
        setLoading(true);
        /*const paginationDTO = { page: page, sortBy: 'name', filters: [] };
            const { data } = await api.post('/projects/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/
        const meta = {
            first: true,
            last: true,
            numberOfElements: 2,
            totalElements: 8,
            pageNumber: 2,
            pageSize: 6,
        };


        const res = [
            {
                id: 7, description: 'Project G', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'IN_PROGRESS',
                basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
            },
            {
                id: 8, description: 'Project H', type: 'NEW_CONSTRUCTION', typeDescription: 'New Construction', status: 'COMPLETED',
                basePrice: 50000, selections: 10000, purchaseOrders: 5000, totalPrice: 60000, invoiced: 1000, payments: 20500, amountDue: 500
            },
        ];
        const newData = listData.concat(res);
        setListData(newData);
        setPage(page + 1);
        setLoading(false);
        window.dispatchEvent(new Event('resize'));
    };

    useEffect(() => {
        if (visible) {
            setPage(0);
            setInitLoading(false);
            fetchRecords();
        }
    }, [visible]);

    const style = {
        project: {
            cardBody: { paddingLeft: '0px' },
            status: {
                new: { backgroundColor: '#1890FF' },
                open: { backgroundColor: '#13C2C2' },
                inProgress: { backgroundColor: '#A0D911' },
                completed: { backgroundColor: '#FAAD14' },
                onHold: { backgroundColor: '#F5222D' }
            }
        },
        statistic2: { fontSize: '18px' },
        statistic3: { paddingBottom: '20px' },
    }

    function getStyle(value) {
        switch (value) {
            case 'open':
                return (style.project.status.open);
            case 'in_progress':
                return (style.project.status.inProgress);
            case 'completed':
                return (style.project.status.completed);
            case 'on_hold':
                return (style.project.status.onHold);
            default:
                return (style.project.status.new);
        }
    }

    const tableColumns = [
        {
            title: t('screens:projects.data.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('screens:leads.data.basePrice'),
            dataIndex: 'basePrice',
            key: 'basePrice',
            render: basePrice => formatPrice(basePrice)
        },
        {
            title: t('screens:leads.data.selections'),
            dataIndex: 'selections',
            key: 'selections',
            render: selections => formatPrice(selections)
        },
        {
            title: t('screens:leads.data.totalPrice'),
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: totalPrice => formatPrice(totalPrice)
        },
        {
            title: t('screens:projects.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '100px',
            render: status =>
                <Badge
                    count={(t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase())}
                    style={getStyle(status.toLowerCase())} />
        },
    ];

    const loadMore = !initLoading && !loading ? (<LoadingMore><Button onClick={onLoadMore}>{t('screens:leads.loadingMore')}</Button></LoadingMore>) : null;

    return (
        <>
            {modeView === 'list' ?
                <>
                    <Table
                        bordered
                        size='small'
                        rowKey="id"
                        pagination={false}
                        loading={loading}
                        columns={tableColumns}
                        dataSource={tableData}
                    />

                    <Pagination
                        onChange={value => {
                            handleChangePage(value);
                        }}
                        meta={meta}
                    />
                </>
                :
                <>
                    <Row>
                        {listData.map(project =>
                            <>
                                <ColCustom xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Card size='small' bodyStyle={style.project.cardBody}>
                                        <Row>
                                            <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                                                <DividerCustom type='vertical' style={getStyle(project.status.toLowerCase())} />
                                            </Col>
                                            <Col xs={22} sm={22} md={22} lg={23} xl={23}>
                                                <p><Text strong>{project.description}</Text></p>
                                                <p><Badge
                                                    count={(t('screens:projects.data.status.' + project.status.toLowerCase()).toUpperCase())}
                                                    style={getStyle(project.status.toLowerCase())} />
                                                </p>
                                                <Row xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:leads.data.basePrice')} prefix={prefixDollar} value={project.basePrice} precision={2} valueStyle={style.statistic2} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:leads.data.selections')} prefix={prefixDollar} value={project.selections} precision={2} valueStyle={style.statistic2} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:leads.data.purchaseOrders')} prefix={prefixDollar} value={project.purchaseOrders} precision={2} valueStyle={style.statistic2} />
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Statistic title={t('screens:leads.data.totalPrice')} prefix={prefixDollar} value={project.totalPrice} precision={2} valueStyle={style.statistic2} />
                                                <Divider />
                                                <Row xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:leads.data.invoiced')} prefix={prefixDollar} value={project.invoiced} precision={2} valueStyle={style.statistic2} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:leads.data.payments')} prefix={prefixDollar} value={project.payments} precision={2} valueStyle={style.statistic2} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:leads.data.amountDue')} prefix={prefixDollar} value={project.amountDue} precision={2} valueStyle={style.statistic2} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </ColCustom>
                            </>
                        )}
                    </Row>
                    {loadMore}
                </>
            }
        </>
    );
}