import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, Checkbox } from 'formik-antd';
import { Modal, message, Spin, Typography, Descriptions, Collapse, Divider, Empty, Space, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import InputError from '~/components/Form/Error';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Addresses from './Addresses';
import { TabAddress } from './styles';
import { store } from '~/store';
import Contacts from './Contact';
import BidContactForm from './BidContact/form';
import { customerSchema } from './validationSchemas';
// import ContactForm from './Contact/form';

const initialValues = {
  id: null,
  company: null,
  primaryContactFirstName: '',
  primaryContactLastName: '',
  primaryContactJobTitle: '',
  primaryContactMainPhone: '',
  primaryContactMainEmail: '',
  primaryContactWorkPhone: '',
  primaryContactccEmail: '',
  primaryContactMobilePhone: '',
  secondaryContactFirstName: '',
  secondaryContactLastName: '',
  secondaryContactJobTitle: '',
  secondaryContactMainPhone: '',
  secondaryContactMainEmail: '',
  secondaryContactWorkPhone: '',
  secondaryContactccEmail: '',
  secondaryContactMobilePhone: '',
  website: '',
  faxNumber: '',
  other: '',
  companyName: '',
  checkPayByACH: false,
  checkPayByCheck: false,
  salesTaxCode: 'NON',
  taxItem: null,
  addresses: [],
};

const initValuesCContact = {
  id: null,
  primaryContact: null,
  secondaryContact: null,
  website: '',
  faxNumber: '',
  other: '',
};

export default function BidForm({ visible, onClose, id }) {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { Option } = Select;
  const { Panel } = Collapse;

  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [customer, setBids] = useState(null);
  const [customerContact, setBidContact] = useState(initValuesCContact);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [secondaryContact, setSecondaryContact] = useState(null);
  const [taxItem, setTaxItem] = useState(null);
  const [company, setCompany] = useState(null);
  const [taxItems, setTaxItems] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const fetchRecords = async () => {
    setLoading(true);

    try {
      if (id === null) {
        setBids(null);
        setPrimaryContact(null);
        setSecondaryContact(null);
        setBidContact(initValuesCContact);
        setTaxItem(null);
        setRecordData(initialValues);
      } else {
        const { data } = await api.get('/customers/findById', {
          params: { id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        let initialData = { initialValues };
        initialData.id = data.id;
        initialData.company = data.company;
        initialData.companyName = data.companyName;
        initialData.checkPayByACH = data.checkPayByACH;
        initialData.checkPayByCheck = data.checkPayByCheck;
        initialData.salesTaxCode = data.salesTaxCode;
        initialData.taxItem = data.taxItem !== null ? data.taxItem.id : null;

        let count = 1;
        data.addresses.forEach(address => {
          address.uuid = count;
          count = count + 1;
        });
        initialData.addresses = data.addresses;

        if (data.customerContact !== null) {
          const cc = data.customerContact;
          const p = cc.primaryContact;
          const s = cc.secondaryContact;

          setBidContact(data.customerContact);
          setPrimaryContact(p);
          setSecondaryContact(s);

          initialData.primaryContactFirstName = p.firstName;
          initialData.primaryContactLastName = p.lastName;
          initialData.primaryContactJobTitle = p.jobTitle;
          initialData.primaryContactMainPhone = p.mainPhone;
          initialData.primaryContactMainEmail = p.mainEmail;
          initialData.primaryContactWorkPhone = p.workPhone;
          initialData.primaryContactccEmail = p.ccEmail;
          initialData.primaryContactMobilePhone = p.mobilePhone;

          if (s !== null) {
            initialData.secondaryContactFirstName = s.firstName;
            initialData.secondaryContactLastName = s.lastName;
            initialData.secondaryContactJobTitle = s.jobTitle;
            initialData.secondaryContactMainPhone = s.mainPhone;
            initialData.secondaryContactMainEmail = s.mainEmail;
            initialData.secondaryContactWorkPhone = s.workPhone;
            initialData.secondaryContactccEmail = s.ccEmail;
            initialData.secondaryContactMobilePhone = s.mobilePhone;
          }

          initialData.website = cc.website;
          initialData.faxNumber = cc.faxNumber;
          initialData.other = cc.other;
        } else {
          setBidContact(initValuesCContact);
          setPrimaryContact(null);
          setSecondaryContact(null);
        }

        setBids(data);
        setTaxItem(data.taxItem);
        setRecordData(initialData);
      }
      setAddresses(recordData.addresses);
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const fetchCompany = async () => {
    setLoading(true);
    const { profile } = store.getState().user;
    setCompany(profile.company);
    setLoading(false);
  };

  const fetchTaxItems = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/taxItems/findAll');
      setTaxItems(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors, setFieldError }) => {
    setLoading(true);
    try {
      if (customer && customer.id !== null) {
        const c = {
          id: customer.id,
          company: values.company,
          customerContact: customerContact,
          companyName: values.companyName,
          checkPayByACH: values.checkPayByACH,
          checkPayByCheck: values.checkPayByCheck,
          salesTaxCode: values.salesTaxCode,
          taxItem: null,
          addresses: addresses,
        };

        await api.put('/customers/update', c);
        message.success(t('messages:customerSuccessEdit'));
      } else {
        const c = {
          id: null,
          company: company,
          customerContact: customerContact,
          companyName: values.companyName,
          checkPayByACH: values.checkPayByACH,
          checkPayByCheck: values.checkPayByCheck,
          salesTaxCode: values.salesTaxCode,
          taxItem: null,
          addresses: addresses,
        };

        await api.post('/customers/create', c);
        message.success(t('messages:customerSuccess'));
      }

      onClose();
    } catch (error) {
      errorHandler(error);

      const { response } = error;
      if (response) {
        const { status } = response;

        if (status === 400 && response.data.errors) {
          response.data.errors.forEach(async item => {
            if (item.sqlState === 'duplicateCustomer') {
              await setErrors({ companyName: "Value to field already exists" });
            }
          }
        )}
      }
    }
    setLoading(false);
  };

  const handleSelect = (contact, primary) => {
    let cc = customerContact;
    if (primary) {
      cc.primaryContact = contact;
      setPrimaryContact(contact);
    } else {
      cc.secondaryContact = contact;
      setSecondaryContact(contact);
    }
    setBidContact(cc);
  };

  useEffect(() => {
    if (visible) {
      fetchCompany();
      fetchRecords();
      fetchTaxItems();
    }
  }, [visible]);

  const style = {
    divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px` },
  };

  const salesTaxCodes = [
    {
      id: 1,
      code: 'TAX',
      description: t('screens:leads.data.salesTaxCodes.tax'),
    },
    {
      id: 2,
      code: 'NON',
      description: t('screens:leads.data.salesTaxCodes.non'),
    },
  ];

  const genExtra = key =>
    (key === 1 && primaryContact !== null) || (key === 2 && secondaryContact !== null) ? (
      <DeleteOutlined
        onClick={event => {
          switch (key) {
            case 1:
              handleSelect(null, true);
              break;
            case 2:
              handleSelect(null, false);
              break;
            case 3:
              setShowForm(true);
              break;
          }
          event.stopPropagation();
        }}
      />
    ) : key === 3 ? (
      <EditOutlined
        onClick={event => {
          switch (key) {
            case 3:
              setShowForm(true);
              break;
          }
          event.stopPropagation();
        }}
      />
    ) : null;

  const getAddresses = (type, values) => (
    <Addresses
      addressType={type}
      addresses={addresses}
      onHandleAddresses={e => {
        setAddresses(e);
      }}
    />
  );

  function getTabItems(errors, values) {
    return [
      {
        label: 'Billing Address',
        key: 'billingAddress',
        children: getAddresses('BILLING', values),
      },
      {
        label: 'Shipping Address',
        key: 'shippingAddress',
        children: getAddresses('SHIPPING', values),
      },
    ];
  }

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={customerSchema}
    >
      {({ errors, isSubmitting, touched, submitForm, resetForm, values, setFieldValue, setFieldError }) => (
        <Modal
          title={t('screens:leads.btnNew')}
          width={800}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Text style={style.divider}>{t('screens:leads.data.generalContactInformation')}</Text>
              </Row>
              <Row>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  error={errors.companyName}
                  field="companyName"
                  label={t('screens:leads.data.companyName')}
                  required
                >
                  <Input name="companyName" autoComplete="off" />
                </FormControl>
              </Row>
              <br />
              {/* <InputError error={errors.primaryContactFirstName || errors.primaryContactLastName || errors.primaryContactJobTitle  } msg={errors.primaryContactFirstName || errors.primaryContactLastName || errors.primaryContactJobTitle } /> */}
              <span
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  color: '#600',
                  fontSize: '12px',
                  fontStyle: 'italic',
                  opacity: '0.9',
                }}
              >
                {errors.primaryContactFirstName || errors.primaryContactLastName || errors.primaryContactJobTitle
                  ? 'Please select a primary contact'
                  : ''}
              </span>

              <Collapse defaultActiveKey={'1'} className="ant-collapse-content-inactive">
                <Panel
                  style={
                    errors.primaryContactFirstName || errors.primaryContactLastName || errors.primaryContactJobTitle
                      ? { border: '1px solid red', color: 'red' }
                      : {}
                  }
                  header="Primary Contact"
                  key="1"
                  extra={genExtra(1)}
                >
                  <Contacts
                    primary={true}
                    onSelect={c => {
                      handleSelect(c, true);
                      setFieldValue('primaryContactFirstName', c.firstName);
                      setFieldValue('primaryContactLastName', c.lastName);
                      setFieldValue('primaryContactJobTitle', c.jobTitle);
                    }}
                    contact={primaryContact}
                    onChange={c => {
                      handleSelect(c, true);
                      setFieldValue('primaryContactFirstName', c.firstName);
                      setFieldValue('primaryContactLastName', c.lastName);
                      setFieldValue('primaryContactJobTitle', c.jobTitle);
                    }}
                  />
                </Panel>

                <Panel header="Secondary Contact" key="2" extra={genExtra(2)}>
                  <Contacts
                    primary={false}
                    onSelect={c => handleSelect(c, false)}
                    contact={secondaryContact}
                    onChange={c => {
                      handleSelect(c, false);
                    }}
                  />
                </Panel>
                <Panel header="Others" key="3" extra={genExtra(3)}>
                  <Descriptions size="small" column={{ xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}>
                    <Descriptions.Item
                      span={24}
                      label={<Text type="secondary">{t('screens:leads.data.website')}</Text>}
                    >
                      {customerContact.website !== null ? customerContact.website : ''}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={24}
                      label={<Text type="secondary">{t('screens:leads.data.faxNumber')}</Text>}
                    >
                      {customerContact.faxNumber !== null ? customerContact.faxNumber : ''}
                    </Descriptions.Item>
                    <Descriptions.Item span={24} label={<Text type="secondary">{t('screens:leads.data.other')}</Text>}>
                      {customerContact.other !== null ? customerContact.other : ''}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
              <br />
              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:leads.data.paymentInformation')}</Text>
              </Divider>
              <Row>
                <FormControl field="checkPayByACH" cols={{ xs: 24 }}>
                  <div style={{ paddingTop: 0 }}>
                    <Checkbox value="checkPayByACH" name="checkPayByACH" />
                    <span style={{ marginLeft: 10 }}>{t('screens:leads.data.checkPayByACH')}</span>
                  </div>
                </FormControl>
                <FormControl field="checkPayByCheck" cols={{ xs: 24 }}>
                  <div style={{ paddingTop: 0 }}>
                    <Checkbox value="checkPayByCheck" name="checkPayByCheck" />
                    <span style={{ marginLeft: 10 }}>{t('screens:leads.data.checkPayByCheck')}</span>
                  </div>
                </FormControl>
              </Row>
              <br />
              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:leads.data.taxInformation')}</Text>
              </Divider>
              <Row>
                <FormControl
                  cols={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                  error={errors.salesTaxCode}
                  field="salesTaxCode"
                  label={t('screens:leads.data.salesTaxCode')}
                  required
                >
                  <Select name="salesTaxCode" placeholder="Select">
                    {salesTaxCodes.map(item => {
                      return (
                        <Option key={item.id} value={item.code}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  field="taxItem"
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  label={t('screens:leads.data.taxItem')}
                >
                  <Select name="taxItem" placeholder="Select">
                    {taxItems.map(item => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
              <br />
              <span
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  color: '#600',
                  fontSize: '12px',
                  fontStyle: 'italic',
                  opacity: '0.9',
                }}
              >
                {errors.addresses}
              </span>
              <TabAddress
                size="small"
                defaultActiveKey="1"
                type="card"
                items={getTabItems(errors, values)}
                style={errors.addresses ? { border: '1px solid red', color: 'red' } : {}}
              />
            </Form>
            <BidContactForm
              customerContact={customerContact}
              visible={showForm}
              onClose={() => setShowForm(false)}
              onChange={cc => {
                setBidContact(cc);
                setShowForm(false);
              }}
            />
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
