import React, { useEffect, useState } from 'react';
import { ConsoleSqlOutlined, DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Divider, Badge, Image, Row, Descriptions, Typography, Modal, Card, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { formatPrice, formatPercent } from '~/Utils';
import { ModalFooter } from '~/components/Modal';
import Box from '~/components/Box';
import moment from 'moment';
import './index.css';

const { Text } = Typography;
const { Title } = Typography;

// const style = { divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px` } };

export default function AppCertificationForm({
  selectedInvoice,
  customer,
  itemsDetailsFromProposal,
  originalContractSum,
  changeOrdersSum,
  totalCompletedAndStored,
  totalRetainage,
  totalEarnedLessRetainage,
  visible,
  onClose,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const contractSumToDate = originalContractSum + changeOrdersSum;
  const balanceToFinish = contractSumToDate - totalCompletedAndStored;
  const currentPaymentDue = selectedInvoice.payAppClient.total;

  const tableColumns = [
    {
      // title: t('screens:workbook.receipts.items.payAppId'),
      // dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '50px',
      // render: id => <Text strong>{id}</Text>,
    },
    {
      title: t('screens:workbook.receipts.items.descriptionOfWork'),
      dataIndex: 'descriptionOfWork',
      key: 'descriptionOfWork',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '300px',
      render: descriptionOfWork => <Text strong>{descriptionOfWork}</Text>,
    },
    {
      title: t('screens:workbook.receipts.items.total'),
      key: 'total',
      ellipsis: { showTitle: false },
      align: 'center',
    },
    {
      title: t('screens:workbook.receipts.items.totalToDate'),
      key: 'total',
      ellipsis: { showTitle: false },
      align: 'center',
    },
    {
      title: t('screens:workbook.receipts.items.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      align: 'center',
    },
    {
      title: t('screens:workbook.receipts.items.balanceToFinish'),
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      align: 'center',
    },
    {
      title: t('screens:workbook.receipts.items.retainage'),
      key: 'retainage',
      ellipsis: { showTitle: false },
      align: 'center',
    },
    {
      title: t('screens:workbook.receipts.items.total'),
      key: 'total',
      ellipsis: { showTitle: true },
      align: 'center',
      hidden: true,
    },
  ].filter(item => !item.hidden);

  const tableItemsDetailsColumns = [
    {
      title: t('screens:workbook.receipts.items.itemId'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      width: '50px',
      align: 'center',
    },
    {
      title: t('screens:workbook.receipts.items.description'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '300px',
    },
    {
      title: t('screens:workbook.receipts.items.total'),
      dataIndex: 'total',
      key: 'total',
      ellipsis: { showTitle: false },
      align: 'center',
      render: total => <>{formatPrice(total)}</>,
    },
    {
      title: t('screens:workbook.receipts.items.totalToDate'),
      key: 'total',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.paid ? record.total : 0)}</>,
    },
    {
      title: t('screens:workbook.receipts.items.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPercent(record.paid ? 1 : 0)}</>,
    },
    {
      title: t('screens:workbook.receipts.items.balanceToFinish'),
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.paid ? 0 : record.total)}</>,
    },
    {
      title: t('screens:workbook.receipts.items.retainage'),
      key: 'retainage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.paid ? record.total * 0.2 : 0)}</>,
    },
  ];

  const customerMainAddress = customer.addresses.find(address => address.main);

  const recordData = null;

  const handleSave = async values => {
    console.log(values);
  };

  const company = invoice => {
    console.log('Company:' + invoice);
    return '';
  };

  const valuesSchema = Yup.object().shape({
    // notes: Yup.string().required(),
    // type: Yup.object().required(),
  });

  return (
    <Formik enableReinitialize initialValues={recordData} onSubmit={handleSave} validationSchema={valuesSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values }) => (
        <Modal
          width="1100px"
          description={t('screens:workbook.subcontractors.modal.description')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Box>
            <Row gutter={16}>
              <Col span={24}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions
                    title={<Title level={4}>APPLICATION AND CERTIFICATION FOR PAYMENT</Title>}
                    layout="vertical"
                    bordered
                    column={4}
                    // contentStyle={{ width: '70%' }}
                  >
                    <Descriptions.Item label="TO">
                      {/* CUSTOMER COMPANY NAME */}
                      <Text strong>{customer.companyName}</Text>
                      <br />
                      {/* CUSTOMER ADDRESS */}
                      {`${customerMainAddress.addressLineOne} ${
                        customerMainAddress.addressLineTwo ? ', ' + customerMainAddress.addressLineTwo : ''
                      }`}
                      <br />
                      {/* CITY, CEP */}
                      {`${customerMainAddress.city}, ${customerMainAddress.zipCode}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="PROJECT">
                      {/* PROJECT NAME */}
                      <Text strong>{selectedInvoice?.payAppClient.project.description}</Text>
                      <br />
                      {/* PROJECT ADDRESS */}
                      {`${selectedInvoice?.payAppClient.project.property.address.addressLineOne} ${
                        selectedInvoice?.payAppClient.project.property.address.addressLineTwo
                          ? ', ' + selectedInvoice?.payAppClient.project.property.address.addressLineTwo
                          : ''
                      }`}
                      <br />
                      {/* CITY, STATE, CEP */}
                      {`${selectedInvoice?.payAppClient.project.property.address.city}, ${selectedInvoice.payAppClient.project.property.address.state} ${selectedInvoice.payAppClient.project.property.address.zipCode}`}
                    </Descriptions.Item>

                    <Descriptions.Item label="APPLICATION #">{selectedInvoice?.payAppClient.id}</Descriptions.Item>
                    <Descriptions.Item label="PERIOD TO">
                      {moment(selectedInvoice?.date).format('MM/DD/YYYY')}
                    </Descriptions.Item>
                    <Descriptions.Item label="FROM">
                      {/* COMPANY NAME */}
                      <Text strong>{customer.company.name}</Text>
                      <br />
                      {/* COMPANY ADDRESS, CITY, CEP */}
                      {customer.company.id === 1 ? (
                        <>
                          11501 Lake Underhill Road <br /> Orlando FL 32825
                        </>
                      ) : (
                        <>
                          7362 Futures Dr Suite 12B <br /> Orlando, Fl 32819
                        </>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="VIA (ARCHITECT)">
                      {/* ARCHITECT NAME */}
                      <Text strong>{selectedInvoice?.payAppClient.project.architect.companyName}</Text>
                      <br />
                      {/* ARCHITECT ADDRESS, CITY, CEP */}
                      {`${selectedInvoice?.payAppClient.project.architect.address.addressLineOne} ${
                        selectedInvoice?.payAppClient.project.architect.address.addressLineTwo
                          ? ', ' + selectedInvoice?.payAppClient.project.architect.address.addressLineTwo
                          : ''
                      }`}
                      <br />
                      {`${selectedInvoice?.payAppClient.project.architect.address.city}, ${selectedInvoice?.payAppClient.project.architect.address.state} ${selectedInvoice?.payAppClient.project.architect.address.zipCode}`}
                      <br />
                      {/* ARCHITECT PROJECT Nº*/}
                      {`ARCHITECT'S PROJECT Nº: ${selectedInvoice?.architectsProjectNumber}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="WORK DESCRIPTION">
                      {selectedInvoice?.payAppClient.descriptionOfWork}
                    </Descriptions.Item>
                    <Descriptions.Item label="DUE DATE">
                      {moment(selectedInvoice?.dueDate).format('MM/DD/YYYY')}
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              </Col>
            </Row>
            <Row gutter={16} style={{ paddingTop: '30px' }}>
              <Col span={12} style={{ paddingTop: '0px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions
                    title="SUBCONTRACTOR'S APPLICATION FOR PAYMENT"
                    layout="horizontal"
                    bordered
                    column={1}
                    contentStyle={{ width: 120 }}
                  >
                    <Descriptions.Item label="1. ORIGINAL CONTRACT/PROPOSAL SUM">
                      {formatPrice(originalContractSum || 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label="2. NET CHANGE BY CHANGE ORDERS">
                      {formatPrice(changeOrdersSum)}
                    </Descriptions.Item>
                    <Descriptions.Item label="3. CONTRACT SUM TO DATE">
                      {formatPrice(contractSumToDate)}
                    </Descriptions.Item>
                    <Descriptions.Item label="4. TOTAL COMPLETED & STORED TO DATE">
                      {formatPrice(totalCompletedAndStored)}
                    </Descriptions.Item>
                  </Descriptions>

                  <Card
                    title={<Text style={{ fontSize: 14 }}>5. RETAINAGE</Text>}
                    bordered={true}
                    bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    <Descriptions
                      layout="horizontal"
                      bordered
                      column={1}
                      contentStyle={{ width: 120 }}
                      labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                      size="middle"
                    >
                      <Descriptions.Item label="5.1. 10	% of Completed Work">
                        {formatPrice(totalRetainage)}
                      </Descriptions.Item>
                      <Descriptions.Item label="5.2. 10	% of Stored Material">$0.00</Descriptions.Item>
                      <Descriptions.Item label="TOTAL RETAINAGE">{formatPrice(totalRetainage)}</Descriptions.Item>
                    </Descriptions>
                  </Card>

                </Space>
              </Col>
              {/* <Col span={12} style={{ paddingTop: '0px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions title="&nbsp;" layout="vertical" bordered column={1}>
                    <Descriptions.Item label="OBSERVATION">
                      The undersigned Contractor certified that to the best of the Contractor's knowledge, information
                      and belief the Work covered by this Application for Payment has been completed in accordance with
                      the Contract Documents, that all amounts have been paid by the Contractor for Work for which
                      previous Certificates for Payment were issued and payments received from the Owner, and that
                      current payment shown herein is now due.
                    </Descriptions.Item>
                    <Descriptions.Item label="SUBCONTRACTOR">
                      SUBCONTRACTOR 1
                      <br />
                      SUBCONTRACTOR ADDRESS 1
                      <br />
                      SUBCONTRACTOR ADDRESS 3
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="horizontal" bordered column={1}>
                    <Descriptions.Item label="BY">{formatPrice(changeOrdersSum)}</Descriptions.Item>
                    <Descriptions.Item label="DATE">value date</Descriptions.Item>
                    <Descriptions.Item label="STATE OF">value state of</Descriptions.Item>
                    <Descriptions.Item label="COUNTRY OF">country of</Descriptions.Item>
                  </Descriptions>
                  <Card
                    title={<Text style={{ fontSize: 14 }}>CONTRACT DETAILS</Text>}
                    bordered={true}
                    bodyStyle={{ fontSize: 14, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    Subscribed and sworn to before this _______ day
                    <br />
                    <br />
                    Of __________, 21
                    <br />
                    <br />
                    Notary Public: _______________________________
                    <br />
                    <br />
                    My Commission Expires:______________________
                  </Card>
                </Space>
              </Col> */}
              <Col span={12} style={{ paddingTop: '0px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions title="&nbsp;" layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                    <Descriptions.Item label="6. TOTAL EARNED LESS RETAINAGE">
                      {formatPrice(totalEarnedLessRetainage)}
                    </Descriptions.Item>
                    <Descriptions.Item label="7. LESS PREVIOUS CERTIFICATES FOR PAYMENT">input</Descriptions.Item>
                    <Descriptions.Item label="8. LESS INTEREST & INSP. FEES PAID">input</Descriptions.Item>
                  </Descriptions>

                  <Card
                    title={<Text style={{ fontSize: 14 }}>9. PAYMENT DUE</Text>}
                    bordered={true}
                    bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    <Descriptions
                      layout="horizontal"
                      bordered
                      column={1}
                      contentStyle={{ width: 120 }}
                      labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                      size="middle"
                    >
                      <Descriptions.Item label="9.1. Current Payment Due">
                        {formatPrice(currentPaymentDue || 0)}
                      </Descriptions.Item>
                      <Descriptions.Item label="9.2. Cost of Work">input</Descriptions.Item>
                      <Descriptions.Item label="9.3. Sales and Use Tax">input</Descriptions.Item>
                      <Descriptions.Item label="TOTAL DUE">{formatPrice(totalEarnedLessRetainage)}</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                    <Descriptions.Item label="10. BALANCE TO FINISH, INCLUDING RETAINAGE">
                      {formatPrice(balanceToFinish)}
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              </Col>
            </Row>
            <Row gutter={16} style={{ paddingTop: '30px' }}>
              <Col span={24} >
              <Descriptions
                    title="PROJECT COST BREAKDOWN SHEET"
                    layout="horizontal"
                    column={1}/>
                <Table
                  rowKey="id"
                  className="parentTable"
                  columns={tableColumns}
                  dataSource={itemsDetailsFromProposal}
                  pagination={false}
                  bordered
                  size="middle"
                  showExpandColumn={false}
                  expandable={{
                    defaultExpandAllRows: true,
                    rowExpandable: record => record.name !== 'Not Expandable',
                    expandedRowRender: record => (
                      <p style={{ margin: 0, padding: 0 }}>
                        <Table
                          className="nestedTable"
                          showTitle={false}
                          showHeader={false}
                          bordered
                          rowKey="id"
                          pagination={false}
                          loading={loading}
                          columns={tableItemsDetailsColumns}
                          dataSource={record.items}
                          size="small"
                        />
                      </p>
                    ),
                  }}
                  summary={pageData => {
                    let totalAll = 0;
                    let totalToDateAll = 0;
                    let totalPercentageComplete = 0;
                    let totalRetainage = 0;
                    let totalBalanceToFinish = 0;
                    pageData.forEach(({ total, paidAll }) => {
                      totalAll += total;
                      totalToDateAll += paidAll ? total : 0;
                    });
                    totalRetainage = totalToDateAll * 0.2;
                    totalBalanceToFinish = totalAll - totalToDateAll;
                    totalPercentageComplete = totalToDateAll / totalAll;
                    return (
                      <>
                        <Table.Summary.Row style={{ backgroundColor: '#fffff0' }}>
                          <Table.Summary.Cell index={0} colSpan={2} />
                          <Table.Summary.Cell index={1} align="center">
                            <Text strong>{formatPrice(totalAll)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align="center">
                            <Text strong>{formatPrice(totalToDateAll)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} align="center">
                            <Text strong>{formatPercent(totalPercentageComplete)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4} align="center">
                            <Text strong>{formatPrice(totalBalanceToFinish)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5} align="center">
                            <Text strong>{formatPrice(totalRetainage)}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />

              </Col>
            </Row>
          </Box>
        </Modal>
      )}
    </Formik>
  );
}
