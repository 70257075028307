import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import errorHandler from 'Utils/errorHandler';
import InputCurrency from '~/components/Form/InputCurrency';
import { dynamicCost } from '~/Utils';

export default function ProductForm({ visible, onClose, onAddNewProduct, onUpdateProduct, product }) {
  const { t } = useTranslation();
  const initialValues = {
    id: null,
    code: '',
    name: '',
    description: '',
    quantity: 0,
    unitPrice: '0.00',
    labor: '0.00',
    total: '0.00',
  };
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      if (product === null) {
        setRecordData(initialValues);
      } else {
        setRecordData(product);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const newProduct = {
        id: values.id,
        code: values.code,
        name: values.name,
        description: values.description,
        quantity: values.quantity,
        unitPrice: values.unitPrice,
        labor: values.labor,
        total: values.total
      };

      if (product === null) {
        onAddNewProduct(newProduct);
      } else {
        onUpdateProduct(product, newProduct);
      }
      onClose();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const itemSchema = Yup.object().shape({
    name: Yup.string().required(),
    quantity: Yup.number().positive().moreThan(0).required(),
  });

  const calculateTotal = (values, setFieldValue) => {
    var bigDecimal = require('js-big-decimal');
    var v1 = new bigDecimal(values.quantity);
    var v2 = new bigDecimal( values.unitPrice);
    var v3 = new bigDecimal( values.labor);
    var result = v1.multiply(v2);
    result = result.add(v3);
    setFieldValue("total", result.round(2).getValue());
  };

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={itemSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setValues, setFieldValue }) => (
        <Modal
          title={product !== null ? t('screens:items.btnEdit') : t('screens:items.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
          <Form>
              <Row>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  field="code"
                  label={t('screens:items.data.code')}
                >
                  <Input name="code" autoComplete="off" />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  error={touched.name && errors.name}
                  field="name"
                  label={t('screens:items.data.name')}
                  required
                >
                  <Input name="name" autoComplete="off" />
                </FormControl>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                  field="description"
                  label={t('screens:items.data.description')}
                >
                  <Input.TextArea name="description" showCount maxLength={500} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 6 }}
                  error={touched.quantity && errors.quantity}
                  field="quantity"
                  label={t('screens:items.data.quantity')}
                  required
                >
                  <Input name="quantity" autoComplete="off"
                    onBlur={() => {
                      calculateTotal(values, setFieldValue);
                    }} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 8 }}
                  field="unitPrice"
                  label={t('screens:items.data.unitPrice')}
                >
                  <InputCurrency
                    name="unitPrice" currency="$" number={false} style={{ textAlign: 'right' }}
                    onChange={event => {
                      setTimeout(() => {
                        setValues({ ...values, unitPrice: dynamicCost(event.target.value)});
                      }, 100);
                    }}
                    onBlur={() => {
                      calculateTotal(values, setFieldValue);
                    }}
                  />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 8 }}
                  field="labor"
                  label={t('screens:items.data.labor')}
                >
                  <InputCurrency
                    name="labor" currency="$" number={false} style={{ textAlign: 'right' }}
                    onChange={event => {
                      setTimeout(() => {
                        setValues({ ...values, labor: dynamicCost(event.target.value)});
                      }, 100);
                    }}
                    onBlur={() => {
                      calculateTotal(values, setFieldValue);
                    }}
                  />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 8 }}
                  field="total"
                  label={t('screens:items.data.total')}
                >
                  <InputCurrency name="total" currency="$" number={false} style={{ textAlign: 'right' }}/>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
