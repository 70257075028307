import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { store } from '~/store';
import { tokenExpired } from '~/store/modules/auth/actions';
import SideMenu from '~/pages/_partials/SideMenu';
import Footer from '~/pages/_partials/Footer';
import ModalCompany from '~/components/ModalCompany';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { Container, Content } from './styles';
import Header from '~/pages/_partials/Header';
import Logo from '~/components/Logo';

const { Sider } = Layout;

export default function DefaulLayout({ breadcrumb, children }) {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalCompany, setModalCompany] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);
  const dispatch = useDispatch();

  const fetchModalCompany = () => {
    setModalCompany(
      <ModalCompany
        visible={store.getState().auth.signed && store.getState().user.profile.company === null}
        onClose={() => {
          fetchModalCompany();
        }}
      />
    );
  }

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/find');
     /* const response = await api.get('/notices/findById', {
        params: { id: 1 },
        headers: { 'Content-Type': 'multipart/form-data' },
      });*/
    } catch (error) {
      if (error.message === 'Network Error') {
        dispatch(tokenExpired());
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const sizeOfThings = () => {
    var windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', function() {
    sizeOfThings();
  });

  useEffect(() => {
    fetchRecords();
    fetchModalCompany();
    sizeOfThings();
  }, []);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Container id="fullLayout" className="layout">
      <Header>
        {windowWidth < 500 ?
          <Logo logoName="logo-light.png" width="auto" height="35" />
        :<Logo logoName="logo-light.png" width="auto" height="35" />
        }
      </Header>
      <Layout>
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          width={200}
          className="site-layout-background"
        >
          <div className="logo" />
          <SideMenu />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          {breadcrumb}
          <Content
            className="site-layout-background"
            style={{ padding: 0, margin: 0, minHeight: 280 }}
          >
            {children}
            {modalCompany}
          </Content>
          <Footer/>
        </Layout>
      </Layout>
    </Container>
  );
}

DefaulLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
