import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin, Col, Avatar, List, Button as AntButton } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
// import { ModalFooter } from '~/components/Modal';
import errorHandler from 'Utils/errorHandler';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { updateTask } from '~/services/hooks/tasks';

export default function CommentsForm({ item, visible, onClose, onUpdateItem }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.user.profile);

  const handleSave = async (values, { setErrors }) => {
    delete values.newComment; // remove newComment from values
    setLoading(true);
    try {
      const data = await updateTask(values);
      onUpdateItem(data);
      // onClose();
    } catch (error) {
      errorHandler(error);
      setErrors(error);
    }
    setLoading(false);
  };

  return (
    <Formik initialValues={item} enableReinitialize onSubmit={handleSave}>
      {({ isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
        <Modal
          width="650px"
          title={t('screens:task.commentsTitle')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={null}
          // footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                {/* PROJECT DESCRIPTION */}
                <Col span={24}>
                  <FormControl field="project.description" label={t('screens:task.projectName')}>
                    <span>{values?.project?.description}</span>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                {/* TASK TILTE */}
                <Col span={24}>
                  <FormControl field="title" label={t('screens:task.data.title')}>
                    <span>{values?.title}</span>
                  </FormControl>
                </Col>
              </Row>
              {/* TASK COMMENTS */}
              <Row>
                <Col span={24} style={{ paddingTop: 10 }}>
                  <List
                    size="small"
                    bordered
                    // data source of comments order by date
                    dataSource={values?.comments?.sort((a, b) => new Date(b.date) - new Date(a.date))}
                    header={
                      <>
                        <Input.TextArea showCount maxLength={250} name="newComment" />
                        <AntButton
                          style={{ marginTop: 10, marginBottom: 10 }}
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            values?.newComment &&
                              setFieldValue('comments', [
                                ...values?.comments,
                                {
                                  id: Math.random(),
                                  user: profile,
                                  description: values?.newComment,
                                  date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                },
                              ]) &&
                              setFieldValue('newComment', '');
                            submitForm();
                          }}
                        >
                          Add Comment
                        </AntButton>
                      </>
                    }
                    // START LIST OF COMMENTS
                    renderItem={comment => (
                      <List.Item
                        key={comment.id}
                        align="start"
                        actions={[
                          comment.user_id === profile?.id && (
                            <AntButton
                              danger
                              type="default"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setFieldValue(
                                  'comments',
                                  values?.comments?.filter(t => t.id !== comment.id)
                                );
                                submitForm();
                              }}
                            />
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src={comment.image} />}
                          title={comment.user.name + ' - ' + moment(comment.date).format('YYYY/MM/DD HH:mm')}
                          description={comment.description}
                        />
                      </List.Item>
                    )}
                    // END LIST OF COMMENTS
                  />
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
