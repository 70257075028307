import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const { Option } = Select;

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters, user, subVendors }) {
  const { t } = useTranslation();

  const handleSearch = values => {
    if (values.descriptionFilter === '0') {
      values.descriptionFilter = '';
    }

    const filter = [];
    try {
      if (
        values.descriptionFilter !== undefined &&
        values.descriptionFilter !== null &&
        values.descriptionFilter !== ''
      ) {
        filter.push({
          field: 'description',
          value: values.descriptionFilter,
          restriction: 'LIKE',
        });
      }
      if (
        values.subVendorsFilter !== undefined &&
        values.subVendorsFilter !== null &&
        values.subVendorsFilter !== 'All'
      ) {
        if (values.subVendorsFilter === 'owner') {
          filter.push({
            field: 'subVendor',
            restriction: 'IS_NULL',
          });
        } else {
          filter.push({
            columnJoin: 'subVendor',
            field: 'id',
            value: values.subVendorsFilter,
            restriction: 'EQUAL',
          });
        }
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ descriptionFilter: '', subVendorsFilter: 'All' }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span="10">
                  <FormControl
                    error={errors.descriptionFilter}
                    field="descriptionFilter"
                    label={t('screens:closeout.files.description')}
                  >
                    <Input name="descriptionFilter" placeholder={t('screens:closeout.files.descriptionPlaceholder')} />
                  </FormControl>
                </Col>
                <Col span="6">
                  <FormControl
                    error={errors.subVendorsFilter}
                    field="subVendorsFilter"
                    label={t('screens:closeout.subcontractor.companyName')}
                  >
                    <Select name="subVendorsFilter">
                      <Option value="All">All</Option>
                      <Option value="owner">{user?.company?.name}</Option>
                      {subVendors?.map(item => {
                        return (
                          <Option key={item?.id} value={item?.id.toString()}>
                            {item?.companyName}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
