import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Img = styled.img`
  background-size: 100px 25px;
  width: 100px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;