import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 300%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #071d3d;
  background: linear-gradient(90deg, #071d3d 0%, #0152a2 50%, #012a53 100%);
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
`;
