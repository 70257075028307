import styled from 'styled-components';
import { Layout } from 'antd';

export const Container = styled(Layout)`
  min-height: 100%;

  > .ant-layout-content {
    padding: 30px;
  }

  .ant-layout-header {
    background: white;
  }

  .ant-layout-sider-light .ant-layout-sider-trigger {
    background: #f7f7f7;
  }
`;

export const Content = styled(Layout.Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;
`;
