import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, message, Col } from 'antd';
import AddressForm from './form';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import { Table, TableActions } from '~/components/Table';

export default function Addresses({ addressType, addresses, onHandleAddresses }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchRecords = () => {
    setLoading(true);

    const filteredAddresses = [];
    const selectedAddresses = [];

    addresses.forEach((address) => {
      if (address.type.toString() === addressType) {
        filteredAddresses.push(address);

        if (address.main === true) {
          selectedAddresses.push(address.uuid);
        }
      }
    });

    setTableData(filteredAddresses);
    setSelectedRowKeys(selectedAddresses);
    setLoading(false);
  };

  const handleEdit = address => {
    setSelectedRecord(address);
    setShowForm(true);
  };

  const handleDelete = address => {
    setLoading(true);
    addresses = addresses.filter(item => item.uuid !== address.uuid);
    onHandleAddresses(addresses);
    fetchRecords();
    message.success(t('messages:addressSuccessDeleted'));
    setLoading(false);
  };

  function addNewAddress(address) {
    address.uuid = addresses.length > 0 ? addresses[addresses.length - 1].uuid + 1 : 1;
    addresses.push(address);
    onHandleAddresses(addresses);
  }

  function updateAddress(oldAddress, newAddress) {
    addresses[addresses.indexOf(oldAddress)] = newAddress;
    onHandleAddresses(addresses);
  }

  useEffect(() => {
    fetchRecords();
  }, [showForm, addresses]);

  const style = {
    billing: { backgroundColor: '#52c41a' },
    shipping: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    }
  }

  function getStyle(value) {
    switch (value) {
      case 'active':
        return (style.billing);
      default:
        return (style.shipping);
    }
  }

  const tableColumns = [
    {
      title: t('screens:addresses.data.addressLineOne'),
      dataIndex: 'addressLineOne',
      key: 'addressLineOne',
    },
    {
      title: t('screens:addresses.data.city'),
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: t('screens:addresses.data.state'),
      dataIndex: 'state',
      key: 'state',
    },
    /*{
      title: t('screens:addresses.data.type'),
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '100px',
      render: type =>
        <Badge
          count={t('screens:addresses.data.' + type.toLowerCase())}
          style={getStyle(type.toLowerCase())} />
    },*/
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button
            style={{padding: '0, 1px'}}
            onClick={() => handleEdit(record)}
            title={t('messages:edit')}>
              <EditOutlined />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      addresses.forEach((address) => {
        if (address.type.toString() === addressType) {
          address.main = address.uuid === selectedRowKeys[0] ? true : false;
        }
      });

      onHandleAddresses(addresses);
    },
  };

  return (
    <Box>
      <Row>
        <Col span={12} style={style.btnNew.col}>
          <Button
            loading={loading}
            color="primary"
            onClick={() => {
              setSelectedRecord(null);
              setShowForm(true);
            }}
            style={style.btnNew.btn}
          >
            <PlusOutlined />
            {t('screens:addresses.btnNew')}
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        rowKey="uuid"
        rowSelection={{
          type: 'radio',
          columnTitle: t('screens:addresses.data.main'),
          ...rowSelection,
        }}
        pagination={true}
        loading={loading}
        columns={tableColumns}
        dataSource={tableData}
      />

      <AddressForm
        address={selectedRecord}
        type={addressType}
        visible={showForm}
        onClose={() => setShowForm(false)}
        onAddNewAddress={(e) => addNewAddress(e)}
        onUpdateAddress={(oldAddress, newAddress) => updateAddress(oldAddress, newAddress)} />
    </Box>
  );
}
