import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Popconfirm, message, Spin, Col, Breadcrumb, Badge } from 'antd';
import { Form, Select, Input } from 'formik-antd';
import { Formik } from 'formik';
import UserForm from './form';
import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';

export default function Users() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [roles, setRoles] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const { Option } = Select;

  const fetchRecords = async (filters) => {
    setLoading(true);
    try {
      const pagePaginationDTO = { page: 0, sortBy: 'name', filters: filters};
      const { data } = await api.post('/users/pagination', pagePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles/findAll');
      data.splice(0, 0, { id: 0, description: 'All' });
      setRoles(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async id => {
    setLoading(true);

    try {
      await api.delete(`/users/delete/${id}`);
      fetchRecords(filters);
      message.success(t('messages:success'));
    } catch (error) {
      if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
        message.warning(t('messages:user:foreignKeyViolation'));
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const handleSearch = values => {
    setLoading(true);

    if (values.nameFilter === '0') {
      values.nameFilter = '';
    }
    if (values.roleFilter === '0') {
      values.roleFilter = 'All';
    }
    if (values.statusFilter === 'All') {
      values.statusFilter = 'ALL';
    }

    const filter = [];

    try {
      if (values.nameFilter !== undefined && values.nameFilter !== null && values.nameFilter !== '') {
        filter.push({
          field: 'name',
          value: values.nameFilter,
          restriction: 'LIKE',
        });
      }
      if (values.roleFilter !== undefined && values.roleFilter !== null && values.roleFilter !== 'All') {
        filter.push({
          columnJoin: 'roles',
          field: 'id',
          value: values.roleFilter,
          restriction: 'EQUAL',
        });
      }
      if (values.statusFilter !== undefined && values.statusFilter !== null && values.statusFilter !== 'ALL') {
        filter.push({
          field: 'status',
          value: values.statusFilter,
          restriction: 'EQUAL',
        });
      }
      setFilters(filter);
      fetchRecords(filter);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async page => {
    setLoading(true);
    try {
      const paginationDTO = { page: page - 1, sortBy: 'name', filters: filters};
      const { data } = await api.post('/users/pagination', paginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber + 1,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords([]);
    fetchRoles();
  }, [showForm]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { backgroundColor: '#52c41a' },
    inactive: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
    btnClear: { marginLeft: 'auto', marginTop: 25 },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    }
  }

  const status = [
    {
      id: 0,
      code: 'ALL',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 'ACTIVE',
      description: t('screens:status.active'),
    },
    {
      id: 2,
      code: 'INACTIVE',
      description: t('screens:status.inactive'),
    },
  ];

  const tableColumns = [
    {
      title: t('screens:users.data.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:users.data.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('screens:users.data.role'),
      dataIndex: 'roleDescription',
      key: 'roleDescription',
    },
    {
      title: t('screens:users.data.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '100px',
      render: status =>
        status === 'ACTIVE' ? (
          <Badge count={t('screens:users.data.active')} style={style.active} />
        ) : (
          <Badge count={t('screens:users.data.inactive')} style={style.inactive} />
        ),
    },
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}><EditOutlined /></Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb =
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/">{t('menus:settings')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:users')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  ;

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:users.title')} style={style.pageTitle} />
      <Box>
        <Formik
          initialValues={{ nameFilter: '', roleFilter: 'All', statusFilter: 'All' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={7} xl={9}>
                    <FormControl error={errors.nameFilter} field="nameFilter" label={t('screens:users.data.name')}>
                      <Input name="nameFilter" placeholder={t('fields:users.name.placeholder')} />
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                    <FormControl error={errors.roleFilter} field="roleFilter" label={t('screens:users.data.role')}>
                      <Select name="roleFilter">
                        {roles.map(item => {
                          return (
                            <Option key={item.id} value={item.id.toString()}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                    <FormControl error={errors.statusFilter} field="statusFilter" label={t('screens:users.data.status')}>
                      <Select name="statusFilter">
                        {status.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Button
                    color="default"
                    style={style.btnClear}
                    onClick={() => {
                      resetForm();
                      setFilters([]);
                      fetchRecords([]);
                      fetchRoles();
                    }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                  <Button type="submit" color="primary" style={style.btnSubmit}>
                    <SearchOutlined />
                    {t('screens:dateSearch.button')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:users.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />

        <Pagination
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
        <UserForm id={selectedRecord} visible={showForm} disabledImage={true} onClose={() => setShowForm(false)} />
      </Box>
    </DefautLayout>
  );
}
