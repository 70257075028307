import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();

  const handleSearch = values => {
    if (values.contractForFilter === '0') {
      values.contractForFilter = '';
    }
    const filter = [];

    try {
      if (
        values.contractForFilter !== undefined &&
        values.contractForFilter !== null &&
        values.contractForFilter !== ''
      ) {
        filter.push({
          field: 'contractFor',
          value: values.contractForFilter,
          restriction: 'LIKE',
        });
      }
      // if (values.scopeFilter !== undefined && values.scopeFilter !== null && values.scopeFilter !== '') {
      //   filter.push({
      //     field: 'scope',
      //     value: values.scopeFilter,
      //     restriction: 'LIKE',
      //   });
      // }

      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          // scopeFilter: '',
          contractForFilter: '',
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                {/* <Col span={10}>
                  <FormControl error={errors.scopeFilter} field="scopeFilter" label={t('screens:payAppsClients.scope')}>
                    <Input name="scopeFilter" placeholder={t('screens:payAppsClients.scopePlaceholder')} />
                  </FormControl>
                </Col> */}
                <Col span={10}>
                  <FormControl
                    error={errors.contractForFilter}
                    field="contractForFilter"
                    label={t('screens:payAppsClients.contractFor')}
                  >
                    <Input name="contractForFilter" placeholder={t('screens:payAppsClients.contractForPlaceholder')} />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
