import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

const initialValues = {
    id: null,
    firstName: '',
    lastName: '',
    jobTitle: '',
    mobilePhone: '',
    mainPhone: '',
    workPhone: '',
    mainEmail: '',
    ccEmail: ''
};

export default function ContactForm({ visible, onClose, onChange, contact }) {
    const { t } = useTranslation();
    const [recordData, setRecordData] = useState(initialValues);
    const [loading, setLoading] = useState(false);

    const fetchRecords = async () => {
        setLoading(true);
        setRecordData(contact === null ? initialValues : contact);
        setLoading(false);
    }

    const handleSave = async (values, { setErrors }) => {
        setLoading(true);
        const c = {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: values.jobTitle,
            mobilePhone: values.mobilePhone,
            mainPhone: values.mainPhone,
            workPhone: values.workPhone,
            mainEmail: values.mainEmail,
            ccEmail: values.ccEmail
        };
        setLoading(false);
        onChange(c);
    };

    useEffect(() => {
        if (visible) {
            fetchRecords();
        }
    }, [visible]);

    const userSchema = Yup.object().shape({
        firstName: Yup.string().min(2, 'Too Short!').max(25, 'Too Long!').required(),
        lastName: Yup.string().min(2, 'Too Short!').max(25, 'Too Long!').required(),
        jobTitle: Yup.string().min(2, 'Too Short!').max(40, 'Too Long!').required(),
        mobilePhone: Yup.string().max(27, 'Too Long!'),
        mainPhone: Yup.string().max(27, 'Too Long!'),
        workPhone: Yup.string().max(27, 'Too Long!'),
        mainEmail: Yup.string().typeError('Insert your e-mail').email('Invalid email'),
        ccEmail: Yup.string().typeError('Insert your e-mail').email('Invalid email'),
    });

    return (
        <Formik
            initialValues={recordData}
            enableReinitialize
            onSubmit={handleSave}
            validationSchema={userSchema}
        >
            {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
                <Modal
                    title={contact !== null ? t('screens:contacts.btnEdit') : t('screens:contacts.btnNew')}
                    onCancel={onClose}
                    afterClose={resetForm}
                    open={visible}
                    loading={loading || isSubmitting}
                    footer={
                        <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
                    }
                >
                    <Spin spinning={loading || isSubmitting}>
                        <Form>
                            <Row>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="firstName"
                                    label={t('screens:customers.data.firstName')}
                                    error={touched.firstName && errors.firstName}
                                    required
                                >
                                    <Input name="firstName" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="lastName"
                                    label={t('screens:customers.data.lastName')}
                                    error={touched.lastName && errors.lastName}
                                    required
                                >
                                    <Input name="lastName" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="jobTitle"
                                    label={t('screens:customers.data.jobTitle')}
                                    error={touched.jobTitle && errors.jobTitle}
                                    required
                                >
                                    <Input name="jobTitle" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="mobilePhone"
                                    label={t('screens:customers.data.mobilePhone')}
                                    error={touched.mobilePhone && errors.mobilePhone}
                                >
                                    <Input name="mobilePhone" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="mainPhone"
                                    label={t('screens:customers.data.mainPhone')}
                                    error={touched.mainPhone && errors.mainPhone}
                                >
                                    <Input name="mainPhone" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="workPhone"
                                    label={t('screens:customers.data.workPhone')}
                                    error={touched.workPhone && errors.workPhone}
                                >
                                    <Input name="workPhone" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="mainEmail"
                                    label={t('screens:customers.data.mainEmail')}
                                    error={touched.mainEmail && errors.mainEmail}
                                >
                                    <Input type="email" name="mainEmail" autoComplete="off" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12 }}
                                    field="ccEmail"
                                    label={t('screens:customers.data.ccEmail')}
                                    error={touched.ccEmail && errors.ccEmail}
                                >
                                    <Input type="email" name="ccEmail" />
                                </FormControl>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            )}
        </Formik>
    );
}