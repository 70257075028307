import React, { useState, useEffect } from 'react';
import { Col, Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createSpecBookSubmittial, updateSpecBookSubmittial } from '~/services/hooks/specBookSubmittials';
import { paginationUsers } from '~/services/hooks/users';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function RFIForm({ project, user, selectedRecord, isReply, visible, onClose }) {
  const initialValues = {
    id: null,
    type: 'RFI',
    description: '',
    reply: null,
    project: project,
    sentBy: user,
    arquive: null,
    users: [],
  };

  // remove replies object from selectedRecord object
  if (selectedRecord?.replies) delete selectedRecord.replies;

  const { Option } = Select;
  const [uploadList, setUploadList] = useState([]);
  const submittial = isReply ? initialValues : { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function loadUsers() {
      const { content } = await paginationUsers({
        page: 0,
        maxSize: true,
        filters: [
          {
            columnJoin: 'roles',
            field: 'name',
            value: 'ROLE_ADMIN',
            restriction: 'NOT_EQUAL',
          },
        ],
      });
      setUsers(content);
    }
    loadUsers();
  }, []);

  const handleSave = async values => {
    delete values.undefined; // delete 'undefined' from values // Object.keys(values).forEach(key => values[key] === 'undefined' && delete values[key]);
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    if (isReply) {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      selectedRecord.replied = true;
      values.reply = selectedRecord;
      await createSpecBookSubmittial(values); // create reply
      await updateSpecBookSubmittial(selectedRecord); // update replied
      onClose();
    } else {
      if (values.id !== null) {
        await updateSpecBookSubmittial(values); // update submittial
      } else {
        if (uploadList.length < 1) {
          message.error(t('messages:uploadFileRequired'));
          setLoading(false);
          return;
        }
        await createSpecBookSubmittial(values); // create submittial
      }
    }
    setLoading(false);
    onClose();
  };

  const submittialSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  const handleTitle = () => {
    if (isReply) return t('screens:RFI.isReplyTitle');
    if (submittial.id !== null) return t('screens:RFI.btnEdit');
    return t('screens:RFI.btnNew');
  };

  return (
    <Formik
      initialValues={submittial}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={submittialSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue, touched }) => (
        <Modal
          width="650px"
          title={handleTitle()}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:RFI.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" placeholder={t('fields:RFI.descriptionPlaceholder')} />
                  </FormControl>
                </Col>
              </Row>
              {values.id !== null && (
                <Row>
                  <Col span={24}>
                    <FormControl
                      field="type"
                      label={t('screens:RFI.data.type')}
                      error={(touched.type && errors.type) || errors.type}
                    >
                      <Select
                        name="type"
                        disabled={
                          isReply ? true : user.roles.map(role => role.name).includes('ROLE_ADMIN') ? false : true
                        }
                      >
                        <Option value="SPEC_BOOK">SPEC BOOK</Option>
                        <Option value="SUBMITTIAL">SUBMITTIAL</Option>
                        <Option value="BOTH">BOTH</Option>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <FormControl field="submittial_file">
                    <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={submittial?.arquive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
