import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, SelectOutlined } from '@ant-design/icons';
import { Spin, Col, Modal } from 'antd';
import { Form, Select, Input } from 'formik-antd';
import { Formik } from 'formik';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Row from '~/components/Row';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions } from '~/components/Table';
import { Footer } from './styles';

export default function ContactsModal({ visible, onClose, onSelect }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState([]);

    const { Option } = Select;

    const fetchRecords = async (filters) => {
        setLoading(true);
        try {
            const pagePaginationDTO = { page: 0, sortBy: 'firstName', filters: filters };
            const { data } = await api.post('/contacts/pagination', pagePaginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleSearch = values => {
        setLoading(true);

        if (values.firstName === '0') {
            values.firstName = '';
        }

        const filter = [];

        try {
            if (values.firstName !== undefined && values.firstName !== null && values.firstName !== '') {
                filter.push({
                    field: 'firstName',
                    value: values.firstName,
                    restriction: 'LIKE',
                });
            }

            setFilters(filter);
            fetchRecords(filter);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleChangePage = async page => {
        setLoading(true);
        try {
            const paginationDTO = { page: page - 1, sortBy: 'firstName', filters: filters };
            const { data } = await api.post('/contacts/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleSelect = contact => {
        onSelect(contact);
    };

    useEffect(() => {
        if (visible) {
            fetchRecords();
        }
    }, [visible]);

    const style = {
        btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
        btnClear: { marginLeft: 'auto', marginTop: 25 },
    }

    const tableColumns = [
        {
            title: t('screens:contacts.data.firstName'),
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: t('screens:contacts.data.lastName'),
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: t('screens:contacts.data.jobTitle'),
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        },
        {
            title: t('screens:users.data.actions'),
            key: 'actions',
            align: 'center',
            width: '10%',
            className: 'table-action',
            render: (text, record) => (
                <TableActions>
                    <Button onClick={() => handleSelect(record)} title={t('messages:edit')}><SelectOutlined /></Button>
                </TableActions>
            ),
        },
    ];

    return (
        <Modal
            title={t('screens:contacts.btnChoose')}
            onCancel={onClose}
            open={visible}
            loading={loading}
            footer={
                <Footer>
                  <Button onClick={() => onClose()} color="default">{'Close'}</Button>
                </Footer>
              }
        >
            <Spin spinning={loading}>
                <Formik
                    initialValues={{ firstName: '' }}
                    enableReinitialize
                    onSubmit={handleSearch}
                >
                    {({ errors, submitForm, resetForm }) => (
                        <Spin spinning={loading}>
                            <Form>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={7} xl={9}>
                                        <FormControl error={errors.firstName} field="firstName" label={t('screens:contacts.data.firstName')}>
                                            <Input name="firstName" placeholder={t('fields:contacts.firstName.placeholder')} />
                                        </FormControl>
                                    </Col>
                                    <Button
                                        color="default"
                                        style={style.btnClear}
                                        onClick={() => {
                                            resetForm();
                                            setFilters([]);
                                            fetchRecords([]);
                                        }}
                                    >
                                        {t('screens:dateSearch.clearButton')}
                                    </Button>
                                    <Button type="submit" color="primary" style={style.btnSubmit}>
                                        <SearchOutlined />
                                        {t('screens:dateSearch.button')}
                                    </Button>
                                </Row>
                            </Form>
                        </Spin>
                    )}
                </Formik>

                <Table
                    bordered
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={tableData}
                />

                <Pagination
                    onChange={value => {
                        handleChangePage(value);
                    }}
                    meta={meta}
                />
            </Spin>
        </Modal>
    );
}