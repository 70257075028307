import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BankFilled, DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Popconfirm, message, Col } from 'antd';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import PurchaseOrderForm from './form';
import PoDownloadModal from './modal';
import Filter from './filter';
import { paginationPurchaseOrders, deletePurchaseOrder } from '~/services/hooks/purchaseOrders';

export default function PurchaseOrders({ project, customer }) {

  const projectId = project.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [showDownloadModal, setDownloadModal] = useState(false);
  const [meta, setMeta] = useState({});
  const [sortBy, setSortBy] = useState('id'); // description
  const [sortDirection, setSortDirection] = useState('DESC');
  const [tableData, setTableData] = useState([]);
  const defaultFilter = { columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' };
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationPurchaseOrders({ page: page - 1, sortBy, sortDirection, filters: [...filters, defaultFilter] });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const handleDelete = async id => {
    await deletePurchaseOrder(id);
    fetchData(1);
  };

  // useEffect(() => {
  //   fetchRecords([]);
  // }, [showForm]);

  const style = {
    subVendor: { color:'#a1a1a1', marginRight: '8px' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    }
  }

  const tableColumns = [
    {
      title: t('screens:purchaseOrders.data.title'),
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: t('screens:purchaseOrders.data.date'),
    //   dataIndex: 'date',
    //   key: 'date',
    //   render: (text, record) => (<>{format(parse(record.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'MM/dd/yyyy')}</>),
    // },
    {
      title: t('screens:purchaseOrders.data.project'),
      dataIndex: 'project',
      key: 'project',
      render: (text, record) => (
        <>{record.project !== null ? record.project.description : ""}</>
      ),
    },
    {
      title: t('screens:purchaseOrders.data.subContractor'),
      dataIndex: 'subVendor',
      key: 'subVendor',
      render: (text, record) => (
        <>{record.subVendor !== null ?
          <>
            <div><UserOutlined style={style.subVendor}/>{record.subVendor?.contact?.firstName + " " + record.subVendor?.contact?.lastName}</div>
            <div><BankFilled style={style.subVendor}/>{record.subVendor?.companyName}</div>
          </>
          : ""}
        </>
      ),
    },
    {
      title: t('screens:purchaseOrders.data.customer'),
      key: 'customer',
      render: (record) => (
        <>{record.customer !== null ?
          <>
            {record.customer.customerContact !== null ?
              <div><UserOutlined style={style.subVendor}/>{record.customer.customerContact.primaryContact.firstName  + " " + record.customer.customerContact.primaryContact.lastName}</div>
            : null}
            <div><BankFilled style={style.subVendor}/>{record.customer.companyName}</div>
          </>
          : ""}
        </>
      ),
    },
    {
      title: t('screens:purchaseOrders.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (record) => (
        <TableActions>
          <Button
            onClick={() => {
              setSelectedRecord(record);
              setDownloadModal(true);
            }}
            title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}><EditOutlined /></Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];
  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:purchaseOrders.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        <PurchaseOrderForm selectedRecord={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} project={project}/>
        <PoDownloadModal id={selectedRecord?.id} visible={showDownloadModal} onClose={() => setDownloadModal(false)} />
      </Box>
    </>
  );
}
