import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Descriptions, Typography, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { formatPrice } from '~/Utils';
import Box from '~/components/Box';
import { downloadFile } from '~/services/hooks/files';
import moment from 'moment';
import { findByIdData, findByIdDataProject } from '~/services/hooks/projects';

export default function Informations({ project, customer }) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [invoiced, setInvoiced] = useState(0);
  const [payments, setPayments] = useState(0);
  const [purchaseOrders, setPurchaseOrders] = useState(0);
  const { Text } = Typography;

  const style = {
    pageTitle: { color: '#0f4c81', fontSize: '20px' },
    divider: { margin: '0px' },
    cardBody: { background: '#F5F5F5', paddingTop: '10px', paddingBottom: '10px' },
    badgeCount: {
      minWidth: '14px',
      height: '14px',
      padding: '0 8px',
      fontSize: '12px',
      lineHeight: '14px',
      borderRadius: '7px',
      textAlign: 'center',
      background: '#E6F7FF',
      color: '#1890FF',
      marginLeft: '3px',
    },
    contacts: { color: '#a1a1a1', marginRight: '8px' },
    statistic1: { fontSize: '18px', textAlign: 'center' },
    statistic2: { fontSize: '18px' },
  };

  useEffect(() => {
    findByIdDataProject(project.id).then(project => {
      setInvoiced(project.invoiced);
      setPayments(project.payments);
      setPurchaseOrders(project.purchaseOrders);
    });
  }, []);

  return (
    <>
      <Box>
        <Row gutter={16}>
          <Col span={24}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Descriptions
                // title={<Title level={4}>INFORMATION ABOUT THE PROJECT/BID AND CUSTOMER/LEAD</Title>}
                layout="vertical"
                bordered
                column={4}
                // contentStyle={{ width: '70%' }}
              >
                <Descriptions.Item label={t('screens:informations.description').toUpperCase()}>
                  <b>{t('screens:informations.description')}: </b> {project.description}
                  <br />
                  <b>{t('screens:informations.type')}: </b> {project.type}
                  <br />
                  <b>{t('screens:informations.status')}: </b> {project.status}
                  <br /><br />
                </Descriptions.Item>
                <Descriptions.Item label={t('screens:informations.dates').toUpperCase()}>
                  <b>{t('screens:informations.start')}: </b>
                  {moment(project.startDate).format('MM/DD/yyyy')}
                  <br />
                  <b>{t('screens:informations.projectedEnd')}: </b>
                  {moment(project.endDate).format('MM/DD/yyyy')}
                  <br />
                  <b>{t('screens:informations.actualEnd')}: </b>
                  {moment(project.actualEndDate).format('MM/DD/yyyy')}
                  <br />
                  <b>{t('screens:informations.insuranceExpiration')}: </b>
                  {moment(project.insuranceExpirationDate).format('MM/DD/yyyy')}
                </Descriptions.Item>
                <Descriptions.Item label={t('screens:informations.customer').toUpperCase()}>
                  <b>{t('screens:informations.companyName')}: </b> {customer.companyName} <br />
                  <b>{t('screens:informations.type')}: </b> {customer.flag} <br />
                  <b>{t('screens:informations.createDate')}: </b> {customer.createDate} <br />
                  <b>{t('screens:informations.createBy')}: </b> {customer.createBy} <br />
                </Descriptions.Item>
                <Descriptions.Item label="ARCHIVES">
                  {/* <b>Tenant's Insurance : </b>{customer.tenantInsurance}  
                  <br />*/}
                  <b>{t('screens:informations.tenantsContractInPlace')}: </b>
                  { project.contract ? <a>{t('screens:informations.download')} <DownloadOutlined onClick={async () => await downloadFile(project.contract)} /></a> : null }
                  <br />
                  <b>{t('screens:informations.tenantsInsuranceOnFile')}: </b>
                  { project.tenantInsurance ? <a>{t('screens:informations.download')} <DownloadOutlined onClick={async () => await downloadFile(project.tenantInsurance)} /></a> : null }
                  <br /><br /><br />
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                layout="vertical"
                bordered
                column={4}
                // contentStyle={{ width: '70%' }}
              >
                {/*<Descriptions.Item label="BASE PRICE"> {formatPrice(project.basePrice)}</Descriptions.Item>
                <Descriptions.Item label="SELECTIONS"> {formatPrice(project.selections)}</Descriptions.Item>*/}
                {/*<Descriptions.Item label="TOTAL PRICE"> {formatPrice(project.totalPrice)}</Descriptions.Item>*/}
                <Descriptions.Item label={t('screens:informations.purchaseOrders').toUpperCase()}> {formatPrice(purchaseOrders)}</Descriptions.Item>
                <Descriptions.Item label={t('screens:informations.invoiced').toUpperCase()}> {formatPrice(invoiced)}</Descriptions.Item>
                <Descriptions.Item label={t('screens:informations.payments').toUpperCase()}> {formatPrice(payments)}</Descriptions.Item>
                {/*<Descriptions.Item label="AMOUNT DUE"> {formatPrice(project.amountDue)}</Descriptions.Item>*/}
              </Descriptions>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="horizontal" align="start" size="middle" style={{ paddingTop: 30, display: 'flex' }}>
              {/* PROPERTY */}
              <Card
                title={<Text style={{ fontSize: 14 }}>{t('screens:informations.property').toUpperCase()}</Text>}
                bordered={true}
                bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                style={{ fontSize: 12, backgroundColor: '#fafafa' }}
              >
                <Descriptions
                  layout="horizontal"
                  bordered
                  column={1}
                  contentStyle={{ backgroundColor: '#ffffff' }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, width: 220, fontWeight: 'bold' }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.name')}>{project.property?.name}</Descriptions.Item>
                  {/*<Descriptions.Item label="Representative Company">
                    {project.property?.representativeCompany}
                  </Descriptions.Item>
                  <Descriptions.Item label="Representative Name">
                    {project.property?.representativeName}
                  </Descriptions.Item>*/}
                  <Descriptions.Item label={t('screens:informations.representativePhone')}>
                    {project.property?.phone}
                  </Descriptions.Item>
                  {/*<Descriptions.Item label="Representative Email">
                    {project.property?.representativeEmail}
                  </Descriptions.Item>*/}
                </Descriptions>

                <p
                  style={{
                    fontSize: 14,
                    paddingLeft: 40,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {t('screens:informations.address')}
                </p>
                <Descriptions
                  layout="horizontal"
                  column={1}
                  contentStyle={{ backgroundColor: '#ffffff' }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.type')}>{project.property?.address.type}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineOne')}>
                    {project.property?.address.addressLineOne}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineTwo')}>
                    {project.property?.address.addressLineTwo}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.city')}>{project.property?.address.city}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.state')}>{project.property?.address.state}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.zipCode')}>{project.property?.address.zipCode}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.country')}>{project.property?.address.country}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.note')}>{project.property?.address.note}</Descriptions.Item>
                </Descriptions>
              </Card>

              {/* OWNERSHIP */}
              <Card
                title={<Text style={{ fontSize: 14 }}>{t('screens:informations.ownership').toUpperCase()}</Text>}
                bordered={true}
                bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                style={{ fontSize: 12, backgroundColor: '#fafafa' }}
              >
                <Descriptions
                  layout="horizontal"
                  bordered
                  column={1}
                  // contentStyle={{ width: 160 }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, width: 220, fontWeight: 'bold' }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.name')}>{project.ownership?.name}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.representativeCompany')}>
                    {project.ownership?.representativeCompany}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.representativeName')}>
                    {project.ownership?.representativeName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.representativePhone')}>
                    {project.ownership?.representativePhone}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.representativeEmail')}>
                    {project.ownership?.representativeEmail}
                  </Descriptions.Item>
                </Descriptions>

                <p
                  style={{
                    fontSize: 14,
                    paddingLeft: 40,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {t('screens:informations.address')}
                </p>
                <Descriptions
                  layout="horizontal"
                  column={1}
                  contentStyle={{ backgroundColor: '#ffffff' }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.type')}>{project.ownership?.address.type}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineOne')}>
                    {project.ownership?.address.addressLineOne}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineTwo')}>
                    {project.ownership?.address.addressLineTwo}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.city')}>{project.ownership?.address.city}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.state')}>{project.ownership?.address.state}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.zipCode')}>{project.ownership?.address.zipCode}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.country')}>{project.ownership?.address.country}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.note')}>{project.ownership?.address.note}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="horizontal" align="start" size="middle" style={{ paddingTop: 30, display: 'flex' }}>
              {/* ARCHITECT */}
              <Card
                title={<Text style={{ fontSize: 14 }}>{t('screens:informations.architect').toUpperCase()}</Text>}
                bordered={true}
                bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                style={{ fontSize: 12, backgroundColor: '#fafafa' }}
              >
                <Descriptions
                  layout="horizontal"
                  bordered
                  column={1}
                  // contentStyle={{ width: 160 }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, width: 220, fontWeight: 'bold' }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.name')}>{project.architect.companyName}</Descriptions.Item>
                  {/*<Descriptions.Item label="Representative Company">
                    {project.architect.representativeCompany}
                  </Descriptions.Item>*/}
                  <Descriptions.Item label={t('screens:informations.representativeName')}>
                    {project.architect.representativeName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.representativePhone')}>
                    {project.architect.representativePhone}
                  </Descriptions.Item>
                  {/*<Descriptions.Item label="Representative Email">
                    {project.architect.representativeEmail}
                  </Descriptions.Item>*/}
                </Descriptions>
                <p
                  style={{
                    fontSize: 14,
                    paddingLeft: 40,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {t('screens:informations.address')}
                </p>
                <Descriptions
                  layout="horizontal"
                  column={1}
                  contentStyle={{ backgroundColor: '#ffffff' }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.type')}>{project.architect.address.type}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineOne')}>
                    {project.architect.address.addressLineOne}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineTwo')}>
                    {project.architect.address.addressLineTwo}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.city')}>{project.architect.address.city}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.state')}>{project.architect.address.state}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.zipCode')}>{project.architect.address.zipCode}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.country')}>{project.architect.address.country}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.note')}>{project.architect.address.note}</Descriptions.Item>
                </Descriptions>
              </Card>

              {/* DESIGNER */}
              <Card
                title={<Text style={{ fontSize: 14 }}>{t('screens:informations.designer').toUpperCase()}</Text>}
                bordered={true}
                bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                style={{ fontSize: 12, backgroundColor: '#fafafa' }}
              >
                <Descriptions
                  layout="horizontal"
                  bordered
                  column={1}
                  // contentStyle={{ width: 160 }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, width: 220, fontWeight: 'bold' }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.name')}>{project.designer?.companyName}</Descriptions.Item>
                  {/*<Descriptions.Item label="Representative Company">
                    {project.designer?.representativeCompany}
                  </Descriptions.Item>*/}
                  <Descriptions.Item label={t('screens:informations.representativeName')}>
                    {project.designer?.representativeName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.representativePhone')}>
                    {project.designer?.representativePhone}
                  </Descriptions.Item>
                  {/*<Descriptions.Item label="Representative Email">
                    {project.designer?.representativeEmail}
                  </Descriptions.Item>*/}
                </Descriptions>
                <p
                  style={{
                    fontSize: 14,
                    paddingLeft: 40,
                    paddingTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {t('screens:informations.address')}
                </p>
                <Descriptions
                  layout="horizontal"
                  column={1}
                  contentStyle={{ backgroundColor: '#ffffff' }}
                  labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                  size="small"
                >
                  <Descriptions.Item label={t('screens:informations.type')}>{project.designer?.address.type}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineOne')}>
                    {project.designer?.address.addressLineOne}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.addressLineTwo')}>
                    {project.designer?.address.addressLineTwo}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.city')}>{project.designer?.address.city}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.state')}>{project.designer?.address.state}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.zipCode')}>{project.designer?.address.zipCode}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.country')}>{project.designer?.address.country}</Descriptions.Item>
                  <Descriptions.Item label={t('screens:informations.note')}>{project.designer?.address.note}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Space>
          </Col>
        </Row>
      </Box>
    </>
  );
}
