import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { List, Col, Table as TableAntd, Button, Tooltip, Space, Typography, Popover } from 'antd';
import Row from '~/components/Row';
import { findAllEstimateSubVendors } from '~/services/hooks/estimateSubVendors';
import { findAllLaborTypes } from '~/services/hooks/laborTypes';
import { formatPrice } from '~/Utils';
import './styles.css';
import RequestForQuotationForm from './form';
import { downloadFile } from '~/services/hooks/files';
import ObsModal from './obsModal';
import history from '~/services/history';

const { Text } = Typography;
const style = {
  btnNew: {
    col: { marginLeft: 'auto', paddingBottom: '20px' },
    btn: { marginLeft: 'auto' },
  },
  header: {
    col: { paddingBottom: '20px', paddingTop: '10px' },
  },
  footer: {
    col: { paddingTop: '20px' },
  },
};

export default function Quotation({ subVendor, estimate }) {
  const subVendorId = subVendor?.id;
  const estimateId = estimate?.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [estimateSubvendors, setEstimateSubvendors] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [showType, setShowType] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [responser, setResponser] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    const data = await findAllEstimateSubVendors([
      {
        columnJoin: 'estimateItem',
        columnSecondJoin: 'estimate',
        field: 'id',
        value: estimateId,
        restriction: 'EQUAL',
      },
      { columnJoin: 'subVendor', field: 'id', value: subVendorId, restriction: 'EQUAL' },
    ]);
    setEstimateSubvendors(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [subVendorId]);

  useEffect(() => {
    findAllLaborTypes().then(data => {
      setLaborTypes(data);
    });
  }, []);

  // mount a list of uniques labor_types that are in estimate_items
  const mountLaborTypes = () => {
    const listLaborTypes = [];
    if (estimateSubvendors.length === 0) return setLaborTypes([]);
    laborTypes.forEach(element => {
      if (estimateSubvendors.some(estimateSubvendor => estimateSubvendor.estimateItem.laborType.id === element.id)) {
        listLaborTypes.push(element);
      }
    });
    setLaborTypes(listLaborTypes);
  };

  useEffect(() => {
    if (estimateSubvendors !== undefined) mountLaborTypes();
  }, [estimateSubvendors]);

  const onUpdateItem = item => {
    const index = estimateSubvendors.findIndex(i => i.id === item.id);
    if (index > -1) {
      setResponser({ name: item.responserName, jobTitle: item.responserJobTitle });
      const newData = [...estimateSubvendors];
      newData[index] = item;
      setEstimateSubvendors(newData);
    }
  };

  useEffect(() => {
    if (laborTypes !== undefined) setShowType(laborTypes[0]?.id);
  }, [laborTypes]);

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const handleNoteModal = record => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const tableColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '50px',
    },
    {
      title: t('screens:estimate.description'),
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: record => (
        <Tooltip placement="topLeft" title={record.estimateItem.estimateDescription.description}>
          {record.estimateItem.estimateDescription.description}
        </Tooltip>
      ),
    },
    {
      title: t('screens:estimate.area'),
      key: 'area',
      ellipsis: { showTitle: false },
      render: record => (
        <Tooltip placement="topLeft" title={record.estimateItem.area.name}>
          {record.estimateItem.area.name}
        </Tooltip>
      ),
    },
    {
      title: t('screens:estimate.unit'),
      key: 'unit',
      width: '80px',
      ellipsis: {
        showTitle: false,
      },
      render: record => <>{record.estimateItem.estimateDescription.unit}</>,
    },
    {
      title: t('screens:estimate.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: {
        showTitle: false,
      },
      width: '80px',
    },
    // {
    //   title: t('screens:estimate.materialValue'),
    //   dataIndex: 'materialValue',
    //   key: 'materialValue',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   width: '100px',
    //   render: materialValue => <>{formatPrice(materialValue)}</>,
    // },
    // {
    //   title: t('screens:estimate.laborValue'),
    //   dataIndex: 'laborValue',
    //   key: 'laborValue',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   width: '100px',
    //   render: laborValue => <>{formatPrice(parseFloat(laborValue))}</>,
    // },
    // {
    //   title: t('screens:estimate.totalMaterial'),
    //   key: 'total_material_value',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   width: '100px',
    //   render: record => <>{formatPrice(parseFloat(record.materialValue * record.quantity))}</>,
    // },
    // {
    //   title: t('screens:estimate.totalLabor'),
    //   key: 'total_labor',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   width: '100px',
    //   render: record => <>{formatPrice(parseFloat(record.laborValue * record.quantity))}</>,
    // },
    {
      title: t('screens:estimate.totalMaterialAndLabor'),
      key: 'total_material',
      ellipsis: {
        showTitle: false,
      },
      width: '100px',
      render: record => <Popover content={
        <div>
        <p>{`${t('screens:estimate.materialValue')}: ${formatPrice(parseFloat(record.materialValue))}`}</p>
        <p>{`${t('screens:estimate.laborValue')}: ${formatPrice(parseFloat(record.laborValue))}`}</p>
        <p>{`${t('screens:estimate.totalMaterial')}: ${formatPrice(parseFloat(record.materialValue * record.quantity))}`}</p>
        <p>{`${t('screens:estimate.totalLabor')}: ${formatPrice(parseFloat(record.laborValue * record.quantity))}`}</p>
      </div>
      } title="Values details" trigger="focus">
      {formatPrice(parseFloat((record.laborValue + record.materialValue) * record.quantity))}
      <Button
              type="link"
              icon={<InfoCircleOutlined />}
              title="Values details"
            />
      </Popover>,
    },
    {
      title: t('screens:estimate.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '100px',
      ellipsis: {
        showTitle: false,
      },
      render: dueDate => <>{`${dueDate} business days`}</>,
    },
    {
      title: t('screens:estimate.filedBy'),
      key: 'responser',
      width: '150px',
      ellipsis: {
        showTitle: false,
      },
      render: record => (
        <>{`${record?.responserName} - ${record?.responserJobTitle}`}</>
      ),
    },

    {
      title: t('screens:estimate.notes'),
      key: 'note',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '150px',
      render: record => (
        <Space>
          {record.note && record.note.length > 0 && (
            <Button
              type="link"
              icon={<InfoCircleOutlined />}
              onClick={() => handleNoteModal(record)}
              title={t('screens:estimate.viewSubcontractorNote')}
            />
          )}
          {record?.arquive?.id && (
            <Button
              type="link"
              icon={<DownloadOutlined />}
              onClick={async () => await downloadFile(record.arquive)}
              title={t('screens:estimate.downloadSubcontractorFile')}
            />
          )}
          {/* {record?.responserName && record?.responserJobTitle && (
            <Tooltip placement="topLeft" title={`filled by ${record?.responserName} - ${record?.responserJobTitle}`}>
              <UserOutlined color='#2d91fd' />
            </Tooltip>
          )} */}
          {!(record.note || record.arquive?.id) && <>N/A</>}
        </Space>
      ),
    },
    {
      title: t('screens:estimate.actions'),
      key: 'actions',
      align: 'center',
      width: '90px',
      render: record => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            title={t('screens:estimate.editItem')}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={4} style={{ paddingTop: 50 }}>
          <List
            header={t('screens:estimate.quoted_services')}
            bordered
            dataSource={laborTypes}
            renderItem={item => (
              <List.Item>
                <Button style={{ color: '#1890ff' }} type="link" onClick={() => setShowType(item.id)}>
                  <span style={{ fontWeight: showType === item.id ? 'bold' : 'normal' }}>{item.name}</span>
                </Button>
              </List.Item>
            )}
          />
        </Col>
        <Col span={20} style={{ paddingTop: 50 }}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              {/* show labor type name */}
              <p style={{ paddingBottom: 5 }}>{laborTypes.find(laborType => laborType.id === showType)?.name}</p>
            </Col>
          </Row>
          <TableAntd
            bordered
            size="small"
            rowKey="id"
            pagination={false}
            loading={loading}
            columns={tableColumns}
            dataSource={estimateSubvendors}
            showHeader={true}
            summary={pageData => {
              let totalMaterial = 0;
              let totalLabor = 0;
              let total = 0;
              pageData.forEach(({ quantity, materialValue, laborValue }) => {
                totalMaterial += materialValue * quantity;
                totalLabor += laborValue * quantity;
                total = totalMaterial + totalLabor;
              });
              return (
                <>
                  <TableAntd.Summary.Row style={{ backgroundColor: '#fffff0' }}>
                    <TableAntd.Summary.Cell index={0} colSpan={5} />
                    <TableAntd.Summary.Cell index={1}>
                      <Text strong>Totals</Text>
                    </TableAntd.Summary.Cell>
                    <TableAntd.Summary.Cell index={2}>
                      <Text strong>{formatPrice(totalMaterial)}</Text>
                    </TableAntd.Summary.Cell>
                    <TableAntd.Summary.Cell index={3}>
                      <Text strong>{formatPrice(totalLabor)}</Text>
                    </TableAntd.Summary.Cell>
                    <TableAntd.Summary.Cell index={4}>
                      <Text strong>{formatPrice(total)}</Text>
                    </TableAntd.Summary.Cell>
                  </TableAntd.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={style.footer.col}>
          <Space style={{ float: 'right' }}>
            <Button type="primary" loading={loading} color="primary" onClick={() => history.goBack()}>
              {t('screens:estimate.btnBack')}
            </Button>
          </Space>
        </Col>
      </Row>
      <RequestForQuotationForm
        visible={showForm}
        selectedRecord={selectedRecord}
        onClose={() => setShowForm(false)}
        responser={responser}
        onUpdateItem={item => onUpdateItem(item)}
      />

      <ObsModal
        visible={visible}
        onCancel={() => setVisible(false) && setSelectedRecord(null)}
        selectedRecord={selectedRecord}
      />
    </>
  );
}
