import React, { useState } from 'react';
import { Avatar, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signOutRequest } from '~/store/modules/auth/actions';
import { AntMenu, CustomAvatar, MenuGlobalStyle } from './styles';
import { BellOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import UserForm from '~/pages/Users/form';

export default function HeaderMenu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const roleList = useSelector(state => (state.user.profile ? state.user.profile.roles : null));
  const userName = useSelector(state => (state.user.profile ? state.user.profile.name : null));
  const userId = useSelector(state => (state.user.profile ? state.user.profile.id : null));
  const profile = useSelector(state => (state.user.profile ? state.user.profile : null));

  const checkAuth = roles => {
    var i = 0;
    for (i = 0; i < roles.length; i++) {
      let auth = false;
      if (roleList && !roleList[0].name.includes('ROLE_ADMIN') && !roleList[0].name.includes('ROLE_SUPER_ADMIN')) {
        if (roles[i] && roles[i] !== 'DEFAULT') {
          auth = roleList && roleList[0].name.includes(roles[i]);
        } else if (roles[i] === 'DEFAULT') {
          auth = true;
        } else {
          auth = false;
        }
      } else {
        auth = true;
      }
      return auth;
    }
  };

  function handleEdit() {
    setSelectedRecord(userId);
    setShowForm(true);
  }

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  const renderSubMenuTitle = label => {
    return (
      <>
        <span>{label}</span>
      </>
    );
  };

  const renderMenu = item => {
    const { type } = item;

    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    } else if (type === 'notifications') {
      return (
        checkAuth(item.role) && (
          <AntMenu.Item key={item.key}>
            <Badge size="small" count={item.count}>
              <BellOutlined id="bell" style={{ fontSize: '20px' }} />
            </Badge>
            <Link to={item.url} />
          </AntMenu.Item>
        )
      );
    } else if (type === 'sub') {
      return (
        checkAuth(item.role) && (
          <AntMenu.SubMenu
            key={item.key}
            title={
              item.profile !== null ? (
                <span>
                  <CustomAvatar>
                    <li style={{ marginRight: '14px' }}>
                      {item.profile && item.profile.image ? (
                        <Avatar src={item.profile.image} />
                      ) : (
                        <Avatar icon={<UserOutlined style={{ marginRight: '0px' }} />} />
                      )}
                    </li>
                    <li>{item.label}</li>
                  </CustomAvatar>
                </span>
              ) : (
                <span>
                  {item.icon}
                  <span>{renderSubMenuTitle(item.label)}</span>
                </span>
              )
            }
          >
            {item.items.map(subItem => renderMenu(subItem))}
          </AntMenu.SubMenu>
        )
      );
    } else if (type === 'sub-item') {
      return checkAuth(item.role) ? (
        <AntMenu.Item key={item.key}>
          <span>{item.label}</span>
          <Link to={item.url} />
        </AntMenu.Item>
      ) : null;
    } else {
      return checkAuth(item.role) ? (
        <AntMenu.Item key={item.key}>
          {//TODO: Converter o Link com click para Button
          item.click && item.key === 'logout' ? (
            <Button id="btnLogout" onClick={item.click}>
              {item.icon}
              {item.label}
            </Button>
          ) : item.click && item.key === 'profile' ? (
            <Button id="btnProfile" onClick={item.click}>
              {item.icon}
              {item.label}
            </Button>
          ) : (
            <>
              {item.icon}
              <span>{item.label}</span>
              <Link to={item.url} />
            </>
          )}
        </AntMenu.Item>
      ) : null;
    }
  };

  const userMenuItems = [
    {
      type: 'notifications',
      key: 'notifications',
      role: ['DEFAULT'],
      url: '/notifications',
      count: 0,
    },
    {
      type: 'sub',
      key: 'user',
      role: ['DEFAULT'],
      label: userName !== null ? userName : 'Username',
      profile: profile,
      items: [
        {
          type: 'item',
          key: 'profile',
          role: ['DEFAULT'],
          label: t('menus:user_profile'),
          icon: <UserOutlined style={{ marginLeft: '0px' }} />,
          click: handleEdit,
        },
        {
          type: 'item',
          key: 'logout',
          role: ['DEFAULT'],
          label: t('menus:user_logout'),
          icon: <LogoutOutlined style={{ marginLeft: '0px' }} />,
          click: handleSignOut,
        },
      ],
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <AntMenu id="headerMenu" mode="horizontal" theme="light">
        {userMenuItems.map(item => renderMenu(item))}
      </AntMenu>
      <UserForm id={selectedRecord} visible={showForm} disabledImage={false} onClose={() => setShowForm(false)} />
    </>
  );
}
