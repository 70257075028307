import React from 'react';
import { Col, Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createProposalClient, updateProposalClient } from '~/services/hooks/proposalClients';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function ProposalClientForm({ project, selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const initialValues = {
    id: null,
    description: '',
    project: project,
    arquive: null,
  };

  const [uploadList, setUploadList] = useState([]);
  const proposalClient = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    setLoading(true);
    if (values.id !== null) {
      const data = await updateProposalClient(values);
      onUpdateItem(data);
    } else {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      const data = await createProposalClient(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const proposalClientSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={proposalClient}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={proposalClientSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          width="650px"
          title={values.id !== null ? t('screens:proposalClient.btnEdit') : t('screens:proposalClient.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:proposalClient.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl field="proposal_client_file" label={t('screens:proposalClient.data.file')}>
                    <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={proposalClient.arquive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
