import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Descriptions, Empty, Space, Col } from 'antd';
import ContactForm from './form';
import ContactsModal from './modal';
import Row from '~/components/Row';
import Button from '~/components/Button';
import { PlusOutlined, SelectOutlined } from '@ant-design/icons';

export default function Contacts({ contact, onSelect, primary }) {
    const { t } = useTranslation();
  const { Text } = Typography;

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  useEffect(() => {
    setSelectedRecord(contact);
  }, [showForm, contact]);

    return (
        <>
            {contact !== null ?
                <Descriptions size="small" column={{ xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}>
                    <Descriptions.Item span={13} label={<Text type="secondary">{t('screens:customers.data.firstName')}</Text>}>{contact.firstName}</Descriptions.Item>
                    <Descriptions.Item span={11} label={<Text type="secondary">{t('screens:customers.data.lastName')}</Text>}>{contact.lastName}</Descriptions.Item>
                    <Descriptions.Item span={13} label={<Text type="secondary">{t('screens:customers.data.jobTitle')}</Text>}>{contact.jobTitle}</Descriptions.Item>
                    <Descriptions.Item span={11} label={<Text type="secondary">{t('screens:customers.data.mobilePhone')}</Text>}>{contact.mobilePhone}</Descriptions.Item>
                    <Descriptions.Item span={13} label={<Text type="secondary">{t('screens:customers.data.mainPhone')}</Text>}>{contact.mainPhone}</Descriptions.Item>
                    <Descriptions.Item span={11} label={<Text type="secondary">{t('screens:customers.data.workPhone')}</Text>}>{contact.workPhone}</Descriptions.Item>
                    <Descriptions.Item span={13} label={<Text type="secondary">{t('screens:customers.data.mainEmail')}</Text>}>{contact.mainEmail}</Descriptions.Item>
                    <Descriptions.Item span={11} label={<Text type="secondary">{t('screens:customers.data.ccEmail')}</Text>}>{contact.ccEmail}</Descriptions.Item>
                </Descriptions>
                :
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE} // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60, }}
                    description={
                        <>
                            <Text strong>{t('screens:customers.data.noData')}</Text>
                            <p><Text type="secondary">{primary === true ? t('screens:customers.data.createPrimaryContactForCustomer')
                                : t('screens:customers.data.createSecondaryContactForCustomer')}</Text>
                            </p>
                        </>
                    }
                >
                    <Row>
                        <Col
                            xs={24} sm={24} md={24} lg={24} xl={24}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Space>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setSelectedRecord(null);
                                        setShowForm(true);
                                    }}>
                                    <PlusOutlined />
                                    {t('screens:contacts.btnNew')}
                                </Button>
                                <Button
                                    onClick={() => setShowContactModal(true)}>
                                    <SelectOutlined />
                                    {t('screens:contacts.btnChoose')}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Empty>
            }
            <ContactForm contact={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
            <ContactsModal
                visible={showContactModal}
                onClose={() => setShowContactModal(false)}
                onSelect={(c) => {
                    setShowContactModal(false);
                    onSelect(c);
                }}
            />
        </>
    );
}