import React, { useEffect, useState } from 'react';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Divider, Badge, Image, Row, Descriptions, Typography, Modal, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { formatPrice, formatPercent } from '~/Utils';
import { ModalFooter } from '~/components/Modal';
// import Row from '~/components/Row';
import Box from '~/components/Box';
import moment from 'moment';

const { Text } = Typography;
const { Title } = Typography;

export default function AppCertificationForm({ selectedSubcontractor, visible, onClose }) {
  const payAppId = selectedSubcontractor.id;
  const originalContractSum = selectedSubcontractor.totalScheduledValue;
  const changeOrdersSum = selectedSubcontractor.changeOrdersSum;
  const totalCompletedAndStored = selectedSubcontractor.paidToDate;
  const totalRetainage = selectedSubcontractor.retainage;
  const totalEarnedLessRetainage = selectedSubcontractor.paidToDate - selectedSubcontractor.retainage;
  const [loading, setLoading] = useState(false);
  const contractSumToDate = originalContractSum + changeOrdersSum;
  // const balanceToFinish = contractSumToDate - totalCompletedAndStored;
  const { t } = useTranslation();
  const recordData = null;

  console.log('selectedSubcontractor', selectedSubcontractor);
  console.log('originalContractSum', originalContractSum);
  console.log('changeOrdersSum', changeOrdersSum);
  console.log('contractSumToDate', contractSumToDate);
  console.log('totalCompletedAndStored', totalCompletedAndStored);
  console.log('totalRetainage', totalRetainage);
  console.log('totalEarnedLessRetainage', totalEarnedLessRetainage);

  const handleSave = async values => {
    console.log(values);
  };

  const valuesSchema = Yup.object().shape({
    // notes: Yup.string().required(),
    // type: Yup.object().required(),
  });

  return (
    <Formik enableReinitialize initialValues={recordData} onSubmit={handleSave} validationSchema={valuesSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values }) => (
        <Modal
          width="1100px"
          description={t('screens:workbook.subcontractors.modal.description')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Box>
            <Row gutter={16}>
              <Col span={24}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions
                    title={<Title level={4}>APPLICATION AND CERTIFICATION FOR PAYMENT</Title>}
                    layout="vertical"
                    bordered
                    column={4}
                    // contentStyle={{ width: '70%' }}
                  >
                    <Descriptions.Item label="TO">
                      {/* PROJECT NAME */}
                      <Text strong>{selectedSubcontractor.contract.project.description}</Text>
                      <br />
                      {/* PROJECT ADDRESS */}
                      {`${selectedSubcontractor.contract.project.property.address.addressLineOne} ${
                        selectedSubcontractor.contract.project.property.address.addressLineTwo
                          ? ', ' + selectedSubcontractor.contract.project.property.address.addressLineTwo
                          : ''
                      }`}
                      <br />
                      {/* CITY, STATE, CEP */}
                      {`${selectedSubcontractor.contract.project.property.address.city}, ${selectedSubcontractor.contract.project.property.address.state} ${selectedSubcontractor.contract.project.property.address.zipCode}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="FROM">
                      {/* SUBCONTRACTOR NAME */}
                      <Text strong>{selectedSubcontractor.contract.subVendor.companyName}</Text>
                      <br />
                      {/* SUBCONTRACTOR ADDRESS */}
                      {`${selectedSubcontractor.contract.subVendor.contact.address.addressLineOne} ${
                        selectedSubcontractor.contract.subVendor.contact.address.addressLineTwo
                          ? ', ' + selectedSubcontractor.contract.subVendor.contact.address.addressLineTwo
                          : ''
                      }`}
                      <br />
                      {/* CITY, STATE, CEP */}
                      {`${selectedSubcontractor.contract.subVendor.contact.address.city}, ${selectedSubcontractor.contract.subVendor.contact.address.state} ${selectedSubcontractor.contract.subVendor.contact.address.zipCode}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="CONTRACT FOR"></Descriptions.Item>
                    <Descriptions.Item label="PROJECT">
                      <Text strong>{selectedSubcontractor.contract.project.description}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="APPLICATION #">{payAppId}</Descriptions.Item>
                    <Descriptions.Item label="PERIOD TO">{selectedSubcontractor.contract.sentDate}</Descriptions.Item>
                    <Descriptions.Item label="PROJECT #">{selectedSubcontractor.contract.project.id}</Descriptions.Item>
                    <Descriptions.Item label="CONTRACT DATE">{selectedSubcontractor.contract.date}</Descriptions.Item>
                  </Descriptions>
                </Space>
              </Col>
            </Row>
            <Row gutter={16} style={{ paddingTop: '30px' }}>
              <Col span={12} style={{ paddingTop: '0px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions
                    title="SUBCONTRACTOR'S APPLICATION FOR PAYMENT"
                    layout="horizontal"
                    bordered
                    column={1}
                    contentStyle={{ width: 120 }}
                  >
                    <Descriptions.Item label="1. ORIGINAL CONTRACT SUM">
                      {formatPrice(originalContractSum || 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label="2. NET CHANGE BY CHANGE ORDERS">
                      {formatPrice(changeOrdersSum || 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label="3. CONTRACT SUM TO DATE">
                      {formatPrice(contractSumToDate || 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label="4. TOTAL COMPLETED & STORED TO DATE">
                      {formatPrice(totalCompletedAndStored || 0)}
                    </Descriptions.Item>
                  </Descriptions>

                  <Card
                    title={<Text style={{ fontSize: 14 }}>5. RETAINAGE</Text>}
                    bordered={true}
                    bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    <Descriptions
                      layout="horizontal"
                      bordered
                      column={1}
                      contentStyle={{ width: 120 }}
                      labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                      size="middle"
                    >
                      <Descriptions.Item label="5.1. 10	% of Completed Work">
                        {formatPrice(totalRetainage)}
                      </Descriptions.Item>
                      <Descriptions.Item label="5.2. 10	% of Stored Material">$0.00</Descriptions.Item>
                      <Descriptions.Item label="TOTAL RETAINAGE">{formatPrice(totalRetainage)}</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                    <Descriptions.Item label="6. TOTAL EARNED LESS RETAINAGE">
                      {formatPrice(totalEarnedLessRetainage)}
                    </Descriptions.Item>
                    <Descriptions.Item label="7. LESS PREVIOUS CERTIFICATES FOR PAYMENT">
                      {formatPrice(selectedSubcontractor.paidToDate * 0.9 || 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label="8. LESS INTEREST & INSP. FEES PAID">input</Descriptions.Item>
                  </Descriptions>

                  <Card
                    title={<Text style={{ fontSize: 14 }}>9. PAYMENT DUE</Text>}
                    bordered={true}
                    bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    <Descriptions
                      layout="horizontal"
                      bordered
                      column={1}
                      contentStyle={{ width: 120 }}
                      labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                      size="middle"
                    >
                      <Descriptions.Item label="9.1. Current Payment Due">
                        {formatPrice(totalEarnedLessRetainage)}
                      </Descriptions.Item>
                      <Descriptions.Item label="9.2. Cost of Work">$0.00</Descriptions.Item>
                      <Descriptions.Item label="9.3. Sales and Use Tax">$0.00</Descriptions.Item>
                      <Descriptions.Item label="TOTAL DUE">{formatPrice(totalEarnedLessRetainage)}</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                    <Descriptions.Item label="10. BALANCE TO FINISH, INCLUDING RETAINAGE">
                      {formatPrice(selectedSubcontractor.balanceToBePaid || 0)}
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              </Col>
              <Col span={12} style={{ paddingTop: '0px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Descriptions title="&nbsp;" layout="vertical" bordered column={1}>
                    <Descriptions.Item label="OBSERVATION">
                      The undersigned Contractor certified that to the best of the Contractor's knowledge, information
                      and belief the Work covered by this Application for Payment has been completed in accordance with
                      the Contract Documents, that all amounts have been paid by the Contractor for Work for which
                      previous Certificates for Payment were issued and payments received from the Owner, and that
                      current payment shown herein is now due.
                    </Descriptions.Item>
                    <Descriptions.Item label="SUBCONTRACTOR">
                      {selectedSubcontractor.contract.subVendor.companyName}
                      <br />
                      {/* SUBCONTRACTOR ADDRESS */}
                      {`${selectedSubcontractor.contract.subVendor.contact.address.addressLineOne} ${
                        selectedSubcontractor.contract.subVendor.contact.address.addressLineTwo
                          ? ', ' + selectedSubcontractor.contract.subVendor.contact.address.addressLineTwo
                          : ''
                      }`}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="horizontal" bordered column={1}>
                    <Descriptions.Item label="BY">
                      {selectedSubcontractor.contract.subVendor.contact.firstName}{' '}
                      {selectedSubcontractor.contract.subVendor.contact.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item label="DATE">
                      {moment(selectedSubcontractor.contract.date).format('MM/DD/YYYY')}
                    </Descriptions.Item>
                    <Descriptions.Item label="STATE OF">
                      {selectedSubcontractor.contract.cityStateZipCode}
                    </Descriptions.Item>
                    <Descriptions.Item label="COUNTRY OF">country of</Descriptions.Item>
                  </Descriptions>
                  <Card
                    title={<Text style={{ fontSize: 14 }}>CONTRACT DETAILS</Text>}
                    bordered={true}
                    bodyStyle={{ fontSize: 14, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    {selectedSubcontractor.contract.dateDescription}
                    <br />
                    <br />
                    Notary Public: _______________________________
                    <br />
                    <br />
                    My Commission Expires:______________________
                  </Card>
                </Space>
              </Col>
            </Row>
          </Box>
        </Modal>
      )}
    </Formik>
  );
}
