import { PageHeader, Tabs, Divider, Badge, Col } from 'antd';
import styled from 'styled-components';

export const TabAddress = styled(Tabs)`
    .ant-tabs-content-holder {
        border: 1px solid #f0f0f0;
        border-top: transparent; /* Remove the top border so that there is no overlap*/
    }

    .ant-tabs-nav {
        margin: 0px;
    }
`;

export const ClientHeader = styled(PageHeader)`
    .ant-page-header-heading-title {
        color: #0f4c81;
    }

    #box_customer {
        padding: 0px;
    }
`;

export const DividerCustom = styled(Divider)`
    width: 4px;
    height: 100%;
    margin-left: 0px;
    border-radius: 7px;
`;

export const BadgeCustom = styled(Badge)`
    .ant-badge-status-text {
        font-size: 10px;
        font-weight: bold;
    }
`;

export const ColCustom = styled(Col)`
    padding-bottom: 10px;
    padding-right: 10px;
`;

export const CustomTotals = styled.ul`
    overflow: auto;
    white-space: nowrap;

    li {
        display: inline-block;
        padding-right: 14px;
        padding-top: 14px;
        padding-bottom: 14px;
    }
`;

export const LoadingMore = styled.div`
    text-align: center;
    margin-top: 12px;
    height: 32px;
    line-height: 32px;
`;