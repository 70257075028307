import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, message, Popconfirm, Button as ButtonAntd, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import Row from '~/components/Row';
import { itemMarkupTypes } from '~/Utils/data/jsonData';
import ItemForm from './form';
import { TableItems } from './styles';
import { formatPrice, formatPercent } from '~/Utils';

export default function Items({ items, onHandleItems }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const fetchRecords = () => {
    setLoading(true);
    setLoading(false);
  };

  const handleEdit = item => {
    setSelectedRecord(item);
    setShowForm(true);
  };

  const handleDelete = item => {
    setLoading(true);
    if (item.id) {
      items = items.filter(i => i.id !== item.id);
    } else {
      items = items.filter(i => {
        let equal = true;
        Object.keys(item).forEach(key => {
          if (item[key] !== i[key]) equal = false;
        });
        return !equal;
      });
    }
    onHandleItems(items);
    fetchRecords();
    message.success(t('messages:changeOrders.itemSuccessDeleted'));
    setLoading(false);
  };

  function addNewItem(item) {
    item.id = null;
    items.push(item);
    onHandleItems(items);
  }

  function updateItem(oldItem, newItem) {
    items[items.indexOf(oldItem)] = newItem;
    onHandleItems(items);
  }

  useEffect(() => {
    fetchRecords();
  }, [showForm, items]);

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const COLUMN_SIZE = 40;

  const tableColumns = [
    {
      title: t('screens:changeOrders.item.data.title'),
      ellipsis: { showTitle: true },
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('screens:changeOrders.item.data.costTypes'),
      ellipsis: { showTitle: true },
      dataIndex: 'costTypes',
      key: 'costTypes',
      render: costTypes => {
        return costTypes.map(costType => {
          return (
            <>
              {costType.name}
              <br />
            </>
          );
        });
      },
    },
    {
      title: t('screens:changeOrders.item.data.unitCost'),
      ellipsis: { showTitle: true },
      dataIndex: 'unitCost',
      key: 'unitCost',
      render: unitCost => formatPrice(unitCost),
    },
    {
      title: t('screens:changeOrders.item.data.quantity'),
      ellipsis: { showTitle: true },
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('screens:changeOrders.item.data.unit'),
      ellipsis: { showTitle: true },
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: t('screens:changeOrders.item.data.builderCost'),
      ellipsis: { showTitle: true },
      dataIndex: 'builderCost',
      key: 'builderCost',
      render: builderCost => formatPrice(builderCost),
    },
    {
      title: t('screens:changeOrders.item.data.markup'),
      ellipsis: { showTitle: true },
      key: 'markup',
      render: record => {
        let markup = record.markup;
        switch (record.markupType) {
          case 'PERCENT':
            markup = `${formatPercent(markup)} %`;
            break;
          case 'DOLLAR':
            markup = `${formatPrice(markup)}`;
            break;
          case 'DOLLAR_UNIT':
            markup = `${formatPrice(markup)} / ${record.unit}`;
            break;
          case 'DOUBLE_DASH':
            markup = `${parseFloat(markup).toFixed(2)} --`;
            break;
          default:
            markup = `${parseFloat(markup).toFixed(2)}`;
            break;
        }
        return markup;
      },
    },
    {
      title: t('screens:changeOrders.item.data.ownerPrice'),
      ellipsis: { showTitle: true },
      dataIndex: 'costTypes',
      dataIndex: 'ownerPrice',
      key: 'ownerPrice',
      render: ownerPrice => formatPrice(ownerPrice),
    },
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      className: 'table-action',
      render: record => (
        <Space>
          <ButtonAntd
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            title={t('messages:edit')}
          />
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <ButtonAntd type="link" icon={<DeleteOutlined />} title={t('messages:delete')} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ paddingTop: 20 }}>
      <Row>
        <Col span={12} style={style.btnNew.col}>
          <Button
            loading={loading}
            color="primary"
            onClick={() => {
              setSelectedRecord(null);
              setShowForm(true);
            }}
            style={style.btnNew.btn}
          >
            <PlusOutlined />
            {t('screens:changeOrders.item.btnNew')}
          </Button>
        </Col>
      </Row>
      <TableItems
        bordered
        rowKey="id"
        pagination={false}
        loading={loading}
        columns={tableColumns}
        dataSource={[...items]}
      />
      <ItemForm
        item={selectedRecord}
        visible={showForm}
        itemMarkupTypes={itemMarkupTypes}
        onClose={() => setShowForm(false)}
        onAddNewItem={e => addNewItem(e)}
        onUpdateItem={(oldItem, newItem) => updateItem(oldItem, newItem)}
      />
    </div>
  );
}
