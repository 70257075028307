import React, { useState, useEffect } from 'react';
import { Modal, Typography, Spin, message, Upload, Radio, Space, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import axios from 'axios';
import moment from 'moment';
import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';
import Button from '~/components/Button';
import errorHandler from 'Utils/errorHandler';
import { DeleteOutlined, DownloadOutlined, FilePdfOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import FormControl from '~/components/Form/FormControl';

export default function SendContractModal({ visible, onClose, record }) {
    const token = useSelector(state => state.auth.token);
    const { t } = useTranslation();
    const { Text } = Typography;
    const [loading, setLoading] = useState(false);
    const [uploadList, setUploadList] = useState([]);
    const [sendOtherFile, setSendOtherFile] = useState(false);

    const onChangeRadio = (e) => {
        setSendOtherFile(e.target.value);
    };

    const handleSendContract = async () => {
        setLoading(true);
        try {
            if (sendOtherFile === true) {
                if (uploadList.length > 0) {
                    let file = uploadList[0].originFileObj;
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('contractId', record.id);
                    const config = { "headers": { "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s' } }
                    const { data } = await api.post('/uploadFileContract', formData, config);
                    if (data) {
                        record = data;
                    }

                    const params = { sendOtherFile: sendOtherFile };
                    await api.post(`/mail/sendSubcontractAgreementOrAddendum/${record.id}`, params);
                    record.sentDate = moment().format('YYYY-MM-DD HH:mm:ss');

                    await api.put('/contracts/update', record);
                    message.success(t('messages:sendContractSuccess'));
                    onClose();
                } else {
                    message.error(t('messages:requiredFileUpload'));
                }
            } else {
                const params = { sendOtherFile: sendOtherFile };
                await api.post(`/mail/sendSubcontractAgreementOrAddendum/${record.id}`, params);
                record.sentDate = moment().format('YYYY-MM-DD HH:mm:ss');

                await api.put('/contracts/update', record);
                message.success(t('messages:sendContractSuccess'));
                onClose();
            }
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    }

    // file upload functions
    const handleUpload = async (event) => {
        event.fileList.map(file => {
            var extension = '';
            var name = '';
            if (file.name.substring(file.name.length - 4) === 'jpeg') {
                extension = file.name.substring(file.name.length - 5);
                name = file.name.replaceAll('.', '');
                name = name.substring(0, name.length - 4);
            } else {
                extension = file.name.substring(file.name.length - 4);
                name = file.name.replaceAll('.', '');
                name = name.substring(0, name.length - 3);
            }
            file.name = name + extension;
        });
        setUploadList(event.fileList);
    };

    const beforeUpload = file => {
        const isValid =
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png' ||
            file.type === 'application/pdf';
        if (!isValid) {
            message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
            setTimeout(function () {
                handleRemoveUploadListItem(file.uid);
            }, 100);
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('O arquivo deve ter menos de 10MB!');
            setTimeout(function () {
                handleRemoveUploadListItem(file.uid);
            }, 100);
        }
        return isValid && isLt2M;
    };

    const handleRemoveUploadListItem = uid => {
        const data = uploadList.filter(item => item.uid !== uid);
        setUploadList(data);
    };
    // end file upload functions

    const handleDownload = async (file) => {
        axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.name}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.originalName);
            document.body.appendChild(link);
            link.click();
        });
    };

    useEffect(() => {
        setUploadList([]);
        setSendOtherFile(false);
    }, [visible]);

    return (
        <Modal
            width="450px"
            title={t('screens:purchaseOrders.preferences')}
            onCancel={onClose}
            open={visible}
            loading={loading}
            footer={
                <ModalFooter onOk={handleSendContract} loading={loading} onCancel={onClose} cancelColor="default" okText={'Send'} />
            }
        >
            <Spin spinning={loading}>
                <Row>
                    <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }} field="sendOtherFile">
                        <Radio.Group value={sendOtherFile} onChange={onChangeRadio}>
                            <Space direction="vertical">
                                <Radio value={false}>{t('screens:contracts.send')} {record !== null ? record.typeDescription : ''}</Radio>
                                <Radio value={true}>{t('screens:contracts.sendOtherFile')}</Radio>
                            </Space>
                        </Radio.Group>
                    </FormControl>
                </Row>
                {sendOtherFile ?
                    <Row>
                        <FormControl cols={{ xs: 7, sm: 6, md: 6, lg: 6, xl: 6 }} field="payment_receipt2">
                            <Upload
                                name={'file'}
                                accept=".pdf"
                                multiple={false}
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleUpload}
                                fileList={uploadList}
                                maxCount="1"
                            >
                                <Button color="default">
                                    {'Upload file'}
                                    <UploadOutlined />
                                </Button>
                            </Upload>
                        </FormControl>
                        {uploadList.length > 0 ?
                            <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                                {uploadList?.map(item => {
                                    return (
                                        <span key={item.uid}>
                                            <PaperClipOutlined />
                                            <Text>{item.name}</Text>
                                            <DeleteOutlined
                                                style={{ paddingLeft: '5px' }}
                                                onClick={() => handleRemoveUploadListItem(item.uid)}
                                            />
                                        </span>
                                    );
                                })}
                            </FormControl>
                            : null}

                        {record.arquive !== null ?
                            <>
                                <Divider orientation="left" orientationMargin="0"/>
                                <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                                <Text strong>{t('screens:contracts.lastFileSentForSubcontractor')}</Text>
                                    <p>
                                        <FilePdfOutlined />
                                        {' '}{record.arquive?.originalName}{' '}
                                        <DownloadOutlined onClick={() => handleDownload(record.arquive)}/>
                                    </p>
                                </FormControl>
                             </>
                            : null
                        }
                    </Row>
                    : null}
            </Spin>
        </Modal>
    );
}