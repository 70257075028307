import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import moment from 'moment';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ costTypes, isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    if (values.descriptionFilter === '0') {
      values.descriptionFilter = '';
    }
    const filter = [];

    try {
      if (
        values.descriptionFilter !== undefined &&
        values.descriptionFilter !== null &&
        values.descriptionFilter !== ''
      ) {
        filter.push({
          field: 'description',
          value: values.descriptionFilter,
          restriction: 'LIKE',
        });
      }
      if (values.dateIniFilter !== undefined && values.dateIniFilter !== null && values.dateIniFilter !== '') {
        filter.push({
          field: 'date',
          value: moment(values.dateIniFilter).format('YYYY-MM-DD'),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        });
      }
      if (values.dateEndFilter !== undefined && values.dateEndFilter !== null && values.dateEndFilter !== '') {
        filter.push({
          field: 'date',
          value: moment(values.dateEndFilter).format('YYYY-MM-DD'),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        });
      }
      if (values.typeFilter !== undefined && values.typeFilter !== null && values.typeFilter !== '') {
        filter.push({
          columnJoin: 'costType',
          field: 'id',
          value: values.typeFilter,
          restriction: 'EQUAL',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          descriptionFilter: '',
          typesFilter: 'All',
          costTypes: costTypes,
          dateIniFilter: '',
          dataEndFilter: '',
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span={11}>
                  <FormControl
                    error={errors.descriptionFilter}
                    field="descriptionFilter"
                    label={t('screens:workbook.costs.data.title')}
                  >
                    <Input name="descriptionFilter" placeholder={t('fields:claims.title.placeholder')} />
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl error={errors.typeFilter} field="typeFilter" label={t('screens:workbook.costs.data.type')}>
                    <Select name="typeFilter" placeholder="Select">
                      <Option value="All">All</Option>
                      {costTypes?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl
                    field="dateIniFilter"
                    label={t('screens:workbook.costs.data.date_ini')}
                    error={errors.dateIniFilter}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <DatePicker name="dateIniFilter" format="DD/MM/Y" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl
                    field="dateEndFilter"
                    label={t('screens:workbook.costs.data.date_end')}
                    error={errors.dateEndFilter}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <DatePicker name="dateEndFilter" format="DD/MM/Y" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
