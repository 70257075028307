import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, DatePicker, Select, Checkbox } from 'formik-antd';
import { Modal, message, Spin, Col, Typography, Divider, Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import InputCurrency from '~/components/Form/InputCurrency';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { format } from 'date-fns';
import { TabProduct } from './styles';
import { dynamicCost, formatPercent, formatPrice } from '~/Utils';
import Products from './Products';

const initialValues = {
    id: null,
    proposalNumber: '',
    project: null,
    projectId: null,
    subVendor: null,
    subContractorId: null,
    customer: null,
    customerId: null,
    addProposal: false,
    contractor: false,
    shippingSettings: null,
    title: '',
    approvalStatus: 'PENDING_APPROVAL',
    date: format(new Date(), 'MM-dd-yyyy HH:mm:ss'),
    termsAndConditions: '',
    acceptancePurchaseOrder: '',
    discount: 0.00,
    salesTaxRate: 0,
    salesTax: 0.00,
    otherCost: 0.00,
    others: 0.00,
    shEstimated: 0.00,
    items: [],
    products: [],
};

export default function PurchaseOrderForm({ project, selectedRecord, visible, onClose }) {

    console.log(selectedRecord);

    const projectId = project.id;
    const id = selectedRecord ? selectedRecord.id : null;
    const { t } = useTranslation();
    const { Option } = Select;
    const { Text, Paragraph } = Typography;
    const [recordData, setRecordData] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(1);
    const [contractor, setContractor] = useState(false);
    //const [projects, setProjects] = useState([]);
    const [subContractors, setSubContractors] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [products, setProducts] = useState([]);
    const [editableStr, setEditableStr] = useState('');
    const [total, setTotal] = useState(0.00);
    const[subtotal, setSubtotal] = useState(0.00);

    const fetchRecords = async () => {
        setLoading(true);
        try {
            initialValues.termsAndConditions = t('screens:purchaseOrders.data.termsAndConditionsVendor');
            initialValues.acceptancePurchaseOrder = t('screens:purchaseOrders.data.acceptancePurchaseOrderVendor');

            if (id === null) {
                setProducts([]);
                setRecordData(initialValues);
                setSubtotal(0.00);
                setTotal(0.00);
            } else {
                const { data } = await api.get('/purchaseOrders/findById', {
                    params: { id },
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                console.log('Purchase Order', data);
                let initialData = { initialValues };
                initialData.id = data.id;
                initialData.proposalNumber = data.proposalNumber;
                initialData.project = project;
                initialData.subVendor = data.subVendor;
                initialData.customer = data.customer;
                initialData.addProposal = data.addProposal;
                initialData.contractor = data.contractor;
                initialData.shippingSettings = data.shippingSettings;
                initialData.title = data.title;
                initialData.approvalStatus = data.approvalStatus;
                initialData.date = data.date;
                initialData.termsAndConditions = data.termsAndConditions;
                initialData.acceptancePurchaseOrder = data.acceptancePurchaseOrder;
                initialData.discount = data.discount;
                initialData.salesTaxRate = data.salesTaxRate;
                initialData.salesTax = data.salesTax;
                initialData.otherCost = data.otherCost;
                initialData.others = data.others;
                initialData.shEstimated = data.shEstimated;
                initialData.projectId = projectId;
                initialData.subContractorId = data.subVendor !== null ? data.subVendor.id.toString() : null;
                initialData.customerId = data.customer !== null ? data.customer.id.toString() : null;

                let subtotalValue = 0;
                let count = 1;
                data.items.forEach((item) => {
                    item.uuid = count;
                    count = count + 1;
                    subtotalValue = subtotalValue + item.total;
                });
                initialData.items = data.items;

                setSubtotal(subtotalValue);
                setTotal((subtotalValue + data.salesTax + data.otherCost + data.others + data.shEstimated) - data.discount);
                setRecordData(initialData);
                setProducts(data.items);
                setContractor(data.contractor);
                var v = data.subVendor !== null ? 1 : 2;
                setValue(v);
            }
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    // const fetchProjects = async () => {
    //     setLoading(true);
    //     try {
    //         const { data } = await api.get('/projects/findAll');
    //         setProjects(data);
    //     } catch (error) {
    //         errorHandler(error);
    //     }
    //     setLoading(false);
    // };

    const fetchSubContractors = async () => {
        setLoading(true);
        try {
            const { data } = await api.get('/subVendors/findAll');
            setSubContractors(data);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const fetchCustomers = async () => {
        setLoading(true);
        try {
            const { data } = await api.get('/customers/findAll');
            setCustomers(data);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleSave = async (values, { setErrors }) => {
        setLoading(true);
        try {

            let subVendor = null;
            if (value === 1 && values.subContractorId !== null && values.subContractorId !== '') {
                subContractors.forEach((s) => {
                    if (s.id.toString() === values.subContractorId) {
                        subVendor = s;
                    }
                });
            }

            let customer = null;
            if (value === 2 && values.customerId !== null && values.customerId !== '') {
                customers.forEach((c) => {
                    if (c.id.toString() === values.customerId) {
                        customer = c;
                    }
                });
            }

            let newPurchaseOrder = {
                id: values.id,
                proposalNumber: values.proposalNumber,
                project: project,
                subVendor: subVendor,
                customer: customer,
                addProposal: values.addProposal,
                contractor: contractor,
                shippingSettings: values.shippingSettings,
                title: values.title,
                approvalStatus: values.approvalStatus,
                date: values.date,
                termsAndConditions: values.termsAndConditions,
                acceptancePurchaseOrder: values.acceptancePurchaseOrder,
                discount: values.discount,
                salesTaxRate: values.salesTaxRate,
                salesTax: values.salesTax,
                otherCost: values.otherCost,
                others: values.others,
                shEstimated: values.shEstimated,
                items: products,
            };

            console.log(newPurchaseOrder)

            if (values.id !== null) {
                await api.put('/purchaseOrders/update', newPurchaseOrder);
                message.success(t('messages:purchaseOrderSuccessEdit'));
            } else {
                await api.post('/purchaseOrders/create', newPurchaseOrder);
                message.success(t('messages:purchaseOrderSuccess'));
            }
            onClose();
        } catch (error) {
            setErrors(errorHandler(error));
        }
        setLoading(false);
    };

    const onChangeRadio = (e) => {
        setValue(e.target.value);
    };

    const onChangeContractor = (e) => {
        setContractor(e.target.value);
    };

    function calculateSubtotal(items, values, setFieldValue, msg) {
        try {
            var bigDecimal = require('js-big-decimal');
            var subtotalValue = new bigDecimal(0);

            items.forEach((item) => {
                subtotalValue = subtotalValue.add(new bigDecimal(item.total));
            });

            var salesTax = new bigDecimal(values.salesTax);
            var otherCost = new bigDecimal(values.otherCost);
            var others = new bigDecimal(values.others);
            var shEstimated = new bigDecimal(values.shEstimated);
            var discount = new bigDecimal(values.discount);

            var total = new bigDecimal(0);
            total = total.add(subtotalValue).add(salesTax).add(otherCost).add(others).add(shEstimated);
            total = total.subtract(discount);

            setSubtotal(subtotalValue.getValue());
            setTotal(total.getValue());
            setProducts(items);
            setFieldValue("", subtotalValue);
            message.success(msg);
        } catch (error) {
            errorHandler(error);
        }
    }

    function calculateTotal(subtotal, values) {
        var bigDecimal = require('js-big-decimal');
        var subtotals = new bigDecimal(subtotal);
        var salesTax = new bigDecimal(values.salesTax);
        var otherCost = new bigDecimal(values.otherCost);
        var others = new bigDecimal(values.others);
        var shEstimated = new bigDecimal(values.shEstimated);
        var discount = new bigDecimal(values.discount);

        var total = new bigDecimal(0);
        total = total.add(subtotals).add(salesTax).add(otherCost).add(others).add(shEstimated);
        total = total.subtract(discount);
        setTotal(total.getValue());
    }

    useEffect(() => {
        if (visible) {
            setEditableStr('');
            setProducts([]);
            fetchSubContractors();
            fetchCustomers();
            fetchRecords();
        }
    }, [visible]);

    function getTabItems(values, setFieldValue) {
        return (
          [
            {
                label: 'Products',
                key: 'products',
                children: getProducts(values, setFieldValue),
            },
          ]
        )
    }

    const getProducts = (values, setFieldValue) => (
        <Products products={products}
          onHandleProducts={(e, msg) => {
            calculateSubtotal(e, values, setFieldValue, msg);
        }} />
    );

    const s = {
        paragraph: { marginBottom: '0px' },
        paragraphValue: { marginBottom: '0px', textAlign: 'right' }
    };

    const purchaseOrderSchema = Yup.object().shape({
        title: Yup.string().required(),
        projectId: Yup.string().required(),
    });

    return (
        <Formik
            initialValues={recordData}
            enableReinitialize
            onSubmit={handleSave}
            validationSchema={purchaseOrderSchema}
        >
            {({ errors, isSubmitting, submitForm, resetForm, touched, setValues, setFieldValue, values }) => (
                <Modal
                    width="95%"
                    title={id !== null ? t('screens:purchaseOrders.btnEdit') : t('screens:purchaseOrders.btnNew')}
                    onCancel={onClose}
                    afterClose={resetForm}
                    open={visible}
                    loading={loading || isSubmitting}
                    footer={
                        <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
                    }
                >
                    <Spin spinning={loading || isSubmitting}>
                        <Form>
                            <Row>
                                <FormControl
                                    cols={{ xs: 11, sm: 7, md: 6, lg: 5, xl: 4 }}
                                    field="id"
                                    label={t('screens:purchaseOrders.data.poNumber')}
                                >
                                    <Input name="id" disabled />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 4, xl: 3 }}
                                    field="date"
                                    label={t('screens:purchaseOrders.data.date')}
                                >
                                    <DatePicker name="date" format="MM/DD/YYYY" disabled placeholder={t('messages:select')} />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 4, xl: 5 }}
                                    field="projectId"
                                    label={t('screens:purchaseOrders.data.project')}
                                    required
                                    error={(touched.projectId && errors.projectId) || errors.projectId}
                                >
                                    <Select name="projectId" placeholder="Select">
                                        <Option key={project.id} value={project.id.toString()}>{project.description}</Option>
                                    </Select>

                                </FormControl>
                                <FormControl
                                    cols={{ xs: 24, sm: 14, md: 13, lg: 10, xl: 10 }}
                                    field="title"
                                    label={t('screens:purchaseOrders.data.title')}
                                    required
                                    error={(touched.title && errors.title) || errors.title}
                                >
                                    <Input name="title" />
                                </FormControl>
                            </Row>
                            <Row>
                                <FormControl
                                    cols={{ xs: 11, sm: 7, md: 6, lg: 5, xl: 4 }}
                                    field="proposalNumber"
                                    label={t('screens:purchaseOrders.data.proposalNumber')}
                                >
                                    <Input name="proposalNumber" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12, sm: 7, md: 6, lg: 5, xl: 4 }}
                                    field="addProposal"
                                    label={<span style={{ color: 'white' }}>addProposal</span>}>
                                    <div style={{ paddingTop: 0 }}>
                                        <Checkbox value="addProposal" name="addProposal" />
                                        <Tooltip style={{ marginLeft: 10 }} title={t('screens:purchaseOrders.data.addProposalCheckbox')}>
                                            <span style={{ marginLeft: 10 }}>{t('screens:purchaseOrders.data.addProposal')}</span>
                                        </Tooltip>
                                    </div>
                                </FormControl>
                            </Row>
                            <Row>
                                <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 4, xl: 6 }}
                                    field="contractor"
                                    required
                                    error={(touched.contractor && errors.contractor) || errors.contractor}
                                >
                                    <Radio.Group
                                        value={contractor}
                                        onChange={(e) => {
                                            onChangeContractor(e);
                                        }}
                                    >
                                        <Radio value={false}>{t('screens:purchaseOrders.data.purchaser')}</Radio>
                                        <Radio value={true}>{t('screens:purchaseOrders.data.contractor')}</Radio>
                                    </Radio.Group>
                                </FormControl>
                            </Row>
                            <Row>
                                {value === 1 ?
                                    <FormControl
                                        cols={{ xs: 8, sm: 7, md: 5, lg: 4, xl: 6 }}
                                        field="subContractorId"
                                        required
                                        error={(touched.subContractorId && errors.subContractorId) || errors.subContractorId}
                                    >
                                        <Radio.Group
                                            onChange={(e) => {
                                                onChangeRadio(e);
                                                setFieldValue("subContractorId", '');
                                                setFieldValue("termsAndConditions", t('screens:purchaseOrders.data.termsAndConditionsCustomer'));
                                                setFieldValue("acceptancePurchaseOrder", t('screens:purchaseOrders.data.acceptancePurchaseOrderCustomer'));
                                            }}
                                            value={value}
                                        >
                                            <Radio value={1}>{t('screens:purchaseOrders.data.subContractor')}</Radio>
                                            <Radio value={2}>{t('screens:purchaseOrders.data.customer')}</Radio>
                                        </Radio.Group>
                                        <Select name="subContractorId" placeholder="Select">
                                            {subContractors.map(item => {
                                                return (
                                                    <Option key={item.id} value={item.id.toString()}>{item.companyName}</Option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl
                                        cols={{ xs: 8, sm: 7, md: 5, lg: 4, xl: 6 }}
                                        field="customerId"
                                        required
                                        error={(touched.customerId && errors.customerId) || errors.customerId}
                                    >
                                        <Radio.Group
                                            onChange={(e) => {
                                                onChangeRadio(e);
                                                setFieldValue("subContractorId", '');
                                                setFieldValue("termsAndConditions", t('screens:purchaseOrders.data.termsAndConditionsVendor'));
                                                setFieldValue("acceptancePurchaseOrder", t('screens:purchaseOrders.data.acceptancePurchaseOrderVendor'));
                                            }}
                                            value={value}
                                        >
                                            <Radio value={1}>{t('screens:purchaseOrders.data.subContractor')}</Radio>
                                            <Radio value={2}>{t('screens:purchaseOrders.data.customer')}</Radio>
                                        </Radio.Group>
                                        <Select name="customerId" placeholder="Select">
                                            {customers.map(item => {
                                                return (
                                                    <Option key={item.id} value={item.id.toString()}>{item.companyName}</Option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                }
                            </Row>
                            <Divider orientation="left" orientationMargin="0">
                                <Text>{t('screens:purchaseOrders.data.shippingInformations')}</Text>
                            </Divider>
                            <Row>
                                <FormControl
                                    cols={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
                                    field="shipVia"
                                    label={t('screens:purchaseOrders.data.shipVia')}
                                >
                                    <Input name="shipVia" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
                                    field="shippingMethod"
                                    label={t('screens:purchaseOrders.data.shippingMethod')}
                                >
                                    <Input name="shippingMethod" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
                                    field="shippingTerms"
                                    label={t('screens:purchaseOrders.data.shippingTerms')}
                                >
                                    <Input name="shippingTerms" />
                                </FormControl>
                                <FormControl
                                    cols={{ xs: 8, sm: 6, md: 5, lg: 4, xl: 3 }}
                                    field="deliveryDate"
                                    label={t('screens:purchaseOrders.data.deliveryDate')}
                                >
                                    <DatePicker name="deliveryDate" format="MM/DD/YYYY" placeholder={t('messages:select')} />
                                </FormControl>
                            </Row>
                            <Row>
                                <FormControl
                                    cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                                    field="termsAndConditions"
                                    label={t('screens:purchaseOrders.data.termsAndConditions')}
                                >
                                    <Input.TextArea name="termsAndConditions" showCount maxLength={500} />
                                </FormControl>

                                <FormControl
                                    cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                                    field="acceptancePurchaseOrder"
                                    label={t('screens:purchaseOrders.data.acceptancePurchaseOrder')}
                                >
                                    <Input.TextArea name="acceptancePurchaseOrder" showCount maxLength={500} />
                                </FormControl>
                            </Row>
                            <br />
                                <TabProduct size="small" defaultActiveKey="1" type="card" items={getTabItems(values, setFieldValue)} />
                            <br />
                            <Row>
                                <Col xs={8} sm={11} md={14} lg={16} xl={17} onClick={() => { setEditableStr('') }}>
                                    <span style={{ height: '140px' }} />
                                </Col>
                                <Col xs={8} sm={6} md={5} lg={4} xl={3} onClick={() => { setEditableStr('') }}>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.subtotal')}</Paragraph>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.discount')}</Paragraph>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.salesTaxRate')}</Paragraph>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.salesTax')}</Paragraph>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.otherCost')}</Paragraph>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.others')}</Paragraph>
                                    <Paragraph style={s.paragraph} type="secondary">{t('screens:purchaseOrders.data.shEstimated')}</Paragraph>
                                    <Paragraph style={s.paragraph} strong>{t('screens:purchaseOrders.data.total')}</Paragraph>
                                </Col>
                                <Col xs={8} sm={7} md={5} lg={4} xl={4}>
                                    <Paragraph style={s.paragraphValue}>{formatPrice(subtotal)}</Paragraph>
                                    {editableStr === 'discount' ?
                                        <InputCurrency
                                            name="discount" currency="$" number={false} style={{ textAlign: 'right' }}
                                            onBlur={() => {
                                                setEditableStr('discount');
                                                calculateTotal(subtotal, values);
                                            }}
                                            onChange={event => {
                                                setTimeout(() => {
                                                    setValues({ ...values, discount: dynamicCost(event.target.value) });
                                                }, 100);
                                            }}
                                        />
                                        : <Paragraph style={s.paragraphValue} onClick={() => { setEditableStr('discount') }}>{formatPrice(values.discount)}</Paragraph>
                                    }

                                    {editableStr === 'salesTaxRate' ?
                                        <InputCurrency
                                            name="salesTaxRate" currency="%" number={false} style={{ textAlign: 'right' }}
                                            onBlur={() => { setEditableStr('salesTaxRate') }}
                                            onChange={event => {
                                                setTimeout(() => {
                                                    setValues({ ...values, salesTaxRate: dynamicCost(event.target.value) });
                                                }, 100);
                                            }}
                                        />
                                        : <Paragraph style={s.paragraphValue} onClick={() => { setEditableStr('salesTaxRate') }}>{formatPercent(values.salesTaxRate / 100)}</Paragraph>
                                    }

                                    {editableStr === 'salesTax' ?
                                        <InputCurrency
                                            name="salesTax" currency="$" number={false} style={{ textAlign: 'right' }}
                                            onBlur={() => {
                                                setEditableStr('salesTax');
                                                calculateTotal(subtotal, values);
                                            }}
                                            onChange={event => {
                                                setTimeout(() => {
                                                    setValues({ ...values, salesTax: dynamicCost(event.target.value) });
                                                }, 100);
                                            }}
                                        />
                                        : <Paragraph style={s.paragraphValue} onClick={() => { setEditableStr('salesTax') }}>{formatPrice(values.salesTax)}</Paragraph>
                                    }

                                    {editableStr === 'otherCost' ?
                                        <InputCurrency
                                            name="otherCost" currency="$" number={false} style={{ textAlign: 'right' }}
                                            onBlur={() => {
                                                setEditableStr('otherCost');
                                                calculateTotal(subtotal, values);
                                            }}
                                            onChange={event => {
                                                setTimeout(() => {
                                                    setValues({ ...values, otherCost: dynamicCost(event.target.value) });
                                                }, 100);
                                            }}
                                        />
                                        : <Paragraph style={s.paragraphValue} onClick={() => { setEditableStr('otherCost') }}>{formatPrice(values.otherCost)}</Paragraph>
                                    }

                                    {editableStr === 'others' ?
                                        <InputCurrency
                                            name="others" currency="$" number={false} style={{ textAlign: 'right' }}
                                            onBlur={() => {
                                                setEditableStr('others');
                                                calculateTotal(subtotal, values);
                                            }}
                                            onChange={event => {
                                                setTimeout(() => {
                                                    setValues({ ...values, others: dynamicCost(event.target.value) });
                                                }, 100);
                                            }}
                                        />
                                        : <Paragraph style={s.paragraphValue} onClick={() => { setEditableStr('others') }}>{formatPrice(values.others)}</Paragraph>
                                    }

                                    {editableStr === 'shEstimated' ?
                                        <InputCurrency
                                            name="shEstimated" currency="$" number={false} style={{ textAlign: 'right' }}
                                            onBlur={() => {
                                                setEditableStr('shEstimated');
                                                calculateTotal(subtotal, values);
                                            }}
                                            onChange={event => {
                                                setTimeout(() => {
                                                    setValues({ ...values, shEstimated: dynamicCost(event.target.value) });
                                                }, 100);
                                            }}
                                        />
                                        : <Paragraph style={s.paragraphValue} onClick={() => { setEditableStr('shEstimated') }}>{formatPrice(values.shEstimated)}</Paragraph>
                                    }

                                    <Paragraph strong style={s.paragraphValue}>{formatPrice(total)}</Paragraph>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            )}
        </Formik>
    );
}