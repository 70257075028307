import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { DeleteOutlined, EditOutlined, PlusOutlined, DownloadOutlined, MessageOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Table as TableAntd, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import SubmittialForm from './form';
import Filter from './filter';
import { paginationSpecBookSubmittials, deleteSpecBookSubmittial } from '~/services/hooks/specBookSubmittials';
import { downloadFile } from '~/services/hooks/files';
import { useSelector } from 'react-redux';
import moment from 'moment';
import UserInfo from '~/components/UserInfo';
import UsersInfo from '~/components/UsersInfo';
import './styles.css';

export default function Submittials({ project, customer }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const user = useSelector(state => state.user.profile);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationSpecBookSubmittials({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [
        ...filters,
        { columnJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' },
        { field: 'type', value: 'SPEC_BOOK', restriction: 'NOT_EQUAL' },
        { field: 'type', value: 'RFI', restriction: 'NOT_EQUAL' },
        // { field: 'reply', value: null, restriction: 'IS_NULL' }, // original questions
        // { columnJoin: 'reply', field: 'id', value: replyId, restriction: 'EQUAL' }, // replies
      ],
    });
    const content = data.content;
    const originalData = content.filter(i => i.reply === null);
    originalData.map(item => {
      if (item.replied === true) {
        item.replies = content.filter(i => i.reply?.id === item.id);
      }
    });
    setTableData(originalData);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (project.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy, project]);

  const handleDeleteReplies = async item => {
    if (item?.replies?.length === 0) return;
    item.replies.map(async i => {
      await deleteSpecBookSubmittial(i.id);
    });
  };

  const handleDelete = async id => {
    const item = tableData.find(i => i.id === id);
    if (item?.replies?.length > 0) {
      handleDeleteReplies(item).then(async () => {
        deleteSpecBookSubmittial(id).then(() => {
          fetchData(meta.pageNumber + 1);
        });
      });
    } else {
      deleteSpecBookSubmittial(id).then(() => {
        fetchData(meta.pageNumber + 1);
      });
    }
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setIsReply(false);
    setShowForm(true);
  };

  const handleReply = record => {
    setSelectedRecord(record);
    setIsReply(true);
    setShowForm(true);
  };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    TableAntd.EXPAND_COLUMN,
    {
      title: t('screens:submittial.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: true,
      width: 60,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:submittial.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 'auto', // width: 200,
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:submittial.sentBy'),
      dataIndex: 'sentBy',
      key: 'sentBy',
      align: 'left',
      width: 250,
      ellipsis: { showTitle: true },
      render: sentBy => <UserInfo user={sentBy} />,
    },
    {
      title: t('screens:submittial.sentTo'),
      dataIndex: 'users',
      key: 'sentTo',
      align: 'left',
      width: 250,
      ellipsis: { showTitle: true },
      render: users => <UsersInfo users={users} />
        // if (users.length === 0) return <>Not assigned</>;
        // const usersList = (
        //   <Space direction="vertical" size={3}>
        //     {users.map(user => (
        //       <UserInfo key={user.id} user={user} />
        //     ))}
        //   </Space>
        // );
        // return usersList;

    },
    {
      title: t('screens:submittial.createDate'),
      dataIndex: 'audit',
      key: 'audit.createDate',
      align: 'left',
      width: 150,
      sorter: true,
      ellipsis: { showTitle: true },
      render: audit => <>{moment(audit.createdDate).format('YYYY/MM/DD HH:MM:ss')}</>, // render: (audit) => (<>{audit.createDate}</>)
    },
    // {
    //   title: t('screens:submittial.type'),
    //   dataIndex: 'type',
    //   key: 'type',
    //   align: 'left',
    //   width: 150,
    //   sorter: true,
    //   ellipsis: {
    //     showTitle: true,
    //   },
    //   render: type => <>{type === 'SUBMITTIAL' ? t('messages:specBookAndSubmittials.submittialOnly') : t('messages:specBookAndSubmittials.submittialAndSpecBook')}</>,
    // },
    {
      title: t('screens:submittial.actions'),
      key: 'actions',
      align: 'center',
      width: '180px',
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={async () => await downloadFile(record.arquive)} title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          {user.id === record.sentBy.id && (
            <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
              <EditOutlined />
            </Button>
          )}
          {user.id === record.sentBy.id && (
            <Popconfirm
              icon={false}
              title={t('messages:confirmDelete')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <Button title={t('messages:delete')}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
          {user.id !== record.sentBy.id && (
            <Button onClick={() => handleReply(record)} title={t('messages:reply')}>
              <MessageOutlined />
            </Button>
          )}
        </TableActions>
      ),
    },
  ];

  const tableColumns2 = [
    {
      title: t('screens:submittial.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 60,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:submittial.description'),
      dataIndex: 'description',
      key: 'description',
      width: 'auto',
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:submittial.sentBy'),
      dataIndex: 'sentBy',
      key: 'sentBy',
      align: 'left',
      width: 250,
      ellipsis: { showTitle: true },
      render: sentBy => <UserInfo user={sentBy} />,
    },
    {
      key: 'sentTo',
      align: 'left',
      width: 250,
      ellipsis: { showTitle: true },
      render: record => <UsersInfo users={record.users} />
    },
    {
      title: t('screens:submittial.createDate'),
      dataIndex: 'audit',
      key: 'audit.createDate',
      width: 150,
      align: 'left',
      ellipsis: { showTitle: true },
      render: audit => <>{moment(audit?.createdDate).format('YYYY/MM/DD HH:MM:ss')}</>,
    },
    {
      title: t('screens:submittial.actions'),
      key: 'actions',
      align: 'center',
      width: '180px',
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={async () => await downloadFile(record.arquive)} title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          {user.id === record.sentBy.id && (
            <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
              <EditOutlined />
            </Button>
          )}
          {user.id === record.sentBy.id && (
            <Popconfirm
              icon={false}
              title={t('messages:confirmDelete')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <Button title={t('messages:delete')}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        </TableActions>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:submittial.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          defaultExpandAllRows={true}
          expandable={{
            expandedRowRender: record => (
              <TableAntd
                showHeader={false}
                size="small"
                bordered
                rowKey="id"
                rowClassName="table-parent-row-winner"
                pagination={false}
                columns={tableColumns2}
                title={() => 'Reply'}
                dataSource={record.replies}
              />
            ),
            rowExpandable: record => record.replied === true,
          }}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        <SubmittialForm
          project={project}
          isReply={isReply}
          user={user}
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => {
            setShowForm(false);
            fetchData(1);
          }}
        />
      </Box>
    </>
  );
}
