import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, Checkbox } from 'formik-antd';
import { Modal, message, Spin, Typography, Descriptions, Collapse, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Addresses from './Addresses';
import { TabAddress } from './styles';
import { store } from '~/store';
import Contacts from './Contact';
import CustomerContactForm from './CustomerContact/form';
import ContactForm from './Contact/form';

const initialValues = {
  id: null,
  flag: 'LEAD',
  company: null,
  primaryContactFirstName: '',
  primaryContactLastName: '',
  primaryContactJobTitle: '',
  primaryContactMainPhone: '',
  primaryContactMainEmail: '',
  primaryContactWorkPhone: '',
  primaryContactccEmail: '',
  primaryContactMobilePhone: '',
  secondaryContactFirstName: '',
  secondaryContactLastName: '',
  secondaryContactJobTitle: '',
  secondaryContactMainPhone: '',
  secondaryContactMainEmail: '',
  secondaryContactWorkPhone: '',
  secondaryContactccEmail: '',
  secondaryContactMobilePhone: '',
  website: '',
  faxNumber: '',
  other: '',
  companyName: '',
  checkPayByACH: false,
  checkPayByCheck: false,
  salesTaxCode: 'NON',
  taxItem: null,
  addresses: [],
};

const initValuesCContact = {
  id: null,
  primaryContact: null,
  secondaryContact: null,
  website: '',
  faxNumber: '',
  other: ''
}

export default function CustomerForm({ visible, onClose, id }) {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { Option } = Select;
  const { Panel } = Collapse;

  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerContact, setCustomerContact] = useState(initValuesCContact);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [secondaryContact, setSecondaryContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  const [taxItem, setTaxItem] = useState(null);
  const [company, setCompany] = useState(null);
  const [taxItems, setTaxItems] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [showContactForm, setShowContactForm] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);

    try {
      if (id === null) {
        setAddresses([]);
        setCustomer(null);
        setPrimaryContact(null);
        setSecondaryContact(null);
        setCustomerContact(initValuesCContact);
        setTaxItem(null);
        setRecordData(initialValues);
      } else {
        const { data } = await api.get('/customers/findById', {
          params: { id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        let initialData = { initialValues };
        initialData.id = data.id;
        initialData.flag = data.flag;
        initialData.company = data.company;
        initialData.companyName = data.companyName;
        initialData.checkPayByACH = data.checkPayByACH;
        initialData.checkPayByCheck = data.checkPayByCheck;
        initialData.salesTaxCode = data.salesTaxCode;
        initialData.taxItem = data.taxItem !== null ? data.taxItem.id : null;

        let count = 1;
        data.addresses.forEach((address) => {
          address.uuid = count;
          count = count + 1;
        });
        initialData.addresses = data.addresses;

        if (data.customerContact !== null) {
          const cc = data.customerContact;
          const p = cc.primaryContact;
          const s = cc.secondaryContact;

          setCustomerContact(data.customerContact);
          setPrimaryContact(p);
          setSecondaryContact(s);

          initialData.primaryContactFirstName = p.firstName;
          initialData.primaryContactLastName = p.lastName;
          initialData.primaryContactJobTitle = p.jobTitle;
          initialData.primaryContactMainPhone = p.mainPhone;
          initialData.primaryContactMainEmail = p.mainEmail;
          initialData.primaryContactWorkPhone = p.workPhone;
          initialData.primaryContactccEmail = p.ccEmail;
          initialData.primaryContactMobilePhone = p.mobilePhone;

          if (s !== null) {
            initialData.secondaryContactFirstName = s.firstName;
            initialData.secondaryContactLastName = s.lastName;
            initialData.secondaryContactJobTitle = s.jobTitle;
            initialData.secondaryContactMainPhone = s.mainPhone;
            initialData.secondaryContactMainEmail = s.mainEmail;
            initialData.secondaryContactWorkPhone = s.workPhone;
            initialData.secondaryContactccEmail = s.ccEmail;
            initialData.secondaryContactMobilePhone = s.mobilePhone;
          }

          initialData.website = cc.website;
          initialData.faxNumber = cc.faxNumber;
          initialData.other = cc.other;
        } else {
          setCustomerContact(initValuesCContact);
          setPrimaryContact(null);
          setSecondaryContact(null);
        }

        setAddresses(data.addresses);
        setCustomer(data);
        setTaxItem(data.taxItem);
        setRecordData(initialData);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCompany = async () => {
    setLoading(true);
    try {
      const { name } = store.getState().user.profile.company;
      console.log('companyName', name);
      const { data } = await api.get('/companies/findByName', {
        params: { name: name }
      });
      setCompany(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchTaxItems = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/taxItems/findAll');
      setTaxItems(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (customer && customer.id !== null) {
        const c = {
          id: customer.id,
          flag: values.flag,
          company: values.company,
          customerContact: customerContact,
          companyName: values.companyName,
          checkPayByACH: values.checkPayByACH,
          checkPayByCheck: values.checkPayByCheck,
          salesTaxCode: values.salesTaxCode,
          taxItem: null,
          addresses: addresses,
        };

        await api.put('/customers/update', c);
        message.success(t('messages:customerSuccessEdit'));
      } else {
        const c = {
          id: null,
          flag: values.flag,
          company: company,
          customerContact: customerContact,
          companyName: values.companyName,
          checkPayByACH: values.checkPayByACH,
          checkPayByCheck: values.checkPayByCheck,
          salesTaxCode: values.salesTaxCode,
          taxItem: null,
          addresses: addresses,
        };

        await api.post('/customers/create', c);
        message.success(t('messages:customerSuccess'));
      }

      onClose();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const onChange = (key) => {
    //console.log(key);
  };

  const handleSelect = (contact, primary) => {
    let cc = customerContact;
    if (primary) {
      cc.primaryContact = contact;
      setPrimaryContact(contact);
    } else {
      cc.secondaryContact = contact;
      setSecondaryContact(contact);
    }
    setCustomerContact(cc);
  };

  useEffect(() => {
    if (visible) {
      setAddresses([]);
      fetchCompany();
      fetchRecords();
      fetchTaxItems();
    }
  }, [visible]);

  const style = {
    divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px`, }
  }

  const salesTaxCodes = [
    {
      id: 1,
      code: 'TAX',
      description: t('screens:customers.data.salesTaxCodes.tax'),
    },
    {
      id: 2,
      code: 'NON',
      description: t('screens:customers.data.salesTaxCodes.non'),
    },
  ];

  const genExtra = (key) => (
    (key === 1 && primaryContact !== null) || (key === 2 && secondaryContact !== null) ?

    <DeleteOutlined
      onClick={(event) => {
        switch (key) {
          case 1:
            handleSelect(null, true);
            break;
          case 2:
            handleSelect(null, false);
            break;
          case 3:
            setShowForm(true);
            break;
        }
        event.stopPropagation();
      }}
    />
    : key === 3 ?
    <EditOutlined
      onClick={(event) => {
        switch (key) {
          case 3:
            setShowForm(true);
            break;
        }
        event.stopPropagation();
      }}
    />
      : null
  );

  const getAddresses = (type, values) => (
    <Addresses addressType={type} addresses={addresses}
      onHandleAddresses={(e, msg) => {
        setAddresses(e);
        message.success(msg);
      }} />
  );

  function getTabItems(errors, values) {
    return (
      [
        {
          label: 'Billing Address',
          key: 'billingAddress',
          children: getAddresses('BILLING', values),
        },
        {
          label: 'Shipping Address',
          key: 'shippingAddress',
          children: getAddresses('SHIPPING', values),
        },
      ]
    )
  }

  const customerSchema = Yup.object().shape({
    companyName: Yup.string().max(20, 'Too Long! Maximum 20 characters').required(),
    salesTaxCode: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={customerSchema}
    >
      {({ errors, isSubmitting, touched, submitForm, resetForm, values }) => (
        <Modal
          title={t('screens:customers.btnNew')}
          width={800}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row><Text style={style.divider}>{t('screens:customers.data.generalContactInformation')}</Text></Row>
              <Row>
                <FormControl
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  error={touched.companyName && errors.companyName}
                  field="companyName"
                  label={t('screens:customers.data.companyName')}
                  required
                >
                  <Input name="companyName" autoComplete="off" />
                </FormControl>
              </Row>
              <br />
              <Collapse defaultActiveKey={'1'} onChange={onChange} className="ant-collapse-content-inactive">
                <Panel header="Primary Contact" key="1" extra={genExtra(1)}>
                  <Contacts primary={true} onSelect={(c) => handleSelect(c, true)} contact={primaryContact} />
                </Panel>
                <Panel header="Secondary Contact" key="2" extra={genExtra(2)}>
                  <Contacts primary={false} onSelect={(c) => handleSelect(c, false)} contact={secondaryContact} />
                </Panel>
                <Panel header="Others" key="3" extra={genExtra(3)}>
                  <Descriptions size="small" column={{ xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}>
                    <Descriptions.Item span={24} label={<Text type="secondary">{t('screens:customers.data.website')}</Text>}>{customerContact.website !== null ? customerContact.website : ''}</Descriptions.Item>
                    <Descriptions.Item span={24} label={<Text type="secondary">{t('screens:customers.data.faxNumber')}</Text>}>{customerContact.faxNumber !== null ? customerContact.faxNumber : ''}</Descriptions.Item>
                    <Descriptions.Item span={24} label={<Text type="secondary">{t('screens:customers.data.other')}</Text>}>{customerContact.other !== null ? customerContact.other : ''}</Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
              <br />
              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:customers.data.paymentInformation')}</Text>
              </Divider>
              <Row>
                <FormControl field="checkPayByACH" cols={{ xs: 24 }}>
                  <div style={{ paddingTop: 0 }}>
                    <Checkbox value="checkPayByACH" name="checkPayByACH" />
                    <span style={{ marginLeft: 10 }}>{t('screens:customers.data.checkPayByACH')}</span>
                  </div>
                </FormControl>
                <FormControl field="checkPayByCheck" cols={{ xs: 24 }}>
                  <div style={{ paddingTop: 0 }}>
                    <Checkbox value="checkPayByCheck" name="checkPayByCheck" />
                    <span style={{ marginLeft: 10 }}>{t('screens:customers.data.checkPayByCheck')}</span>
                  </div>
                </FormControl>
              </Row>
              <br />
              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:customers.data.taxInformation')}</Text>
              </Divider>
              <Row>
                <FormControl
                  cols={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                  error={errors.salesTaxCode}
                  field="salesTaxCode"
                  label={t('screens:customers.data.salesTaxCode')}
                  required
                >
                  <Select name="salesTaxCode" placeholder="Select">
                    {salesTaxCodes.map(item => {
                      return (
                        <Option key={item.id} value={item.code}>{item.description}</Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  field="taxItem"
                  cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  label={t('screens:customers.data.taxItem')}
                >
                  <Select name="taxItem" placeholder="Select">
                    {taxItems.map(item => {
                      return (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
              <br />
              <TabAddress size="small" defaultActiveKey="1" type="card" items={getTabItems(errors, values)} />
            </Form>
            <ContactForm
              contact={selectedContact}
              visible={showContactForm}
              onClose={() => setShowContactForm(false)}
              onChange={(c) => {
                handleSelect(c, isPrimaryContact);
                setShowContactForm(false);
              }}
            />
            <CustomerContactForm
              customerContact={customerContact}
              visible={showForm}
              onClose={() => setShowForm(false)}
              onChange={(cc) => {
                setCustomerContact(cc);
                setShowForm(false);
              }}
            />
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
