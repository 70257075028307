import { Tabs } from 'antd';
import styled from 'styled-components';

export const TabProduct = styled(Tabs)`
    .ant-tabs-content-holder {
        border: 1px solid #f0f0f0;
        border-top: transparent; /* Remove the top border so that there is no overlap*/
    }

    .ant-tabs-nav {
        margin: 0px;
    }
`;