import React from 'react';
import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import { approvalStatusList } from '~/Utils/data/jsonData';
import ChangeOrdersClientForm from './form';
import { paginationChangeOrdersClient, deleteChangeOrderClient } from '~/services/hooks/changeOrdersClient';
import { downloadFile } from '~/services/hooks/files';
import Filter from './filter';

export default function ChangeOrdersClient({ project, customer }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const defaultFilter = { columnJoin: 'project', field: 'id', value: project.id, restriction: 'EQUAL' };
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationChangeOrdersClient({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [...filters, defaultFilter],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const handleDelete = async id => {
    await deleteChangeOrderClient(id);
    fetchData(1);
  };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:changeOrders.data.number'),
      dataIndex: 'id',
      width: '100px',
      key: 'id',
      align: 'center',
    },
    {
      title: t('screens:changeOrders.data.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: t('screens:changeOrders.data.approvalDeadline'),
      dataIndex: 'approvalDeadline',
      key: 'approvalDeadline',
      width: '200px',
      render: approvalDeadline => {
        if (approvalDeadline === null || approvalDeadline === undefined) {
          return <>N/A</>;
        }
        return moment(approvalDeadline).format('YYYY/MM/DD');
      },
    },
    {
      title: t('screens:changeOrders.data.approvalStatus'),
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      width: '200px',
      hidden: true,
      render: approvalStatus => {
        if (approvalStatus === null || approvalStatus === undefined) {
          return <>N/A</>;
        }
        return approvalStatusList.find(item => item.value === approvalStatus).label;
      },
    },
    {
      title: t('screens:changeOrders.data.projectName'),
      dataIndex: 'project',
      width: '200px',
      key: 'project',
      hidden: true,
      render: project => {
        if (project?.description === null || project?.description === undefined) {
          return <>N/A</>;
        }
        return project?.description;
      },
    },
    {
      title: t('screens:changeOrders.data.actions'),
      key: 'actions',
      align: 'center',
      width: '100px',
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          {record.attachments.length > 0 ? (
            <Popover
              content={
                <div>
                  {record.attachments.map(item => {
                    return (
                      <p key={item.arquive.id}>
                        <FilePdfOutlined /> {item.arquive.originalName}{' '}
                        <DownloadOutlined onClick={async () => await downloadFile(item.arquive)} />
                      </p>
                    );
                  })}
                </div>
              }
              title="Download Attachments"
              trigger="focus"
            >
              <Button title="Download Attachments">
                <DownloadOutlined />
              </Button>
            </Popover>
          ) : null}
        </TableActions>
      ),
    },
  ].filter(item => !item.hidden);

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter approvalStatusList={approvalStatusList} isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:changeOrders.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        {showForm && (
          <ChangeOrdersClientForm
            selectedRecord={selectedRecord}
            visible={showForm}
            approvalStatusList={approvalStatusList}
            project={project}
            customer={customer}
            onCancel={() => {
              setShowForm(false);
            }}
            onClose={() => {
              setShowForm(false);
              fetchData(1);
            }}
          />
        )}
      </Box>
    </>
  );
}
