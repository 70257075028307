import React, { useEffect } from 'react';
import { Col, Modal, Spin, Typography, InputNumber } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import FormTxt from '~/components/Form/FormTxt';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { unitList } from '~/services/lists/unit';
import { formatPrice } from '~/Utils';
import { createEstimateDescription, updateEstimateDescription } from '~/services/hooks/estimateDescriptions';

export default function EstimateDescriptionForm({
  selectedRecord,
  laborTypes,
  visible,
  onClose,
  onUpdateItem,
  onInsertItem,
}) {
  const initialValues = {
    id: null,
    laborType: laborTypes.length > 0 ? laborTypes[0] : { id: null, code: null, name: '' },
    unit: 'SF',
    description: '',
    materialValue: 0.0,
    laborValue: 0.0,
  };

  const estimateDescription = { ...initialValues, ...selectedRecord };
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    setLoading(true);
    if (values.id !== null) {
      const data = await updateEstimateDescription(values);
      onUpdateItem(data);
    } else {
      const data = await createEstimateDescription(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const estimateDescriptionSchema = Yup.object().shape({
    description: Yup.string().required(),
    laborType: Yup.object().shape({
      id: Yup.number().required(),
    }),
    unit: Yup.string().required(),
    materialValue: Yup.number()
      .required()
      .min(0.0),
    laborValue: Yup.number()
      .required()
      .min(0.0),
  });

  return (
    <Formik
      initialValues={estimateDescription}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={estimateDescriptionSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          width="850px"
          title={
            values.id !== null ? t('screens:estimateDescription.btnEdit') : t('screens:estimateDescription.btnNew')
          }
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  {/* DESCRIPTION */}
                  <FormControl
                    field="description"
                    label={t('screens:estimateDescription.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {/* SERVICE TYPE */}
                  <FormControl
                    field="laborType.id"
                    label={t('screens:estimateDescription.data.laborType')}
                    required
                    error={(touched.laborType?.id && errors.laborType?.id) || errors.laborType?.id}
                  >
                    <Select
                      name="laborType.id"
                      id="laborType.id"
                      placeholder="Select"
                      value={values.laborType?.id}
                      onChange={value => {
                        setFieldValue(
                          'laborType',
                          laborTypes.find(item => item.id === value)
                        );
                      }}
                    >
                      {laborTypes?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {/* UNIT */}
                  <FormControl field="unit" label={t('screens:estimateDescription.data.unit')} required>
                    <Select
                      name="unit"
                      id="unit"
                      placeholder="Select"
                      value={values.unit}
                      onChange={value => {
                        setFieldValue('unit', value);
                      }}
                    >
                      {unitList?.map(item => {
                        return (
                          <Option key={item.id} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {/* MATERIAL UNIT PRICE */}
                  <FormControl
                    field="materialValue"
                    label={t('screens:estimateDescription.data.materialValue')}
                    required
                    error={(touched.materialValue && errors.materialValue) || errors.materialValue}
                  >
                    <InputNumber
                      addonBefore="$"
                      name="materialValue"
                      id="materialValue"
                      placeholder="Enter material unit cost"
                      value={values.materialValue}
                      onChange={value => {
                        setFieldValue('materialValue', value);
                      }}
                    />
                  </FormControl>
                </Col>

                <Col span={8}>
                  {/* LABOR UNIT PRICE */}
                  <FormControl
                    field="laborValue"
                    label={t('screens:estimateDescription.data.laborValue')}
                    required
                    error={(touched.laborValue && errors.laborValue) || errors.laborValue}
                  >
                    <InputNumber
                      addonBefore="$"
                      name="laborValue"
                      id="laborValue"
                      placeholder="Enter labor unit cost"
                      value={values.laborValue}
                      onChange={value => {
                        setFieldValue('laborValue', value);
                      }}
                    />
                  </FormControl>
                </Col>

                <Col span={8}>
                  {/* TOTAL UNIT */}
                  <FormTxt label={t('screens:estimateDescription.data.totalUnit')}>
                    <>{formatPrice(
                      parseFloat(
                        (values.materialValue !== undefined ? values.materialValue : 0) +
                          (values.laborValue !== undefined ? values.laborValue : 0)
                      )
                    )}</>
                  </FormTxt>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
