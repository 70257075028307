import React from 'react';
import { EditOutlined, EyeOutlined, TrophyTwoTone } from '@ant-design/icons';
import { Breadcrumb, Col, Tooltip, Button as ButtonAntd, Badge } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import Filter from './filter';
import DefautLayout from '~/pages/_layouts/full';
//import { paginationEstimatesBySubVendor } from '~/services/hooks/estimates';
//import { findAllSubVendors } from '~/services/hooks/subVendors';
import history from '~/services/history';
import moment from 'moment-business-days';
import { findAllProjectTypes, paginationProjects } from '~/services/hooks/projects';

export default function ClientProjects() {
    const profile = useSelector(state => state.user.profile);
    const customerId = profile.customerId;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState({});
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [projectTypes, setProjectTypes] = useState([]);
    const [sortBy, setSortBy] = useState('id');
    const [sortDirection, setSortDirection] = useState('DESC');
    const [filters, setFilters] = useState([]);
    const defaultFilter = { field: 'flag', value: 'PROJECT', restriction: 'EQUAL' };
    const defaultFilterCustomer = customerId !== undefined ? { columnJoin: 'customer', field: 'id', value: customerId, restriction: 'EQUAL' } : {};

    const fetchData = async page => {
        setLoading(true);
        const data = await paginationProjects({
            page: page - 1,
            sortBy,
            filters: [...filters, defaultFilterCustomer], // filters: [...filters, defaultFilter, defaultFilterCustomer],
        });
        setTableData(data.content);
        const metaData = {
            first: data.first,
            last: data.last,
            numberOfElements: data.numberOfElements,
            pageNumber: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            totalElements: data.totalElements,
        };
        setMeta(metaData);
        setLoading(false);
    };

    const fetchProjectTypes = async () => {
        setLoading(true);
        const data = await findAllProjectTypes();
        setProjectTypes(data);
        setLoading(false);
    };

    useEffect(() => {
        fetchProjectTypes();
    }, []);

    useEffect(() => {
        fetchData(1);
    }, [filters, sortBy]);

    const style = {
        breadcrumb: { margin: '16px 0' },
        pageTitle: { marginRight: 'auto', width: '100%' },
        btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
        btnClear: { marginLeft: 'auto', marginTop: 25 },
        btnNew: {
            col: { marginLeft: 'auto', paddingBottom: '20px' },
            btn: { marginLeft: 'auto' }
        },
        status: {
            new: { backgroundColor: '#1890FF' },
            open: { backgroundColor: '#13C2C2' },
            inProgress: { backgroundColor: '#A0D911' },
            completed: { backgroundColor: '#FAAD14' },
            onHold: { backgroundColor: '#F5222D' }
        }
    }

    function getStyle(value) {
        switch (value) {
            case 'open':
                return (style.status.open);
            case 'in_progress':
                return (style.status.inProgress);
            case 'completed':
                return (style.status.completed);
            case 'on_hold':
                return (style.status.onHold);
            default:
                return (style.status.new);
        }
    }

    const projectStatus = [
        {
            id: 0,
            code: 'ALL',
            description: t('screens:status.all'),
        },
        {
            id: 1,
            code: 'NEW',
            description: t('screens:projects.data.status.new'),
        },
        {
            id: 2,
            code: 'OPEN',
            description: t('screens:projects.data.status.open'),
        },
        {
            id: 3,
            code: 'IN_PROGRESS',
            color: '',
            description: t('screens:projects.data.status.in_progress'),
        },
        {
            id: 4,
            code: 'COMPLETED',
            description: t('screens:projects.data.status.completed'),
        },
        {
            id: 5,
            code: 'ON_HOLD',
            description: t('screens:projects.data.status.on_hold'),
        },
    ];

    const tableColumns = [
        {
            title: t('screens:projects.data.code'),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 70,
        },
        {
            title: t('screens:projects.data.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('screens:projects.data.type'),
            dataIndex: 'typeDescription',
            key: 'typeDescription',
        },
        {
            title: t('screens:projects.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '120px',
            render: status =>
                <Badge
                    count={(t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase())}
                    style={getStyle(status.toLowerCase())} />
        },
        {
            title: t('screens:projects.data.actions'),
            key: 'actions',
            align: 'center',
            width: 85,
            ellipsis: { showTitle: true },
            render: record => (
              <ButtonAntd
                title={t('messages:view')}
                type="link"
                icon={<EyeOutlined />}
                onClick={() =>
                  history.push({
                    pathname: '/client-project-details',
                    state: { project: record, customer: record.customer },
                  })
                }
              />
            ),
          },
    ];

    const breadcrumb = (
        <BreadcrumbCustom>
            <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
            <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
        </BreadcrumbCustom>
    );

    return (
        <DefautLayout breadcrumb={breadcrumb}>
            <Filter projectStatus={projectStatus} isLoading={loading} setFilters={setFilters} />

            <Box>
                <Table
                    bordered
                    size="small"
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={tableData}
                />

                <Pagination
                    onChange={value => {
                        fetchData(value);
                    }}
                    meta={meta}
                />
            </Box>
        </DefautLayout>
    );
}