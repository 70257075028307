import React from 'react';
import { Col, Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createProposalSubcontractor, updateProposalSubcontractor } from '~/services/hooks/proposalSubcontractors';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function ProposalSubcontractorForm({
  project,
  subVendor,
  selectedRecord,
  visible,
  onClose,
  onUpdateItem,
  onInsertItem,
}) {
  const initialValues = {
    id: null,
    description: '',
    project: project,
    subVendor: subVendor,
    arquive: null,
  };

  const [uploadList, setUploadList] = useState([]);
  const proposalSubcontractor = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    setLoading(true);
    if (values.id !== null) {
      const data = await updateProposalSubcontractor(values);
      onUpdateItem(data);
    } else {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      const data = await createProposalSubcontractor(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const proposalSubcontractorSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={proposalSubcontractor}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={proposalSubcontractorSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          width="650px"
          title={
            values.id !== null ? t('screens:proposalSubcontractor.btnEdit') : t('screens:proposalSubcontractor.btnNew')
          }
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:proposalSubcontractor.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl field="proposal_subcontractor_file">
                    <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={proposalSubcontractor?.arquive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
