import React, { useState, useEffect, useMemo } from 'react';
import DefaultLayout from '~/pages/_layouts/full';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Badge, Tag, Tabs, Typography } from 'antd';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import ChangeOrders from './ChangeOrders';
import ChangeOrdersClient from './ChangeOrdersClient';
import PurchaseOrders from './PurchaseOrders';
import Warranty from './CloseOut/Warranty';
import Tasks from './Tasks';
import Informations from '~/pages/BidDetails/Informations';
import Costs from './Accounting/Workbook/Costs';
import Subcontractors from './Accounting/Workbook/Subcontractors';
import Receipts from './Accounting/Workbook/Receipts';
import ProjectDashboards from './Accounting/Workbook/ProjectDashboard';
import Estimates from '../BidDetails/Estimates';
import PriceQuotes from '../BidDetails/PriceQuotes';
import Drawings from '../BidDetails/Drawings';
import ProposalClients from '../BidDetails/ProposalClients';
import ProposalSubcontractors from '../BidDetails/ProposalSubcontractors';
import Subcontractor from './LegalDocuments/Subcontractor';
import SubcontractorCloseOut from './CloseOut/SubcontractorFiles';
import CloseOutFiles from './CloseOut/Files';
import RFIs from '../BidDetails/RFIs';
import PayAppsClients from './Accounting/PayAppsClients';

import { getProjectStatusColor } from '~/Utils';
import Invoices from './Invoices';

export default function ProjectDetails(data) {
  const customer = data.location.state?.customer;
  const project = data.location.state?.data;
  const { t } = useTranslation();

  // const [project, setProject] = useState(project);
  const { Text } = Typography;
  const [selectedTab, setSelectedTab] = useState(0);
  const tasksCount = 12;
  const changeOrdersCount = 6;
  const purchaseOrdersCount = 0;

  const style = {
    pageTitle: { color: '#0f4c81', fontSize: '20px' },
    badgeCount: {
      minWidth: '14px',
      height: '14px',
      padding: '0 8px',
      fontSize: '12px',
      lineHeight: '14px',
      borderRadius: '7px',
      textAlign: 'center',
      background: '#E6F7FF',
      color: '#1890FF',
      marginLeft: '3px',
    },
    status: {
      new: { backgroundColor: '#1890FF' },
      open: { backgroundColor: '#13C2C2' },
      inProgress: { backgroundColor: '#A0D911' },
      completed: { backgroundColor: '#FAAD14' },
      onHold: { backgroundColor: '#F5222D' },
    },
  };

  const onChangeTab = key => {
    setSelectedTab(key);
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/clients">{t('menus:clients')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/projects-list">{t('menus:projectList')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projectDetails')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const items = [
    {
      key: 0,
      label: <span>Informations</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            {
              key: 0,
              label: <span>Informations</span>,
              children: <Informations project={project} customer={customer} />,
            },
          ]}
        />
      ),
      // <Informations data={project} />,
      // <Documents visible={selectedTab === 1} modeView={modeView} onCount={(value) => setDocumentsCount(value)} />,
    },
    {
      key: 1,
      label: <span>Accounting</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          tabBarStyle={{ marginLeft: '20px' }}
          defaultActiveKey={0}
          items={[
            {
              key: 11,
              label: <span>Workbook</span>,
              children: (
                <Tabs
                  size="small"
                  tabPosition="top"
                  tabBarStyle={{ marginLeft: '20px' }}
                  defaultActiveKey={111}
                  items={[
                    // {
                    //   key: 111,
                    //   label: <span>Project Dashboard</span>,
                    //   children: <ProjectDashboards project={project} customer={customer} />,
                    // },
                    {
                      key: 112,
                      label: <span>Receipts</span>,
                      children: <Receipts project={project} customer={customer} />,
                    },
                    { key: 113, label: <span>Costs</span>, children: <Costs project={project} customer={customer} /> },
                    {
                      key: 114,
                      label: <span>SubContractors</span>,
                      children: <Subcontractors project={project} />,
                    },
                  ]}
                />
              ),
            },
            // { key: 12, label: <span>Bid</span>, children: <div style={{ height: '100vh' }}>Bid Content</div> },
            // { key: 13, label: <span>Insurance</span>, children: <div style={{ height: '100vh' }}>Insurance Content</div>, disabled: true },
            {
              key: 14,
              label: <span>Invoices</span>,
              children: <Invoices project={project} />,
            },
            {
              key: 15,
              label: <span>Pay Apps</span>,
              children: <PayAppsClients project={project} customer={customer} />,
            },
            { 
              key: 16,
              label: <span>Purchase Orders</span>, 
              children: <PurchaseOrders project={project}/> },
          ]}
        />
      ),
    },
    {
      key: 2,
      label: <span>Estimating</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            {
              key: 21,
              label: <span>Budgets</span>,
              children: <Estimates project={project} customer={customer} />,
            },
            {
              key: 22,
              label: <span>Price Quotes</span>,
              children: <PriceQuotes project={project} customer={customer} />,
            },
            {
              key: 23,
              label: <span>{t('menus:drawings')}</span>,
              children: <Drawings project={project} customer={customer} />,
            },
            {
              key: 24,
              label: <span>Proposals Client</span>,
              children: <ProposalClients project={project} customer={customer} />,
            },
            {
              key: 25,
              label: <span>Proposals Subcontractor</span>,
              children: <ProposalSubcontractors project={project} customer={customer} />,
            },
            {
              key: 26,
              label: <span>RFI</span>,
              children: <RFIs project={project} customer={customer} />,
            },
          ]}
        />
      ),
    },
    {
      key: 4,
      label: <span>Legal Documents</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            // { key: 41, label: <span>Liens</span>, children: <div style={{ height: '100vh' }}>Liens Content</div> },
            // { key: 42, label: <span>Owner</span>, children: <div style={{ height: '100vh' }}>Owner Content</div> },
            {
              key: 43,
              label: <span>Subcontractor</span>,
              children: <Subcontractor project={project} />,
            },
          ]}
        />
      ),
    },
    {
      key: 7,
      label: (
        <span>
          {t('menus:changeOrders')}
          {/* <Text style={style.badgeCount}>{changeOrdersCount}</Text> */}
        </span>
      ),
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            {
              key: 0,
              label: <span>{t('menus:changeOrders')}</span>,
              children: <ChangeOrders project={project} customer={customer} />,
            },
          ]}
        />
      ),
    },
    {
      key: 8,
      label: <span>{t('menus:changeOrdersClient')}</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            {
              key: 0,
              label: <span>{t('menus:changeOrdersClient')}</span>,
              children: <ChangeOrdersClient project={project} customer={customer} />,
            },
          ]}
        />
      ),
    },
    {
      key: 9,
      label: <span>Close Out Procedures</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            { key: 0, label: <span>Warranty</span>, children: <Warranty project={project} customer={customer} /> },
            {
              key: 1,
              label: <span>Close Out Files</span>,
              children: <CloseOutFiles project={project} customer={customer} />,
            },
            {
              key: 2,
              label: <span>Subcontractor Close Out Files</span>,
              children: <SubcontractorCloseOut project={project} customer={customer} />,
            },
          ]}
        />
      ),
    },
    {
      key: 11,
      label: (
        <span>
          {t('menus:tasks')}
          {/* <Text style={style.badgeCount}>{tasksCount}</Text> */}
        </span>
      ),
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[{ key: 0, label: <span>Tasks</span>, children: <Tasks project={project} customer={customer} /> }]}
        />
      ),
      // children: <Tasks />,
      // <Documents visible={selectedTab === 1} modeView={modeView} onCount={(value) => setDocumentsCount(value)} />,
    },
    {
      key: 12,
      label: <span>Reports</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            { key: 0, label: <span>Reports</span>, children: <div style={{ height: '100vh' }}>Reports Content</div> },
          ]}
        />
      ),
      // children: <div style={{ height: '100vh' }}>Reports</div>,
      // <Documents visible={selectedTab === 1} modeView={modeView} onCount={(value) => setDocumentsCount(value)} />,
    },
  ];

  const getTag = status => {
    return (
      <Tag color={getProjectStatusColor(status.toLowerCase())}>
        {t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase()}
      </Tag>
    );
  };

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle title={project?.description} subtitle={t('menus:projectDetails')} tags={getTag(project?.status)} />
        <Tabs size="small" items={items} tabPosition="left" defaultActiveKey={0} onChange={onChangeTab} />
      </Box>
    </DefaultLayout>
  );
}
