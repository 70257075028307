import React from 'react';
import { ClockCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Col, Switch, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import moment from 'moment';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({
  // completedTasks,
  users,
  priorities,
  tags,
  isLoading,
  setSelectedRecord,
  setShowForm,
  setFilters,
}) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    const filter = [];

    try {
      // COMPLETE TASK FILTER
      if (
        values.showCompletedTasks !== undefined &&
        values.showCompletedTasks !== null &&
        values.showCompletedTasks !== '' &&
        values.showCompletedTasks !== 'ALL' &&
        values.showCompletedTasks !== true
      ) {
        filter.push({
          field: 'complete',
          value: values.showCompletedTasks,
          restriction: 'EQUAL',
        });
      }
      // PRIORITY FILTER
      if (
        values.prioritiesTasks !== undefined &&
        values.prioritiesTasks !== null &&
        values.prioritiesTasks !== '' &&
        values.prioritiesTasks !== 'ALL' &&
        values.prioritiesTasks !== true
      ) {
        filter.push({
          field: 'type',
          value: values.prioritiesTasks,
          restriction: 'EQUAL',
        });
      }

      // TAG FILTER
      if (
        values.tagsFilter !== undefined &&
        values.tagsFilter !== null &&
        values.tagsFilter !== '' &&
        values.tagsFilter !== 'ALL'
      ) {
        filter.push({
          columnJoin: 'tags',
          field: 'id',
          value: values.tagsFilter,
          restriction: 'EQUAL',
        });
      }
      // ASSIGNED TO USER FILTER
      if (
        values.assignedToUserFilter !== undefined &&
        values.assignedToUserFilter !== null &&
        values.assignedToUserFilter !== '' &&
        values.assignedToUserFilter !== 'ALL'
      ) {
        filter.push({
          columnJoin: 'assignments',
          field: 'id',
          value: values.assignedToUserFilter,
          restriction: 'EQUAL',
        });
      }
      // DATE FILTER
      if (
        values.dateFilter !== undefined &&
        values.dateFilter !== null &&
        values.dateFilter !== '' &&
        values.dateFilter !== 'ALL'
      ) {
        let interval = {};
        // today date
        values.dateFilter === 'TODAY' && (interval = { start: moment().startOf('day'), end: moment().endOf('day') });
        // tomorrow date
        values.dateFilter === 'TOMORROW' &&
          (interval = {
            start: moment().add(1, 'days'),
            end: moment().add(1, 'days'),
          });
        // this week
        values.dateFilter === 'THIS_WEEK' &&
          (interval = {
            start: moment().startOf('week'),
            end: moment().endOf('week'),
          });
        // this month
        values.dateFilter === 'THIS_MONTH' &&
          (interval = {
            start: moment().startOf('month'),
            end: moment().endOf('month'),
          });

        filter.push({
          field: 'date',
          value: interval.start.format('YYYY-MM-DD HH:mm:ss'),
          restriction: 'GREATER_THAN_OR_EQUAL_TO',
        });
        filter.push({
          field: 'date',
          value: interval.end.format('YYYY-MM-DD HH:mm:ss'),
          restriction: 'LESS_THAN_OR_EQUAL_TO',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          showCompletedTasks: false,
          assignedToUserFilter: 'ALL',
          assignedToUserFilter: 'ALL',
          prioritiesFilter: 'ALL',
          tagsFilter: 'ALL',
          dateFilter: 'ALL',
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ submitForm, resetForm, setFieldValue, values }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span="3">
                  {/* show number of completed tasks */}
                  <FormControl field="showCompletedTasks" label={t('screens:task.data.showCompletedTasks')}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      {/* <Tag icon={<ClockCircleOutlined />} color="#f56a00" style={{ width: 60, marginTop: '3px' }}>
                        {
                          completedTasks // show number of completed tasks
                        }
                      </Tag> */}
                      <Switch

                        style={{marginBottom: 3, backgroundColor: values.showCompletedTasks ? '#2c93dc' : '#f56a00'}}
                        defaultChecked={values.showCompletedTasks}
                        checkedChildren={<>Hide Completed</>}
                        unCheckedChildren={<><ClockCircleOutlined /> Show Completed</>}
                        onChange={checked => {
                          setFieldValue('showCompletedTasks', checked);
                          submitForm();
                        }}
                      />
                    </div>
                  </FormControl>
                </Col>
                <Col span="3">
                  {/* assigned to user filter */}
                  <FormControl field="assignedToUserFilter" label={t('screens:task.data.assignedToUserFilter')}>
                    <Select name="assignedToUserFilter">
                      <Option value="ALL">All</Option>
                      {users?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col span="3">
                  {/* priority filter */}
                  <FormControl field="prioritiesFilter" label={t('screens:task.data.prioritiesFilter')}>
                    <Select name="prioritiesFilter">
                      <Option value="ALL">All</Option>
                      {priorities?.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col span="3">
                  {/* tag filter */}
                  <FormControl field="tagsFilter" label={t('screens:task.data.tagsFilter')}>
                    <Select name="tagsFilter">
                      <Option value="ALL">All</Option>
                      {tags?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col span="3">
                  {/* date filter */}
                  <FormControl field="dateFilter" label={t('screens:task.data.dateFilter')}>
                    <Select name="dateFilter">
                      <Option value="ALL">All</Option>
                      <Option value="TODAY">Today</Option>
                      <Option value="TOMORROW">Tomorrow</Option>
                      <Option value="THIS_WEEK">This Week</Option>
                      <Option value="THIS_MONTH">This Month</Option>
                    </Select>
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
                <Button
                  loading={isLoading}
                  color="primary"
                  onClick={() => {
                    setSelectedRecord(null);
                    setShowForm(true);
                  }}
                  style={style.btnSubmit}
                >
                  <PlusOutlined />
                  {t('screens:task.btnNew')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
