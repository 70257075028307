import React, { useEffect, useState } from 'react';
import { Button as ButtonAntd, Tooltip, Space, Popconfirm } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import Filter from './filter';
import { paginationDrawings } from '~/services/hooks/drawings';
import { downloadFile } from '~/services/hooks/files';

export default function Drawings({ estimate }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  // const [estimate, setEstimate] = useState(estimate);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationDrawings({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [
        ...filters,
        { columnJoin: 'estimate', field: 'id', value: estimate?.id, restriction: 'EQUAL' },
        { field: 'showToVendor', value: true, restriction: 'EQUAL' },
      ],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (estimate.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy]);

  const tableColumns = [
    {
      title: t('screens:drawing.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: true,
      width: 80,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:drawing.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      sorter: true,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:drawing.actions'),
      key: 'actions',
      align: 'center',
      ellipsis: { showTitle: true },
      width: 100,
      render: record => (
        <Tooltip title={t('messages:download')}>
          <ButtonAntd
            type="link"
            icon={<DownloadOutlined />}
            onClick={async () => await downloadFile(record.arquive)}
          />
        </Tooltip>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
    </>
  );
}
