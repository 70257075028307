import React from 'react';
import { Col, Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Checkbox } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createCloseOutFile, updateCloseOutFile } from '~/services/hooks/closeoutFiles';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function CloseOutFileForm({ project, selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const initialValues = {
    id: null,
    description: '',
    project: project,
    subVendor: null,
    archive: {
      id: null,
      name: '',
      originalName: '',
      contentType: '',
      path: '',
    },
  };

  const [uploadList, setUploadList] = useState([]);
  const proposalSubcontractor = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.archive = data[0];
    }
    setLoading(true);
    if (values.id !== null) {
      const data = await updateCloseOutFile(values);
      onUpdateItem(data);
    } else {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      const data = await createCloseOutFile(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const proposalSubcontractorSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={proposalSubcontractor}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={proposalSubcontractorSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          width="650px"
          title={values.id !== null ? t('screens:closeout.files.btnEdit') : t('screens:closeout.files.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:closeout.files.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="show"
                    label={t('screens:closeout.files.showToCustomer')}
                    required
                    error={errors.show}
                  >
                    <Checkbox name="show" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl field="closeout_file">
                    <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={proposalSubcontractor?.archive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
