import React from 'react';
import { Col, Modal, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createLaborType, updateLaborType } from '~/services/hooks/laborTypes';

export default function LaborTypeForm({ selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const initialValues = {
    id: null,
    code: null,
    name: '',
  };

  const laborType = { ...initialValues, ...selectedRecord };
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    setLoading(true);
    if (values.id !== null) {
      const data = await updateLaborType(values);
      onUpdateItem(data);
    } else {
      const data = await createLaborType(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const laborTypeSchema = Yup.object().shape({
    code: Yup.string().required(),
    name: Yup.string().required(),
  });

  return (
    <Formik initialValues={laborType} enableReinitialize onSubmit={handleSave} validationSchema={laborTypeSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          width="850px"
          title={values.id !== null ? t('screens:laborType.btnEdit') : t('screens:laborType.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={6}>
                  <FormControl field="code" label={t('screens:laborType.data.code')} required error={errors.code}>
                    <Input name="code" />
                  </FormControl>
                </Col>
                <Col span={18}>
                  <FormControl field="name" label={t('screens:laborType.data.name')} required error={errors.name}>
                    <Input name="name" />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
