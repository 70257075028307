import React, { useEffect } from 'react';
import { Col, Divider, Modal, Spin, Typography, Upload, message } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { downloadFile, uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';
import { updateInvoice } from '~/services/hooks/invoices';
import { DeleteOutlined, DownloadOutlined, FilePdfOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import FormTxt from '~/components/Form/FormTxt';
import Button from '~/components/Button';

export default function AttachFileForm({ selectedRecord, visible, onClose, onUpdateItem }) {
  const initialValues = {
    id: null,
    date: '',
    paymentDate: '',
    attachments: [],
  };
  // get initial values and replace with values that exist in selectedRecord
  const invoice = { ...initialValues, ...selectedRecord };
  const { Text } = Typography;
  const [uploadList, setUploadList] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    setLoading(true);
    const archivesArray = [];

    if (values.id !== null) {
      if (uploadList.length !== 0) {
        const data = await uploadFiles(uploadList);
        data.forEach(element => {
          const dataAttachment = {
            id: null,
            arquive: element,
          };
          archivesArray.push(dataAttachment);
        });
        values.attachments = [...selectedRecord.attachments, ...archivesArray];
      }

      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
      } else {
        const data = await updateInvoice(values);
        onUpdateItem(data);
        onClose();
      }
    }
    setLoading(false);
  };

  // file upload functions
  const handleUpload = async event => {
    event.fileList.map(file => {
      var extension = '';
      var name = '';
      if (file.name.substring(file.name.length - 4) === 'jpeg') {
        extension = file.name.substring(file.name.length - 5);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 4);
      } else {
        extension = file.name.substring(file.name.length - 4);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 3);
      }
      file.name = name + extension;
    });
    setUploadList(event.fileList);
  };

  const beforeUpload = file => {
    const isValid =
      file.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: PDF');
      setTimeout(function() {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 10MB!');
      setTimeout(function() {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    return isValid && isLt2M;
  };

  const handleRemoveUploadListItem = uid => {
    const data = uploadList.filter(item => item.uid !== uid);
    setUploadList(data);
  };
  // end file upload functions

  useEffect(() => {
    setUploadList([]);
  }, [visible]);

  return (
    <Formik
      initialValues={invoice}
      enableReinitialize
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          width="500px"
          title={t('screens:invoices.data.attachFile')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={6}>
                  <FormControl field="file" label={t('screens:proposalClient.data.file')}>
                    <Upload
                      name="file"
                      accept=".pdf"
                      multiple={true}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleUpload}
                      fileList={uploadList}
                      maxCount="5"
                    >
                      <Button color="default">{'Upload file'}<UploadOutlined /></Button>
                    </Upload>
                  </FormControl>
                </Col>
              </Row>
              {uploadList.length > 0 ? (
                <Row>
                  <Col span={24}>
                    <FormControl>
                      {uploadList?.map(item => {
                        return (
                          <span key={item.uid}>
                            <PaperClipOutlined />
                            <Text>{item.name}</Text>
                            <DeleteOutlined
                              style={{ paddingLeft: '5px' }}
                              onClick={() => handleRemoveUploadListItem(item.uid)}
                            />
                          </span>
                        );
                      })}
                    </FormControl>
                  </Col>
                </Row>
              ) : null}

              {values.attachments.length > 0 ? (
                <>
                  <Divider orientation="left" orientationMargin="0" />
                  <Row>
                    <Col span={24}>
                      <FormTxt label={t('screens:changeOrders.lastFileSent')}>
                        <p>
                          {values.attachments.map(item => {
                            return (
                              <p key={item.arquive.id}>
                                <FilePdfOutlined /> {item.arquive.originalName}{' '}
                                <DownloadOutlined onClick={async () => await downloadFile(item)} />
                              </p>
                            );
                          })}
                        </p>
                      </FormTxt>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
