import React from 'react';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import { useTranslation } from 'react-i18next';
import { BreadcrumbCustom } from '~/styles/global';
import { Breadcrumb, Tabs } from 'antd';
import Quotation from './Quotation';
import Drawings from './Drawings';
import RFIs from './RFIs/index';
import ProposalSubcontractors from './ProposalSubcontractors';
import Submittials from './Submittials';
import './styles.css';

export default function RequestForQuotation(data) {
  const subVendor = data.location?.state?.subVendor;
  const estimate = data.location?.state?.data;
  const { t } = useTranslation();

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/subcontractor-quotations">{t('menus:subcontractorQuotations')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:estimateQuotation')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );
  return (
    <DefautLayout breadcrumb={breadcrumb} title={subVendor?.companyName}>
      <Box>
        <PageTitle title={t('menus:requestForQuotes')} />
        <>
          <Tabs
            type="card"
            size="small"
            tabPosition="top"
            defaultActiveKey="0"
            items={[
              {
                key: '0',
                label: <span>Quotation Items</span>,
                children: <Quotation subVendor={subVendor} estimate={estimate} />
              },
              {
                key: '1',
                label: <span>Drawings/Info</span>,
                children: <Drawings estimate={estimate} />
              },
              {
                key: '2',
                label: <span>Proposals</span>,
                children: <ProposalSubcontractors subVendor={subVendor} estimate={estimate} />
              },
              {
                key: '3',
                label: <span>Submittals</span>,
                children: <Submittials estimate={estimate} />
              },
              {
                key: '4',
                label: <span>RFI</span>,
                children: <RFIs estimate={estimate} />
              },
            ]}
          />
        </>
      </Box>
    </DefautLayout>
  );
}
