import React from 'react';
import { Col, Modal, Spin } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import InputCurrency from '~/components/Form/InputCurrency';
import { message } from 'antd';

export default function FormItem({
  selectedItem,
  visible,
  onClose,
  onUpdateItem,
}) {

  const { t } = useTranslation();

  const handleSave = async values => {
    onUpdateItem(values);
    message.success('Item updated successfully, need to save the pay app to keep the changes');
    onClose();
  };

  const costschema = Yup.object().shape({
    workInPlace: Yup.number().required(),
    storedMaterials: Yup.number().required(),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={selectedItem}
      onSubmit={handleSave}
      validationSchema={costschema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values, setValues }) => (
        <Modal
          width="500px"
          description={t('screens:payAppsClients.editItem')}
          title={t('screens:payAppsClients.editItem')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={isSubmitting}>
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                <FormControl field="workInPlace" label={t('screens:payAppsClients.item.workInPlace')} error={errors.value}>
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="workInPlace"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
                <Col span={12}>
                <FormControl field="storedMaterials" label={t('screens:payAppsClients.item.storedMaterials')} error={errors.value}>
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="storedMaterials"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
