import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();

  const handleSearch = values => {
    if (values.companyName === '0') {
      values.companyName = '';
    }
    const filter = [];
    try {
      if (values.companyName !== undefined && values.companyName !== null && values.name !== '') {
        filter.push({
          field: 'companyName',
          value: values.companyName,
          restriction: 'LIKE',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik initialValues={{ companyName: '' }} enableReinitialize onSubmit={handleSearch}>
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col xs={24} sm={12} md={12} lg={7} xl={9}>
                  <FormControl
                    error={errors.companyName}
                    field="companyName"
                    label={t('screens:customers.data.companyName')}
                  >
                    <Input name="companyName" placeholder={t('fields:customers.companyName.placeholder')} />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
