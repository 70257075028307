import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

const endPoint = 'audit';

export const findAllRevisions = async (className, filters) => {
    try {
      const { data } = await api.post(`/${endPoint}/findRevisions?className=${className}`, filters);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };