import React, { useEffect, useState } from 'react';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import { Table } from '~/components/Table';
import AppCertificationForm from './appCertificationForm';
import { formatPrice } from '~/Utils';
import 'antd/dist/antd.css';
import './index.css';
import moment from 'moment';
import { findAllInvoices } from '~/services/hooks/invoices';
import { paginationProposalClients } from '~/services/hooks/proposalClients';
import PaymentForm from './paymentForm';

export default function Receipts({ project, customer }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [payVisible, setPayVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoices, setSelectedInvoices] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [proposalClient, setProposalClient] = useState({});

  useEffect(() => {
    if (project?.id !== null) {
      paginationProposalClients({
        page: 0,
        maxSize: true,
        filters: [
          {
            columnJoin: 'project',
            field: 'id',
            value: project.id,
            restriction: 'EQUAL',
          },
          {
            field: 'disapproved',
            value: false,
            restriction: 'EQUAL',
          },
          {
            field: 'proposalSignature',
            restriction: 'IS_NOT_NULL',
          },
        ],
      }).then(data => {
        setProposalClient(data.content[0]);
      });
    }
  }, [project]);

  const fetchData = async () => {
    setLoading(true);
    filters.push({
      columnJoin: 'payAppClient',
      columnSecondJoin: 'project',
      field: 'id',
      value: project.id,
      restriction: 'EQUAL',
    });
    const data = await findAllInvoices(filters);
    setTableData(data);
    setLoading(false);
  };

  useEffect(() => {
    // setSelectedRowKeys([]);
    fetchData(1);
  }, []);

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.paymentDate !== null,
      paymentDate: record.paymentDate,
    }),
  };

  const onUpdateItem = invoices => {
    const newData = [...tableData];
    invoices.map(item => {
      const index = tableData.findIndex(i => i.id === item.id);
      if (index > -1) {
        newData[index] = item;
      }
      setTableData(newData);
    });
  };

  const itemsDetailsFromProposal = () => {
    const itemsList = [];
    // group items by payAppClientData.id
    const groupedItems = proposalClient?.items?.reduce((r, a) => {
      r[a.payAppClientData.id] = [...(r[a.payAppClientData.id] || []), a];
      return r;
    }, {});
    Object.keys(groupedItems).map(key => {
      const items = [];
      // verify if items are paid
      const paidItems = tableData.find(
        i => i.payAppClientData.id === groupedItems[key][0].payAppClientData.id && i.paymentDate !== null
      );

      groupedItems[key].map(i => {
        items.push({
          id: i.id,
          description: i.description,
          unit: i.unit,
          quantity: i.quantity,
          value: i.value,
          total: i.total,
          paid: paidItems !== undefined,
        });
      });
      itemsList.push({
        id: groupedItems[key][0].payAppClientData.id,
        code: groupedItems[key][0].payAppClientData.code,
        descriptionOfWork: groupedItems[key][0].payAppClientData.descriptionOfWork,
        total: groupedItems[key][0].payAppClientData.total,
        paidAll: paidItems !== undefined,
        items: items,
      });
    });
    return itemsList;
  };

  // START FUNCTIONS FOR INVOICE DETAILS

  const originalContractSum = tableData.reduce((acc, item) => acc + item.payAppClientData.total, 0); // const originalContractSum = proposalClient?.items?.reduce((acc, item) => acc + item.total, 0); // sum of all items totals in proposalClient
  const changeOrdersSum = () => {
    return 0;
  };

  const totalCompletedAndStored = () => {
    const paidInvoices = tableData.filter(item => item.paymentDate !== null);
    return paidInvoices.reduce((acc, item) => acc + item.payAppClientData.total, 0);
  };

  const totalRetainage = parseFloat(totalCompletedAndStored() * 0.1); // total completed and stored * 10%
  const totalEarnedLessRetainage = totalCompletedAndStored() - totalRetainage;

  // END FUNCTIONS FOR INVOICE DETAILS

  const viewAppCertificationForm = invoice => {
    setSelectedInvoice(invoice);
    setVisible(true);
  };

  const viewPayment = selectedRowKeys => {
    let invoiceList = [];

    tableData.map(item => {
      const exists = selectedRowKeys.some(value => value === item.id);
      if (exists) {
        invoiceList.push(item);
      }
    });
    setSelectedInvoices(invoiceList);
    setPayVisible(true);
  };

  const tableColumns = [
    {
      title: t('screens:workbook.receipts.data.invoice_number'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      align: 'center',
      width: 90,
    },
    {
      title: t('screens:workbook.receipts.data.invoice_summary'),
      key: 'payAppClientId',
      ellipsis: { showTitle: true },
      align: 'center',
      width: 'auto',
      render: record => {
        return 'Pay App #' + record.payAppClientData?.id;
      },
    },
    {
      title: t('screens:workbook.receipts.data.balanceDue'),
      key: 'balanceDue',
      ellipsis: { showTitle: false },
      render: record => {
        return formatPrice(record.payAppClientData?.total);
      },
    },
    {
      title: t('screens:workbook.receipts.data.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      ellipsis: { showTitle: false },
      align: 'center',
      width: 100,
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('MM/DD/YYYY');
      },
    },
    {
      title: t('screens:workbook.receipts.data.paymentDate'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      ellipsis: { showTitle: false },
      align: 'center',
      width: 120,
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('MM/DD/YYYY');
      },
    },
    {
      title: t('screens:workbook.receipts.data.status'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: (text, record) =>
        record.paymentDate !== null ? (
          <Badge count={t('screens:workbook.receipts.data.paid')} style={style.paid} title={'PAID'} />
        ) : (
          <Badge count={t('screens:workbook.receipts.data.noPaid')} style={style.noPaid} title={'NO PAID'} />
        ),
    },
    // {
    //   title: t('screens:workbook.costs.data.actions'),
    //   key: 'actions',
    //   align: 'center',
    //   //width: '80px',
    //   render: (record) => (
    //       <ButtonAntd
    //         title="APPLICATION AND CERTIFICATION FOR PAYMENT"
    //         type="link"
    //         icon={<EyeOutlined />}
    //         onClick={() => viewAppCertificationForm(record)}
    //       />
    //   ),
    // },
  ];

  const style = {
    btnNew: {
      btn: { marginLeft: 'auto', marginRight: '8px', marginBottom: '20px' },
    },
    paid: { backgroundColor: '#52c41a' },
    noPaid: { backgroundColor: '#d9d9d9', color: 'gray' },
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Box>
        {/* <Row justify="end">
          <ButtonAntd
            style={style.btnNew.btn}
            type="default"
            disabled={!hasSelected}
            icon={<CheckCircleOutlined />}
            onClick={() => viewPayment(selectedRowKeys)}
          >
            {t('screens:workbook.receipts.data.markAsPaid')}
          </ButtonAntd>
        </Row> */}
        <Table
          bordered
          size="small"
          rowKey="id"
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          // rowSelection={rowSelection}
        />
      </Box>
      {selectedInvoice && customer && visible && (
        <AppCertificationForm
          selectedInvoice={selectedInvoice}
          customer={customer}
          itemsDetailsFromProposal={itemsDetailsFromProposal()}
          originalContractSum={originalContractSum}
          totalCompletedAndStored={totalCompletedAndStored()}
          changeOrdersSum={changeOrdersSum()}
          totalRetainage={totalRetainage}
          totalEarnedLessRetainage={totalEarnedLessRetainage}
          onClose={() => setVisible(false)}
          visible={visible}
        />
      )}
      <PaymentForm
        selectedInvoices={selectedInvoices}
        visible={payVisible}
        onUpdateItem={items => onUpdateItem(items)}
        onClose={() => setPayVisible(false)}
      />
    </>
  );
}
