import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function FormTxt({ children, align, label, ...props }) {
  const content = (
    <Container className='form-control'>
      {label && <label {...props}>{label}</label>}
      {children}
    </Container>
  );
  return content;
}

FormTxt.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  align: PropTypes.any,
};
FormTxt.defaultProps = {
  label: null,
  align: 'flex-start',
};
