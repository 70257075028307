import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import { Container, Header, Actions, TableColumn } from './styles';

export function Table({ children, actionSize, pagination, pageSize, ...props }) {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(null);

  const noData = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('screens:noData')} />;

  const sizeOfThings = () => {
    let windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', function () {
    sizeOfThings()
  })

  useEffect(() => {
    sizeOfThings();
  });

  return windowWidth < 500 ? (
    <Container actionSize={actionSize} {...props} pagination={pagination} locale={{ emptyText: noData }}>
      {children}
    </Container>
  ) : (
      <Container
        actionSize={actionSize}
        {...props}
        pagination={
          pagination && {
            pageSize: pageSize,
            showTotal: (total, range) =>
              `${t('screens:pagination.showing')} ${range[0]} ${t('screens:pagination.to')} ${range[1]} ${t(
                'screens:pagination.of'
              )} ${total} ${t('screens:pagination.records')}`,
          }
        }
        locale={{ emptyText: noData }}
      >
        {children}
      </Container>
    );
}

Table.propTypes = {
  children: PropTypes.any,
  actionSize: PropTypes.string,
  pagination: PropTypes.bool,
  pageSize: PropTypes.number,
};

Table.defaultProps = {
  children: null,
  actionSize: '200px',
  pagination: true,
  pageSize: 10,
};

export function TableHeader({ children }) {
  return <Header className="table-header">{children}</Header>;
}

TableHeader.propTypes = {
  children: PropTypes.any,
};

TableHeader.defaultProps = {
  children: null,
};

export function TableActions({ children }) {
  return <Actions className="table-header">{children}</Actions>;
}

TableActions.propTypes = {
  children: PropTypes.any,
};

TableActions.defaultProps = {
  children: null,
};

export function Column({ children, ...props }) {
  return <TableColumn {...props}>{children}</TableColumn>;
}

Column.propTypes = {
  children: PropTypes.any,
};

Column.defaultProps = {
  children: null,
};
