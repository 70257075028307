import React from 'react';
import 'antd/dist/antd.css';
import Icon, { MailOutlined, IdcardOutlined } from '@ant-design/icons';
import { Col, Space, Typography, Avatar, Popover } from 'antd';
import Row from '~/components/Row';
import PropTypes from 'prop-types';
import UserAvatar from '~/components/UserAvatar';

function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}
const { Text } = Typography;
const CompanySvg = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22,7H13V2a1,1,0,0,0-1-1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM11,13H3V11h8Zm0-5V9H3V7h8ZM3,15h8v2H3ZM11,3V5H3V3ZM3,19h8v2H3Zm18,2H13V9h8Zm-5-5H14V14h2Zm0,4H14V18h2Zm4-4H18V14h2Zm-4-4H14V10h2Zm4,0H18V10h2Zm0,8H18V18h2Z" />
  </svg>
);
const CompanyIcon = props => <Icon component={CompanySvg} {...props} />;

export default function UserInfo({ user }) {
  return (
    <Popover
      key={user.id}
      content={
        <>
          <Row wrap={false} type="flex">
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <IdcardOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.roleDescription }} style={{ width: 240 }}>
                {user?.roleDescription}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <CompanyIcon style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.company?.name }} style={{ width: 240 }}>
                {user?.company?.name}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <MailOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.email }} style={{ width: 240 }}>
                {user?.email}
              </Text>
            </Col>
          </Row>
        </>
      }
      title={user?.name}
    ><>
      <Row key={user.id} wrap={false} type="flex">
        <Col style={{ paddingRight: '0px', width: "20px" }}>
          <UserAvatar user={user} size='default' />
        </Col>
        <Col style={{ width: "180px", paddingLeft: '26px' }}>
          <Space size={0.5} direction="vertical">
            <Text ellipsis={true} style={{ width: 180 }}>
              {user?.name}
            </Text>
            <Text
              type="secondary"
              ellipsis={true}
              style={{
                width: 180,
                whiteSpace: 'nowrap',
                fontSize: '12px',
                position: 'relative',
                top: '-7px',
              }}
            >
              {user?.company?.name}
            </Text>
          </Space>
        </Col>
      </Row>
      </>
    </Popover>
  );
}

UserInfo.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};
