import { Avatar, Breadcrumb, Button, Card, Col, DatePicker, Divider, Image, Row, Space, Spin, Statistic, Tabs, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { BadgeCustom, CustomerHeader, CustomTotals } from '../styles';
import { AppstoreOutlined, ContactsOutlined, EditOutlined, EnvironmentFilled, FileTextOutlined, GlobalOutlined, LeftOutlined, MailFilled, PhoneFilled, UnorderedListOutlined } from '@ant-design/icons';
import CustomerForm from '../form';
import Documents from './documents';
import Projects from './projects';
import Files from './files';
import Photos from './photos';
import { BreadcrumbCustom } from '~/styles/global';

export default function CustomerView(props) {
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;
    const { Text } = Typography;
    const prefixDollar = '$';
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [recordData, setRecordData] = useState([]);
    const [customerContact, setCustomerContact] = useState(null);
    const [primaryContact, setPrimaryContact] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedTab, setSelectedTab] = useState(1);
    const [projectsCount, setProjectsCount] = useState(0);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [photosCount, setPhotosCount] = useState(0);
    const [filesCount, setFilesCount] = useState(0);
    const [modeView, setModeView] = useState('grid');
    const operations =
        <>
            <Space>
                <Button
                    size='small'
                    type={modeView === 'list' ? 'primary' : ''}
                    icon={<UnorderedListOutlined />}
                    onClick={() => setModeView('list')}>
                    {t('screens:btnList')}
                </Button>
                <Button
                    size='small'
                    type={modeView === 'grid' ? 'primary' : ''}
                    icon={<AppstoreOutlined />}
                    onClick={() => setModeView('grid')}>
                    {t('screens:btnGrid')}
                </Button>
            </Space>
        </>;

    const fetchRecords = async () => {
        setLoading(true);
        try {
            const { id } = props.match.params;

            const { data } = await api.get('/customers/findById', {
                params: { id },
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setRecordData(data);
            setCustomerContact(data.customerContact);
            if (data.customerContact !== null) {
                const cc = data.customerContact;
                const p = cc.primaryContact;
                setPrimaryContact(p);
            }
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleEdit = id => {
        setSelectedRecord(id);
        setShowForm(true);
    };

    const onChangeTab = (key) => {
        setSelectedTab(key);
    };

    useEffect(() => {
        fetchRecords();
    }, []);

    const style = {
        pageTitle: { color: '#0f4c81', fontSize: '20px' },
        divider: { margin: '0px' },
        cardBody: { background: '#F5F5F5', paddingTop: '10px', paddingBottom: '10px' },
        badgeCount: {
            minWidth: '14px',
            height: '14px',
            padding: '0 8px',
            fontSize: '12px',
            lineHeight: '14px',
            borderRadius: '7px',
            textAlign: 'center',
            background: '#E6F7FF',
            color: '#1890FF',
            marginLeft: '3px'
        },
        contacts: { color: '#a1a1a1', marginRight: '8px' },
        statistic1: { fontSize: '18px', textAlign: 'center' },
        statistic2: { fontSize: '18px' },
    }

    const breadcrumb = <BreadcrumbCustom />;

    const data = [
        {
            key: 1,
            titleTotal: 'TOTAL ESTIMATES',
            title: '800',
            status: 'success',
            color: '#389E0D',
            text: 'ACCEPTED',
            count: 2,
            countTotal: 99
        },
        {
            key: 2,
            titleTotal: '',
            title: '7526',
            status: 'warning',
            color: '#FA8C16',
            text: 'PENDING',
            count: 4,
            countTotal: 0
        },
        {
            key: 3,
            titleTotal: 'PURCHASE ORDERS',
            title: '644',
            status: 'success',
            color: '#389E0D',
            text: 'ACCEPTED',
            count: 2,
            countTotal: 99
        },
        {
            key: 4,
            titleTotal: '',
            title: '10114',
            status: 'warning',
            color: '#FA8C16',
            text: 'PENDING',
            count: 1,
            countTotal: 0
        },
        {
            key: 5,
            titleTotal: 'INVOICES',
            title: '6000',
            status: 'success',
            color: '#389E0D',
            text: 'PAID',
            count: 1,
            countTotal: 99
        },
        {
            key: 6,
            titleTotal: '',
            title: '5000',
            status: 'error',
            color: '#FF4D4F',
            text: 'OVERDUE',
            count: 3,
            countTotal: 0
        },
        {
            key: 7,
            titleTotal: '',
            title: '100550',
            status: 'default',
            color: '#8C8C8C',
            text: 'DRAFT',
            count: 1,
            countTotal: 0
        },
    ];

    const items = [
        {
            key: 1,
            label: (<span>{t('screens:customers.data.documents')}<Text style={style.badgeCount}>{documentsCount}</Text></span>),
            children: <Documents visible={selectedTab === 1} modeView={modeView} onCount={(value) => setDocumentsCount(value)} />,
        },
        {
            key: 2,
            label: (<span>{t('screens:customers.data.projects')}<Text style={style.badgeCount}>{projectsCount}</Text></span>),
            children: <Projects visible={selectedTab === 2} modeView={modeView} onCount={(value) => setProjectsCount(value)} />,
        },
        {
            key: 3,
            label: (<span>{t('screens:customers.data.photos')}<Text style={style.badgeCount}>{photosCount}</Text></span>),
            children: <Photos visible={selectedTab === 3} modeView={modeView} onCount={(value) => setPhotosCount(value)} />,
        },
        {
            key: 4,
            label: (<span>{t('screens:customers.data.files')}<Text style={style.badgeCount}>{filesCount}</Text></span>),
            children: <Files visible={selectedTab === 4} modeView={modeView} onCount={(value) => setFilesCount(value)} />,
        }
    ];

    return (
        <DefautLayout breadcrumb={breadcrumb}>
            <CustomerHeader style={style.pageTitle}
                backIcon={<LeftOutlined />}
                ghost={false}
                onBack={() => window.history.back()}
                title={t('screens:customers.backToCustomers')}
                extra={[
                    <RangePicker size='small' />,
                    <Button size='small' icon={<EditOutlined />} onClick={() => handleEdit(recordData.id)}>{t('screens:customers.data.editCustomerDetails')}</Button>,
                ]}
            />
            <Divider style={style.divider} />
            <Box>
                <Spin spinning={loading}>
                    <CustomTotals>
                        {data.map(item =>
                            <li>
                                <>
                                    {item.key === 1 || item.key === 3 || item.key === 5 ?
                                        <p>{item.titleTotal}<Text style={style.badgeCount}>{item.countTotal}</Text></p>
                                        : null}

                                    <Card
                                        size='small'
                                        title={<Statistic valueStyle={style.statistic1} prefix={prefixDollar} value={item.title / 1000} precision={2} suffix={'k'} />}
                                        style={{ width: 145 }}
                                        bodyStyle={style.cardBody}>
                                        <BadgeCustom
                                            key={item.key}
                                            status={item.status}
                                            text={<Text style={{ color: item.color }}>{item.text + ' (' + item.count + ')'}</Text>}>
                                        </BadgeCustom>
                                    </Card>
                                </>
                            </li>
                        )}
                    </CustomTotals>

                    <br /><br />
                    <Row>
                        <Col xs={24} sm={10} md={8} lg={6} xl={6} style={{ paddingTop: '55px' }}>
                            <Card bodyStyle={{ padding: '14px' }}>
                                <Row xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Col xs={4} sm={2} md={2} lg={2} xl={5} style={{ marginRight: '5px' }}>
                                        <Avatar shape="square" size={38} style={{ backgroundColor: '#0f4c81' }} icon={<ContactsOutlined />} />
                                    </Col>
                                    <Col xs={19} sm={22} md={22} lg={22} xl={18}>
                                        <Text strong>{recordData.companyName}</Text>
                                        <p><Text type="secondary">{primaryContact !== null ? primaryContact.firstName + ' ' + primaryContact.lastName : ''}</Text></p>
                                    </Col>
                                </Row>
                                <p><Text type="secondary">{t('screens:customers.customerInfo')}</Text></p>
                                <Row>
                                    <Col xs={2} sm={3} md={3} lg={3} xl={3}><MailFilled style={style.contacts} /></Col>
                                    <Col xs={22} sm={21} md={21} lg={21} xl={21}>
                                        <Text type="secondary">{t('screens:customers.email')}: </Text>
                                        {primaryContact !== null ? primaryContact.mainEmail : ''}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2} sm={3} md={3} lg={3} xl={3}><PhoneFilled style={style.contacts} /></Col>
                                    <Col xs={22} sm={21} md={21} lg={21} xl={21}>
                                        <Text type="secondary">{t('screens:customers.phone')}: </Text>
                                        {primaryContact !== null ? primaryContact.mainPhone : ''}
                                        <div><Text type="secondary">{t('screens:customers.fax')}: </Text>
                                            {customerContact !== null ? customerContact.faxNumber : ''}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2} sm={3} md={3} lg={3} xl={3}><GlobalOutlined style={style.contacts} /></Col>
                                    <Col xs={22} sm={21} md={21} lg={21} xl={21}>
                                        <Text type="secondary">{t('screens:customers.data.website')}: </Text>
                                        {customerContact !== null ? customerContact.website : ''}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2} sm={3} md={3} lg={3} xl={3}><EnvironmentFilled style={style.contacts} /></Col>
                                    <Col xs={22} sm={21} md={21} lg={21} xl={21}>{recordData.shippingAddress}</Col>
                                </Row>
                                <Divider />
                                <p><Text type="secondary">{t('screens:customers.created')}</Text></p>
                                <p><Text type="secondary">{t('screens:customers.by')} </Text>{recordData.createBy}<Text type="secondary"> {t('screens:customers.on')} </Text>{recordData.createDate}</p>
                                <Divider />
                                <p><Text type="secondary">{t('screens:customers.salesRepresentative')}</Text></p>
                                <Row xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Col xs={4} sm={2} md={2} lg={2} xl={5} style={{ marginRight: '5px' }}>
                                        <Avatar src={<Image src="https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg" style={{ width: 35 }} />} />
                                    </Col>
                                    <Col xs={19} sm={22} md={22} lg={22} xl={18}>
                                        <Text>Michael Cley</Text>
                                    </Col>
                                </Row>
                                <Divider />
                                <p><Text type="secondary">{t('screens:customers.other')}</Text></p>
                                <p>{customerContact !== null && customerContact.other !== null ? customerContact.other : 'N/A'}</p>
                            </Card>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={13} md={15} lg={17} xl={17}>
                            <Tabs size='small' tabBarExtraContent={operations} items={items} defaultActiveKey={1} onChange={onChangeTab} />
                        </Col>
                    </Row>
                </Spin>
            </Box>
            <CustomerForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
        </DefautLayout>
    );
}