import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'changeOrdersClient';

// GET ALL CHANGE ORDERS
export const getAllChangeOrdersClient = async projectId => {
  try {
    const filters = [{ columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' }];
    const params = { page: 0, maxSize: true, sortBy: 'description', filters };
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAGINATION
export const paginationChangeOrdersClient = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createChangeOrderClient = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateChangeOrderClient = async values => {
  console.log('chegou aqui');
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteChangeOrderClient = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationChangeOrdersClient.propTypes = {
  params: PropTypes.object.isRequired,
};

createChangeOrderClient.propTypes = {
  values: PropTypes.object.isRequired,
};

updateChangeOrderClient.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteChangeOrderClient.propTypes = {
  id: PropTypes.string.isRequired,
};
