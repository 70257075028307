import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'estimates';

// SEND ESTIMATE TO SUBVENDOR
export const sendEstimateToSubVendor = async id => {
  try {
    const { data } = await api.post(`/mail/sendEstimate/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successSendEstimate`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAGINATION BY SUBVENDOR
export const paginationEstimatesBySubVendor = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/paginationBySubVendor`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAGINATION
export const paginationEstimates = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllEstimates = async (filters) => {
  try {
    const { data } = await api.post(`/${endPoint}/findAll`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findEstimateById = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/findById/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createEstimate = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateEstimate = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteEstimate = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDeleted`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationEstimates.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllEstimates.propTypes = {
  filters: PropTypes.object.isRequired,
};

createEstimate.propTypes = {
  values: PropTypes.object.isRequired,
};

updateEstimate.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteEstimate.propTypes = {
  id: PropTypes.string.isRequired,
};
