import * as Yup from 'yup';

const name = Yup.string().min(2, 'Too Short!').max(25, 'Too Long!');
const phone = Yup.string().max(27, 'Too Long!'); // .matches(/^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g, 'Invalid phone number');
const email = Yup.string().typeError('Insert your e-mail').email('Invalid email');

export const primaryContactSchema = Yup.object().shape({
  firstName: name.required(),
  lastName: name.required(),
  jobTitle: name.required(),
  mainPhone: phone,
  mainEmail: email.required('Email is required'),
  workPhone: phone,
  ccEmail: email,
  mobilePhone: phone,
});

export const addressSchema = Yup.object().shape({
  addressLineOne: name.required(),
  city: name.required(),
  state: name.required(),
  country: name.required(),
  type: Yup.string().required(),
  main: Yup.bool().required(),
  note: Yup.string().max(41, 'Field must have at most 41 characters'),
});

export const customerSchema = Yup.object().shape({
  companyName: name.required(),
  salesTaxCode: Yup.string().required(),
  primaryContactFirstName: name.required(),
  primaryContactLastName: name.required(),
  primaryContactJobTitle: name.required(),
  addresses: Yup.array().of(addressSchema).min(1, 'At least one address is required'),
  // secondaryContactFirstName: name.required(),
  // secondaryContactLastName: name.required(),
  // secondaryContactJobTitle: name.required(),
  // primaryContact: primaryContactSchema,
});
