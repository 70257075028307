import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { Col, Popconfirm, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import PayAppsClientsForm from './form';
import Filter from './filter';
import moment from 'moment';
import { formatPrice } from '~/Utils';
import { findLastProposalApproved } from '~/services/hooks/proposalClients';
import { paginationPayAppsClients, deletePayAppsClient } from '~/services/hooks/payAppsClients';
// import { FaTruckMonster } from 'react-icons/fa';

export default function PayAppsClients({ project, customer }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [sortBy, setSortBy] = useState('id'); // description
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const [proposalClient, setProposalClient] = useState({});
  const [viewOnly, setViewOnly] = useState(false);

  useEffect(() => {
    if (project?.id !== null) {
      findLastProposalApproved(project.id).then(data => {
        setProposalClient(data);
        console.log('projectId', project.id)
        console.log('proposalClient', data);
      });
    }
  }, [project]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationPayAppsClients({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [...filters, { columnJoin: 'project', field: 'id', value: project.id, restriction: 'EQUAL' }],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber,
      pageSize: data.size,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const handleView = record => {
    setSelectedRecord(record);
    setViewOnly(true);
    setShowForm(true);
  };

  const handleDelete = async id => {
    await deletePayAppsClient(id);
    fetchData(1);
  };

  const handleEdit = record => {
    record.items.forEach(item => {
      // add general conditions and overhead and profit proposalClient.total
      if (item.generalConditions) {
        item.proposalClientItem = {
          total: proposalClient.generalConditions,
        };
      }
      if (item.overheadAndProfit) {
        item.proposalClientItem = {
          total: proposalClient.overheadAndProfit,
        };
      }
      // if (item.changeOrderClient) {
      //   const totalItemsValue = item.changeOrderClient.priceInfo.items.reduce(
      //     (acc, item) => acc + item.unitCost * item.quantity,
      //     0
      //   );
      //   item.
      // }
    });

    setSelectedRecord(record);
    setShowForm(true);
  };

  const getItemsFromProposal = () => {
    const items = [];
    let counter = 0;

    if (!proposalClient) {
      message.error('Waiting for proposal client to be approved.');
      return items;
    }

    if (proposalClient.items){
      proposalClient.items.forEach(item => {
        items.push({
          id: counter++,
          proposalClientItem: item,
          proposalOtherItem: null,
          changeOrderClient: null,
          previousApplications: 0,
          workInPlace: 0,
          storedMaterials: 0,
          generalConditions: false,
          overheadAndProfit: false,
        });
      });
    }


    if (proposalClient.otherItems) {
      proposalClient.otherItems.forEach(item => {
        items.push({
          id: counter++,
          proposalClientItem: null,
          proposalOtherItem: item,
          changeOrderClient: null,
          previousApplications: 0,
          workInPlace: 0,
          storedMaterials: 0,
          generalConditions: false,
          overheadAndProfit: false,
        });
      });
    }
    

    if (proposalClient.generalConditions) {
      items.push({
        id: counter++,
        proposalClientItem: {
          total: proposalClient.generalConditions,
        },
        proposalOtherItem: null,
        changeOrderClient: null,
        previousApplications: 0,
        workInPlace: 0,
        storedMaterials: 0,
        generalConditions: true,
        overheadAndProfit: false,
      });
    }

    if (proposalClient.overheadAndProfit) {
      items.push({
        id: counter++,
        proposalClientItem: {
          total: proposalClient.overheadAndProfit,
        },
        proposalOtherItem: null,
        changeOrderClient: null,
        previousApplications: 0,
        workInPlace: 0,
        storedMaterials: 0,
        generalConditions: false,
        overheadAndProfit: true,
      });
    }
    // TODO: Verify if has change orders items

    return items;
  };

  const handleNew = () => {
    if (tableData.length === 0) {
      const newPayApp = {
        id: null,
        project: project,
        applDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        periodTo: moment().format('YYYY-MM-DD HH:mm:ss'),
        costCode: '',
        contractFor: '',
        signatureDate: proposalClient?.proposalSignature?.date,
        total: 0.0,
        items: getItemsFromProposal(),
      };
      setSelectedRecord(newPayApp);
    } else {
      const lastPayApp = tableData[0];
      lastPayApp.id = null;
      lastPayApp.applDate = moment().format('YYYY-MM-DD HH:mm:ss');
      lastPayApp.periodTo = moment().format('YYYY-MM-DD HH:mm:ss');
      lastPayApp.costCode = '';
      lastPayApp.contractFor = '';
      lastPayApp.items.forEach(item => {
        console.log("Item:", item);
        console.log("Proposal Client:", proposalClient);
        // add general conditions and overhead and profit proposalClient.total
        if (item.generalConditions) {
          if (item.proposalClientItem == null) {
            item.proposalClientItem = {};
            item.proposalClientItem.total = 0;
          } else {
            item.proposalClientItem.total = proposalClient.generalConditions;
          }
        }
        if (item.overheadAndProfit) {
          if (item.proposalClientItem == null) {
            item.proposalClientItem = {};
            item.proposalClientItem.total = 0;
          } else {
            item.proposalClientItem.total = proposalClient.overheadAndProfit;
          }
        }
        item.previousApplications = item.previousApplications + item.workInPlace; // + item.storedMaterials;
        item.workInPlace = 0;
        item.storedMaterials = 0;
      });
      setSelectedRecord(lastPayApp);
    }
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  // START FUNCTIONS FOR INVOICE DETAILS
  // const originalContractSum = proposalClient?.items?.reduce((acc, item) => acc + item.total, 0); // sum of all items totals in proposalClient
  // END FUNCTIONS FOR INVOICE DETAILS

  const tableColumns = [
    {
      title: t('screens:payAppsClients.id'),
      dataIndex: 'id',
      sorter: true,
      key: 'id',
      width: 80,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:payAppsClients.applDate'),
      dataIndex: 'applDate',
      key: 'applDate',
      align: 'center',
      sorter: true,
      ellipsis: { showTitle: true },
      render: applDate => moment(applDate).format('MM/DD/YYYY'),
    },
    {
      title: t('screens:payAppsClients.periodTo'),
      dataIndex: 'periodTo',
      key: 'periodTo',
      align: 'center',
      sorter: true,
      ellipsis: { showTitle: true },
      render: periodTo => moment(periodTo).format('MM/DD/YYYY'),
    },
    {
      title: t('screens:payAppsClients.costCode'),
      dataIndex: 'costCode',
      key: 'costCode',
      sorter: true,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:payAppsClients.architectsProjectNumber'),
      dataIndex: 'architectsProjectNumber',
      key: 'architectsProjectNumber',
      sorter: true,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:payAppsClients.contractFor'),
      dataIndex: 'contractFor',
      key: 'contractFor',
      sorter: true,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:payAppsClients.total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      width: 200,
      ellipsis: { showTitle: true },
      render: total => formatPrice(total),
    },
    {
      title: t('screens:payAppsClients.actions'),
      key: 'actions',
      align: 'center',
      className: 'table-action',
      width: 150,
      ellipsis: { showTitle: true },
      render: record => (
        <TableActions>
          {record.id === tableData[0]?.id ? (
            <>
              <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
                <EditOutlined />
              </Button>
              <Popconfirm
                icon={false}
                title={t('messages:confirmDelete')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button onClick={() => handleView(record)} title={t('messages:view')}>
              <EyeOutlined />
            </Button>
          )}
        </TableActions>
      ),
    },
  ].filter(item => !item.hidden);

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        {/* <FaTruckMonster size={50} /> */}
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                handleNew();
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:payAppsClients.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        {selectedRecord &&
          project &&
          customer &&
          proposalClient &&
          showForm && (
            <PayAppsClientsForm
              selectedRecord={selectedRecord}
              project={project}
              customer={customer}
              proposalClient={proposalClient}
              visible={showForm}
              viewOnly={viewOnly}
              onClose={() => {
                setShowForm(false);
                setSelectedRecord(null);
                setViewOnly(false);
              }}
              onInsertItem={item => {
                fetchData(1); // setTableData([...tableData, item]);
              }}
              onUpdateItem={item => onUpdateItem(item)}
            />
          )}
      </Box>
    </>
  );
}
