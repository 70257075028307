import React from 'react';
import { Col, Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createCloseOutFile, updateCloseOutFile } from '~/services/hooks/closeoutFiles';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function SubContractorCloseOutForm({
  subVendor,
  selectedRecord,
  visible,
  onClose,
  onUpdateItem,
  onInsertItem,
}) {
  const initialValues = {
    id: null,
    description: '',
    project: {},
    subVendor: subVendor,
    archive: {
      id: null,
      name: '',
      originalName: '',
      contentType: '',
      path: '',
    },
  };
  const projects = subVendor?.projectAccess || [];
  const [uploadList, setUploadList] = useState([]);
  const subcontractorCloseOut = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.archive = data[0];
    } else {
      message.error(t('messages:uploadFileRequired'));
      setLoading(false);
      return;
    }
    setLoading(true);
    if (values.id !== null) {
      const data = await updateCloseOutFile(values);
      onUpdateItem(data);
    } else {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      const data = await createCloseOutFile(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const subcontractorCloseOutSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={subcontractorCloseOut}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={subcontractorCloseOutSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
        <Modal
          width="650px"
          title={
            values.id !== null
              ? t('screens:closeout.subcontractor.btnEdit')
              : t('screens:closeout.subcontractor.btnNew')
          }
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:closeout.subcontractor.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="project"
                    label={t('screens:closeout.subcontractor.project')}
                    required
                    error={errors.project}
                  >
                    <Select
                      name="project"
                      placeholder={t('screens:closeout.subcontractor.project')}
                      value={values.project.id}
                      onChange={(value, option) => {
                        console.log('value: ', value, 'option: ', option);
                        setFieldValue(
                          'project',
                          projects.find(project => project.id === value)
                        );
                      }}
                    >
                      {projects.map(project => (
                        <Select.Option key={project.id} value={project.id}>
                          {project.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl field="proposal_subcontractor_file">
                    <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={subcontractorCloseOut?.archive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
