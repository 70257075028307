import React, { useEffect, useState } from 'react';
// import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Modal, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import moment from 'moment';
import { createPayment, updatePayment } from '~/services/hooks/payments';
import { paginationChangeOrders } from '~/services/hooks/changeOrders';

export default function PaymentsForm({
  selectedWork,
  balance,
  selectedPayment,
  visible,
  onClose,
  payItems,
  setPayItems,
  fetchData,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [changeOrder, setChangeOrder] = useState(null);
  const [balanceValue, setBalanceValue] = useState(balance);

  console.log('selectedWork', selectedWork);
  console.log('selectedPayment', selectedPayment);
  console.log('balance', balance);
  console.log('payItems', payItems);

  useEffect(() => {
    if (selectedWork.changeOrderId !== undefined && selectedWork.changeOrderId !== null) {
      setLoading(true);
      paginationChangeOrders({
        page: 0,
        maxSize: true,
        filters: [
          {
            field: 'id',
            value: 1,
            restriction: 'EQUAL',
          },
        ],
      }).then(res => {
        setChangeOrder(res.content[0]);
      });
      setLoading(false);
    }
  }, []);

  const initialContractItem = {
    id: selectedWork?.id,
    code: selectedWork?.code,
    description: selectedWork?.description,
    unit: selectedWork?.unit,
    quantity: selectedWork?.quantity,
    value: selectedWork?.value,
    total: selectedWork?.total,
    note: selectedWork?.note,
  };

  const initialValues = () => {
    if (changeOrder === null) {
      return {
        id: null,
        contractItem: initialContractItem,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        value: 0,
        note: '',
      };
    }
    return {
      id: null,
      changeOrder: changeOrder,
      date: moment().format('YYYY-MM-DD HH:mm:ss'),
      value: 0,
      note: '',
      changeOrder: changeOrder,
    };
  };

  const recordData = selectedPayment || initialValues();

  // calc balance - this payment value
  const balanceUpdated = balance + recordData.value;

  const handleSave = async values => {
    setLoading(true);
    console.log('values', values);
    try {
      if (values.id === null) {
        console.log('values to create a new payment', values);
        const newData = await createPayment(values);
        setPayItems([newData, ...payItems]);
        fetchData();
      } else {
        console.log('values to update a payment', values);
        const updateData = await updatePayment(values);
        fetchData();
        const index = payItems.findIndex(i => i.id === values.id);
        if (index > -1) {
          const newData = [...payItems];
          newData[index] = updateData;
          setPayItems(newData);
        }
      }
      onClose();
    } catch (error) {}
    setLoading(false);
  };

  const itemSchema = Yup.object().shape({
    // verify if value is over balance and show error
    value: Yup.number()
      .required()
      .min(0.01)
      .max(balance, t('screens:workbook.subcontractor.payments.errorBalance')),
    date: Yup.date().required(),
  });

  return (
    <Formik enableReinitialize initialValues={recordData} onSubmit={handleSave} validationSchema={itemSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values }) => (
        <Modal
          title={t('screens:workbook.subcontractor.payments.title')}
          width="600px"
          description={
            selectedPayment !== null
              ? t('screens:workbook.subcontractor.payments.btnEdit')
              : t('screens:workbook.subcontractor.payments.btnNew')
          }
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              {/* <Row>
                <Text style={style.divider}>{t('screens:workbook.subcontractor.payments.information')}</Text>
              </Row> */}
              <Row>
                <Col span={12}>
                  {/* VALUE */}
                  <FormControl
                    field="value"
                    label={t('screens:workbook.subcontractor.payments.value')}
                    error={errors.value}
                  >
                    <>
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="value"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                    {balance}
                    { console.log('values in form', values)}
                    </>
                  </FormControl>
                </Col>
                <Col span={12}>
                  {/* DATE */}
                  <FormControl
                    field="date"
                    label={t('screens:workbook.subcontractor.payments.date')}
                    error={errors.date}
                  >
                    <DatePicker name="date" format="DD/MM/Y" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>
                <Col span={8}></Col>
              </Row>
              {/* NOTES */}
              <Row>
                <Col span={24}>
                  <FormControl
                    field="note"
                    label={t('screens:workbook.subcontractor.payments.note')}
                    error={(touched.note && errors.note) || errors.note}
                  >
                    <Input.TextArea showCount maxLength={250} name="note" />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
