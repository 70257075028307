import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import moment from 'moment';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ primaryContacts, projects, isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    if (values.companyNameFilter === '0') {
      values.companyNameFilter = '';
    }
    const filter = [];
    try {
      if (
        values.companyNameFilter !== undefined &&
        values.companyNameFilter !== null &&
        values.companyNameFilter !== ''
      ) {
        filter.push({
          field: 'companyName',
          value: values.companyNameFilter,
          restriction: 'LIKE',
        });
      }
      if (values.primaryContact !== undefined && values.primaryContact !== null && values.primaryContact !== '') {
        filter.push({
          field: 'contact.name',
          value: values.primaryContact,
          restriction: 'LIKE',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
    <Formik
      initialValues={{
        companyNameFilter: '',
        primaryContactFilter: 'All',
        primaryContacts: primaryContacts,
        projectsFilter: 'All',
        projects: projects,
      }}
      enableReinitialize
      onSubmit={handleSearch}
    >
      {({ errors, resetForm }) => (
        <Spin spinning={isLoading}>
          <Form>
            <Row>
              <Col span="12">
                <FormControl
                  error={errors.companyNameFilter}
                  field="companyNameFilter"
                  label={t('screens:subContractors.data.companyName')}
                >
                  <Input name="companyNameFilter" placeholder={t('fields:customers.companyName.placeholder')} />
                </FormControl>
              </Col>
              <Col span="3">
                <FormControl
                  error={errors.primaryContactFilter}
                  field="primaryContactFilter"
                  label={t('screens:subContractors.data.primary_contact')}
                >
                  <Select name="primaryContactFilter">
                    <Option value="All">All</Option>
                    {primaryContacts?.map(item => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Button
                color="default"
                style={style.btnClear}
                onClick={() => {
                  resetForm();
                  setFilters([]);
                }}
              >
                {t('screens:dateSearch.clearButton')}
              </Button>
              <Button type="submit" color="primary" style={style.btnSubmit}>
                <SearchOutlined />
                {t('screens:dateSearch.button')}
              </Button>
            </Row>
          </Form>
        </Spin>
      )}
    </Formik>
  </Box>
  );
}
