
import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'proposalsSubcontractor';

// PAGINATION
export const paginationProposalSubcontractors = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllProposalSubcontractors = async (filters) => {
  try {
    const { data } = await api.post(`/${endPoint}/findAll`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createProposalSubcontractor = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateProposalSubcontractor = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteProposalSubcontractor = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationProposalSubcontractors.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllProposalSubcontractors.propTypes = {
  filters: PropTypes.object.isRequired,
};

createProposalSubcontractor.propTypes = {
  values: PropTypes.object.isRequired,
};

updateProposalSubcontractor.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteProposalSubcontractor.propTypes = {
  id: PropTypes.string.isRequired,
};
