import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import CostsForm from './form';
import Filter from './filter';
import { paginationCosts, deleteCost } from '~/services/hooks/costs';
import { findAllCostTypes } from '~/services/hooks/costTypes';
import { getAllChangeOrders } from '~/services/hooks/changeOrders';
import { formatPrice } from '~/Utils';
import { downloadFile } from '~/services/hooks/files';

function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export default function Costs({ project, customer }) {
  const projectId = project.id;
  console.log('project', projectId);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [costTypes, setCostTypes] = useState([]);
  const [changeOrders, setChangeOrders] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [sortBy, setSortBy] = useState('id'); // description
  const [sortDirection, setSortDirection] = useState('DESC');

  const defaultFilter = { columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' };
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationCosts({ page: page - 1, sortBy, sortDirection, filters: [...filters, defaultFilter] });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  useEffect(() => {
    const fetchCostTypes = async () => {
      const data = await findAllCostTypes();
      setCostTypes(data);
    };
    fetchCostTypes();
  }, []);

  useEffect(() => {
    const fetchChangeOrders = async () => {
      const data = await getAllChangeOrders(projectId);
      setChangeOrders(data.content);
    };
    fetchChangeOrders();
  }, []);

  const handleDelete = async id => {
    await deleteCost(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const getCostsTag = costType => {
    if (costType === null || costType === undefined) {
      return null;
    }
    const color = stringToHslColor(costType.name, 50, 50);
    return <Tag color={color}>{costType.name}</Tag>;
  };

  const tableColumns = [
    // {
    //   title: t('screens:workbook.costs.data.id'),
    //   dataIndex: 'id',
    //   sorter: true,
    //   key: 'id',
    //   width: 80,
    // },
    {
      title: t('screens:workbook.costs.data.type'),
      dataIndex: 'costType',
      key: 'costType',
      align: 'center',
      ellipsis: { showTitle: false },
      sorter: true,
      width: 120,
      render: record => getCostsTag(record),
    },
    {
      title: t('screens:workbook.costs.data.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: t('screens:workbook.costs.data.value'),
      dataIndex: 'value',
      key: 'value',
      sorter: true,
      ellipsis: { showTitle: false },
      width: 120,
      render: record => formatPrice(record),
    },
    {
      title: t('screens:workbook.costs.data.date'),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      ellipsis: { showTitle: false },
      width: 120,
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('YYYY/MM/DD');
      },
    },
    {
      title: t('screens:workbook.costs.data.invoice'),
      dataIndex: 'invoice',
      key: 'invoice',
      ellipsis: { showTitle: false },
      width: 120,
      render: invoice => {
        if (invoice === null || invoice === undefined) {
          return <>N/A</>;
        }
        return invoice?.code;
      },
    },
    {
      title: t('screens:workbook.costs.data.notes'),
      dataIndex: 'notes',
      key: 'notes',
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: t('screens:workbook.costs.data.employee'),
      dataIndex: 'employee',
      key: 'employee',
      sorter: true,
      ellipsis: { showTitle: false },
      width: 120,
      render: employee => {
        if (employee === null || employee === undefined) {
          return <>N/A</>;
        }
        return employee;
      },
    },
    // {
    //   title: t('screens:workbook.costs.data.changeOrderId'),
    //   dataIndex: 'changeOrderId',
    //   key: 'changeOrderId',
    //   ellipsis: { showTitle: false },
    //   width: 100,
    //   render: record => {
    //     if (record === null || record === undefined) {
    //       return <>N/A</>;
    //     }
    //     return changeOrderList.find((item) => item.id === record)?.title;
    //   }
    // },
    {
      title: t('screens:workbook.costs.data.actions'),
      key: 'actions',
      align: 'center',
      className: 'table-action',
      width: 160,
      render: record => (
        <TableActions>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          {record.receipt && (
            <Button onClick={async () => await downloadFile(record.receipt)} title={t('messages:download')}>
              <DownloadOutlined />
            </Button>
          )}
        </TableActions>
      ),
    },
  ];

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter costTypes={costTypes} isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:workbook.costs.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        <CostsForm
          selectedRecord={selectedRecord}
          visible={showForm}
          costTypes={costTypes}
          changeOrders={changeOrders}
          project={project}
          // customer={customer}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </>
  );
}
