import React, { useEffect } from 'react';
import DefaulLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { BreadcrumbCustom } from '~/styles/global';

export default function Dashboard() {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:dashboard')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:summary')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaulLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle title={t('menus:dashboard')} />
        {/* <div style={{ overflow: 'hidden' }}>
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 1200 120"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: '#ffd6d6', width: '100%', height: 105, transform: 'rotate(180deg)' }}
          >
            <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
          </svg>
        </div> */}
      </Box>
    </DefaulLayout>
  );
}
