import styled from 'styled-components';

export const Container = styled.div`
  font-size: ${props => props.size};
  font-style: italic;
  font-weight: bold;
  color: ${props => props.color || props.theme.color1};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`;

export const Title = styled.h1`
  color: #666666;
  border-top: 0;
  padding-top: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const SubTitle = styled.span`
  margin-bottom: 15px;
`;
