import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'changeOrders';

// GET ALL CHANGE ORDERS
export const getAllChangeOrders = async projectId => {
  try {
    const filters = [{ columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' }];
    const params = { page: 0, maxSize: true, sortBy: 'description', filters };
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAGINATION
export const paginationChangeOrders = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// // FIND ALL
// export const findAllChangeOrders = async (filters) => {
//   try {
//     const { data } = await api.post(`/${endPoint}/findAll`, filters);
//     return data;
//   } catch (error) {
//     errorHandler(error);
//   }
// };

// CREATE
export const createChangeOrder = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateChangeOrder = async values => {
  console.log('chegou aqui');
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteChangeOrder = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationChangeOrders.propTypes = {
  params: PropTypes.object.isRequired,
};

// findAllChangeOrders.propTypes = {
//   filters: PropTypes.object.isRequired,
// };

createChangeOrder.propTypes = {
  values: PropTypes.object.isRequired,
};

updateChangeOrder.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteChangeOrder.propTypes = {
  id: PropTypes.string.isRequired,
};
