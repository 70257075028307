import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BankFilled, DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Popconfirm, message, Spin, Col, Breadcrumb } from 'antd';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import PurchaseOrderForm from './form';
import { format, parse } from 'date-fns';
import PoDownloadModal from './modal';

export default function PurchaseOrders() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDownloadModal, setDownloadModal] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const fetchRecords = async (filters) => {
    setLoading(true);
    try {
      const pagePaginationDTO = { page: 0, sortBy: 'title', filters: filters };
      const { data } = await api.post('/purchaseOrders/pagination', pagePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async id => {
    setLoading(true);

    try {
      await api.delete(`/purchaseOrders/delete/${id}`);
      fetchRecords(filters);
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = values => {
    setLoading(true);
    const filter = [];

    try {
      if (values.titleFilter !== undefined && values.titleFilter !== null && values.titleFilter !== '') {
        filter.push({
          field: 'title',
          value: values.titleFilter,
          restriction: 'LIKE',
        });
      }

      setFilters(filter);
      fetchRecords(filter);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async page => {
    setLoading(true);
    try {
      const paginationDTO = { page: page - 1, sortBy: 'title', filters: filters };
      const { data } = await api.post('/purchaseOrders/pagination', paginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber + 1,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords([]);
  }, [showForm]);

  const style = {
    subcontractor: { color:'#a1a1a1', marginRight: '8px' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
    btnClear: { marginLeft: 'auto', marginTop: 25 },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    }
  }

  const breadcrumb =
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:purchaseOrders')}</Breadcrumb.Item>
    </BreadcrumbCustom>
    ;

  const tableColumns = [
    {
      title: t('screens:purchaseOrders.data.title'),
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: t('screens:purchaseOrders.data.date'),
    //   dataIndex: 'date',
    //   key: 'date',
    //   render: (text, record) => (<>{format(parse(record.date, 'dd-MM-yyyy HH:mm:ss', new Date()), 'MM/dd/yyyy')}</>),
    // },
    {
      title: t('screens:purchaseOrders.data.project'),
      dataIndex: 'project',
      key: 'project',
      render: (text, record) => (
        <>{record.project !== null ? record.project.description : ""}</>
      ),
    },
    {
      title: t('screens:purchaseOrders.data.subContractor'),
      dataIndex: 'subContractor',
      key: 'subContractor',
      render: (text, record) => (
        <>{record.subContractor !== null ?
          <>
            <div><UserOutlined style={style.subcontractor}/>{record.subContractor?.contact?.firstName + " " + record.subContractor?.contact?.lastName}</div>
            <div><BankFilled style={style.subcontractor}/>{record.subContractor?.companyName}</div>
          </>
          : ""}
        </>
      ),
    },
    {
      title: t('screens:purchaseOrders.data.customer'),
      key: 'customer',
      render: (record) => (
        <>{record.customer !== null ?
          <>
            {record.customer.customerContact !== null ?
              <div><UserOutlined style={style.subcontractor}/>{record.customer.customerContact.primaryContact.firstName  + " " + record.customer.customerContact.primaryContact.lastName}</div>
            : null}
            <div><BankFilled style={style.subcontractor}/>{record.customer.companyName}</div>
          </>
          : ""}
        </>
      ),
    },
    {
      title: t('screens:purchaseOrders.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button
            onClick={() => {
              setSelectedRecord(record.id);
              setDownloadModal(true);
            }}
            title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}><EditOutlined /></Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:purchaseOrders')} style={style.pageTitle} />
      <Box>
        <Formik
          initialValues={{ titleFilter: '' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={7} xl={9}>
                    <FormControl error={errors.titleFilter} field="titleFilter" label={t('screens:purchaseOrders.data.title')}>
                      <Input name="titleFilter" placeholder={t('fields:purchaseOrders.title.placeholder')} />
                    </FormControl>
                  </Col>
                  <Button
                    color="default"
                    style={style.btnClear}
                    onClick={() => {
                      resetForm();
                      setFilters([]);
                      fetchRecords([]);
                    }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                  <Button type="submit" color="primary" style={style.btnSubmit}>
                    <SearchOutlined />
                    {t('screens:dateSearch.button')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:purchaseOrders.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />

        <Pagination
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
        <PurchaseOrderForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
        <PoDownloadModal id={selectedRecord} visible={showDownloadModal} onClose={() => setDownloadModal(false)} />
      </Box>
    </DefautLayout>
  );
}
