import React from 'react';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import { useTranslation } from 'react-i18next';
import { BreadcrumbCustom } from '~/styles/global';
import { Breadcrumb, Tabs } from 'antd';
import RFIs from './RFIs/index';
import './styles.css';
import ProposalClients from './ProposalClients';
import Warranty from '~/pages/ClientProjects/ProjectDetails/Warranty';


export default function ClientProjectDetails(data) {
  const project = data.location?.state?.project;
  const customer = data.location?.state?.customer;
  const { t } = useTranslation();

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/client-project-details">{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item>{'Details'}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );
  return (
    <DefautLayout breadcrumb={breadcrumb} title={customer?.companyName}>
      <Box>
        <PageTitle title={project.description} subtitle={t('menus:details')} />
        <>
          <Tabs
            type="card"
            size="small"
            tabPosition="top"
            defaultActiveKey="0"
            items={[
              {
                key: '0',
                label: <span>Proposals</span>,
                children: <ProposalClients project={project} />
              },
              {
                key: '1',
                label: <span>RFI</span>,
                children: <RFIs project={project} />
              },
              {
                key: '3',
                label: <span>Warranty</span>,
                children: <Warranty project={project} />
              },
            ]}
          />
        </>
      </Box>
    </DefautLayout>
  );
}
