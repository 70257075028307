import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import moment from 'moment';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ costTypes, isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    const filter = [];
    try {
      if (values.titleFilter !== undefined && values.titleFilter !== null && values.titleFilter !== '') {
        filter.push({
          field: 'title',
          value: values.titleFilter,
          restriction: 'LIKE',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
    <Formik
      initialValues={{ titleFilter: '' }}
      enableReinitialize
      onSubmit={handleSearch}
    >
      {({ errors, resetForm }) => (
        <Spin spinning={isLoading}>
          <Form>
            <Row>
              <Col xs={24} sm={12} md={12} lg={7} xl={9}>
                <FormControl error={errors.titleFilter} field="titleFilter" label={t('screens:purchaseOrders.data.title')}>
                  <Input name="titleFilter" placeholder={t('fields:purchaseOrders.title.placeholder')} />
                </FormControl>
              </Col>
              <Button
                color="default"
                style={style.btnClear}
                onClick={() => {
                  resetForm();
                  setFilters([]);
                }}
              >
                {t('screens:dateSearch.clearButton')}
              </Button>
              <Button type="submit" color="primary" style={style.btnSubmit}>
                <SearchOutlined />
                {t('screens:dateSearch.button')}
              </Button>
            </Row>
          </Form>
        </Spin>
      )}
    </Formik>
  </Box>
  );
}
