import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Layout, message } from 'antd';
import * as Yup from 'yup';
import { Container, Box } from './styles';
import FormControl from '~/components/Form/FormControl';
import api from '~/services/api';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import history from '~/services/history';
import { CopyrightOutlined, LockOutlined } from '@ant-design/icons';
import { Copyright } from '../Login/styles';
import FooterCustom from '~/components/Footer';

const schema = Yup.object().shape({
  password: Yup.string().required(),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
});

export default function Reset(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { token } = props.match.params;
  const [recordData, setRecordData] = useState([]);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      recordData.password = values.password_confirmation;
      delete recordData.roleDescription;
      await api.put('/users/update', recordData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      history.push('/');
      message.success(t('messages:resetPasswordSuccess'));
    } catch (error) {
      message.error(t('messages:passwordError'));
    }
    setLoading(false);
  };

  const fetchRecords = async () => {
    try {
      const { data } = await api.get('/users/find', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRecordData(data);
    } catch (error) {
      message.error(t('messages:invalidToken'));
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const currentYear = new Date().getFullYear();
  const myStyleLayout = {
    height: '100%',
    background: 'linear-gradient(90deg, #071d3d 0%, #0152a2 50%, #012a53 100%)'
  }

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik 
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Container>
              <Logo title={true} width="100%" height="100%" margin="auto" marginTop="10px" marginBottom="30px" resetPassword={true} />
              <FormControl field="password" error={touched.password && errors.password}>
                <Input
                  type="password"
                  name="password"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('fields:reset.password.placeholder')} />
              </FormControl>
              <FormControl field="password_confirmation" error={touched.password_confirmation && errors.password_confirmation}>
                <Input
                  type="password"
                  name="password_confirmation"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('fields:reset.passwordConfirmation.placeholder')}/>
              </FormControl>
              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('fields:reset.submit')}
              </Button>
              <Link to="/">{t('screens:resetPassword.back')}</Link>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
          {` `}
          <span>{t('fields:login.copyright.copyright')}</span>
          {` `}<CopyrightOutlined />{` ${currentYear} | `}
          <span>{t('fields:login.copyright.produced')}</span>
      </Copyright>
      <FooterCustom/>
    </Layout>
  );
}
