import React from 'react'
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Col, Row } from 'antd';
import HeaderMenu from './HeaderMenu';

export default function Header({ children }) {

  return <Container className="header">
            <Row>
              <Col span={12}>
                <div className="logo">
                  {children}
                </div>
              </Col>
              <Col span={12}>
                <HeaderMenu/>
              </Col>
            </Row>
          </Container>
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
