import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Col } from 'antd';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import { Table, TableActions } from '~/components/Table';
import ProductForm from './form';
import { formatPrice } from '~/Utils';

export default function Products({ products, onHandleProducts }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableData, setTableData] = useState([]);

  const fetchRecords = () => {
    setLoading(true);
    const filteredProducts = [];
    products.forEach((product) => {
      filteredProducts.push(product);
    });

    setTableData(filteredProducts);
    setLoading(false);
  };

  const handleEdit = product => {
    setSelectedRecord(product);
    setShowForm(true);
  };

  const handleDelete = product => {
    setLoading(true);
    let productList = [...products];
    productList = productList.filter(item => item.uuid !== product.uuid);
    onHandleProducts(productList, t('messages:productSuccessDeleted'));
    fetchRecords();
    setLoading(false);
  };

  function addNewProduct(product, message) {
    let prod = product;
    let productList = [...products];
    prod.uuid = productList.length > 0 ? productList[productList.length - 1].uuid + 1 : 1;
    productList.push(prod);
    onHandleProducts(productList, t('messages:itemSuccess'));
  }

  function updateProduct(oldProduct, newProduct) {
    let productList = [...products];
    productList[productList.indexOf(oldProduct)] = newProduct;
    onHandleProducts(productList, t('messages:itemSuccessEdit'));
  }

  useEffect(() => {
    fetchRecords();
  }, [showForm, products]);

  const style = {
    billing: { backgroundColor: '#52c41a' },
    shipping: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    }
  }

  const tableColumns = [
    {
      title: t('screens:items.data.code'),
      dataIndex: 'code',
      key: 'code',
      width: '20%',
    },
    {
      title: t('screens:items.data.name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: t('screens:items.data.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
      align: 'center',
    },
    {
      title: t('screens:items.data.unitPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: unitPrice => formatPrice(unitPrice)
    },
    {
      title: t('screens:items.data.labor'),
      dataIndex: 'labor',
      key: 'labor',
      render: labor => formatPrice(labor)
    },
    {
      title: t('screens:items.data.total'),
      dataIndex: 'total',
      key: 'total',
      render: total => formatPrice(total)
    },
    {
      title: t('screens:items.data.actions'),
      key: 'actions',
      align: 'center',
      actionSize: '10px',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button
            style={{ padding: '0, 1px' }}
            onClick={() => handleEdit(record)}
            title={t('messages:edit')}>
            <EditOutlined />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  return (
    <Box>
      <Row>
        <Col span={12} style={style.btnNew.col}>
          <Button
            loading={loading}
            color="primary"
            onClick={() => {
              setSelectedRecord(null);
              setShowForm(true);
            }}
            style={style.btnNew.btn}
          >
            <PlusOutlined />
            {t('screens:items.btnNew')}
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        rowKey="uuid"
        pagination={true}
        loading={loading}
        columns={tableColumns}
        dataSource={tableData}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0, }}>{record.description}</p>
          ),
          rowExpandable: (record) => record.description !== null && record.description !== '',
        }}
      />

      <ProductForm
        product={selectedRecord}
        visible={showForm}
        onClose={() => setShowForm(false)}
        onAddNewProduct={(e) => addNewProduct(e)}
        onUpdateProduct={(oldProduct, newProduct) => updateProduct(oldProduct, newProduct)} />
    </Box>
  );
}
