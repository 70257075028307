import { Badge, Button, Card, Col, Row, Statistic, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from '~/Utils/errorHandler';
import { Table } from '~/components/Table';
import Pagination from '~/components/Pagination';
import { formatPrice } from '~/Utils';
import { ColCustom, DividerCustom, LoadingMore } from '../styles';

export default function Documents({ visible, modeView, onCount }) {
    const { t } = useTranslation();
    const { Text } = Typography;
    const prefixDollar = '$';
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [listData, setListData] = useState([]);
    const [initLoading, setInitLoading] = useState(true);
    const [page, setPage] = useState(0);

    const fetchRecords = async () => {
        //criar consulta no back end
        /*const pagePaginationDTO = { page: 0, sortBy: 'name', filters: [] };
            const { data } = await api.post('/documents/pagination', pagePaginationDTO);
      
            const meta = {
              first: data.first,
              last: data.last,
              numberOfElements: data.numberOfElements,
              totalElements: data.totalElements,
              pageNumber: data.pageable.pageNumber,
              pageSize: data.pageable.pageSize,
            };
      
            setMeta(meta);
            setTableData(data.content);*/
        const meta = {
            first: true,
            last: true,
            numberOfElements: 6,
            totalElements: 8,
            pageNumber: 1,
            pageSize: 6,
        };

        let content = [
            { id: 1, name: 'Hilton Bathroom Remodel', document: 'Purchase Order #001005', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'ACCEPTED' },
            { id: 2, name: 'Hilton Washroom Remodel', document: 'Purchase Order #001006', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'PENDING' },
            { id: 3, name: 'Hilton Washroom Rework', document: 'Purchase Order #001007', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'OVERDUE' },
            { id: 4, name: 'Hilton Bathroom Rework', document: 'Purchase Order #001008', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'ACCEPTED' },
            { id: 5, name: 'Hilton Bathroom Cleanup', document: 'Purchase Order #001009', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'PENDING' },
            { id: 6, name: 'Hilton Bathroom Remodel', document: 'Purchase Order #001015', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'ACCEPTED' },
        ];
        setMeta(meta);
        setTableData(content);
        setListData(content);
        onCount(meta.totalElements);
    };

    const handleChangePage = async page => {
        setLoading(true);
        try {
            /*const paginationDTO = { page: page - 1, sortBy: 'name', filters: [] };
            const { data } = await api.post('/documents/pagination', paginationDTO);
      
            const meta = {
              first: data.first,
              last: data.last,
              numberOfElements: data.numberOfElements,
              totalElements: data.totalElements,
              pageNumber: data.pageable.pageNumber + 1,
              pageSize: data.pageable.pageSize,
            };*/

            //setMeta(meta);
            //setTableData(data.content);

            const meta = {
                first: true,
                last: true,
                numberOfElements: 2,
                totalElements: 8,
                pageNumber: 2,
                pageSize: 6,
            };

            let content = [
                { id: 7, name: 'Hilton Washroom Remodel', document: 'Purchase Order #001076', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'PENDING' },
                { id: 8, name: 'Hilton Washroom Rework', document: 'Purchase Order #001077', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'OVERDUE' },
            ];
            setMeta(meta);
            setTableData(content);
            onCount(meta.totalElements);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const onLoadMore = () => {
        setLoading(true);
        /*const paginationDTO = { page: page, sortBy: 'name', filters: [] };
            const { data } = await api.post('/documents/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/
        const meta = {
            first: true,
            last: true,
            numberOfElements: 2,
            totalElements: 8,
            pageNumber: 2,
            pageSize: 6,
        };


        let content = [
            { id: 7, name: 'Hilton Washroom Remodel', document: 'Purchase Order #0010076', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'PENDING' },
            { id: 8, name: 'Hilton Washroom Rework', document: 'Purchase Order #0010077', balanceDue: 12099, value: 18099, sentDate: '09/09/2022', dueDate: '09/09/2022', status: 'OVERDUE' },
        ];
        const newData = listData.concat(content);
        setListData(newData);
        setPage(page + 1);
        setLoading(false);
        window.dispatchEvent(new Event('resize'));
    }

    useEffect(() => {
        if (visible) {
            setPage(0);
            setInitLoading(false);
            fetchRecords();
        }
    }, [visible]);

    const style = {
        document: {
            cardBody: { paddingLeft: '0px' },
            status: {
                accepted: { backgroundColor: '#389E0D' },
                pending: { backgroundColor: '#FA8C16' },
                overdue: { backgroundColor: '#FF4D4F' }
            }
        },
        statistic1: { fontSize: '14px' },
        statistic2: { fontSize: '18px' },
        statistic3: { paddingBottom: '20px' },
    }

    function getStyle(value) {
        switch (value) {
            case 'accepted':
                return (style.document.status.accepted);
            case 'pending':
                return (style.document.status.pending);
            case 'overdue':
            default:
                return (style.document.status.overdue);
        }
    }

    const tableColumns = [
        {
            title: t('screens:documents.data.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('screens:documents.data.document'),
            dataIndex: 'document',
            key: 'document',
        },
        {
            title: t('screens:documents.data.balanceDue'),
            dataIndex: 'balanceDue',
            key: 'balanceDue',
            render: balanceDue => formatPrice(balanceDue)
        },
        {
            title: t('screens:documents.data.value'),
            dataIndex: 'value',
            key: 'value',
            render: value => formatPrice(value)
        },
        {
            title: t('screens:documents.data.sentDate'),
            dataIndex: 'sentDate',
            key: 'sentDate',
        },
        {
            title: t('screens:documents.data.dueDate'),
            dataIndex: 'dueDate',
            key: 'dueDate',
        },
        {
            title: t('screens:documents.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '100px',
            render: status =>
                <Badge
                    count={(t('screens:documents.data.status.' + status.toLowerCase()).toUpperCase())}
                    style={getStyle(status.toLowerCase())} />
        },
    ];

    const loadMore = !initLoading && !loading ? (<LoadingMore><Button onClick={onLoadMore}>{t('screens:customers.loadingMore')}</Button></LoadingMore>) : null;

    return (
        <>
            {modeView === 'list' ?
                <>
                    <Table
                        bordered
                        size='small'
                        rowKey="id"
                        pagination={false}
                        loading={loading}
                        columns={tableColumns}
                        dataSource={tableData}
                    />

                    <Pagination
                        onChange={value => {
                            handleChangePage(value);
                        }}
                        meta={meta}
                    />
                </>
                :
                <>
                    <Row>
                        {listData.map(doc =>
                            <>
                                <ColCustom xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Card size='small' bodyStyle={style.document.cardBody}>
                                        <Row>
                                            <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                                                <DividerCustom type='vertical' style={getStyle(doc.status.toLowerCase())} />
                                            </Col>
                                            <Col xs={22} sm={22} md={22} lg={23} xl={23}>
                                                <p><Text strong>{doc.name}</Text></p>
                                                <p><Badge
                                                    count={(t('screens:documents.data.status.' + doc.status.toLowerCase()).toUpperCase())}
                                                    style={getStyle(doc.status.toLowerCase())} />
                                                </p>
                                                <Row xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:documents.data.balanceDue')} prefix={prefixDollar} value={doc.balanceDue} precision={2} valueStyle={style.statistic2} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:documents.data.value')} prefix={prefixDollar} value={doc.value} precision={2} valueStyle={style.statistic2} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Statistic title={t('screens:documents.data.document')} value={doc.document} valueStyle={style.statistic1} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:documents.data.sentDate')} value={doc.sentDate} valueStyle={style.statistic1} style={style.statistic3} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                        <Statistic title={t('screens:documents.data.dueDate')} value={doc.dueDate} valueStyle={style.statistic1} style={style.statistic3} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </ColCustom>
                            </>
                        )}
                    </Row>
                    {loadMore}
                </>
            }
        </>
    );
}