import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Switch, Input, Select } from 'formik-antd';
import { Modal, message, Spin, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import ImageUpload from '~/components/ImageUpload';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '~/store/modules/user/actions';

const initialValues = {
  id: null,
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  newPassword: '',
  password_confirmation: '',
  image: null,
  status: true,
  role_id: '',
  company_id: '',
  newPasswordConfirmReq: false,
  isAdmin: false
};

export default function UserForm({ visible, disabledImage, onClose, id }) {
  const { Title } = Typography;
  const { Option } = Select;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const roleListProfile = useSelector(state => state && state.user && state.user.profile && state.user.profile.roles);

  const fetchRecords = async () => {
    setLoading(true);
    const { data } = await api.get('/roles/findAll');
    setRoles(data);
    let roleList = data;

    try {
      let admin = roleListProfile && roleListProfile[0] && (roleListProfile[0].name === 'ROLE_ADMIN' ||
      roleListProfile[0].name === 'ROLE_SUPER_ADMIN');
      const { data } = await api.get('/companies/findAll');
      setCompanies(data);

      if (id === null) {
        const initValues = {
          id: null,
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          newPassword: '',
          password_confirmation: '',
          newPasswordConfirmReq: false,
          image: null,
          status: true,
          role_id: roleList[0].id.toString(),
          company_id: data[0].id.toString(),
          isAdmin: admin
        };
        setRecordData(initValues);
      } else {
        if (id.toString() === profile.id.toString()) {
          const { data } = await api.get('/users/find');

          {
            data.status === 'ACTIVE' ? (data.status = true) : (data.status = false);
          }

          data.company_id = data.company.id.toString();
          data.role_id = data.roles[0].id.toString();
          data.isAdmin = admin;
          data.newPassword = '';
          data.password_confirmation = '';
          data.newPasswordConfirmReq = false;
          setRecordData(data);
        } else {
          const { data } = await api.get('/users/findById', {
            params: { id },
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          {
            data.status === 'ACTIVE' ? (data.status = true) : (data.status = false);
          }

          data.company_id = data.company.id.toString();
          data.role_id = data.roles[0].id.toString();
          data.isAdmin = admin;
          data.newPassword = '';
          data.password_confirmation = '';
          data.newPasswordConfirmReq = false;
          setRecordData(data);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      let password = values.newPassword.length > 0 ? values.newPassword : values.password;
      let companySelected = companies.filter(item => item.id.toString() === values.company_id);

      let newUser = {
        id: values.id,
        name: values.name,
        email: values.email,
        password: password,
        image: values.image,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
        roles: roles.filter(item => item.id.toString() === values.role_id),
        company: companySelected.length > 0 ? companySelected[0] : null,
      };

      if (values.id !== null) {
        if (id === profile.id) {
          const { data } = await api.get('/users/find');
          newUser.image = data.image;
        }

        await api.put('/users/update', newUser);
        if (id.toString() === profile.id.toString()) {
          dispatch(updateProfile(newUser));
        }
        message.success(t('messages:userSuccessEdit'));
      } else {
        await api.post('/users/create', newUser);
        message.success(t('messages:userSuccess'));
      }

      onClose();
    } catch (error) {
      const { response } = error;
      if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
        message.error(t('messages:duplicatedEmail'));
      } else {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/find');
      delete data.roleDescription;
      dispatch(updateProfile(data));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().when("id", { is: null, then: Yup.string().required() }),
    confirmPassword: Yup.string().when("id", { is: null, then: Yup.string().oneOf([Yup.ref('password'), null], 'Different passwords').required() }),
    password_confirmation: Yup.string().when("newPasswordConfirmReq", { is: true, then: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Different passwords').required() }),
    role_id: Yup.string().required(),
    company_id: Yup.string().required(),
    status: Yup.bool().required(),
  });

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={userSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values }) => (
        <Modal
          width="580px"
          title={id !== null ? t('screens:users.btnEdit') : t('screens:users.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }} required field="avatar">
                  <ImageUpload
                    disabled={disabledImage}
                    image={values.image}
                    upUrl={`${process.env.REACT_APP_BACKEND_URL}/uploadImage`}
                    delUrl={`${process.env.REACT_APP_BACKEND_URL}/uploadImage`}
                    postUpload={handleUpload}
                    postDelete={handleUpload}
                    message={values.image ? '' : t('screens:users.data.sendAvatar')}
                  />
                </FormControl>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={4} style={{ color: '#004080' }}>
                    {t('screens:users.data.personalInfo')}
                  </Title>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}
                      field="name"
                      label={t('screens:users.data.name')}
                      required
                      error={(touched.name && errors.name) || errors.name}
                    >
                      <Input name="name" disabled={!values.isAdmin && id !== null}/>
                    </FormControl>

                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                      field="active"
                      label={t('screens:users.data.active')}
                    >
                      <Switch
                        disabled={!values.isAdmin && id !== null}
                        name="status"
                        checked={recordData.status === undefined ? true : recordData.status}
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        onChange={e => {
                          recordData.status = e;
                        }}
                      ></Switch>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="email"
                      label={t('screens:users.data.email')}
                      required
                      error={(touched.email && errors.email) || errors.email}
                    >
                      <Input type="email" name="email" disabled={!values.isAdmin && id !== null} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="role_id"
                      label={t('screens:users.data.role')}
                      required
                      error={(touched.role_id && errors.role_id) || errors.role_id}
                    >
                      <Select
                        disabled={(!values.isAdmin && id !== null) }
                        showSearch
                        name="role_id"
                        label={t('screens:users.data.role')}

                      >
                        {roles.map(item => {
                          return (
                            <Option key={item.id} value={item.id.toString()}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Row>
                  {id === null ?
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="password"
                        label={t('screens:users.data.password')}
                        required
                        error={(touched.password && errors.password) || errors.password}
                      >
                        <Input name="password" type="password" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="confirmPassword"
                        label={t('screens:users.data.confirmPassword')}
                        required
                        error={(touched.confirmPassword && errors.confirmPassword) || errors.confirmPassword}
                      >
                        <Input name="confirmPassword" type="password" />
                      </FormControl>
                    </Row>
                    :
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="newPassword"
                        label={t('screens:users.data.newPassword')}
                        required
                        error={(touched.newPassword && errors.newPassword) || errors.newPassword}
                      >
                        <Input
                          name="newPassword"
                          type="password"
                          onChange={e => {
                            setFieldValue('newPasswordConfirmReq', e.target.value.length > 0 ? true : false);
                          }} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="password_confirmation"
                        label={t('screens:users.data.newPasswordConfirmation')}
                        required
                        error={(touched.password_confirmation && errors.password_confirmation) || errors.password_confirmation}
                      >
                        <Input name="password_confirmation" type="password" />
                      </FormControl>
                    </Row>
                  }
                  <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="company_id"
                        label={t('screens:users.data.company')}
                        required
                        error={(touched.company_id && errors.company_id) || errors.company_id}
                      >
                        <Select
                          showSearch
                          name="company_id"
                          label={t('screens:users.data.company')}

                        >
                          {companies.map(item => {
                            return (
                              <Option key={item.id} value={item.id.toString()}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
