import React, { useState, useEffect } from 'react';
import { Col, message, Modal, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, Input, InputNumber, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import errorHandler from 'Utils/errorHandler';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { findAllCostTypes } from '~/services/hooks/costTypes';
import { unitList } from '~/services/lists/unit';

const initialValues = {
  id: null,
  showItemsOwner: false,
  title: '',
  unitCost: 0,
  quantity: 0,
  unit: 'Each',
  builderCost: 0,
  markup: 0,
  markupType: 'DOUBLE_DASH',
  ownerPrice: 0,
  description: '',
  internalNotes: null,
  costTypes: [],
};

export default function ItemForm({ visible, onClose, itemMarkupTypes, onAddNewItem, onUpdateItem, item }) {
  // get initial values and replace with values that exist in item
  const itemData = { ...initialValues, ...item };

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [allCostTypes, setAllCostTypes] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    async function loadCostTypes() {
      const data = await findAllCostTypes();
      setAllCostTypes(data);
    }
    loadCostTypes();
  }, []);

  const handleSave = async (values, { setErrors }) => {
    delete values.undefined; // delete 'undefined' from values (caused by select antd component)
    setLoading(true);
    try {
      if (item === null) {
        onAddNewItem(values);
        message.success(t('messages:changeOrders.itemSuccessAdd'));
      } else {
        onUpdateItem(item, values);
        message.success(t('messages:changeOrders.itemSuccessEdit'));
      }
      onClose();
    } catch (error) {
      setErrors(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const itemSchema = Yup.object().shape({
    title: Yup.string().required(),
    unitCost: Yup.number().min(0.01),
    quantity: Yup.number().min(0.01),
    unit: Yup.string().required(),
    builderCost: Yup.number().min(0),
    markup: Yup.number().min(0),
    ownerPrice: Yup.number().min(0),
    costTypes: Yup.array().min(1),
  });

  return (
    <Formik
      initialValues={itemData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={itemSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values }) => (
        <Modal
          title={item !== null ? t('screens:changeOrders.item.btnEdit') : t('screens:changeOrders.item.btnNew')}
          width="600px"
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    error={touched.title && errors.title}
                    field="title"
                    label={t('screens:changeOrders.item.data.title')}
                    required
                  >
                    <Input name="title" autoComplete="off" maxLength={40} />
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <FormControl field="description" label={t('screens:changeOrders.data.description')}>
                    <Input.TextArea showCount maxLength={250} name="description" />
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <FormControl
                    field="costTypes"
                    error={errors.costTypes}
                    label={t('screens:changeOrders.item.data.costTypes')}
                  >
                    <Select
                      key="costTypes"
                      mode="multiple"
                      showArrow
                      style={{ width: '100%' }}
                      placeholder={t('messages:select')}
                      onChange={value => {
                        const selectedCostTypes = allCostTypes.filter(costType => value.includes(costType.id));
                        setFieldValue('costTypes', selectedCostTypes);
                      }}
                      value={values?.costTypes?.map(costType => costType?.id) || []}
                    >
                      {allCostTypes?.map(costType => {
                        return (
                          <Option key={costType.id} value={costType.id}>
                            {costType?.name || `Cost Type ${costType.id}`}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <FormControl
                    field="unitCost"
                    label={t('screens:changeOrders.item.data.unitCost')}
                    error={errors.unitCost}
                  >
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="unitCost"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl
                    field="quantity"
                    label={t('screens:changeOrders.item.data.quantity')}
                    error={errors.quantity}
                  >
                    <InputNumber name="quantity" min={0} max={999999} />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl field="unit" label={t('screens:changeOrders.item.data.unit')} required>
                    <Select
                      name="unit"
                      id="unit"
                      placeholder="Select"
                      value={values.unit}
                      onChange={value => {
                        setFieldValue('unit', value);
                      }}
                    >
                      {unitList?.map(item => {
                        return (
                          <Option key={item.id} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <FormControl
                    field="builderCost"
                    label={t('screens:changeOrders.item.data.builderCost')}
                    error={errors.builderCost}
                  >
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="builderCost"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl field="markup" label={t('screens:changeOrders.item.data.markup')} error={errors.markup}>
                    <InputNumber
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="markup"
                      number={true}
                      addonAfter={
                        <Select name="markupType" placeholder="Select">
                          {itemMarkupTypes?.map(item => {
                            return (
                              <Option key={item.value} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                        </Select>
                      }
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl
                    field="ownerPrice"
                    label={t('screens:changeOrders.item.data.ownerPrice')}
                    error={errors.ownerPrice}
                  >
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="ownerPrice"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
