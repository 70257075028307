import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();

  const handleSearch = values => {

    if (values.nameFilter === '0') {
      values.nameFilter = '';
    }

    if (values.codeFilter === '0') {
      values.codeFilter = '';
    }

    const filter = [];
    try {
      if (
        values.codeFilter !== undefined &&
        values.codeFilter !== null &&
        values.codeFilter !== ''
      ) {
        filter.push({
          field: 'code',
          value: values.codeFilter,
          restriction: 'LIKE',
        });
      }

      if (
        values.nameFilter !== undefined &&
        values.nameFilter !== null &&
        values.nameFilter !== ''
      ) {
        filter.push({
          field: 'name',
          value: values.nameFilter,
          restriction: 'LIKE',
        });
      }

      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
    <Formik
      initialValues={{ nameFilter: '', laborTypeFilter: 'All' }}
      enableReinitialize
      onSubmit={handleSearch}
    >
      {({ errors, resetForm }) => (
        <Spin spinning={isLoading}>
          <Form>
            <Row>
            <Col span="3">
                <FormControl
                  error={errors.codeFilter}
                  field="codeFilter"
                  label={t('screens:laborType.code')}
                >
                  <Input name="codeFilter" placeholder={t('fields:laborType.codePlaceholder')} />
                </FormControl>
              </Col>
              <Col span="12">
                <FormControl
                  error={errors.nameFilter}
                  field="nameFilter"
                  label={t('screens:laborType.name')}
                >
                  <Input name="nameFilter" placeholder={t('fields:laborType.namePlaceholder')} />
                </FormControl>
              </Col>
              <Button
                color="default"
                style={style.btnClear}
                onClick={() => {
                  resetForm();
                  setFilters([]);
                }}
              >
                {t('screens:dateSearch.clearButton')}
              </Button>
              <Button type="submit" color="primary" style={style.btnSubmit}>
                <SearchOutlined />
                {t('screens:dateSearch.button')}
              </Button>
            </Row>
          </Form>
        </Spin>
      )}
    </Formik>
  </Box>
  );
}
