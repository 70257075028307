import styled from 'styled-components';
import { Form } from 'formik-antd';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .form-control {
    margin-bottom: 15px;

    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: #f55;
    }
  }

  .ant-input-affix-wrapper-sm {
    background: #fafafa;
  }

  input {
    background: #fafafa;
    height: 32px;
    padding: 0 15px;
    color: #000;

    &::placeholder {
      color: #a1a1a1;
    }
  }

  span {
    color: #a1a1a1;
  }

  a {
    color: #a1a1a1;
    font-size: 12px;

    &:hover {
      opacity: 1;
      color: #071d3d;
    }
  }

  button {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  img {
    margin: auto;
  }
`;

export const Box = styled.div`
  width: 90%;
  max-width: 390px;
  margin: auto;
  margin-bottom: 0%;
  background: #fff;
  border-radius: 5px;
  padding: 0 60px 20px;
`;

export const Copyright = styled.div`
  margin-top: 16px;
  margin-bottom: auto;
  color: #fff;
  text-align: center;
  font-size: 12px;
`;
