import React from 'react';
import { Col, Divider, message, Modal, Spin, Tabs, Typography, Upload, Select } from 'antd';
import { Field, Formik } from 'formik';
import { Checkbox, DatePicker, Form, Input } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Box from '~/components/Box';
import InputError from '~/components/Form/Error';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { Container } from './styles';
// import SubContractorNotifications from './SubContractorNotifications';
import SubContractorProjects from './SubContractorProjects';
import { findAllCountries } from '~/services/hooks/countries';
import { createSubVendor, updateSubVendor } from '~/services/hooks/subVendors';
import { paginationLaborTypes } from '~/services/hooks/laborTypes';
import { findAllNotifications } from '~/services/hooks/notifications';
import { reminderLimits } from '~/services/lists/reminderLimits';
import { format } from 'date-fns';
import moment from 'moment';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

const { Text } = Typography;

const initialValues = {
  id: null,
  address: null,
  companyName: '',
  notes: '',
  preferenceSubVendor: {
    preferenceFourValue: 2,
  },
  liabilityInsurence: {
    ...null,
    type: 'LIABILITY',
    weeklyReminderType: 'BEFORE',
    weeklyReminderDays: 1,
    expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    reminderLimit: 5,
  },
  workersCompensationInsurence: {
    ...null,
    type: 'WORKERS_COMPENSATION',
    weeklyReminderType: 'BEFORE',
    weeklyReminderDays: 1,
    expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    reminderLimit: 5,
  },
  w9: null,
  contact: {
    id: null,
    address: null,
    firstName: '',
    lastName: '',
    jobTitle: '',
    mainPhone: '',
    mainEmail: '',
    workPhone: '',
    ccEmail: '',
    mobilePhone: '',
  },
  notifications: [],
  projectAccess: [],
  newSubvendor: true,
  user: null,
};

const initialNotificationsValues = [
  {
    id: 1,
    value: false,
  },
  {
    id: 2,
    value: false,
  },
  {
    id: 3,
    value: false,
  },
  {
    id: 4,
    value: false,
  },
  {
    id: 5,
    value: false,
  },
  {
    id: 6,
    value: false,
  },
  {
    id: 7,
    value: false,
  },
  {
    id: 8,
    value: false,
  },
  {
    id: 9,
    value: false,
  },
  {
    id: 10,
    value: false,
  },
  {
    id: 11,
    value: false,
  },
  {
    id: 12,
    value: false,
  },
  {
    id: 13,
    value: false,
  },
  {
    id: 14,
    value: false,
  },
  {
    id: 15,
    value: false,
  },
  {
    id: 16,
    value: false,
  },
  {
    id: 17,
    value: false,
  },
  {
    id: 18,
    value: false,
  },
  {
    id: 19,
    value: false,
  },
  {
    id: 20,
    value: false,
  },
  {
    id: 21,
    value: false,
  },
  {
    id: 22,
    value: false,
  },
  {
    id: 23,
    value: false,
  },
  {
    id: 24,
    value: false,
  },
  {
    id: 25,
    value: false,
  },
  {
    id: 26,
    value: false,
  },
  {
    id: 27,
    value: false,
  },
  {
    id: 28,
    value: false,
  },
  {
    id: 29,
    value: false,
  },
  {
    id: 30,
    value: false,
  },
];

export default function SubContractorForm({ selectedRecord, visible, projects, onClose, onUpdateItem, onInsertItem }) {
  const subcontractor = { ...initialValues, ...selectedRecord }; // get initial values and replace with values that exist in selectedRecord

  if (subcontractor.notifications.length === 0) {
    subcontractor.notifications = initialNotificationsValues;
  }

  if (subcontractor.liabilityInsurence === null) {
    subcontractor.liabilityInsurence = {
      arquive: null,
      type: 'LIABILITY',
      expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      weeklyReminderType: 'BEFORE',
      weeklyReminderDays: 1,
      reminderLimit: 5,
    };
  } else if (subcontractor.liabilityInsurence.weeklyReminderDays === null) {
    subcontractor.liabilityInsurence.weeklyReminderDays = 1;
  }

  if (subcontractor.workersCompensationInsurence === null) {
    subcontractor.workersCompensationInsurence = {
      arquive: null,
      type: 'WORKERS_COMPENSATION',
      expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      weeklyReminderType: 'BEFORE',
      weeklyReminderDays: 1,
      reminderLimit: 5,
    };
  } else if (subcontractor.workersCompensationInsurence.weeklyReminderDays === null) {
    subcontractor.workersCompensationInsurence.weeklyReminderDays = 1;
  }

  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadLiability, setUploadLiability] = useState([]);
  const [uploadWorkers, setUploadWorkers] = useState([]);
  const [uploadTrade, setUploadTrade] = useState([]);
  const [uploadW9, setUploadW9] = useState([]);
  // const [showList, setShowList] = useState('none');
  const [countries, setCountries] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);

  useEffect(() => {
    findAllNotifications().then(data => {
      setNotificationList(data);
    });
    findAllCountries().then(data => {
      setCountries(data);
    });
  }, []);

  useEffect(() => {
    paginationLaborTypes({ page: 0, sortBy: 'name', maxSize: true, filters: [] }).then(data => {
      setLaborTypes(data.content);
      setUploadLiability([]);
      setUploadWorkers([]);
      setUploadW9([]);
    });
  }, []);

  const handleSave = async values => {

    // remove notifications from values
    values.notifications = [];
    values.liabilityInsurence.type = 'LIABILITY';
    values.workersCompensationInsurence.type = 'WORKERS_COMPENSATION';

    if (values.liabilityInsurence.expiration_date === null) {
      message.error('Liability Insurence Expiration Date is required');
      return;
    }

    if (values.workersCompensationInsurence.expiration_date === null) {
      message.error('Workers Compensation Insurence Expiration Date is required');
      return;
    }

    values.liabilityInsurence.expiration_date = format(
      new Date(values.liabilityInsurence.expiration_date),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    values.workersCompensationInsurence.expiration_date = format(
      new Date(values.workersCompensationInsurence.expiration_date),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    if (values.id === null && (uploadWorkers.length < 1 || uploadLiability.length < 1)) {
      message.error(t('messages:uploadFileRequired'));
      return;
    }
    if (uploadLiability.length !== 0) {
      const data = await uploadFiles(uploadLiability);
      values.liabilityInsurence.arquive = data[0];
    }
    if (uploadWorkers.length !== 0) {
      const data = await uploadFiles(uploadWorkers);
      values.workersCompensationInsurence.arquive = data[0];
    }
    if (uploadW9.length !== 0) {
      const data = await uploadFiles(uploadW9);
      values.w9 = data[0];
    }

    // TODO verify if exists in back end
    // if (uploadTrade.length !== 0) {
    //   const data = await uploadFiles(uploadTrade);
    //   values.tradeAgreement.arquive = data[0];
    // }

    setLoading(true);
    if (values.id !== null) {
      const data = await updateSubVendor(values);
      onUpdateItem(data);
    } else {
      const data = await createSubVendor(values);
      onInsertItem(data);
    }
    setLoading(false);
    onClose();
  };

  const subContractorSchema = Yup.object().shape({
    companyName: Yup.string().required(),
    contact: Yup.object().shape({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      jobTitle: Yup.string().required(),
      mainEmail: Yup.string().email().required(),
      mainPhone: Yup.string(),
      workPhone: Yup.string(),
      mobilePhone: Yup.string(),
    }),
    preferenceSubVendor: Yup.object().shape({
      preferenceOne: Yup.boolean(),
      preferenceTwo: Yup.boolean(),
      preferenceThree: Yup.boolean(),
      preferenceFour: Yup.boolean(),
      preferenceFourValue: Yup.number().when('preferenceFour', {
        is: true,
        then: Yup.number()
          .required('This field is required when Preference Four is selected.')
          .min(2, 'Minimum value is 2')
          .max(100, 'Maximum value is 100'),
        otherwise: Yup.number().notRequired(),
      }),
      preferenceFive: Yup.boolean(),
      preferenceFiveNotes: Yup.string().when('preferenceFive', {
        is: true,
        then: Yup.string().required('This field is required when Preference Five is selected.'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    liabilityInsurence: Yup.object().shape({
      //file_pdf: Yup.mixed().required(),
      expiration_date: Yup.date().required(),
      weeklyReminderDays: Yup.number().required(),
    }),
    workersCompensationInsurence: Yup.object().shape({
      //file_pdf: Yup.mixed().required(),
      expiration_date: Yup.date().required(),
      weeklyReminderDays: Yup.number().required(),
    }),
  });

  const style = {
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
    btnClear: { marginLeft: 'auto', marginTop: 25 },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    breadcrumb: { margin: '16px 0' },
  };

  return (
    <Formik
      initialValues={subcontractor}
      enableReinitialize
      onSubmit={handleSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={subContractorSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue, setValues }) => {
        return (
          <Modal
            width="850px"
            title={values.id !== null ? t('screens:subContractors.btnEdit') : t('screens:subContractors.btnNew')}
            onCancel={onClose}
            afterClose={resetForm}
            open={visible}
            loading={loading || isSubmitting}
            footer={
              <ModalFooter
                onOk={submitForm}
                loading={loading || isSubmitting}
                onCancel={onClose}
                cancelColor="default"
              />
            }
          >
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  {' '}
                  <Col span={24}>
                    <Text style={style.divider}>{t('screens:subContractors.data.generalContactInformation')}</Text>
                    <br />
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Col span={24}>
                    <FormControl
                      field="companyName"
                      label={t('screens:subContractors.data.companyName')}
                      required
                      error={errors.companyName}
                    >
                      <Input name="companyName" />
                    </FormControl>
                  </Col>
                </Row>
                {/* PRIMARY CONTACT INFORMATION */}
                <Row>
                  <Col span={8}>
                    <FormControl
                      field="contact.firstName"
                      label={t('screens:subContractors.data.contact.firstName')}
                      required
                      error={errors.contact?.firstName}
                    >
                      <Input name="contact.firstName" maxLength={25} />
                    </FormControl>
                  </Col>

                  <Col span={8}>
                    <FormControl
                      field="contact.lastName"
                      label={t('screens:subContractors.data.contact.lastName')}
                      required
                      error={errors.contact?.lastName}
                    >
                      <Input name="contact.lastName" maxLength={25} />
                    </FormControl>
                  </Col>
                  <Col span={8}>
                    <FormControl
                      field="contact.jobTitle"
                      label={t('screens:subContractors.data.contact.jobTitle')}
                      required
                      error={errors.contact?.jobTitle}
                    >
                      <Input name="contact.jobTitle" maxLength={40} />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormControl
                      field="contact.mainEmail"
                      label={t('screens:subContractors.data.contact.mainEmail')}
                      error={errors.contact?.mainEmail}
                      required
                    >
                      <Input name="contact.mainEmail" />
                    </FormControl>
                  </Col>
                  <Col span={6}>
                    <FormControl
                      field="contact.mainPhone"
                      label={t('screens:subContractors.data.contact.mainPhone')}
                      error={errors.contact?.mainPhone}
                    >
                      <Input name="contact.mainPhone" maxLength={27} />
                    </FormControl>
                  </Col>
                  <Col span={6}>
                    <FormControl
                      field="contact.workPhone"
                      label={t('screens:subContractors.data.contact.workPhone')}
                      error={errors.contact?.workPhone}
                    >
                      <Input name="contact.workPhone" maxLength={27} />
                    </FormControl>
                  </Col>
                  <Col span={6}>
                    <FormControl
                      field="contact.mobilePhone"
                      label={t('screens:subContractors.data.contact.mobilePhone')}
                      error={errors.contact?.mobilePhone}
                    >
                      <Input name="contact.mobilePhone" maxLength={20} />
                    </FormControl>
                  </Col>
                </Row>
                {/* PRIMARY CONTACT ADDRESS */}
                <Row>
                  <Col span={12}>
                    <FormControl
                      field="contact.address.addressLineOne"
                      label={t('screens:subContractors.data.contact.address.addressLineOne')}
                      error={errors.contact?.address?.addressLineOne}
                    >
                      <Input name="contact.address.addressLineOne" maxLength={40} />
                    </FormControl>
                  </Col>
                  <Col span={12}>
                    <FormControl
                      field="contact.address.addressLineTwo"
                      label={t('screens:subContractors.data.contact.address.addressLineTwo')}
                      error={errors.contact?.address?.addressLineTwo}
                    >
                      <Input name="contact.address.addressLineTwo" maxLength={40} />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <FormControl
                      field="contact.address.city"
                      label={t('screens:subContractors.data.contact.address.city')}
                      error={errors.contact?.address?.city}
                    >
                      <Input name="contact.address.city" maxLength={16} />
                    </FormControl>
                  </Col>
                  <Col span={6}>
                    <FormControl
                      field="contact.address.state"
                      label={t('screens:subContractors.data.contact.address.state')}
                      error={errors.contact?.address?.state}
                    >
                      <Input name="contact.address.state" maxLength={21} />
                    </FormControl>
                  </Col>

                  <Col span={6}>
                    <FormControl
                      field="contact.address.zipCode"
                      label={t('screens:subContractors.data.contact.address.zipCode')}
                      error={errors.contact?.address?.zipCode}
                    >
                      <Input name="contact.address.zipCode" maxLength={13} />
                    </FormControl>
                  </Col>

                  <Col span={6}>
                    <FormControl
                      error={errors.country}
                      field="contact.address.country"
                      label={t('screens:addresses.data.country')}
                    >
                      <Select name="contact.address.country" placeholder="Select">
                        {countries.map(item => {
                          return (
                            <Option key={item.name} value={item.name}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                {/* TABS: ADDITIONAL INFORMATION, NOTIFICATIONS, PROJECT ACCESS, TRADE AGREEMENT */}
                <Row>
                  <Col span={24}>
                    <Tabs
                      defaultActiveKey="1"
                      width="100%"
                      type="card"
                      size={'small'}
                      items={[
                        {
                          label: t('screens:subContractors.data.additional_information'),
                          key: 1,
                          children: (
                            <Box>
                              {/* ADDITIONAL INFORMATION - PREFERENCES */}
                              <Row>
                                <Col span={24}>
                                  {/* LABOR TYPES */}
                                  <FormControl
                                    field="laborTypes"
                                    error={errors.laborTypes}
                                    label={t('screens:subContractors.data.subcontractorsTrades')}
                                  >
                                    <Select
                                      mode="multiple"
                                      showArrow
                                      style={{ width: '100%' }}
                                      placeholder={t('messages:select')}
                                      onChange={value => {
                                        // add the selected labor types to the formik values
                                        const selectedLaborTypes = laborTypes.filter(item => value.includes(item.id));
                                        setFieldValue('laborTypes', selectedLaborTypes);
                                      }}
                                      value={values?.laborTypes?.map(item => item?.id) || []}
                                    >
                                      {laborTypes?.map(item => {
                                        return (
                                          <Option key={item.id} value={item.id}>
                                            {`${item.code} - ${item.name}`}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormControl
                                    field="preferenceSubVendor.preferenceOne"
                                    label={t('screens:subContractors.data.preferences')}
                                  >
                                    <div style={{ paddingTop: 10 }}>
                                      <Checkbox
                                        value="preferenceSubVendor.preferenceOne"
                                        name="preferenceSubVendor.preferenceOne"
                                      />
                                      <span style={{ marginLeft: 10 }}>
                                        {t('screens:subContractors.data.preferenceSubVendor.preferenceOne')}
                                      </span>
                                    </div>
                                  </FormControl>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormControl field="preferenceSubVendor.preferenceTwo">
                                    <div style={{ paddingTop: 0 }}>
                                      <Checkbox
                                        value="preferenceSubVendor.preferenceTwo"
                                        name="preferenceSubVendor.preferenceTwo"
                                      />
                                      <span style={{ marginLeft: 10 }}>
                                        {t('screens:subContractors.data.preferenceSubVendor.preferenceTwo')}
                                      </span>
                                    </div>
                                  </FormControl>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormControl field="preferenceSubVendor.preferenceThree">
                                    <div style={{ paddingTop: 0 }}>
                                      <Checkbox
                                        value="preferenceSubVendor.preferenceThree"
                                        name="preferenceSubVendor.preferenceThree"
                                      />
                                      <span style={{ marginLeft: 10 }}>
                                        {t('screens:subContractors.data.preferenceSubVendor.preferenceThree')}
                                      </span>
                                    </div>
                                  </FormControl>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <Container
                                    className={`form-control ${
                                      errors.preferenceSubVendor?.preferenceFourValue ? 'error' : ''
                                    }`}
                                  >
                                    <div style={{ paddingTop: 0 }}>
                                      <Checkbox
                                        value="preferenceSubVendor.preferenceFour"
                                        name="preferenceSubVendor.preferenceFour"
                                      />
                                      <span style={{ marginLeft: 10 }}>
                                        {t('screens:subContractors.data.preferenceSubVendor.preferenceFour.p1')}
                                        <Field
                                          name="preferenceSubVendor.preferenceFourValue"
                                          errors={errors.preferenceSubVendor?.preferenceFourValue}
                                          type="number"
                                          style={{ width: 50, marginLeft: 5, marginRight: 5 }}
                                          min={2} // Valor mínimo permitido
                                          disabled={!values.preferenceSubVendor?.preferenceFour}
                                          value={values.preferenceSubVendor?.preferenceFourValue}
                                        />
                                        {t('screens:subContractors.data.preferenceSubVendor.preferenceFour.p2')}
                                      </span>
                                      <InputError msg={errors.preferenceSubVendor?.preferenceFourValue} />
                                    </div>
                                  </Container>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormControl field="preferenceSubVendor.preferenceFive">
                                    <div style={{ paddingTop: 0 }}>
                                      <Checkbox
                                        value="preferenceSubVendor.preferenceFive"
                                        name="preferenceSubVendor.preferenceFive"
                                      />
                                      <span style={{ marginLeft: 10 }}>
                                        {t('screens:subContractors.data.preferenceSubVendor.preferenceFive')}
                                      </span>
                                    </div>
                                  </FormControl>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <FormControl
                                    field="preferenceSubVendor.preferenceFiveNotes"
                                    error={errors.preferenceSubVendor?.preferenceFiveNotes}
                                  >
                                    <Input.TextArea
                                      showCount
                                      maxLength={500}
                                      name="preferenceSubVendor.preferenceFiveNotes"
                                      disabled={!values.preferenceSubVendor?.preferenceFive}
                                    />
                                  </FormControl>
                                </Col>
                              </Row>
                              {/* ADDITIONAL INFO - CERTIFICATES */}
                              <Divider orientation="left" orientationMargin="0">
                                <Text>{t('screens:subContractors.data.preferenceSubVendor.certificates')}</Text>
                              </Divider>
                              <p>
                                <b>{t('screens:subContractors.data.liabilityInsurence.title')}</b>
                              </p>
                              <Row>
                                  <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 5, xl: 5 }}
                                    field="liabilityInsurence.expiration_date"
                                    label={t('screens:subContractors.data.expiration_date')}
                                    error={errors.liabilityInsurence?.expiration_date}
                                    required
                                  >
                                    <DatePicker
                                      name="liabilityInsurence.expiration_date"
                                      format="MM/DD/Y"
                                      placeholder={t('messages:select')}
                                    />
                                  </FormControl>
                                  <FormControl
                                    cols={{ xs: 13, sm: 11, md: 8, lg: 7, xl: 7 }}
                                    field="weeklyReminderDays"
                                    label={t('screens:subContractors.data.liabilityInsurence.weeklyReminderDays')}
                                    error={
                                      (touched?.liabilityInsurence?.weeklyReminderDays &&
                                        errors?.liabilityInsurence?.weeklyReminderDays)
                                    }
                                    required
                                  >
                                    <Input
                                      name="liabilityInsurence.weeklyReminderDays"
                                      style={{ width: 100 }}
                                    />
                                  </FormControl>
                                  <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 5, xl: 5 }}
                                    error={errors.liabilityInsurence?.reminderLimit}
                                    field="liabilityInsurence.reminderLimit"
                                    label={t('screens:subContractors.data.reminderLimit')}
                                    required
                                  >
                                    <Select
                                      name="liabilityInsurence.reminderLimit"
                                      value={values.liabilityInsurence.reminderLimit}
                                      onChange={value => {
                                        subcontractor.liabilityInsurence.reminderLimit = value;
                                        values.liabilityInsurence.reminderLimit = value;
                                        setValues({ ...values });
                                      }}
                                    >
                                      {reminderLimits?.map(item => {
                                        return (
                                          <Option key={item.value} value={item.value}>
                                            {item.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                                    field="liabilityInsurence"
                                    label={t('screens:subContractors.data.file_pdf')}
                                    required
                                    error={errors.liabilityInsurence?.arquive}
                                  >
                                    <UploadFile
                                      uploadList={uploadLiability}
                                      setUploadList={setUploadLiability}
                                      file={values.liabilityInsurence?.arquive}
                                      accept=".pdf, .jpg, .jpeg, .png"
                                    />
                                  </FormControl>
                              </Row>
                              <p>
                                <b>{t('screens:subContractors.data.workersCompensationInsurence.title')}</b>
                              </p>
                              <Row>
                                  <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 5, xl: 5 }}
                                    field="workersCompensationInsurence.expiration_date"
                                    label={t('screens:subContractors.data.expiration_date')}
                                    error={errors.workersCompensationInsurence?.expiration_date}
                                    required
                                  >
                                    <DatePicker
                                      name="workersCompensationInsurence.expiration_date"
                                      format="MM/DD/Y"
                                      placeholder={t('messages:select')}
                                    />
                                  </FormControl>
                                  <FormControl
                                    cols={{ xs: 13, sm: 11, md: 8, lg: 7, xl: 7 }}
                                    field="workersCompensationInsurence.weeklyReminderDays"
                                    label={t(
                                      'screens:subContractors.data.workersCompensationInsurence.weeklyReminderDays'
                                    )}
                                    error={
                                      (touched?.workersCompensationInsurence?.weeklyReminderDays &&
                                        errors?.workersCompensationInsurence?.weeklyReminderDays)
                                    }
                                    required
                                  >
                                    <Input
                                      name="workersCompensationInsurence.weeklyReminderDays"
                                      style={{ width: 100 }}
                                    />
                                  </FormControl>
                                  <FormControl
                                    cols={{ xs: 8, sm: 7, md: 5, lg: 5, xl: 5 }}
                                    error={errors.workersCompensationInsurence?.reminderLimit}
                                    field="workersCompensationInsurence.reminderLimit"
                                    label={t('screens:subContractors.data.reminderLimit')}
                                    required
                                  >
                                    <Select
                                      name="workersCompensationInsurence.reminderLimit"
                                      value={values.workersCompensationInsurence.reminderLimit}
                                      onChange={value => {
                                        subcontractor.workersCompensationInsurence.reminderLimit = value;
                                        values.workersCompensationInsurence.reminderLimit = value;
                                        setValues({ ...values });
                                      }}
                                    >
                                      {reminderLimits?.map(item => {
                                        return (
                                          <Option key={item.value} value={item.value}>
                                            {item.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                                    field="workersCompensationInsurence"
                                    label={t('screens:subContractors.data.file_pdf')}
                                    required
                                    error={errors.workersCompensationInsurence?.arquive}
                                  >
                                    <UploadFile
                                      uploadList={uploadWorkers}
                                      setUploadList={setUploadWorkers}
                                      file={values.workersCompensationInsurence?.arquive}
                                      accept=".pdf, .jpg, .jpeg, .png"
                                    />
                                  </FormControl>
                              </Row>
                              <p>
                                <b>{t('screens:subContractors.data.w9')}</b>
                              </p>
                              <Row>
                                  <FormControl
                                    cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                                    field="w9"
                                    label={t('screens:subContractors.data.file_pdf')}
                                  >
                                    <UploadFile uploadList={uploadW9} setUploadList={setUploadW9} file={values.w9} accept=".pdf"/>
                                  </FormControl>
                              </Row>
                              <Row>
                                  <FormControl
                                    cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                                    field="notes"
                                    label={t('screens:subContractors.data.preferenceSubVendor.notes')}
                                    error={errors.notes}
                                  >
                                    <Input.TextArea showCount maxLength={500} name="notes" />
                                  </FormControl>
                              </Row>
                            </Box>
                          ),
                        },
                        {
                          label: t('screens:subContractors.data.projectAccess'),
                          key: 3,
                          children: (
                            <SubContractorProjects
                              projects={projects}
                              selectedRowKeys={values.projectAccess.map(item => item.id)}
                              loading={loading}
                              selectedRows={values.projectAccess}
                              onChange={newSelectedRowKeys => {
                                setFieldValue(
                                  'projectAccess',
                                  projects.filter(item => newSelectedRowKeys.includes(item.id))
                                );
                              }}
                              t={t}
                            />
                          ),
                        },
                        // {
                        //   label: t('screens:subContractors.data.trade_agreement'),
                        //   key: 4,
                        //   children: (
                        //     <Box>
                        //       <Text>{t('screens:subContractors.data.agreement.title')}</Text>

                        //       <p>
                        //         <b>{t('screens:subContractors.data.agreement.currentTrades')}</b>
                        //       </p>

                        //       <span style={{ textAlign: 'center', fontSize: 20 }}>
                        //         {t('screens:subContractors.data.agreement.noActiveTrade')}
                        //       </span>

                        //       <span style={{ textAlign: 'center' }}>
                        //         {t('screens:subContractors.data.agreement.noActiveTradeNotification')}
                        //       </span>
                        //       <br />
                        //       <FormControl field="agreement">
                        //         <UploadFile
                        //           name={'file'}
                        //           style={{ textAlign: 'center' }}
                        //           uploadList={uploadTrade}
                        //           setUploadList={setUploadTrade}
                        //           file={values.tradeAgreement?.arquive}
                        //           accept=".pdf, .jpg, .jpeg, .png"
                        //         />
                        //       </FormControl>
                        //     </Box>
                        //   ),
                        // },
                      ]}
                    />
                  </Col>
                </Row>

                {visible && isSubmitting && errors.length > 0 && message.error('There are some errors in your submission: '
                  + Object.values(errors).join(', '))}

              </Form>
            </Spin>
          </Modal>
        );
      }}
    </Formik>
  );
}
