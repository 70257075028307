import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'subVendors';

// PAGINATION
export const paginationSubVendors = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllSubVendors = async filters => {
  try {
    const { data } = await api.post(`/${endPoint}/findAllPost`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findByIdSubVendor = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/findById`, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { id },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createSubVendor = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateSubVendor = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteSubVendor = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationSubVendors.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllSubVendors.propTypes = {
  filters: PropTypes.object.isRequired,
};

createSubVendor.propTypes = {
  values: PropTypes.object.isRequired,
};

updateSubVendor.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteSubVendor.propTypes = {
  id: PropTypes.string.isRequired,
};
