import React from 'react';
import { Upload, message, Typography, Divider } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileJpgOutlined,
  FileImageOutlined,
  FileOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import { downloadFile } from '~/services/hooks/files';
import FormTxt from '../Form/FormTxt';

function FileIcon({ contentType }) {
  switch (contentType) {
    case 'application/pdf':
      return <FilePdfOutlined />;
    case 'image/jpeg':
      return <FileJpgOutlined />;
    case 'image/jpg':
      return <FileJpgOutlined />;
    case 'image/png':
      return <FileImageOutlined />;
    default:
      return <FileOutlined />;
  }
}

export default function UploadFile({ file, uploadList, setUploadList, ...props }) {
  const initialValues = {
    id: null,
    name: '',
    originalName: '',
    contentType: '',
    path: '',
  };

  const { Text } = Typography;
  const archive = { ...initialValues, ...file };
  const { t } = useTranslation();

  const handleUpload = async event => {
    event.fileList.map(file => {
      var extension = '';
      var name = '';
      if (file.name.substring(file.name.length - 4) === 'jpeg') {
        extension = file.name.substring(file.name.length - 5);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 4);
      } else {
        extension = file.name.substring(file.name.length - 4);
        name = file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 3);
      }
      file.name = name + extension;
    });
    setUploadList(event.fileList);
  };

  const beforeUpload = file => {
    const isValid =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isValid) {
      message.error(t('messages:fileTypeError'));
      setTimeout(function() {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error(t('messages:fileSizeError'));
      setTimeout(function() {
        handleRemoveUploadListItem(file.uid);
      }, 100);
    }
    return isValid && isLt2M;
  };

  const handleRemoveUploadListItem = uid => {
    const data = uploadList.filter(item => item.uid !== uid);
    setUploadList(data);
  };

  return (
    <>
      <Upload
        name={'file'}
        accept={props.accept || '.pdf,.png,.jpg,.jpeg'}
        multiple={props.multiple || false}
        showUploadList={props.showUploadList || false}
        beforeUpload={beforeUpload}
        onChange={handleUpload}
        fileList={uploadList}
        maxCount={props.maxCount || 1}
        disabled={props.disabled || false}
      >
        <Button color="default">
          {'Upload file'}
          <UploadOutlined />
        </Button>
      </Upload>

      {uploadList.length > 0
        ? uploadList?.map(item => {
            return (
              <span key={item.uid}>
                <PaperClipOutlined />
                <Text>{item.name}</Text>
                <DeleteOutlined style={{ paddingLeft: '5px' }} onClick={() => handleRemoveUploadListItem(item.uid)} />
              </span>
            );
          })
        : null}

      {archive?.id !== null ? (
        <>
          <Divider orientation="left" orientationMargin="0" />
          <FormTxt label={t('screens:lastFileSent')}>
            <p>
              <FileIcon contentType={archive?.contentType} /> {archive?.originalName}{' '}
              <DownloadOutlined onClick={async () => await downloadFile(archive)} />
            </p>
          </FormTxt>
        </>
      ) : null}
    </>
  );
}
