import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Spin, Tooltip } from 'antd';
import { Container, Footer } from './styles';
import * as Yup from 'yup';
import api from '~/services/api';
import { store } from '~/store';
import Row from '~/components/Row';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import errorHandler from 'Utils/errorHandler';
import { updateProfile } from '~/store/modules/user/actions';

const initialValues = {companyName: ''};

export default function ModalCompany({ visible, onClose }) {
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [recordData, setRecordData] = useState(initialValues);
  const dispatch = useDispatch();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/companies/findAll', {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setCompanies(data);
      const { profile } = store.getState().user;
      let name = profile.company !== null ? profile.company.name : data.length > 0 ? data[0].name : '';
      setRecordData({companyName: name});
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    const name = values.companyName;

    if (name && name !== '') {
      try {
        const { data } = await api.get('/companies/findByName', {
          params: { name: name }
        });

        let company = data;
        if (company !== "") {
          const { data } = await api.get('/users/find');
          data.company = company;
          await api.put('/users/update', data);
          dispatch(updateProfile(data));
          onClose();
        }
      } catch(error) {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if(visible) {
      fetchRecords();
    }
  }, [visible]);

  const schema = Yup.object().shape({
    companyName: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={schema}>

      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Container
          closable={false}
          title={t('screens:companies.title')}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <Footer>
              <Button onClick={submitForm} loading={loading || isSubmitting} color="primary">
                {'Save'}
              </Button>
            </Footer>
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24 }}
                  error={touched.companyName && errors.companyName}
                  field="companyName"
                  label={t('fields:companies.company.label')}
                  required
                >

                  <Select name="companyName"
                    defaultValue={recordData.companyName}
                    style={{ width: 475 }}
                    placeholder={t('fields:companies.company.placeholder')}
                    onChange={value => {
                      recordData.companyName = value;
                    }}
                  >
                    {companies.map(item => {
                      return (
                        <Option id={item.id} key={item.id} value={item.name}>
                          <Tooltip placement="topLeft" title={item.name}>
                            <span>{item.name}</span>
                          </Tooltip>
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Container>
      )}
    </Formik>
  );
}
