import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'purchaseOrders';

// PAGINATION
export const paginationPurchaseOrders = async params => {
  try {
    const { data } = await api.post(`/${endPoint}/pagination`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllPurchaseOrders = async (filters) => {
  try {
    const { data } = await api.post(`/${endPoint}/findAll`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createPurchaseOrder = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/create`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updatePurchaseOrder = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deletePurchaseOrder = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationPurchaseOrders.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllPurchaseOrders.propTypes = {
  filters: PropTypes.object.isRequired,
};

createPurchaseOrder.propTypes = {
  values: PropTypes.object.isRequired,
};

updatePurchaseOrder.propTypes = {
  values: PropTypes.object.isRequired,
};

deletePurchaseOrder.propTypes = {
  id: PropTypes.string.isRequired,
};
