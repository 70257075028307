import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, EyeOutlined, MailFilled, MailOutlined, PhoneFilled, PhoneOutlined, PlusOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Popconfirm, message, Spin, Col, Breadcrumb, Badge } from 'antd';
import { Form, Select, Input } from 'formik-antd';
import { Formik } from 'formik';
import history from '~/services/history';
import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions } from '~/components/Table';
import CustomerForm from './form';
import { BreadcrumbCustom } from '~/styles/global';

export default function Customers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const fetchRecords = async (filters) => {
    setLoading(true);
    try {
      const pagePaginationDTO = { page: 0, sortBy: 'companyName', filters: filters};
      const { data } = await api.post('/customers/pagination', pagePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleView = async id => {
    history.push(`/customers/${id}`);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async id => {
    setLoading(true);

    try {
      await api.delete(`/customers/delete/${id}`);
      fetchRecords(filters);
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = values => {
    setLoading(true);

    if (values.companyName === '0') {
      values.companyName = '';
    }

    const filter = [];

    try {
      if (values.companyName !== undefined && values.companyName !== null && values.name !== '') {
        filter.push({
          field: 'companyName',
          value: values.companyName,
          restriction: 'LIKE',
        });
      }

      setFilters(filter);
      fetchRecords(filter);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async page => {
    setLoading(true);
    try {
      const paginationDTO = { page: page - 1, sortBy: 'companyName', filters: filters};
      const { data } = await api.post('/customers/pagination', paginationDTO);
      
      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber + 1,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords([]);
  }, [showForm]);

  const style = {
    contacts: { color:'#a1a1a1', marginRight: '8px' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
    btnClear: { marginLeft: 'auto', marginTop: 25 },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    }
  }

  const tableColumns = [
    {
      title: t('screens:customers.data.companyName'),
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: t('screens:customers.data.primaryContact'),
      key: 'contact',
      render: (record) => (
        <>{record.customerContact !== null ? 
          <>
            <div><UserOutlined style={style.contacts}/>{record.customerContact.primaryContact.firstName  + " " + record.customerContact.primaryContact.lastName}</div>
            <div><PhoneFilled style={style.contacts}/>{record.customerContact.primaryContact.mainPhone}</div>
            <div><MailFilled style={style.contacts}/>{record.customerContact.primaryContact.mainEmail}</div>
          </>
        : ""}</>
      ),
    },
    {
      title: t('screens:customers.data.secondaryContact'),
      key: 'contact',
      render: (record) => (
        <>{record.customerContact !== null && record.customerContact.secondaryContact !== null ? 
          <>
            <div><UserOutlined style={style.contacts}/>{record.customerContact.secondaryContact.firstName  + " " + record.customerContact.secondaryContact.lastName}</div>
            <div><PhoneFilled style={style.contacts}/>{record.customerContact.secondaryContact.mainPhone}</div>
            <div><MailFilled style={style.contacts}/>{record.customerContact.secondaryContact.mainEmail}</div>
          </>
        : ""}</>
      ),
    },
    {
      title: t('screens:customers.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button onClick={() => handleView(record.id)} title={t('messages:view')}><EyeOutlined /></Button>
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}><EditOutlined /></Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = 
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:customers')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  ;

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:customers')} style={style.pageTitle} />
      <Box>
        <Formik
          initialValues={{ companyName: '' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={7} xl={9}>
                    <FormControl error={errors.companyName} field="companyName" label={t('screens:customers.data.companyName')}>
                      <Input name="companyName" placeholder={t('fields:customers.companyName.placeholder')} />
                    </FormControl>
                  </Col>
                  <Button
                    color="default"
                    style={style.btnClear}
                    onClick={() => {
                      resetForm();
                      setFilters([]);
                      fetchRecords([]);
                    }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                  <Button type="submit" color="primary" style={style.btnSubmit}>
                    <SearchOutlined />
                    {t('screens:dateSearch.button')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary" 
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:customers.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table bordered rowKey="id" pagination={false} loading={loading} columns={tableColumns} dataSource={tableData} />
        
        <Pagination 
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
      <CustomerForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
    </DefautLayout>
  );
}
