import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

// UPLOAD FILE
export const uploadFiles = async uploadList => {
  console.log('uploadList from upload files', uploadList);
  const endPoint = 'uploadMultipleArchives';
  try {
    let files = new FormData();
    uploadList.map(file => files.append('files', file.originFileObj, file.name));
    const { data } = await api.post(`/${endPoint}`, files);
    // const { data } = await api.post(`/${endPoint}`, files, {
    //   headers: { 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s' },
    // });
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    console.log(error);
    errorHandler(error);
  }
};

// DOWNLOAD FILE
export const downloadFile = async file => {
  const endPoint = 'downloadFile';
  try {
    const { data } = await api.get(`/${endPoint}/${file.name}`, { responseType: 'blob' });
    const blob = new Blob([data], { type: file.contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file.originalName;
    link.click();
  } catch (error) {
    errorHandler(error);
  }
};

uploadFiles.PropTypes = {
  uploadList: PropTypes.array.isRequired,
};

downloadFile.PropTypes = {
  file: PropTypes.object.isRequired,
};
