import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Col, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import Filter from './filter';
import { paginationProposalSubcontractors, deleteProposalSubcontractor } from '~/services/hooks/proposalSubcontractors';
import { updateProposalSubcontractor } from '~/services/hooks/proposalSubcontractors';
import { downloadFile } from '~/services/hooks/files';
import moment from 'moment';

export default function ProposalSubcontractors({ project, customer }) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState(null);
  // const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationProposalSubcontractors({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [...filters, { columnJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' }],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (project.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy, project]);

  const handleDelete = async id => {
    await deleteProposalSubcontractor(id);
    fetchData(1);
  };

  // const handleEdit = record => {
  //   setSelectedRecord(record);
  //   setShowForm(true);
  // };

  // const onUpdateItem = item => {
  //   const index = tableData.findIndex(i => i.id === item.id);
  //   if (index > -1) {
  //     const newData = [...tableData];
  //     newData[index] = item;
  //     setTableData(newData);
  //   }
  // };

  // const handleEditItem = async item => {
  //   await updateProposalSubcontractor(item);
  //   fetchData(1);
  // };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:proposalSubcontractor.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: true,
      width: 80,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:proposalSubcontractor.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:proposalSubcontractor.projectDescription'),
      dataIndex: 'project',
      key: 'project.description',
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
      render: (project) => {
        return project.description;
      },
    },
    {
      title: t('screens:proposalSubcontractor.subVendorCompanyName'),
      dataIndex: 'subVendor',
      key: 'subVendor.companyName',
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
      render: (subVendor) => {
        return subVendor.companyName;
      },
    },
    {
      title: t('screens:drawing.createDate'),
      dataIndex: 'audit',
      key: 'audit.createDate',
      align: 'left',
      width: 200,
      sorter: true,
      ellipsis: { showTitle: true },
      render: audit => <>{moment(audit.createdDate).format('YYYY/MM/DD HH:MM:ss')}</>, // render: (audit) => (<>{audit.createDate}</>)
    },
    {
      title: t('screens:proposalSubcontractor.actions'),
      key: 'actions',
      align: 'center',
      width: 140,
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={async () => await downloadFile(record.arquive)} title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          {/* <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button> */}
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        {/* <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:proposalSubcontractor.btnNew')}
            </Button>
          </Col>
        </Row> */}
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        {/* <ProposalSubcontractorForm
          project={project}
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        /> */}
      </Box>
    </>
  );
}
