import React from 'react';
import { Col, Modal, Spin, Checkbox, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createDrawing, updateDrawing } from '~/services/hooks/drawings';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function DrawingForm({ estimate, selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const initialValues = {
    id: null,
    description: '',
    estimate: estimate,
    showToVendor: false,
    arquive: null,
  };
  const [uploadList, setUploadList] = useState([]);
  const drawing = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setUploadList([]);
  };

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    setLoading(true);
    if (values.id !== null) {
      const data = await updateDrawing(values);
      onUpdateItem(data);
    } else {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      const data = await createDrawing(values);
      onInsertItem(data);
    }
    resetForm();
    onClose();
    setLoading(false);
  };

  const drawingSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik initialValues={drawing} enableReinitialize onSubmit={handleSave} validationSchema={drawingSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue, touched }) => (
        <Modal
          width="650px"
          title={values.id !== null ? t('screens:drawing.btnEdit') : t('screens:drawing.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:drawing.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="showToVendor"
                    label={t('screens:drawing.data.showToVendor')}
                    // required
                    error={(touched.showToVendor && errors.showToVendor) || errors.showToVendor}
                  >
                    <Checkbox
                      name="showToVendor"
                      id="showToVendor"
                      checked={values?.showToVendor}
                      onChange={e => {
                        setFieldValue('showToVendor', e.target.checked);
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl field="drawing_file">
                    <UploadFile uploadList={uploadList} setUploadList={setUploadList} file={drawing?.arquive} />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
