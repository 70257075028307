import React, { useState } from 'react';
import DefaultLayout from '~/pages/_layouts/full';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Tabs, Badge, Tag, Button as ButtonAntd, Popconfirm } from 'antd';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import Informations from './Informations';
import history from '~/services/history';
import Estimates from './Estimates';
import PriceQuotes from './PriceQuotes';
import Drawings from './Drawings';
import ProposalClients from './ProposalClients';
import ProposalSubcontractors from './ProposalSubcontractors';
import SpecBooks from './SpecBooks';
import Submittials from './Submittials';
import RFIs from './RFIs';
import ProjectDashboards from '../ProjectDetails/Accounting/Workbook/ProjectDashboard';
import Receipts from '../ProjectDetails/Accounting/Workbook/Receipts';
import Subcontractors from '../ProjectDetails/Accounting/Workbook/Subcontractors';
import Costs from '../ProjectDetails/Accounting/Workbook/Costs';
import { updateProject } from '~/services/hooks/projects';
import { updateCustomer } from '~/services/hooks/customers';


export default function BidDetails(data) {
  const customer = data.location.state?.customer;
  // const project = data.location.state?.data;
  const { t } = useTranslation();
  const [project, setProject] = useState(data.location.state?.data);
  const [selectedTab, setSelectedTab] = useState(0);

  const style = {
    pageTitle: { color: '#0f4c81', fontSize: '20px' },
    divider: { margin: '0px' },
    cardBody: { background: '#F5F5F5', paddingTop: '10px', paddingBottom: '10px' },
    badgeCount: {
      minWidth: '14px',
      height: '14px',
      padding: '0 8px',
      fontSize: '12px',
      lineHeight: '14px',
      borderRadius: '7px',
      textAlign: 'center',
      background: '#E6F7FF',
      color: '#1890FF',
      marginLeft: '3px',
    },
    contacts: { color: '#a1a1a1', marginRight: '8px' },
    statistic1: { fontSize: '18px', textAlign: 'center' },
    statistic2: { fontSize: '18px' },
    status: {
      new: { backgroundColor: '#1890FF' },
      open: { backgroundColor: '#13C2C2' },
      inProgress: { backgroundColor: '#A0D911' },
      completed: { backgroundColor: '#FAAD14' },
      onHold: { backgroundColor: '#F5222D' },
    },
  };

  const onChangeTab = key => {
    setSelectedTab(key);
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/bids">{t('menus:bids')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/bids-list">{t('menus:bidList')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:bidDetails')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const items = [
    {
      key: 0,
      label: <span>Informations</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[{ key: 0, label: <span>Informations</span>, children: <Informations project={project} customer={customer} /> }]}
        />
      ),
    },
    // {
    //   key: 1,
    //   label: <span>Accounting</span>,
    //   children: (
    //     <Tabs
    //       type="card"
    //       size="small"
    //       tabPosition="top"
    //       defaultActiveKey={0}
    //       items={[
    //         { key: 0, label: <span>Workbook</span>, children: <div style={{ height: '100vh' }}>Workbook Content</div> },
    //       ]}
    //     />
    //   ),
    // },
    {
      key: 1,
      label: <span>Accounting</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          tabBarStyle={{ marginLeft: '20px' }}
          defaultActiveKey={0}
          items={[
            {
              key: 11,
              label: <span>Workbook</span>,
              children: (
                <Tabs
                  size="small"
                  tabPosition="top"
                  tabBarStyle={{ marginLeft: '20px' }}
                  defaultActiveKey={111}
                  items={[
                    {
                      key: 111,
                      label: <span>Project Dashboard</span>,
                      children: <ProjectDashboards project={project} customer={customer} />,
                    },
                    {
                      key: 112,
                      label: <span>Receipts</span>,
                      children: <Receipts project={project} customer={customer} />,
                    },
                    { key: 113, label: <span>Costs</span>, children: <Costs project={project} customer={customer} /> },
                    {
                      key: 114,
                      label: <span>SubContractors</span>,
                      children: <Subcontractors project={project} />,
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      ),
    },

    {
      key: 2,
      label: <span>Estimating</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            {
              key: 0,
              label: <span>Budgets</span>,
              children: <Estimates project={project} customer={customer} />,
            },
            {
              key: 22,
              label: <span>Price Quotes</span>,
              children: <PriceQuotes project={project} customer={customer} />,
            },
            {
              key: 2,
              label: <span>{t('menus:drawings')}</span>,
              children: <Drawings project={project} customer={customer} />,
            },
            {
              key: 3,
              label: <span>Proposals Client</span>,
              children: <ProposalClients project={project} customer={customer} />,
            },
            {
              key: 4,
              label: <span>Proposals Subcontractor</span>,
              children: <ProposalSubcontractors project={project} customer={customer} />,
            },
            {
              key: 5,
              label: <span>SpecBook</span>,
              children: <SpecBooks project={project} customer={customer} />,
            },
            {
              key: 6,
              label: <span>Submittials</span>,
              children: <Submittials project={project} customer={customer} />,
            },
            {
              key: 7,
              label: <span>RFI</span>,
              children: <RFIs project={project} customer={customer} />,
            },
          ]}
        />
      ),
    },
    {
      key: 4,
      label: <span>Reports</span>,
      children: (
        <Tabs
          type="card"
          size="small"
          tabPosition="top"
          defaultActiveKey={0}
          items={[
            { key: 0, label: <span>Reports</span>, children: <div style={{ height: '100vh' }}>Reports Content</div> },
          ]}
        />
      ),
      // children: <div style={{ height: '100vh' }}>Reports</div>,
      // <Documents visible={selectedTab === 1} modeView={modeView} onCount={(value) => setDocumentsCount(value)} />,
    },
  ];

  const handleChangeProjectAndCustomerFlag = async projectFlag => {
    if (projectFlag === 'PROJECT' && customer.flag === 'LEAD') await updateCustomer({ ...customer, flag: 'CLIENT' });

    if (projectFlag === 'PROJECT' || projectFlag === 'REJECTED') {
      const status = projectFlag === 'PROJECT' ? 'OPEN' : 'COMPLETED';
      const projectUpdated = await updateProject({ ...project, flag: projectFlag, status: status });
      if (projectUpdated) {
        setProject(projectUpdated);
        history.push({
          pathname: '/bid-confirmation',
          state: { data: projectUpdated, customer: customer, flag: projectFlag },
        });
      }
    }
  };

  function getStyle(value) {
    switch (value) {
      case 'open':
        return style.status.open;
      case 'in_progress':
        return style.status.inProgress;
      case 'completed':
        return style.status.completed;
      case 'on_hold':
        return style.status.onHold;
      default:
        return style.status.new;
    }
  }

  const getBadge = status => {
    return (
      <Badge
        count={t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase()}
        style={getStyle(status.toLowerCase())}
      />
    );
  };

  const getTag = status => {
    return (
      <Tag style={getStyle(status.toLowerCase())}>
        {t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase()}
      </Tag>
    );
  };

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle
          title={project?.description}
          subtitle={t('menus:bidDetails')}
          tags={getBadge(project?.status)}
          extra={[
            <Popconfirm
              key={0}
              icon={false}
              title={t('messages:bid.confirmChangeProjectStatus')}
              onConfirm={() => {
                handleChangeProjectAndCustomerFlag('PROJECT');
              }}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <ButtonAntd key={1} type="primary" style={{ backgroundColor: '#22cc22', borderColor: '#22cc22' }}>
                Mark as won
              </ButtonAntd>
            </Popconfirm>,
            <Popconfirm
              key={2}
              icon={false}
              title={t('messages:bid.confirmChangeProjectStatus')}
              onConfirm={() => {
                handleChangeProjectAndCustomerFlag('REJECTED');
              }}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <ButtonAntd key={2} danger>
                Mark as lost
              </ButtonAntd>
            </Popconfirm>,
          ]}
        />
        <Tabs
          size="small"
          items={items}
          tabPosition="left"
          defaultActiveKey={selectedTab}
          onChange={onChangeTab}
          tabBarStyle={{ width: '150px' }}
        />
      </Box>
    </DefaultLayout>
  );
}
