import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Divider, Typography, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';
import errorHandler from 'Utils/errorHandler';
import axios from 'axios';
import { useSelector } from 'react-redux';

const initialValues = {
    showDiscount: true,
    showSalesTaxRate: true,
    showSalesTax: true,
    showOtherCost: true,
    showOthers: true,
    showShEstimated: true,
};

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Discount', 'Sales Tax Rate', 'Sales Tax', 'Other Cost', 'Others', 'S & H Estimated'];
const defaultCheckedList = [];


export default function PoDownloadModal({ visible, onClose, id }) {
    const { t } = useTranslation();
    const { Text, Paragraph } = Typography;
    const token = useSelector(state => state.auth.token);
    const [loading, setLoading] = useState(false);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (list) => {
        setCheckedList(list);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setCheckAll(e.target.checked);
    };

    const handleDownload = async () => {
        setLoading(true);
        try {
            if (id) {
                axios({
                    url: `${process.env.REACT_APP_BACKEND_URL}/purchaseOrders/reportPO/${id}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${token}`, },
                    responseType: 'blob',
                    data: checkedList,
                }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `PurchaseOrder.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    onClose();
                });
            }
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    useEffect(() => {
    }, [visible]);

    return (

        <Modal
            width="310px"
            title={t('screens:purchaseOrders.preferences')}
            onCancel={onClose}
            open={visible}
            loading={loading}
            footer={
                <ModalFooter onOk={handleDownload} loading={loading} onCancel={onClose} cancelColor="default" okText={'Download File'} />
            }
        >
            <Spin spinning={loading}>
                <Row>
                    <Checkbox indeterminate={false} onChange={onCheckAllChange} checked={checkAll}>Check all</Checkbox>
                    <Divider />
                    <CheckboxGroup style={{ width: '100%', }} value={checkedList} onChange={onChange}>
                        <Row>
                            <Col span={10}>
                                <Checkbox value="Discount">Discount</Checkbox>
                            </Col>
                            <Col span={14}>
                                <Checkbox value="Sales Tax">Sales Tax</Checkbox>
                            </Col>
                            <Col span={10}>
                                <Checkbox value="Other Cost">Other Cost</Checkbox>
                            </Col>
                            <Col span={14}>
                                <Checkbox value="Sales Tax Rate">Sales Tax Rate</Checkbox>
                            </Col>
                            <Col span={10}>
                                <Checkbox value="Others">Others</Checkbox>
                            </Col>
                            <Col span={14}>
                                <Checkbox value="S & H Estimated">S & H Estimated</Checkbox>
                            </Col>
                        </Row>
                    </CheckboxGroup>
                </Row>
            </Spin>
        </Modal>
    );
}