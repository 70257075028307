import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

import {
  DashboardOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
  FormOutlined,
  FileTextOutlined,
  AuditOutlined, 
} from '@ant-design/icons';

export default function SideMenu() {
  const { t } = useTranslation();
  const roleList = useSelector(state => (state.user.profile ? state.user.profile.roles : null));

  const checkAuth = roles => {
    var i = 0;
    for (i = 0; i < roles.length; i++) {
      let auth = false;
      if (roleList && !roleList[0].name.includes('ROLE_ADMIN') && !roleList[0].name.includes('ROLE_SUPER_ADMIN')) {
        if (roles[i] && roles[i] !== 'DEFAULT') {
          auth = roleList && roleList[0].name.includes(roles[i]);
        } else if (roles[i] === 'DEFAULT') {
          auth = true;
        } else {
          auth = false;
        }
      } else {
        auth = true;
        if (roles[i] === 'ROLE_SUBCONTRACTOR' || roles[i] === 'ROLE_CLIENT') auth = false;
      }
      return auth;
    }
  };

  const renderMenu = item => {
    const { type, key } = item;

    if (type === 'sub' && checkAuth(item.role)) {
      return {
        key,
        icon: item.icon,
        label: <Link to={item.url || '#'}>{item.label}</Link>,
        // label: <Link to={item.url}>{item.label}</Link>,
        children: item.items.map(subItem => renderMenu(subItem)),
      };
    } else if (type === 'sub-item' && checkAuth(item.role)) {
      return {
        key,
        label: <Link to={item.url || '#'}>{item.label}</Link>,
        // label: <Link to={item.url}>{item.label}</Link>,
      };
    } else if (checkAuth(item.role)) {
      return {
        key,
        icon: item.icon,
        label: <Link to={item.url || '#'}>{item.label}</Link>,
        // label: <Link to={item.url}>{item.label}</Link>,
        children: null,
      };
    }
    return null;
  };

  const sideMenuItems = [
    {
      type: 'item',
      key: 'dashboard',
      role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      label: t('menus:dashboard'),
      icon: <DashboardOutlined />,
      url: '/dashboard',
    },
    {
      type: 'sub',
      key: 'projects',
      role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      label: t('menus:projects'),
      icon: <ProjectOutlined />,
      items: [
        {
          type: 'sub-item',
          key: 'bids',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:bids'),
          url: '/bids',
        },
        {
          type: 'sub-item',
          key: 'clients',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:clients'),
          url: '/clients',
        },
        {
          type: 'sub-item',
          key: 'subContractors',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:subContractors'),
          url: '/subcontractor',
        },
      ],
    },

    {
      type: 'sub',
      key: 'settings',
      role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
      label: t('menus:settings'),
      icon: <SettingOutlined />,
      items: [
        {
          type: 'sub-item',
          key: 'users',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:users'),
          icon: <TeamOutlined />,
          url: '/users',
        },
        {
          type: 'sub-item',
          key: 'estimateDescription',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:estimateDescription'),
          icon: <TeamOutlined />,
          url: '/estimate-descriptions',
        },
        {
          type: 'sub-item',
          key: 'laborTypes',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:subcontractorsTrades'),
          icon: <TeamOutlined />,
          url: '/labor-types',
        },
        {
          type: 'sub-item',
          key: 'areas',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:area'),
          icon: <TeamOutlined />,
          url: '/areas',
        },
        {
          type: 'sub-item',
          key: 'other_settings',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:otherSettings'),
          icon: <SettingOutlined />,
          url: '/settings',
        },
        {
          type: 'sub-item',
          key: 'audit_log',
          role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
          label: t('menus:auditLog'),
          icon: <AuditOutlined />,
          url: '/audit-log',
        },
      ],
    },

    {
      type: 'item',
      key: 'quotations',
      role: ['ROLE_SUBCONTRACTOR'],
      label: t('menus:quotations'),
      icon: <FormOutlined />,
      url: 'subcontractor-quotations',
    },
    {
      type: 'item',
      key: 'contracts',
      role: ['ROLE_SUBCONTRACTOR'],
      label: t('menus:contracts'),
      icon: <FileTextOutlined />,
      url: 'subcontractor-contracts',
    },
    {
      type: 'item',
      key: 'closeOuts',
      role: ['ROLE_SUBCONTRACTOR'],
      label: t('menus:closeOuts'),
      icon: <FileTextOutlined />,
      url: 'subcontractor-closeouts',
    },
    {
      type: 'item',
      key: 'client-projects',
      role: ['ROLE_CLIENT'],
      label: t('menus:projects'),
      icon: <ProjectOutlined />,
      url: 'client-projects',
    },
  ];

  return (
    // the code cause Warning: [antd: Menu] `children` will be removed in next major version. Please use `items` instead.

    <Menu
      id="sideMenu"
      mode="inline"
      defaultSelectedKeys={['summary']}
      items={sideMenuItems.map(item => renderMenu(item))}
    />

    // new code use items instead of children to avoid warning message above but it cause error with our recursive function
    // <Menu id="sideMenu" mode="inline" theme="light" defaultSelectedKeys={['summary']} items={sideMenuItems} />
  );
}
