import { includes, some } from 'lodash';
import moment from 'moment';

export const { format: formatPrice } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const { format: formatPercent } = new Intl.NumberFormat('en-US', {
  style: 'percent',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const filterString = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export function normalizeString(str) {
  const strAccents = str.split('');
  const strAccentsOut = new Array();

  const strAccentsLen = strAccents.length;

  for (let y = 0; y < strAccentsLen; y++) {
    if (!some(['ä', 'ü', 'ö'], el => includes(strAccents[y], el))) {
      strAccentsOut[y] = strAccents[y].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }

  const newString = strAccentsOut.join('').replace('ß', 'ss');
  return newString;
}

export function dynamicKm(v) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/(\d)(\d{1})$/, '.$1$2'); //Coloca . antes dos 2 ultimos numeros
  return v;
}

export function dynamicCost(v) {
  v = v.replace(/\D/g, '');
  v = (v / 100).toFixed(2) + '';
  v = v.replace(',', '.');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return v;
}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getProjectStatusColor(status) {
  switch (status) {
    case 'open':
      return '#13C2C2';
    case 'in_progress':
      return '#FAAD14';
    case 'completed':
      return '#A0D911';
    case 'on_hold':
      return '#F5222D';
    default:
      return '#1890FF';
  }
};

export function getProjectPriorityColor(status) {
  switch (status) {
    case 'low':
      return '#52C41A';
    case 'medium':
      return '#FAAD14';
    case 'high':
      return '#FA522D';
    case 'emergency':
      return '#F5222D';
    default:
      return '#1890FF';
  }
};