import React, { useEffect, useState } from 'react';
import { MailOutlined, EyeOutlined, PaperClipOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button as ButtonAntd, Badge, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import errorHandler from '~/Utils/errorHandler'
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import Filter from './filter';
import { findAllInvoices, paginationInvoices, sendInvoice } from '~/services/hooks/invoices';
import moment from 'moment';
import { formatPrice } from '~/Utils';
import { useSelector } from 'react-redux';
import AttachFileForm from './form';
import Row from '~/components/Row';
import PaymentForm from './paymentForm';

export default function Invoices({ project }) {

  const { t } = useTranslation();
  const token = useSelector(state => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [payVisible, setPayVisible] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    /*const data = await paginationInvoices({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [...filters,
      { columnJoin: 'payAppClient', columnSecondJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' }],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);*/

    filters.push({
      columnJoin: 'payAppClient',
      columnSecondJoin: 'project',
      field: 'id',
      value: project.id,
      restriction: 'EQUAL',
    });
    const data = await findAllInvoices(filters);
    setTableData(data);
    setLoading(false);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const handleSendInvoice = async id => {
    await sendInvoice(id);
  };

  const showFile = (record) => {
    setLoading(true);
    try {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/invoices/generateInvoice/${record.id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        var win = window.open(url, '_blank');
        win.focus();
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  const viewPayment = selectedRowKeys => {
    let invoiceList = [];

    tableData.map(item => {
      const exists = selectedRowKeys.some(value => value === item.id);
      if (exists) {
        invoiceList.push(item);
      }
    });
    setSelectedInvoices(invoiceList);
    setPayVisible(true);
  };

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.paymentDate !== null,
      paymentDate: record.paymentDate,
    }),
  };

  useEffect(() => {
    if (project.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy, project]);

  const style = {
    btnNew: {
      btn: { marginLeft: 'auto', marginRight: '8px', marginBottom: '20px' },
    },
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btn: { padding: '0 5px' },
    paid: { backgroundColor: '#52c41a' },
    noPaid: { backgroundColor: '#d9d9d9', color: 'gray' },
  };

  const tableColumns = [
    {
      title: t('screens:invoices.data.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:invoices.data.date'),
      dataIndex: 'date',
      key: 'date',
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('YYYY/MM/DD');
      },
    },
    {
      title: t('screens:workbook.receipts.data.paymentDate'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      ellipsis: { showTitle: false },
      align: 'center',
      width: 120,
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('MM/DD/YYYY');
      },
    },
    {
      title: t('screens:workbook.receipts.data.status'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      width: 120,
      render: (text, record) =>
        record.paymentDate !== null ? (
          <Badge count={t('screens:workbook.receipts.data.paid')} style={style.paid} title={'PAID'} />
        ) : (
          <Badge count={t('screens:workbook.receipts.data.noPaid')} style={style.noPaid} title={'NO PAID'} />
        ),
    },
    {
      title: t('screens:invoices.data.actions'),
      key: 'actions',
      align: 'center',
      ellipsis: { showTitle: true, },
      width: 120,
      render: record => (
        <TableActions>
          <Button
            style={style.btn}
            title={t('screens:invoices.data.attachFile')}
            onClick={() => {
              console.log("attachments" + record.attachments);
              setSelectedRecord(record);
              setShowForm(true);
            }}>
            <PaperClipOutlined />
          </Button>
          <Button style={style.btn} title={t('messages:view')} loading={loading} onClick={() => showFile(record)}><EyeOutlined /></Button>
          <Popconfirm
            icon={false}
            title={t('messages:invoices.confirmSendCustomer')}
            onConfirm={() => handleSendInvoice(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button style={style.btn} title={t('screens:invoices.btnSendCustomer')} loading={loading}><MailOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const hasSelected = selectedRowKeys.length > 0;
  
  return (
    <>
    <Box>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Row justify="end">
          <ButtonAntd
            style={style.btnNew.btn}
            type="default"
            disabled={!hasSelected}
            icon={<CheckCircleOutlined />}
            onClick={() => viewPayment(selectedRowKeys)}
          >
            {t('screens:workbook.receipts.data.markAsPaid')}
          </ButtonAntd>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id"
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          rowSelection={rowSelection}
        />
        {/*<Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />*/}
        <AttachFileForm
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onUpdateItem={item => onUpdateItem(item)}
        />
        <PaymentForm
        selectedInvoices={selectedInvoices}
        visible={payVisible}
        onUpdateItem={items => onUpdateItem(items)}
        onClose={() => setPayVisible(false)}
      />
      </Box>
    </>
  );
}
