import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Popconfirm, message, Spin, Col, Breadcrumb, Badge } from 'antd';
import { Form, Select, Input } from 'formik-antd';
import { Formik } from 'formik';
import DefautLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import FormControl from '~/components/Form/FormControl';
import { Table, TableActions } from '~/components/Table';
import ProjectForm from './form';
import { BreadcrumbCustom } from '~/styles/global';


export default function ProjectsList() {
  const { Option } = Select;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const fetchRecords = async (filters) => {
    setLoading(true);
    try {
      const pagePaginationDTO = { page: 0, sortBy: 'description', filters: filters };
      const { data } = await api.post('/projects/pagination', pagePaginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async id => {
    setLoading(true);

    try {
      await api.delete(`/projects/delete/${id}`);
      fetchRecords(filters);
      message.success(t('messages:success'));
    } catch (error) {
      if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
        message.warning(t('messages:project:foreignKeyViolation'));
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const handleSearch = values => {
    setLoading(true);

    if (values.descriptionFilter === '0') {
      values.descriptionFilter = '';
    }

    const filter = [];
    try {
      if (values.descriptionFilter !== undefined && values.descriptionFilter !== null && values.descriptionFilter !== '') {
        filter.push({
          field: 'description',
          value: values.descriptionFilter,
          restriction: 'LIKE',
        });
      }

      setFilters(filter);
      fetchRecords(filter);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async page => {
    setLoading(true);
    try {
      const paginationDTO = { page: page - 1, sortBy: 'description', filters: filters };
      const { data } = await api.post('/projects/pagination', paginationDTO);

      const meta = {
        first: data.first,
        last: data.last,
        numberOfElements: data.numberOfElements,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber + 1,
        pageSize: data.pageable.pageSize,
      };

      setMeta(meta);
      setTableData(data.content);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords([]);
  }, [showForm]);

  const style = {
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
    btnClear: { marginLeft: 'auto', marginTop: 25 },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' }
    },
    status: {
      new: { backgroundColor: '#1890FF' },
      open: { backgroundColor: '#13C2C2' },
      inProgress: { backgroundColor: '#A0D911' },
      completed: { backgroundColor: '#FAAD14' },
      onHold: { backgroundColor: '#F5222D' }
    }
  }

  function getStyle(value) {
    switch (value) {
      case 'open':
        return (style.status.open);
      case 'in_progress':
        return (style.status.inProgress);
      case 'completed':
        return (style.status.completed);
      case 'on_hold':
        return (style.status.onHold);
      default:
        return (style.status.new);
    }
  }

  const projectStatus = [
    {
      id: 0,
      code: 'ALL',
      description: t('screens:status.all'),
    },
    {
        id: 1,
        code: 'NEW',
        description: t('screens:projects.data.status.new'),
    },
    {
        id: 2,
        code: 'OPEN',
        description: t('screens:projects.data.status.open'),
    },
    {
        id: 3,
        code: 'IN_PROGRESS',
        color: '',
        description: t('screens:projects.data.status.in_progress'),
    },
    {
        id: 4,
        code: 'COMPLETED',
        description: t('screens:projects.data.status.completed'),
    },
    {
        id: 5,
        code: 'ON_HOLD',
        description: t('screens:projects.data.status.on_hold'),
    },
  ];

  const tableColumns = [
    {
      title: t('screens:projects.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:projects.data.type'),
      dataIndex: 'typeDescription',
      key: 'typeDescription',
    },
    {
      title: t('screens:projects.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '100px',
      render: status =>
        <Badge
          count={(t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase())}
          style={getStyle(status.toLowerCase())} />
    },
    {
      title: t('screens:projects.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}><EditOutlined /></Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}><DeleteOutlined /></Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb =
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:dashboard')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projectList')}</Breadcrumb.Item>
    </BreadcrumbCustom>;

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:projectList')} style={style.pageTitle} />
      <Box>
        <Formik
          initialValues={{ descriptionFilter: '', statusFilter: 'All' }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {({ errors, submitForm, resetForm }) => (
            <Spin spinning={loading}>
              <Form>
                <Row>
                    <FormControl 
                      cols={{ xs: 24, sm: 12, md: 12, lg: 7, xl: 9 }}
                      error={errors.descriptionFilter}
                      field="descriptionFilter"
                      label={t('screens:projects.data.description')}>
                      <Input name="descriptionFilter" placeholder={t('fields:projects.description.placeholder')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }}
                      error={errors.statusFilter}
                      field="statusFilter"
                      label={t('screens:projects.status')}>
                      <Select name="statusFilter">
                        {projectStatus.map(item => {
                          return (
                            <Option key={item.id} value={item.code}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  <Button
                    color="default"
                    style={style.btnClear}
                    onClick={() => {
                      resetForm();
                      setFilters([]);
                      fetchRecords([]);
                    }}
                  >
                    {t('screens:dateSearch.clearButton')}
                  </Button>
                  <Button type="submit" color="primary" style={style.btnSubmit}>
                    <SearchOutlined />
                    {t('screens:dateSearch.button')}
                  </Button>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:projects.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />

        <Pagination
          onChange={value => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
      <ProjectForm id={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
    </DefautLayout>
  );
}
