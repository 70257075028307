import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  ApiOutlined,
  BulbOutlined,
  MailFilled,
  PhoneFilled,
  UserOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Popconfirm, Breadcrumb, Tag, Tooltip, Col, Row, Button as ButtonAntd } from 'antd';
import history from '~/services/history';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import CustomerForm from './form';
import { BreadcrumbCustom } from '~/styles/global';
import Filter from './filter';
import { paginationCustomers, deleteCustomer } from '~/services/hooks/customers';
import { store } from '~/store';

export default function Bids() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [sortBy, setSortBy] = useState('companyName');
  // const defaultFilter = { field: 'flag', value: 'LEAD', restriction: 'EQUAL' };
  const { company } = store.getState().user.profile;
  const defaultFilterCompany = { columnJoin: 'company', field: 'id', value: company.id, restriction: 'EQUAL' };
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationCustomers({
      page: page - 1,
      sortBy,
      filters: [...filters, defaultFilterCompany],
    });

    const filteredData = data.content.filter(customer => {
      const projects = customer.projects.filter(project => project.flag === 'BID');
      customer.projects = projects;
      return customer.flag === 'LEAD' || projects.length > 0;
    });

    setTableData(filteredData);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  const handleDelete = async id => {
    await deleteCustomer(id);
    fetchData(1);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const handleView = async id => {
    history.push(`/customers/${id}`);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const style = {
    contacts: { color: '#a1a1a1', marginRight: '8px' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const getProjectTag = (number, flag, record) => {
    if (number === 0) {
      return null;
    }
    const icon = flag === 'PROJECT' ? <BulbOutlined /> : <ApiOutlined />;
    const color = flag === 'PROJECT' ? 'blue' : flag === 'BID' ? 'green' : 'red';
    return (
      <Tooltip placement="topLeft" title={t('screens:leads.data.viewBids')}>
        <Tag
          icon={icon}
          color={color}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            history.push({
              pathname: '/bids-list',
              state: { data: record },
            })
          }
        >
          {number}
        </Tag>
      </Tooltip>
    );
  };

  const getTags = record => {
    if (record.projects === undefined) return null;
    let projectsNumber = 0;
    let bidsNumber = 0;
    let projectsArr = [];
    let bidsArr = [];
    let rejectedNumber = 0;
    let rejectedArr = [];

    // count number of flags projects and bids in projects list
    record.projects.forEach(project => {
      if (project.flag === 'PROJECT') {
        projectsNumber++;
        projectsArr.push(project.description);
      } else if (project.flag === 'BID') {
        bidsNumber++;
        bidsArr.push(project.description);
      } else {
        rejectedNumber++;
        rejectedArr.push(project.description);
      }
    });

    return (
      <>
        {getProjectTag(projectsNumber, 'PROJECT', record)}
        {getProjectTag(bidsNumber, 'BID', record)}
        {getProjectTag(rejectedNumber, 'REJECTED', record)}
      </>
    );
  };

  const tableColumns = [
    {
      title: t('screens:leads.data.companyName'),
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => (
        <>
          <ButtonAntd
            type="link"
            onClick={() =>
              history.push({
                pathname: '/bids-list',
                state: { data: record },
              })
            }
            title={t('messages:view')}
          >
            {text}
          </ButtonAntd>{' '}
          {record.flag === 'CLIENT' ? (
            <Tag color="blue" style={{ marginRight: '8px' }}>
              Client
            </Tag>
          ) : null}
        </>
      ),
    },
    {
      title: t('screens:customers.data.primaryContact'),
      dataIndex: 'customerContact',
      key: 'primaryContact',
      render: customerContact => (
        <>
          {customerContact !== null ? (
            <>
              <div>
                <UserOutlined style={style.contacts} />
                {customerContact.primaryContact.firstName + ' ' + customerContact.primaryContact.lastName}
              </div>
              {customerContact.primaryContact.mainPhone !== null && customerContact.primaryContact.mainPhone !== '' ? (
                <div>
                  <PhoneFilled style={style.contacts} />
                  {customerContact.primaryContact.mainPhone}
                </div>
              ) : (
                ''
              )}
              {customerContact.primaryContact.mainEmail !== null && customerContact.primaryContact.mainEmail !== '' ? (
                <div>
                  <MailFilled style={style.contacts} />
                  {customerContact.primaryContact.mainEmail}
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: t('screens:leads.data.secondaryContact'),
      dataIndex: 'customerContact',
      key: 'secondaryContact',
      render: customerContact => (
        <>
          {customerContact !== null && customerContact.secondaryContact !== null ? (
            <>
              <>
                <div>
                  <UserOutlined style={style.contacts} />
                  {customerContact.secondaryContact.firstName + ' ' + customerContact.secondaryContact.lastName}
                </div>
                {customerContact.secondaryContact.mainPhone !== null &&
                customerContact.secondaryContact.mainPhone !== '' ? (
                  <div>
                    <PhoneFilled style={style.contacts} />
                    {customerContact.secondaryContact.mainPhone}
                  </div>
                ) : (
                  ''
                )}
                {customerContact.secondaryContact.mainEmail !== null &&
                customerContact.secondaryContact.mainEmail !== '' ? (
                  <div>
                    <MailFilled style={style.contacts} />
                    {customerContact.secondaryContact.mainEmail}
                  </div>
                ) : (
                  ''
                )}
              </>
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: t('screens:leads.data.bids'),
      key: 'projects',
      render: record => getTags(record),
    },
    {
      title: t('screens:leads.data.actions'),
      key: 'actions',
      align: 'center',
      width: 150,
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Button
            onClick={() =>
              history.push({
                pathname: '/bids-list',
                state: { data: record },
              })
            }
            title={t('screens:leads.data.viewBids')}
          >
            <ApiOutlined />
          </Button>
          {/* <Button onClick={() => handleView(record.id)} title={t('messages:view')}>
            <EyeOutlined />
          </Button> */}
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:Bids')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );
  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:Bids')} style={style.pageTitle} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:leads.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
      <CustomerForm
        id={selectedRecord}
        visible={showForm}
        onClose={() => {
          setShowForm(false);
          setSelectedRecord(null);
          fetchData(1);
        }}
      />
    </DefautLayout>
  );
}
