import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

// GET ALL TAGS
export const findAllTags = async () => {
  try {
    const { data } = await api.post('/tags/findAll');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};