import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import SubContractorForm from './form';
import { downloadFile } from '~/services/hooks/files';
import Filter from './filter';
import { paginationSubVendors, deleteSubVendor } from '~/services/hooks/subVendors';
import { paginationProjects } from '~/services/hooks/projects';

export default function SubContractors() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [primaryContacts, setPrimaryContacts] = useState([]);
  const [sortBy, setSortBy] = useState('id'); // description
  const [sortDirection, setSortDirection] = useState('DESC');
  // const defaultFilter = { columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' };
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationSubVendors({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: filters, // [...filters, defaultFilter],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  useEffect(() => {
    const fetchProjects = async () => {
      const data = await paginationProjects({
        page: 0,
        maxSize: true,
        sortBy: 'description',
        filters: [
          { field: 'flag', value: 'PROJECT', restriction: 'EQUAL' },
          { field: 'status', value: 'COMPLETED', restriction: 'NOT_EQUAL' },
        ],
      });
      setProjects(data.content);
    };
    fetchProjects();
  }, []);

  // useEffect(() => {
  //   const fetchPrimaryContacts = async () => {
  //     const data = await getAllPrimaryContacts(projectId);
  //     setPrimaryContacts(data.content);
  //   };
  //   fetchPrimaryContacts();
  // }, []);

  const handleDelete = async id => {
    await deleteSubVendor(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:subContractors.data.companyName'),
      dataIndex: 'companyName',
      key: 'companyName',
    },
    // {
    //   title: t('screens:subContractors.data.divisions'),
    //   width: '16%',
    //   dataIndex: 'divisions',
    //   key: 'divisions',
    //   render: (record, text) =>
    //     record && record.length > 0 && Array.prototype.map.call(text.divisions, item => item).join(', '),
    // },
    {
      title: t('screens:subContractors.data.primary_contact'),
      width: '15%',
      dataIndex: 'contact',
      key: 'contactName',
      render: contact => `${contact?.firstName} ${contact?.lastName}`,
    },
    {
      title: t('screens:subContractors.data.contactAddress'),
      key: 'contact.address',
      width: '20%',
      render: text => {
        if (text.contact.address?.addressLineOne && text.contact.address?.city && text.contact.address?.state) {
          return `${text.contact.address?.addressLineOne}, ${text.contact.address?.city}, ${text.contact.address?.state}`;
        }
        return 'N/A';
      },
    },
    {
      title: t('screens:subContractors.data.contactWorkPhone'),
      key: 'contact.work_phone',
      width: '10%',
      render: record => {
        if (record.contact.workPhone) {
          return record.contact.workPhone;
        }
        return 'N/A';
      },
    },
    {
      title: t('screens:subContractors.data.actions'),
      key: 'actions',
      align: 'center',
      width: '100px',
      render: record => (
        <TableActions>
          {/*record?.liabilityInsurence?.id && (
            <Button
              onClick={async () => await downloadFile(record?.liabilityInsurence?.arquive)}
              title={t('messages:download')}
            >
              <DownloadOutlined />
            </Button>
          )*/}

          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:subContractors')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:SubContractors')} />
      <Filter primaryContacts={primaryContacts} projects={projects} isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:subContractors.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />

        <SubContractorForm
          selectedRecord={selectedRecord}
          visible={showForm}
          projects={projects}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </DefaultLayout>
  );
}
