import React, { useEffect, useState } from 'react';
import { DislikeOutlined, DownloadOutlined, LikeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import Filter from './filter';
import { approveProposal, paginationProposalClients } from '~/services/hooks/proposalClients';
import { updateProposalClient } from '~/services/hooks/proposalClients';
import { downloadFile } from '~/services/hooks/files';
import ModalSignatureCanvas from '~/components/ModalSignatureCanvas/ModalSignatureCanvas';
import errorHandler from '~/Utils/errorHandler';
import { Badge, Button as ButtonAntd, Popconfirm, Space } from 'antd';

export default function ProposalClients({ project }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showSignatureModal, setSignatureModal] = useState(false);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationProposalClients({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [...filters, { columnJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' }],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (project.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy, project]);

  const handleEditItem = async item => {
    await updateProposalClient(item);
    fetchData(1);
  };

  const handleDisapproveItem = async item => {
    item.disapproved = true;
    await updateProposalClient(item);
    fetchData(1);
  }

  const handleSignatureChange = async ({ name, jobRole, signatureResult, rubricResult, password }) => {
    try {
      const formData = new FormData();
      formData.append('proposalId', selectedRecord.id);
      formData.append('name', name);
      formData.append('jobRole', jobRole);
      formData.append('signature', signatureResult);
      formData.append('rubric', rubricResult);
      formData.append('password', password);

      await approveProposal(formData);

      fetchData(1);

      setSignatureModal(false);
      setFilters([]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  }

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    approved: { backgroundColor: '#52c41a' },
    disapproved: { backgroundColor: '#f5222d' }
  };

  const tableColumns = [
    {
      title: t('screens:proposalSubcontractor.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      ellipsis: { showTitle: true, },
    },
    {
      title: t('screens:proposalSubcontractor.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      sorter: true,
      ellipsis: { showTitle: true, },
    },
    {
      title: t('screens:contracts.data.status'),
      dataIndex: 'proposalSignature',
      key: 'proposalSignature',
      align: 'center',
      width: 130,
      render: (text, record) => (
        record.disapproved === true ?
          (<Badge count={t('screens:proposalClient.data.disapproved')} style={style.disapproved} title={'DISAPPROVED'} />) :
          record.proposalSignature !== null ?
            (<Badge count={t('screens:proposalClient.data.approved')} style={style.approved} title={'APPROVED'} />) :
            (<Space>
              <ButtonAntd
                type="primary"
                icon={<LikeOutlined />}
                size='small'
                title={t('Approve the proposal')}
                onClick={() => {
                  setSelectedRecord(record);
                  setSignatureModal(true);
                }} />
              <Popconfirm
                key={0}
                icon={false}
                title={t('messages:confirmProposalClientDisapprove')}
                onConfirm={() => {
                  handleDisapproveItem(record);
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <ButtonAntd danger type="primary" icon={<DislikeOutlined />} size='small' title={t('Disapproved the proposal')} />
              </Popconfirm>
            </Space>)
      ),
    },
    {
      title: t('screens:proposalSubcontractor.actions'),
      key: 'actions',
      align: 'center',
      width: 85,
      ellipsis: { showTitle: true, },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={async () => await downloadFile(record.arquive)} title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
        </TableActions>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />

        <ModalSignatureCanvas
          record={selectedRecord}
          visible={showSignatureModal}
          loading={loading}
          canvasProps={{ width: 450, height: 80, className: 'sigCanvas' }}
          onSave={handleSignatureChange}
          onLoad={() => { setLoading(true) }}
          onClose={() => {
            setSignatureModal(false);
            setFilters([]);
          }}
        />
      </Box>
    </>
  );
}
