import React, { useEffect } from 'react';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { BreadcrumbCustom } from '~/styles/global';

export default function PoPayments() {
  const { t } = useTranslation();

  useEffect(() => {
    
  }, []);

  const breadcrumb = 
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:financial')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:poPayments')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  ;

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle title={t('menus:poPayments')} />
      </Box>
    </DefautLayout>
  );
}
