import styled from 'styled-components';
import { Divider } from 'antd';

export const DividerCustom = styled(Divider)`
    width: 4px;
    height: 100%;
    margin-left: 0px;
    border-radius: 7px;
    background-color: ${props => props.theme.color1};
`;

export const TotalValue = styled.h1`
  margin-left: auto;
  margin-top: 10px;
  font-size: 18pt;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  width: 100%;
  margin-bottom: 12px;

  &.error {
    input {
      background-color: rgba(255, 0, 0, 0.2);
      &:hover {
        border: 1px solid #e34b4b;
      }
    }

    & > span.ant-input-affix-wrapper-sm,
    & > span.ant-input-affix-wrapper-md,
    & > span.ant-input-affix-wrapper-lg {
      background-color: rgba(255, 0, 0, 0.2);
      &:hover {
        border: 1px solid #e34b4b;
      }

      input {
        background-color: transparent;
        &:hover {
          border: none;
        }
      }
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-switch {
    width: max-content;
    margin-top: 5px;
  }

  > label {
    display: flex;
    margin-bottom: 5px;
    color: ${props => props.theme.color1};

    .field-required {
      margin-left: 3px;
    }
  }
`;

export const TableTask = styled.table`
  // background-color: #ff0;
  width: 100%;
  tr {
    th {
      white-space: nowrap;
      text-align: left;
      padding: 2px;
    }

    th.title, td.title{
      font-size: 11pt;
      text-transform: uppercase;
      font-weight: 400;
      color: #999;
    }

    td {
      white-space: nowrap;
      text-align: left;
      padding: 2px 10px 2px 10px;
    }

    td.icon {
      white-space: nowrap;
      width: 30px;
      text-align: center;
      padding-right: 1px;
    }

    td.icon-text {
      padding-left: 1px;
      width: 10px;
    }

    td.principal {
      font-size: 15pt;
      font-weight: 400;
    }

    td.notes {
      white-space: normal;
      text-align: left;
    }

    td.avatar {
      white-space: nowrap;
      width: 64px;
      height: 64px;
      margin 0;
      padding: 0;
    }
  }
  `;
