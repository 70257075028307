import React, { useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { Layout, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import qs from 'qs';
import api from '~/services/api';
import { Container, Box, Copyright } from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import errorHandler from 'Utils/errorHandler';
import { CopyrightOutlined, MailOutlined } from '@ant-design/icons';
import FooterCustom from '~/components/Footer';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid e-mail')
    .required('Required field'),
});

export default function Remember() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRemember = async values => {
    setLoading(true);
    try {
      var data = qs.stringify({
        grant_type: 'password',
        username: values.email + '/resetPassword',
        password: 'A6Xz4RH75y',
      });

      var client_id = 'client';
      var client_secret = '123';
      var basicAuth = 'Basic ' + btoa(client_id + ':' + client_secret);

      const response = await api.post('/oauth/token', data, { headers: { Authorization: basicAuth } });

      const data2 = {
        to: values.email,
        token: response.data.access_token,
      };

      await api.post('/mail/sendResetPassword', data2, {
        headers: { Authorization: `Bearer ${response.data.access_token}` },
      });

      message.success(t('messages:successNewPassword'));
    } catch (error) {
      const { response } = error;
      if (response.data) {
        if (response.data.error === 'invalid_grant') {
          message.error(t('messages:emailInvalid'));
        } else {
          errorHandler(error);
        }
      }
    }
    setLoading(false);
  };

  const currentYear = new Date().getFullYear();
  const myStyleLayout = {
    height: '100%',
    background: 'linear-gradient(90deg, #071d3d 0%, #0152a2 50%, #012a53 100%)'
  }

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik 
          initialValues={{}}
          onSubmit={handleRemember}
          validationSchema={schema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Container>
              <Logo title={true} width="100%" height="100%" margin="auto" marginTop="10px" marginBottom="30px" forgetPassword={true} />
              <FormControl field="email" error={touched.email && errors.email}>
                <Input 
                  type="email"
                  name="email"
                  size="small"
                  prefix={<MailOutlined />}
                  placeholder={t('fields:remember.email.placeholder')} />
              </FormControl>
              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('fields:remember.submit')}
              </Button>
              <Link to="/">{t('fields:remember.back')}</Link>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
          {` `}
          <span>{t('fields:login.copyright.copyright')}</span>
          {` `}<CopyrightOutlined />{` ${currentYear} | `}
          <span>{t('fields:login.copyright.produced')}</span>
      </Copyright>
      <FooterCustom/>
    </Layout>
  );
}
