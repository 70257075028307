import React from 'react';
import { Col, Modal, Spin, Upload, Divider, message, Typography } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import FormTxt from '~/components/Form/FormTxt';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import Button from '~/components/Button';
import { createSpecBookSubmittial, updateSpecBookSubmittial } from '~/services/hooks/specBookSubmittials';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function SpecBookForm({ project, user, selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const initialValues = {
    id: null,
    type: 'SPEC_BOOK',
    description: '',
    replied: false,
    reply: null,
    project: project,
    sentBy: user,
    arquive: null,
  };
  const { Option } = Select;
  const { Text } = Typography;
  const [uploadList, setUploadList] = useState([]);
  const specBook = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    setLoading(true);
    if (values.id !== null) {
      const data = await updateSpecBookSubmittial(values);
      onUpdateItem(data);
    } else {
      if (uploadList.length < 1) {
        message.error(t('messages:uploadFileRequired'));
        setLoading(false);
        return;
      }
      const data = await createSpecBookSubmittial(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const specBookSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  return (
    <Formik initialValues={specBook} enableReinitialize onSubmit={handleSave} validationSchema={specBookSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue, touched }) => (
        <Modal
          width="650px"
          title={values.id !== null ? t('screens:specBook.btnEdit') : t('screens:specBook.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:specBook.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              {values.id !== null && (
                <Row>
                  <Col span={24}>
                    <FormControl
                      field="type"
                      label={t('screens:specBook.data.type')}
                      error={(touched.type && errors.type) || errors.type}
                    >
                      <Select name="type" disabled={
                        user.roles.map(role => role.name).includes('ROLE_ADMIN') ||
                        user.roles.map(role => role.name).includes('ROLE_SUPER_ADMIN') ? false : true
                        } >
                        <Option value="SPEC_BOOK">SPEC BOOK</Option>
                        <Option value="SUBMITTIAL">SUBMITTIAL</Option>
                        <Option value="BOTH">BOTH</Option>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <FormControl field="specBook_file">
                    <UploadFile uploadList={uploadList} setUploadList={setUploadList} file={specBook?.arquive} />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
