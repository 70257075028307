import React, { useState, useEffect } from 'react';
import { Spin, message as AntdMessage, Popconfirm, Typography } from 'antd';
import PropTypes from 'prop-types';
import { FaRegTrashAlt } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '~/store/modules/user/actions';
import { ImagePreview, UploadStyle, Container } from './styles';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

export default function ImageUpload({ icon, text, upUrl, delUrl, size, image, postUpload, postDelete, message, disabled }) {
  const { Text } = Typography;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const { token } = useSelector(state => state.auth);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = file => {
    const isValid =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf';
    if (!isValid) {
      AntdMessage.error(t('messages:userImageTypeError'));
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      AntdMessage.error(t('messages:userImageSizeError'));
    }
    return isValid && isLt2M;
  };

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      handleUpload(info);
      getBase64(info.file.originFileObj, image => {
        setImageUrl(image);
        if (postUpload) postUpload();
        setLoading(false);
      });
    }
  };

  const handleUpload = async info => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/find');
      delete data.roleDescription;
      data.image = `${process.env.REACT_APP_BACKEND_UPLOAD_URL}/${info.file.response.name}`;
      await api.put('/users/update', data);
      dispatch(updateProfile(data));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const uploadButton = <>{icon}</>;

  const handleDelete = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/find');
      data.image = null;
      delete data.roleDescription;
      await api.put('/users/update', data);
      dispatch(updateProfile(data));
      setImageUrl(null);
      postDelete();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setImageUrl(image);
  }, [image]);

  const uploadConfig = {
    name: 'file',
    action: upUrl,
    listType: 'picture-card',
    showUploadList: false,
    beforeUpload: beforeUpload,
    onChange: handleChange,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  return (
    <>
      <UploadStyle />
      {imageUrl ? (
        <ImagePreview className="img-preview" size={size} background={imageUrl}>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            okText={t('messages:yes')}
            onConfirm={() => handleDelete()}
            cancelText={t('messages:no')}
            disabled={disabled}
          >
            <div className="img-preview-wrapper">
              <div className="img-preview-delete">
                {t('messages:delete')}
                <FaRegTrashAlt color={'red'} />
              </div>
              <div className="img-preview-file"></div>
            </div>
          </Popconfirm>
        </ImagePreview>
      ) : (
        <Container {...uploadConfig} size={size} disabled={disabled}>
          <Spin spinning={loading}>{imageUrl || uploadButton}</Spin>
        </Container>
      )}
      <Text style={{textAlign: 'center'}}>{message}</Text>
    </>
  );
}

ImageUpload.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  upUrl: PropTypes.string.isRequired,
  delUrl: PropTypes.string.isRequired,
  size: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
};

ImageUpload.defaultProps = {
  icon: <AiOutlineUser size={100} />,
  text: null,
  size: '100px',
  message: null,
  disabled: false,
};
