import { Button as ButtonAnt, Card, Row, Space, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from '~/Utils/errorHandler';
import { Table, TableActions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import { DownloadOutlined, FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import { ColCustom, LoadingMore } from '../styles';

export default function Files({ visible, modeView, onCount }) {
    const { t } = useTranslation();
    const { Text } = Typography;
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [listData, setListData] = useState([]);
    const [initLoading, setInitLoading] = useState(true);
    const [page, setPage] = useState(0);
    const token = useSelector(state => state.auth.token);

    const fetchRecords = async () => {
        try {
            /*const pagePaginationDTO = { page: 0, sortBy: 'name', filters: [] };
            const { data } = await api.post('/arquives/pagination', pagePaginationDTO);
      
            const meta = {
              first: data.first,
              last: data.last,
              numberOfElements: data.numberOfElements,
              totalElements: data.totalElements,
              pageNumber: data.pageable.pageNumber,
              pageSize: data.pageable.pageSize,
            };
      
            setMeta(meta);
            setTableData(data.content);*/
            const meta = {
                first: true,
                last: true,
                numberOfElements: 6,
                totalElements: 8,
                pageNumber: 1,
                pageSize: 6,
            };

            let content = [
                { id: 1, originalName: 'User.png', name: '9746459c-55f3-49f7-aaa7-f1e256317532.png', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.png', contentType: 'image/png' },
                { id: 2, originalName: 'Licence.pdf', name: '1f840319-984d-4c36-8471-2d81508614fe.pdf', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.pdf', contentType: 'application/pdf' },
                { id: 3, originalName: 'Licence.csv', name: '1f840319-984d-4c36-8471-2d81508614fe.csv', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.csv', contentType: 'text/csv' },
                { id: 4, originalName: 'Licence.docx', name: '1f840319-984d-4c36-8471-2d81508614fe.docx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.docx', contentType: 'application/msword' },
                { id: 5, originalName: 'Licence.xlsx', name: '1f840319-984d-4c36-8471-2d81508614fe.xlsx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.xlsx', contentType: 'application/vnd.ms-excel' },
                { id: 6, originalName: 'Licence.xlsx', name: '1f840319-984d-4c36-8471-2d81508614fe.xlsx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.xlsx', contentType: 'application/vnd.ms-excel' },
            ];

            setMeta(meta);
            setTableData(content);
            setListData(content);
            onCount(meta.totalElements);
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleChangePage = async page => {
        setLoading(true);
        try {
            /*const paginationDTO = { page: page - 1, sortBy: 'name', filters: [] };
            const { data } = await api.post('/arquives/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/

            const meta = {
                first: true,
                last: true,
                numberOfElements: 2,
                totalElements: 8,
                pageNumber: 2,
                pageSize: 6,
            };

            const res = [
                { id: 7, originalName: 'Licence.docx', name: '1f840319-984d-4c36-8471-2d81508614fe.docx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.docx', contentType: 'application/msword' },
                { id: 8, originalName: 'Licence.xlsx', name: '1f840319-984d-4c36-8471-2d81508614fe.xlsx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.xlsx', contentType: 'application/vnd.ms-excel' },
            ];

            setMeta(meta);
            setTableData(res);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleDownload = async (file) => {
        axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.name}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.originalName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const onLoadMore = () => {
        setLoading(true);
        /*const paginationDTO = { page: page, sortBy: 'name', filters: [] };
        const { data } = await api.post('/projects/pagination', paginationDTO);

        const meta = {
            first: data.first,
            last: data.last,
            numberOfElements: data.numberOfElements,
            totalElements: data.totalElements,
            pageNumber: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
        };

        setMeta(meta);
        setTableData(data.content);*/
        const meta = {
            first: true,
            last: true,
            numberOfElements: 2,
            totalElements: 8,
            pageNumber: 2,
            pageSize: 6,
        };


        const res = [
            { id: 7, originalName: 'Licence.docx', name: '1f840319-984d-4c36-8471-2d81508614fe.docx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.docx', contentType: 'application/msword' },
            { id: 8, originalName: 'Licence.xlsx', name: '1f840319-984d-4c36-8471-2d81508614fe.xlsx', path: 'https://uploads.jmc.voxtell.app/9746459c-55f3-49f7-aaa7-f1e256317532.xlsx', contentType: 'application/vnd.ms-excel' },
        ];
        const newData = listData.concat(res);
        setListData(newData);
        setPage(page + 1);
        setLoading(false);
        window.dispatchEvent(new Event('resize'));
    };

    useEffect(() => {
        if (visible) {
            setPage(0);
            setInitLoading(false);
            fetchRecords();
        }
    }, [visible]);

    const style = {
        file: { cardBody: { paddingLeft: '0px' } },
    };

    const tableColumns = [
        {
            title: t('screens:files.data.file'),
            dataIndex: 'originalName',
            key: 'originalName',
            render: (text, record) => (
                <Space>
                    {getIcon(record)}
                    <Text>{record.originalName}</Text>
                </Space>
            ),
        },
        {
            title: t('screens:files.data.actions'),
            key: 'actions',
            align: 'center',
            width: '10%',
            className: 'table-action',
            render: (text, record) => (
                <TableActions>
                    <Button onClick={() => handleDownload(record)} title={t('messages:download')}><DownloadOutlined /></Button>
                </TableActions>
            ),
        },
    ];

    function getIcon(file) {
        switch (file.contentType) {
            case 'image/png':
            case 'image/jpeg':
                return <FileImageOutlined />;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <FileWordOutlined />;
            case 'application/pdf':
                return <FilePdfOutlined />;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <FileExcelOutlined />;
            default:
                return <FileOutlined />;
        }
    }

    const loadMore = !initLoading && !loading ? (<LoadingMore><ButtonAnt onClick={onLoadMore}>{t('screens:customers.loadingMore')}</ButtonAnt></LoadingMore>) : null;

    return (
        <>
            {modeView === 'list' ?
                <>
                    <Table
                        bordered
                        size='small'
                        rowKey="id"
                        pagination={false}
                        loading={loading}
                        columns={tableColumns}
                        dataSource={tableData}
                    />

                    <Pagination
                        onChange={value => {
                            handleChangePage(value);
                        }}
                        meta={meta}
                    />
                </>
                :
                <>
                    <Row>
                        {listData.map(file =>
                            <>
                                <ColCustom xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Card size='small' bodyStyle={style.file.cardBody}>
                                        <Row>
                                            <ul style={{ width: '100%', margin: '0px' }}>
                                                <li style={{ display: 'inline-block', paddingTop: '5px' }}>
                                                    <span style={{ paddingLeft: '5px' }}>{getIcon(file)}<Text>{file.originalName}</Text></span>
                                                </li>
                                                <li style={{ display: 'inline-block', float: 'right' }}>
                                                    <TableActions>
                                                        <Button style={{ padding: '0' }} onClick={() => handleDownload(file)} title={t('messages:download')}><DownloadOutlined /></Button>
                                                    </TableActions>
                                                </li>
                                            </ul>
                                        </Row>
                                    </Card>
                                </ColCustom>
                            </>
                        )}
                    </Row>
                    {loadMore}
                </>
            }
        </>
    );
}