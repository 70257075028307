import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import Filter from './filter';
import { paginationCloseOutFiles, deleteCloseOutFile, updateCloseOutFile } from '~/services/hooks/closeoutFiles';
import { downloadFile } from '~/services/hooks/files';
import moment from 'moment';
import CloseOutFileForm from './form';
import { useSelector } from 'react-redux';

export default function Warranty({ project, customer }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const [subVendors, setSubVendors] = useState([]);
  const user = useSelector(state => state.user.profile);


  const fetchData = async page => {
    setLoading(true);
    const data = await paginationCloseOutFiles({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [
        ...filters,
        { columnJoin: 'project', field: 'id', value: project?.id, restriction: 'EQUAL' },
        { field: 'show', value: true, restriction: 'EQUAL' },
      ],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (project.id !== null) {
      fetchData(1);
    }
  }, [filters, sortBy, project]);

  useEffect(() => {
    if (tableData.length > 0 && subVendors.length === 0) {
      const subVendors = tableData.map(item => item.subVendor);
      const uniqueSubVendors = [...new Set(subVendors.filter(item => item !== null))];
      setSubVendors(uniqueSubVendors);
    }
  }, [tableData]);

  const handleDelete = async id => {
    await deleteCloseOutFile(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:closeout.files.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: true,
      width: 80,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:closeout.files.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:closeout.subcontractor.companyName'),
      key: 'companyName',
      align: 'left',
      width: 300,
      ellipsis: { showTitle: true },
      render: record => {
        if (!record.subVendor) return user?.company?.name;
        return record.subVendor.companyName;
      },
    },
    {
      title: t('screens:closeout.files.createDate'),
      dataIndex: 'audit',
      key: 'audit.createDate',
      align: 'left',
      width: 200,
      sorter: true,
      ellipsis: { showTitle: true },
      render: audit => <>{moment(audit.createdDate).format('YYYY/MM/DD HH:MM:ss')}</>,
    },
    {
      title: t('screens:closeout.files.showToCustomer'),
      key: 'show',
      align: 'left',
      width: 150,
      sorter: true,
      ellipsis: { showTitle: true },
      render: record => {
        return <Checkbox checked={record.show}
        onChange={async (e) => {
          await updateCloseOutFile({ ...record, show: e.target.checked });
          onUpdateItem({ ...record, show: e.target.checked });
        }}
         />;
      }
    },
    {
      title: t('screens:closeout.files.actions'),
      key: 'actions',
      align: 'center',
      width: 150,
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          {record.archive && (
            <Button onClick={async () => await downloadFile(record.archive)} title={t('messages:download')}>
              <DownloadOutlined />
            </Button>
          )}
          {/* <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button> */}
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <>
      <Filter isLoading={loading} setFilters={setFilters} user={user}  subVendors={subVendors} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:closeout.files.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        <CloseOutFileForm
          project={project}
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </>
  );
}
