import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';

const endPoint = 'payApps';

// FIND ALL BY PROJECT
export const findAllByProject = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/findAllByProject`, {
      params: {
        ...params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL ALL PAY APP ITEM
export const findAllPayAppItem = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/findAllPayAppItem`, {
      params: {
        ...params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

findAllByProject.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllPayAppItem.propTypes = {
  params: PropTypes.object.isRequired,
};
