import { Button as ButtonAnt, Card, Image, Row, Space, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from '~/Utils/errorHandler';
import { Table, TableActions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import { ColCustom, LoadingMore } from '../styles';

export default function Photos({ visible, modeView, onCount }) {
    const { t } = useTranslation();
    const { Text } = Typography;
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [listData, setListData] = useState([]);
    const [initLoading, setInitLoading] = useState(true);
    const [page, setPage] = useState(0);
    const token = useSelector(state => state.auth.token);

    const fetchRecords = async () => {
        try {
            /*const pagePaginationDTO = { page: 0, sortBy: 'name', filters: [] };
            const { data } = await api.post('/arquives/pagination', pagePaginationDTO);

            const meta = {
              first: data.first,
              last: data.last,
              numberOfElements: data.numberOfElements,
              totalElements: data.totalElements,
              pageNumber: data.pageable.pageNumber,
              pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/
            const meta = {
                first: true,
                last: true,
                numberOfElements: 6,
                totalElements: 8,
                pageNumber: 1,
                pageSize: 6,
            };

            let content = [
                { id: 1, originalName: 'User1.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
                { id: 2, originalName: 'User2.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
                { id: 3, originalName: 'User3.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
                { id: 4, originalName: 'User4.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
                { id: 5, originalName: 'User5.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
                { id: 6, originalName: 'User6.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
            ];

            setMeta(meta);
            setTableData(content);
            setListData(content);
            onCount(meta.totalElements);
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleChangePage = async page => {
        setLoading(true);
        try {
            /*const paginationDTO = { page: page - 1, sortBy: 'name', filters: [] };
            const { data } = await api.post('/arquives/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/

            const meta = {
                first: true,
                last: true,
                numberOfElements: 2,
                totalElements: 8,
                pageNumber: 2,
                pageSize: 6,
            };


            const res = [
                { id: 7, originalName: 'User7.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
                { id: 8, originalName: 'User8.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
            ];

            setMeta(meta);
            setTableData(res);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleDownload = async (file) => {
        axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/downloadFile/${file.name}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.originalName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const onLoadMore = () => {
        setLoading(true);
        /*const paginationDTO = { page: page, sortBy: 'name', filters: [] };
        const { data } = await api.post('/arquives/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);*/
        const meta = {
            first: true,
            last: true,
            numberOfElements: 2,
            totalElements: 8,
            pageNumber: 2,
            pageSize: 6,
        };


        const res = [
            { id: 7, originalName: 'User7.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
            { id: 8, originalName: 'User8.png', name: '3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', path: 'https://uploads.stg.tanksbr.com.br/3a76060b-b40b-4f21-92a1-85c5d593dce5.jpg', contentType: 'image/png' },
        ];
        const newData = listData.concat(res);
        setListData(newData);
        setPage(page + 1);
        setLoading(false);
        window.dispatchEvent(new Event('resize'));
    };

    useEffect(() => {
        if (visible) {
            setPage(0);
            setInitLoading(false);
            fetchRecords();
        }
    }, [visible]);

    const tableColumns = [
        {
            title: t('screens:files.data.file'),
            dataIndex: 'originalName',
            key: 'originalName',
            render: (text, record) => (
                <Space>
                    <Image width={20} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
                    <Text>{record.originalName}</Text>
                </Space>
            ),
        },
        {
            title: t('screens:files.data.actions'),
            key: 'actions',
            align: 'center',
            width: '10%',
            className: 'table-action',
            render: (text, record) => (
                <TableActions>
                    <Button onClick={() => handleDownload(record)} title={t('messages:download')}><DownloadOutlined /></Button>
                </TableActions>
            ),
        },
    ];

    const loadMore = !initLoading && !loading ? (<LoadingMore><ButtonAnt onClick={onLoadMore}>{t('screens:leads.loadingMore')}</ButtonAnt></LoadingMore>) : null;

    return (
        <>
            {modeView === 'list' ?
                <>
                    <Table
                        bordered
                        size='small'
                        rowKey="id"
                        pagination={false}
                        loading={loading}
                        columns={tableColumns}
                        dataSource={tableData}
                    />

                    <Pagination
                        onChange={value => {
                            handleChangePage(value);
                        }}
                        meta={meta}
                    />
                </>
                :
                <>
                    <Row>
                        {listData.map(file =>
                            <>
                                <ColCustom xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Card size='small' hoverable style={{ width: '100%' }} cover={<img alt="photo" src={file.path} />}>
                                        <Card.Meta description={file.originalName} />
                                    </Card>
                                </ColCustom>
                            </>
                        )}
                    </Row>
                    {loadMore}
                </>
            }
        </>
    );
}