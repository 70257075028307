import React, { useState, useEffect } from 'react';
import { Col, Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { paginationEstimates } from '~/services/hooks/estimates';
import {
  createSpecBookSubmittial,
  updateSpecBookSubmittial,
  sendNoticeOfSubmittial,
} from '~/services/hooks/specBookSubmittials';
import { paginationUsers } from '~/services/hooks/users';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

export default function SubmittialForm({ project, user, selectedRecord, isReply, visible, onClose }) {
  const initialValues = {
    id: null,
    type: 'SUBMITTIAL',
    description: '',
    reply: null,
    project: project,
    sentBy: user,
    arquive: null,
    users: [],
  };

  // remove replies object from selectedRecord object
  if (selectedRecord?.replies) delete selectedRecord.replies;

  const { Option } = Select;
  const [uploadList, setUploadList] = useState([]);
  const submittial = isReply ? initialValues : { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(selectedRecord?.users || []);
  const [estimateData, setEstimateData] = useState(null);

  const fetchDataEstimate = async () => {
    setLoading(true);
    const data = await paginationEstimates({
      page: 0,
      maxSize: true,
      filters: [{ columnJoin: 'project', field: 'id', value: project.id, restriction: 'EQUAL' }],
    });
    if (data.numberOfElements === 0) {
      setEstimateData({});
    } else {
      setEstimateData(data.content[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataEstimate();
  }, []);

  useEffect(() => {
    async function loadUsers() {
      const { content } = await paginationUsers({
        page: 0,
        maxSize: true,
        filters: [
          {
            columnJoin: 'roles',
            field: 'name',
            value: 'ROLE_ADMIN',
            restriction: 'NOT_EQUAL',
          },
        ],
      });
      setUsers(content);
    }
    loadUsers();
  }, []);

  const sendNotifications = async values => {
    if (values?.users?.length === 0) return null;
    if (estimateData?.id === undefined) return null;
    // users that will receive the notification is equal to users - selectedUsers, because selectedUsers already received the notification
    const newUsersSelected = values.users.filter(user => !selectedUsers.includes(user));
    if (newUsersSelected.length === 0) return null;
    delete values.users;
    const objectNotification = {
      estimateId: estimateData.id,
      specBookSubmittial: values,
      users: newUsersSelected,
    };
    await sendNoticeOfSubmittial(objectNotification);
    return;
  };

  const createReply = async values => {
    if (uploadList.length < 1) {
      message.error(t('messages:uploadFileRequired'));
      setLoading(false);
      return;
    }
    selectedRecord.replied = true;
    values.reply = selectedRecord;
    setLoading(true);
    const data = await uploadFiles(uploadList);
    values.arquive = data[0];
    await createSpecBookSubmittial(values); // create reply
    await updateSpecBookSubmittial(selectedRecord); // update replied submittial to replied = true
    await sendNotifications(values);
    setLoading(false);
    onClose();
  };

  const createSubmittial = async values => {
    if (uploadList.length < 1) {
      message.error(t('messages:uploadFileRequired'));
      setLoading(false);
      return;
    }
    setLoading(true);
    const data = await uploadFiles(uploadList);
    values.arquive = data[0];
    await createSpecBookSubmittial(values);
    await sendNotifications(values);
    setLoading(false);
    onClose();
  };

  const updateSubmittial = async values => {
    setLoading(true);
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    await updateSpecBookSubmittial(values);
    await sendNotifications(values);
    setLoading(false);
    onClose();
  };

  const handleSave = async values => {
    delete values.undefined; // delete 'undefined' from values

    if (isReply) await createReply(values);
    else {
      if (values.id !== null) await updateSubmittial(values);
      else await createSubmittial(values);
    }
  };

  const submittialSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  const handleTitle = () => {
    if (isReply) return t('screens:submittial.isReplyTitle');
    if (submittial.id !== null) return t('screens:submittial.btnEdit');
    return t('screens:submittial.btnNew');
  };

  return (
    <Formik
      initialValues={submittial}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={submittialSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue, touched }) => (
        <Modal
          width="650px"
          title={handleTitle()}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    field="description"
                    label={t('screens:submittial.data.description')}
                    required
                    error={errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
              </Row>
              {/* SENT TO USERS */}
              {!isReply && (
                <Row>
                  <Col span={24}>
                    <FormControl field="users" error={errors.users} label={t('screens:subContractors.data.users')}>
                      <Select
                        mode="multiple"
                        showArrow
                        style={{ width: '100%' }}
                        placeholder={t('messages:select')}
                        onChange={value => {
                          const selectedUsers = users.filter(item => value.includes(item.id));
                          setFieldValue('users', selectedUsers);
                        }}
                        value={values?.users?.map(item => item?.id) || []}
                      >
                        {users?.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {`${item?.name} - ${item?.email} - ${item.company?.name} (${item?.roleDescription})`}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              )}
              {values.id !== null && (
                <Row>
                  <Col span={24}>
                    <FormControl
                      field="type"
                      label={t('screens:submittial.data.type')}
                      error={(touched.type && errors.type) || errors.type}
                    >
                      <Select
                        name="type"
                        disabled={
                          isReply
                            ? true
                            : user.roles.map(role => role.name).includes('ROLE_ADMIN') ||
                              user.roles.map(role => role.name).includes('ROLE_SUPER_ADMIN')
                            ? false
                            : true
                        }
                      >
                        <Option value="SPEC_BOOK">SPEC BOOK</Option>
                        <Option value="SUBMITTIAL">SUBMITTIAL</Option>
                        <Option value="BOTH">BOTH</Option>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <FormControl field="submittial_file">
                  <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={submittial?.arquive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
