import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { getProjectStatusColor } from '~/Utils';
import { TableProjects } from './styles';

export default function SubContractorProjects({ projects, loading, selectedRowKeys, selectedRows, onChange, t }) {
  const tableColumns = [
    {
      title: t('screens:subContractors.data.projectDescription'),
      ellipsis: { showTitle: false },
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:subContractors.data.projectStartDate'),
      ellipsis: { showTitle: false },
      dataIndex: 'startDate',
      key: 'startDate',
      render: startDate => {
        if (startDate === null || startDate === undefined) {
          return <>N/A</>;
        }
        return moment(startDate).format('MM/DD/YYYY');
      },
    },
    {
      title: t('screens:projects.status'),
      ellipsis: { showTitle: false },
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Tag color={getProjectStatusColor(status.toLowerCase())}>
          {t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase()}
        </Tag>
      ),
    },
  ];

  return (
    <TableProjects
      rowKey="id"
      bordered
      rowSelection={{ selectedRowKeys, onChange }}
      selectedRowKeys={selectedRowKeys}
      selectedRows={selectedRows}
      loading={loading}
      columns={tableColumns}
      dataSource={projects}
      pagination={false}
    />
  );
}
