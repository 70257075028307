import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '~/components/Logo';
import { Container } from './styles';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Container>
      {t('app:poweredBy')} <Logo width="65" height='17' voxtell /> | {t('app:version')}
    </Container>
  );
}
