import React from 'react';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button as AntButton, Card, Col, Image, Modal, Spin, Upload } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from 'Utils/errorHandler';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { fallbackImage } from '~/Utils/data/jsonData';
import { updateTask } from '~/services/hooks/tasks';
import { uploadFiles } from '~/services/hooks/files';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const download = e => {
  console.log(e.target.href);
  fetch(e.target.href, {
    method: 'GET',
    headers: {},
  })
    .then(response => {
      response.arrayBuffer().then(function(buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', e.target.download);
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export default function PhotosForm({ item, visible, onClose, onUpdateItem }) {
  const { Meta } = Card;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadBeforeImage, setUploadBeforeImage] = useState(null);
  const [beforeImageUrl, setBeforeImageUrl] = useState(null);
  const [uploadAfterImage, setUploadAfterImage] = useState(null);
  const [afterImageUrl, setAfterImageUrl] = useState(null);

    const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    if (uploadBeforeImage) {
      const originFileObj = new Blob([uploadBeforeImage], { type: uploadBeforeImage.type });
      uploadBeforeImage.originFileObj = originFileObj;
      const data = await uploadFiles([uploadBeforeImage]);
      values.beforePicture = data[0];
    }

    if (uploadAfterImage) {
      const originFileObj = new Blob([uploadAfterImage], { type: uploadAfterImage.type });
      uploadAfterImage.originFileObj = originFileObj;
      const data = await uploadFiles([uploadAfterImage]);
      values.afterPicture = data[0];
    }

    try {
      await updateTask(values).then(response => {
        onUpdateItem(response.data.content);
      });
    } catch (error) {
      errorHandler(error);
      setErrors(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const tasksSchema = Yup.object().shape({
    title: Yup.string().required(t('messages:required')),
  });

  return (
    <Formik initialValues={item} enableReinitialize onSubmit={handleSave} validationSchema={tasksSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          width="556px"
          title={t('screens:task.photosTitle')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                {/* PROJECT NAME */}
                <Col span={24}>
                  <FormControl field="project.name" label={t('screens:task.projectName')}>
                    <span>{values?.project?.description}</span>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                {/* TASK TILTE */}
                <Col span={24}>
                  <FormControl field="title" label={t('screens:task.data.title')}>
                    <span>{values?.title}</span>
                  </FormControl>
                </Col>
              </Row>
              {/* TASK PHOTOS BEFORE AND AFTER */}
              <Row>
                <Col span={12}>
                  <Card
                    style={{
                      width: 250,
                      marginTop: 16,
                    }}
                    actions={[
                      <Upload
                        name="beforePicture"
                        listType="text"
                        accept={'image/*'}
                        showUploadList={false}
                        beforeUpload={file => {
                          setUploadBeforeImage(file);
                          getBase64(file, url => {
                            setBeforeImageUrl(url);
                          });
                          return false;
                        }}
                      >
                        <AntButton type="text" icon={<UploadOutlined />}>
                          Upload
                        </AntButton>
                      </Upload>,
                      values.beforePicture?.id && (
                        <AntButton
                          type="text"
                          icon={<DownloadOutlined />}
                          href={values.beforePicture?.path}
                          onClick={e => download(e)}
                          // target="_blank"
                          title="Download Previous sent picture"
                        >
                          Download
                        </AntButton>
                      ),
                    ]}
                  >
                    <Meta title="Before Picture" />
                    <Image
                      src={beforeImageUrl || values.beforePicture?.path || fallbackImage}
                      alt="Before Picture"
                      style={{ paddingTop: 20, height: 200, width: 200, objectFit: 'cover' }}
                      preview={true}
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    style={{
                      width: 250,
                      marginTop: 16,
                    }}
                    actions={[
                      <Upload
                        name="afterPicture"
                        listType="text"
                        accept={'image/*'}
                        showUploadList={false}
                        beforeUpload={file => {
                          setUploadAfterImage(file);
                          getBase64(file, url => {
                            setAfterImageUrl(url);
                          });
                          return false;
                        }}
                      >
                        <AntButton type="text" icon={<UploadOutlined />}>
                          Upload
                        </AntButton>
                      </Upload>,
                      values.afterPicture?.id && (
                        <AntButton
                          type="text"
                          icon={<DownloadOutlined />}
                          href={values.afterPicture?.path}
                          title="Download Previous sent picture"
                        >
                          Download
                        </AntButton>
                      ),
                    ]}
                  >
                    <Meta title="After Picture" />
                    <Image
                      src={afterImageUrl || values.afterPicture?.path || fallbackImage}
                      alt="After Picture"
                      style={{ paddingTop: 20, height: 200, width: 200, objectFit: 'cover' }}
                      preview={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
