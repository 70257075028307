import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, Checkbox, DatePicker } from 'formik-antd';
import {
  Modal,
  Spin,
  Col,
  Switch,
  List,
  Input as AntInput,
  Button as AntButton,
  Typography,
  Select as SelectAntd,
} from 'antd';
import { DeleteOutlined, PlusOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import errorHandler from 'Utils/errorHandler';
import Notifications from './Notifications';
import moment from 'moment';
import { updateTask, createTask } from '~/services/hooks/tasks';
import { format } from 'date-fns';

const { Text } = Typography;

const style = {
  pageTitle: { color: '#0f4c81', fontSize: '20px' },
  badge: {
    minWidth: '14px',
    height: '14px',
    padding: '0 8px',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '7px',
    textAlign: 'center',
    marginLeft: '3px',
  },
  badgeCount: { background: '#FFCCCC', color: '#F52222' },
  badgeCountEmpty: { background: '#CCFFCC', color: '#009900' },

  divider: { paddingLeft: '8px', marginTop: '20px', marginBottom: '10px', fontSize: `16px` },
};

export default function TaskForm({
  item,
  visible,
  priorities,
  tags,
  project,
  users,
  onClose,
  onUpdateItem,
  onInsertItem,
}) {
  const initialValues = {
    id: null,
    priority: 'NONE',
    title: '',
    startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    project: project,
    assignments: [],
    notes: '',
    duration: '',
    day: '',
    date: moment().format('YYYY-MM-DD HH:mm:ss'),
    completeWork: false,
    complete: false,
    takeBeforePicture: false,
    beforePicture: null,
    takeAfterPicture: false,
    afterPicture: null,
    todos: [],
    tags: [],
    comments: [],
  };
  const task = { ...initialValues, ...item };
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (task.id === null) return;
  //   if (!task.todos) return;
  //   // add idTemp field to toDos
  //   task.todos.map(todo => {
  //     todo.idTemp = Math.random();
  //     return todo;
  //   });
  // }, [task]);

  const removeSupportValues = values => {
    // remove idTemp field from toDos
    values.todos.map(todo => {
      delete todo.idTemp;
      return todo;
    });
    delete values.notifications; // TODO remove this line when notifications are well formed
    const { newTodo, showTodos, showNotifications, ...rest } = values;
    return rest;
  };

  const handleSave = async (values, { setErrors }) => {
    values = removeSupportValues(values);
    setLoading(true);
    values.date = format(new Date(values.date), 'yyyy-MM-dd\'T\'HH:mm:ss');
    values.startDate = format(new Date(values.startDate), 'yyyy-MM-dd\'T\'HH:mm:ss');
    try {
      if (values.id !== null) {
        const data = await updateTask(values);
        onUpdateItem(data);
      } else {
        const data = await createTask(values);
        onInsertItem(data);
      }
      onClose();
    } catch (error) {
      errorHandler(error);
      setErrors(error);
    }
    setLoading(false);
  };

  const tasksSchema = Yup.object().shape({
    title: Yup.string().required(t('messages:required')),
    startDate: Yup.string().required(t('messages:required')),
    notes: Yup.string(),
    duration: Yup.string(),
    day: Yup.string(),
    date: Yup.date().required(t('messages:required')),
  });

  return (
    <Formik initialValues={task} enableReinitialize onSubmit={handleSave} validationSchema={tasksSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          width="850px"
          title={values.id !== null ? t('screens:task.btnEdit') : t('screens:task.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              {/* GENERAL INFORMATION */}
              <Row>
                {/* TITLE */}
                <Col span={16}>
                  <FormControl field="title" label={t('screens:task.data.title')} required error={errors.title}>
                    <Input name="title" />
                  </FormControl>
                </Col>
                {/* COMPLETE */}
                <Col span={8}>
                  <FormControl field="complete" label="&nbsp;">
                    {/* <div style={{ paddingTop: 0 }}> */}
                    <Checkbox value="complete" name="complete">
                      {t('screens:task.data.complete')}
                    </Checkbox>
                    {/* </div> */}
                  </FormControl>
                </Col>
              </Row>
              {/* ASSIGNED TO, PRIORITY */}
              <Row>
                <Col span={16}>
                  <FormControl
                    field="assignments"
                    label={t('screens:task.data.assignments')}
                    required
                    error={errors.assignments}
                  >
                    <SelectAntd
                      key="assignments"
                      mode="multiple"
                      showArrow
                      style={{ width: '100%' }}
                      placeholder={t('messages:select')}
                      onChange={value => {
                        const selectedAssignments = users.filter(assignment => value.includes(assignment.id));
                        setFieldValue('assignments', selectedAssignments);
                      }}
                      value={values?.assignments?.map(assignment => assignment?.id) || []}
                    >
                      {users?.map(assignment => {
                        return (
                          <Option key={assignment.id} value={assignment.id}>
                            {assignment?.name || `Assignment ${assignment.id}`}
                          </Option>
                        );
                      })}
                    </SelectAntd>
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl
                    error={(touched.priority && errors.priority) || errors.priority}
                    field="priority"
                    label={t('screens:task.data.priority')}
                  >
                    <Select showSearch name="priority" placeholder="Select">
                      {priorities?.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              {/* DATE INFORMATION  */}
              <Row>
                <Col span={8}>
                  <FormControl field="date" label={t('screens:task.data.date')} error={errors.date}>
                    <DatePicker name="date" format="MM/DD/Y" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>

                <Col span={8}>
                  <FormControl field="startDate" label={t('screens:task.data.startDate')} error={errors.startDate}>
                    <DatePicker showTime name="startDate" format="MM/DD/Y HH:mm" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl field="day" label={t('screens:task.data.day')} error={errors.day}>
                    <Input name="day" />
                  </FormControl>
                </Col>
              </Row>
              {/* DURATION, TAGS */}
              <Row>
                <Col span={8}>
                  <FormControl field="duration" label={t('screens:task.data.duration')} error={errors.duration}>
                    <Input name="duration" />
                  </FormControl>
                </Col>
                <Col span={16}>
                  <FormControl error={errors.tags} label={t('screens:task.data.tags')} field="tags">
                    <SelectAntd
                      key="tags"
                      mode="multiple"
                      showArrow
                      style={{ width: '100%' }}
                      placeholder={t('messages:select')}
                      onChange={value => {
                        const selectedTags = tags.filter(tag => value.includes(tag.id));
                        setFieldValue('tags', selectedTags);
                      }}
                      value={values?.tags?.map(tag => tag?.id) || []}
                    >
                      {tags?.map(tag => {
                        return (
                          <Option key={tag.id} value={tag.id}>
                            {tag?.name || `Tag ${tag.id}`}
                          </Option>
                        );
                      })}
                    </SelectAntd>
                  </FormControl>
                </Col>
              </Row>
              {/* STEPS */}
              <span style={{ color: '#0f4c81', marginBottom: 5 }}>Steps</span>
              <Row>
                <Col span={12}>
                  <Checkbox value="takeBeforePicture" name="takeBeforePicture" style={{ margin: 3 }}>
                    {t('screens:task.data.takeBeforePicture')}
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Checkbox value="completeWork" name="completeWork" style={{ margin: 3 }}>
                    {t('screens:task.data.completeWork')}
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Checkbox value="takeAfterPicture" name="takeAfterPicture" style={{ margin: 3 }}>
                    {t('screens:task.data.takeAfterPicture')}
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div style={{ padding: 0, margin: '12px 4px 4px 4px' }}>
                    {t('screens:task.data.allSteps')}&nbsp;
                    <Switch
                      defaultChecked={!(values.completeWork && values.takeAfterPicture && values.takeBeforePicture)}
                      onChange={checked => {
                        setFieldValue('completeWork', checked);
                        setFieldValue('takeAfterPicture', checked);
                        setFieldValue('takeBeforePicture', checked);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              {/* NOTES */}
              <Row>
                <Col span={24} style={{ paddingTop: 10 }}>
                  <FormControl label={t('screens:task.data.notes')} field="notes" error={errors.notes}>
                    <Input.TextArea showCount maxLength={250} name="notes" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                {/* TODOS LIST */}
                <Col span={24}>
                  <div style={{ width: '100%', textAlign: 'start' }}>
                    <AntButton
                      type="link"
                      icon={values.showTodos ? <UpOutlined /> : <DownOutlined />}
                      onClick={() => setFieldValue('showTodos', !values.showTodos)}
                      style={{ color: '#0f4c81' }}
                    >
                      <>To-dos</>
                      <Text
                        style={
                          values?.todos?.filter(todo => !todo.complete).length > 0
                            ? { ...style.badge, ...style.badgeCount }
                            : { ...style.badge, ...style.badgeCountEmpty }
                        }
                      >
                        {values?.todos?.filter(todo => !todo.complete).length}
                      </Text>
                    </AntButton>
                  </div>
                  {values.showTodos && (
                    <List
                      size="small"
                      bordered
                      dataSource={values?.todos}
                      header={
                        <AntInput.Group compact>
                          <AntInput
                            id="newTodo"
                            name="newTodo"
                            style={{ width: 'calc(100% - 150px)' }}
                            value={values.newTodo}
                            onChange={e => {
                              setFieldValue('newTodo', e.target.value);
                            }}
                          />
                          <AntButton
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setFieldValue('todos', [
                                ...values?.todos,
                                { id: null, idTemp: Math.random(), description: values.newTodo, complete: false },
                              ]);
                              setFieldValue('newTodo', '');
                            }}
                          >
                            Add new todo
                          </AntButton>
                        </AntInput.Group>
                      }
                      // START LIST OF TODOS
                      renderItem={todo => (
                        <List.Item
                          key={todo.idTemp}
                          align="start"
                          actions={[
                            <AntButton
                              danger
                              type="default"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setFieldValue(
                                  'todos',
                                  values?.todos?.filter(t => t.idTemp !== todo.idTemp || t.id !== todo.id)
                                );
                              }}
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            description={
                              <Checkbox
                                checked={todo.complete}
                                name="complete"
                                onChange={e => {
                                  setFieldValue(
                                    'todos',
                                    values?.todos?.map(t => {
                                      if (t.idTemp === todo.idTemp && t.id === todo.id) {
                                        return {
                                          ...t,
                                          complete: e.target.checked,
                                        };
                                      }
                                      return t;
                                    })
                                  );
                                }}
                              >
                                <span style={todo?.complete ? { textDecoration: 'line-through' } : null}>
                                  {todo?.description}
                                </span>
                              </Checkbox>
                            }
                          />
                        </List.Item>
                      )}
                      // END LIST OF TODOS
                    />
                  )}
                </Col>
              </Row>
              {/* NOTIFICATIONS  */}
              <Row>
                {/* <Text style={style.divider}>{t('screens:task.data.notifications')}</Text>
              </Row>
              <Row> */}
                <Col span={24}>
                  <div style={{ width: '100%', textAlign: 'start', paddingTop: 20 }}>
                    <AntButton
                      type="link"
                      icon={values.showNotifications ? <UpOutlined /> : <DownOutlined />}
                      onClick={() => setFieldValue('showNotifications', !values.showNotifications)}
                      style={{ color: '#0f4c81' }}
                    >
                      <>{t('screens:task.data.notifications')}</>
                    </AntButton>
                  </div>
                  {values.showNotifications && <Notifications setFieldValue={setFieldValue} values={values} />}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
