import React from 'react';
import { EyeOutlined, TrophyTwoTone } from '@ant-design/icons';
import { Breadcrumb, Col, Tooltip, Button as ButtonAntd } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import Filter from './filter';
import { paginationEstimatesBySubVendor } from '~/services/hooks/estimates';
import { findAllSubVendors } from '~/services/hooks/subVendors';
import history from '~/services/history';
import moment from 'moment-business-days';

export default function SubContractorQuotations() {
  const profile = useSelector(state => state.user.profile);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [subVendor, setSubVendor] = useState({});
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);

  // calc final date from given workdays number
  const calcFinalDate = (startDate, workdays) => {
    moment.updateLocale('en', { workingWeekdays: [1, 2, 3, 4, 5] });
    const finalDate = moment(startDate, 'YYYY-MM-DD')
      .businessAdd(workdays)
      .format('YYYY-MM-DD');
    return finalDate;
  };

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationEstimatesBySubVendor({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: filters,
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  useEffect(() => {
    if (profile.id)
      findAllSubVendors([
        {
          field: 'user',
          value: profile.id,
          restriction: 'EQUAL',
        },
      ]).then(data => {
        data.length > 0 && setSubVendor(data[0]);
      });
  }, [profile]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:estimatesQuotation.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 90,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:estimatesQuotation.project'),
      key: 'winner',
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
      render: record => {
        return (
          <Row>
            <Col flex="30px">{record.winner ? <TrophyTwoTone twoToneColor="#52c41a" /> : null}</Col>
            <Col flex="auto">
              <Tooltip placement="topLeft" title={record.project.description}>
                {record.project.description}
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t('screens:estimatesQuotation.sent'),
      dataIndex: 'sent',
      key: 'sent',
      align: 'left',
      width: 150,
      ellipsis: { showTitle: true },
      render: sent => moment(sent).format('YYYY/MM/DD'),
    },
    {
      title: t('screens:estimatesQuotation.expireDate'),
      key: 'expireDate',
      align: 'left',
      width: 150,
      ellipsis: { showTitle: true, tooltip: true },
      render: record => {
        const expireTime = record.expireTime;
        const finalDate = calcFinalDate(record.sent, expireTime);
        // verify if final date is expired
        const moment = require('moment-business-days');
        moment.updateLocale('en', { workingWeekdays: [1, 2, 3, 4, 5] });
        const isExpired = moment(finalDate, 'YYYY-MM-DD').isBefore(moment(new Date(), 'YYYY-MM-DD'));
        return <span style={{ color: isExpired ? 'red' : 'green' }}>{moment(finalDate).format('YYYY/MM/DD')}</span>;
      },
    },
    {
      title: t('screens:estimatesQuotation.expireTime'),
      dataIndex: 'expireTime',
      key: 'expireTime',
      align: 'left',
      width: 150,
      ellipsis: { showTitle: true },
      render: expireTime => `${expireTime} ${t('screens:estimatesQuotation.businessDays')}`,
    },
    {
      title: t('screens:estimatesQuotation.actions'),
      key: 'actions',
      align: 'center',
      width: 100,
      ellipsis: { showTitle: true },
      render: record => (
        <ButtonAntd
          title={t('screens:bids.data.bidDetails')}
          type="link"
          icon={<EyeOutlined />}
          onClick={() =>
            history.push({
              pathname: '/request-for-quotation',
              state: { data: record, subVendor: subVendor },
            })
          }
        />
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:subcontractorQuotations')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:subcontractorQuotations')} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
