import React from 'react';
import { Collapse } from 'antd';
import { Field, FieldArray } from 'formik';
import { tasksNotificationList } from '~/Utils/data/jsonData';

const { Panel } = Collapse;

export default function Notifications({ values, setFieldValue }) {

  return (
    <FieldArray
      name="notifications"
      render={({ push, remove }) => (

          <Collapse
            defaultActiveKey={tasksNotificationList?.groupings?.map((grouping, index) => {
              return index;
            })}
          >
            {tasksNotificationList?.groupings?.map((grouping, index) => (
              <Panel key={index} header={grouping.name}>
                {grouping.subgroups.map((subgroup, index) => (
                  <Collapse
                    defaultActiveKey={grouping.subgroups.map((item, index) => {
                      return index.toString();
                    })}
                  >
                    <Panel key={index} header={subgroup.name}>
                      {subgroup.items.map(item => (
                        <div style={{ display: 'flex' }}>
                          <div style={{ alignSelf: 'self-start' }}>
                            <span style={{ marginLeft: 10 }}>{item.description}</span>
                          </div>
                          <div
                            style={{
                              alignSelf: 'self-end',
                              marginLeft: 'auto',
                              marginBottom: '0px',
                            }}
                          >
                            <Field
                              name="notificationsItem"
                              type="checkbox"
                              value={item.id}
                              checked={values?.notifications?.map(e => e.id).includes(item.id)}
                              onChange={e => {
                                if (e.target.checked) {
                                  push({ id: item.id, value: true });
                                } else {
                                  const index = values.notifications
                                    .map(function(e) {
                                      return e.id;
                                    })
                                    .indexOf(item.id);
                                  remove(index);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                ))}
              </Panel>
            ))}
          </Collapse>
      )}
    />
  );
}
