import React, { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Modal, Popconfirm, Typography, Space, Button as ButtonAntd, Table as TableAntd } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import Row from '~/components/Row';
import moment from 'moment';
import { Table } from '~/components/Table';
import PaymentsForm from './paymentsForm';
import { ModalFooter } from '~/components/Modal';
import { formatPrice, formatPercent } from '~/Utils';
import { findByIdSubVendor } from '~/services/hooks/subVendors';
import { deletePayment } from '~/services/hooks/payments';

const { Text } = Typography;

export default function Payments({ selectedWork, visible, onClose, fetchData, payItems, setPayItems }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [balance, setBalance] = useState(0);
  const [subcontractor, setSubcontractor] = useState({});

  useEffect(() => {
    if (selectedWork?.subcontractorId !== undefined) {
      setLoading(true);
      findByIdSubVendor(selectedWork?.subcontractorId).then(res => {
        setSubcontractor(res);
      });
      setLoading(false);
    }
  }, [selectedWork]);

  const handleDelete = async id => {
    setLoading(true);
    await deletePayment(id);
    const newPayItems = payItems.filter(item => item.id !== id);
    setPayItems(newPayItems);
    setLoading(false);
    fetchData();
  };

  const handleEdit = record => {
    setSelectedPayment(record);
    setShowForm(true);
  };

  const style = {
    divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px` },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: {
        showTitle: false,
      },
      align: 'left',
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('YYYY/MM/DD');
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      ellipsis: {
        showTitle: false,
      },
      align: 'left',
      render: record => formatPrice(record),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      align: 'left',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('screens:claims.data.actions'),
      key: 'actions',
      width: '120px',
      align: 'center',
      render: record => (
        <Space size="small">
          <ButtonAntd
            icon={<EditOutlined />}
            type="link"
            onClick={() => handleEdit(record)}
            title={t('messages:edit')}
          />
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <ButtonAntd icon={<DeleteOutlined />} type="link" title={t('messages:delete')} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      width="800px"
      title={`Payments: ${subcontractor?.companyName}/ ${selectedWork?.description}`}
      description={selectedWork !== null ? selectedWork.id : t('screens:workbook.costs.btnNew')}
      onCancel={onClose}
      open={visible}
      loading={loading}
      footer={<ModalFooter loading={loading} onCancel={onClose} showOk={false} />}
    >
      <Row>
        <Col span={12} style={style.btnNew.col}>
          <Button
            loading={loading}
            onClick={() => {
              if (balance <= 0) return;
              setSelectedPayment(null);
              setShowForm(true);
            }}
            style={style.btnNew.btn}
          >
            <PlusOutlined />
            New Payment
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        size="small"
        rowKey="id"
        pagination={false}
        loading={loading}
        columns={tableColumns}
        dataSource={payItems}
        summary={pageData => {
          let totalApplications = 0;
          pageData.forEach(({ value }) => {
            totalApplications += parseFloat(value);
          });
          setBalance(selectedWork?.scheduledValue - totalApplications);
          return (
            <>
              <TableAntd.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                <TableAntd.Summary.Cell index={0}>
                  <Text strong>Total</Text>
                </TableAntd.Summary.Cell>
                <TableAntd.Summary.Cell index={1} colSpan={3}>
                  <Text strong>{formatPrice(totalApplications)}</Text>
                </TableAntd.Summary.Cell>
              </TableAntd.Summary.Row>
              <TableAntd.Summary.Row
                style={
                  selectedWork?.scheduledValue - totalApplications !== 0
                    ? { backgroundColor: '#fafafa' }
                    : { backgroundColor: '#aaffaa' }
                }
              >
                <TableAntd.Summary.Cell index={0}>
                  <Text strong type="secondary">
                    Balance to finish
                  </Text>
                </TableAntd.Summary.Cell>
                <TableAntd.Summary.Cell index={1}>
                  <Text strong type="secondary">
                    {formatPrice(balance)}
                  </Text>
                </TableAntd.Summary.Cell>
                <TableAntd.Summary.Cell index={2} colSpan={2}>
                  <Text strong type="secondary">
                    {` ${formatPercent(balance / selectedWork?.scheduledValue)} ${
                      balance > 0 ? 'to finish' : 'due'
                    }, scheduled value: ${formatPrice(selectedWork?.scheduledValue)}`}
                  </Text>
                </TableAntd.Summary.Cell>
              </TableAntd.Summary.Row>
            </>
          );
        }}
      />
      <PaymentsForm
        selectedWork={selectedWork}
        balance={balance}
        selectedPayment={selectedPayment}
        payItems={payItems}
        setPayItems={setPayItems}
        fetchData={fetchData}
        visible={showForm}
        onClose={() => setShowForm(false)}
      />
    </Modal>
  );
}
