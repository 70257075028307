import React from 'react';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Breadcrumb, Badge, Button as ButtonAntd } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-business-days';
import axios from 'axios';
import { approveContract } from '~/services/hooks/contracts';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import Filter from './filter';
import { paginationContracts } from '~/services/hooks/contracts';
import ModalSignatureCanvas from '~/components/ModalSignatureCanvas/ModalSignatureCanvas';

export default function SubContractorContracts() {
  const profile = useSelector(state => state.user.profile);
  const token = useSelector(state => state.auth.token);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showSignatureModal, setSignatureModal] = useState(false);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationContracts({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: filters,
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  const handleDownload = async (record) => {
    setLoading(true);
    try {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/contracts/generateContract/${record.id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${record.typeDescription}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const showFile = (record) => {
    setLoading(true);
    try {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/contracts/generateContract/${record.id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        var win = window.open(url, '_blank');
        win.focus();
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  }

  const handleSignatureChange = async ({ name, jobRole, signatureResult, rubricResult, password }) => {
    try {
      const formData = new FormData();
      formData.append('contractId', selectedRecord.id);
      formData.append('name', name);
      formData.append('jobRole', jobRole);
      formData.append('signature', signatureResult);
      formData.append('rubric', rubricResult);
      formData.append('password', password);

      await approveContract(formData);
      setSignatureModal(false);
      setFilters([]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
}

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  useEffect(() => {

  }, [profile]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    sent: { backgroundColor: '#52c41a' },
    notSent: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    btnDownload: { padding: '0 5px' },
    btnEdit: { padding: '0 5px' }
  };

  const tableColumns = [
    {
      title: t('screens:contracts.data.number'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',

    },
    {
      title: t('screens:contracts.data.type'),
      dataIndex: 'typeDescription',
      key: 'typeDescription',
    },
    {
      title: t('screens:contracts.data.date'),
      dataIndex: 'date',
      key: 'date',
      render: record => {
        if (record === null || record === undefined) {
          return <>N/A</>;
        }
        return moment(record).format('YYYY/MM/DD');
      },
    },
    {
      title: t('screens:contracts.data.project'),
      dataIndex: 'project',
      key: 'project',
      render: (text, record) => (
        <>{record.project.description}</>
      ),
    },
    {
      title: t('screens:contracts.data.status'),
      dataIndex: 'approvedBySub',
      key: 'approvedBySub',
      align: 'center',
      render: (text, record) => (
        record.approvedBySub === true ?
          (<Badge count={t('screens:contracts.data.approved')} style={style.sent} title={'APPROVED'} />) :
          (<ButtonAntd
            type="primary"
            size='small'
            style={style.btnApprove}
            title={t('Approve the contract')}
            onClick={() => {
              setSelectedRecord(record);
              setSignatureModal(true);
            }}>
            {t('screens:contracts.data.approve')}
          </ButtonAntd>)
      ),
    },
    {
      title: t('screens:contracts.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10px',
      render: (text, record) => (
        <TableActions>
          <Button style={style.btnDownload} title={t('messages:download')} onClick={() => handleDownload(record)}><DownloadOutlined /></Button>
          <Button style={style.btnEdit} title={t('messages:view')} onClick={() => showFile(record)}><EyeOutlined /></Button>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:contracts')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:contracts')} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Table
          size="small"
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />

        <ModalSignatureCanvas
          record={selectedRecord}
          visible={showSignatureModal}
          loading={loading}
          canvasProps={{ width: 450, height: 80, className: 'sigCanvas' }}
          onSave={handleSignatureChange}
          onLoad={() => { setLoading(true)}}
          onClose={() => {
            setSignatureModal(false);
            setFilters([]);
          }}
        />
      </Box>
    </DefaultLayout>
  );
}
