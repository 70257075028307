import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

// GET ALL NOTIFICATIONS
export const findAllNotifications = async () => {
  try {
    const { data } = await api.get('/notifications/findAll');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};