import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

// GET ALL COSTS TYPES
export const findAllCostTypes = async () => {
  try {
    const { data } = await api.get('/costTypes/findAll');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};
