import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Row, Descriptions, Typography, Space } from 'antd';
import { formatPrice, formatPercent } from '~/Utils';
import Box from '~/components/Box';
import { findAllInvoices } from '~/services/hooks/invoices';
import { paginationProposalClients } from '~/services/hooks/proposalClients';

const { Text } = Typography;
const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';
const style = {
  divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px` },
};

export default function ProjectDashboard({ project, customer }) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [proposalClient, setProposalClient] = useState({});
  const [invoiceData, setInvoiceData] = useState([]);
  const [lastInvoice, setLastInvoice] = useState({});

  // GET CLIENT PROPOSAL
  useEffect(() => {
    paginationProposalClients({
      page: 0,
      maxSize: true,
      filters: [
        {
          columnJoin: 'project',
          field: 'id',
          value: project?.id,
          restriction: 'EQUAL',
        },
        {
          field: 'disapproved',
          value: false,
          restriction: 'EQUAL',
        },
        {
          field: 'proposalSignature',
          restriction: 'IS_NOT_NULL',
        },
      ],
    }).then(data => {
      setProposalClient(data.content[0]);
    });
  }, []);

  // GET INVOICES
  useEffect(() => {
    findAllInvoices([
      {
        columnJoin: 'payAppClient',
        columnSecondJoin: 'project',
        field: 'id',
        value: project?.id,
        restriction: 'EQUAL',
      },
    ]).then(data => {
      setInvoiceData(data);
      setLastInvoice(data?.length > 0 ? data[data.length - 1] : {});
    });
  }, [project]);

  console.log('invoiceData lenght', invoiceData.length);
  console.log('proposalClient', proposalClient);
  console.log('lastInvoice', lastInvoice);

  return (
    <>
      <Box>
        <Row gutter={16}>
          <Col span={24}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Descriptions
                title={<Title level={4}>Part 1</Title>}
                layout="vertical"
                bordered
                column={3}
                // contentStyle={{ width: '70%' }}
              >
                <Descriptions.Item label="Started">{moment(project.startDate).format(dateFormat)}</Descriptions.Item>
                <Descriptions.Item label="Finished">
                  {moment(project.actualEndDate).format(dateFormat)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice #">
                  { lastInvoice?.id ? lastInvoice?.id : 'N/A' }
                </Descriptions.Item>
                <Descriptions.Item label="Proposal #">{proposalClient?.id}</Descriptions.Item>
                <Descriptions.Item label="Project #">{project?.id}</Descriptions.Item>
                <Descriptions.Item label="Super">-</Descriptions.Item>
              </Descriptions>
            </Space>
          </Col>
        </Row>

        {/* PROJECT FINANCIAL VALUES */}
        <Row gutter={16} style={{ paddingTop: '30px' }}>
          <Col span={12} style={{ paddingTop: '0px' }}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <Descriptions
                title="PROJECT FINANCIAL VALUES"
                layout="horizontal"
                bordered
                column={1}
                contentStyle={{ width: 120 }}
              >
                <Descriptions.Item label="Contract">{formatPrice(1000)}</Descriptions.Item>
                <Descriptions.Item label="Total Amount in Change Orders">{formatPrice(1000)}</Descriptions.Item>
                <Descriptions.Item label="Total Revenue">{formatPrice(1000)}</Descriptions.Item>
                <Descriptions.Item label="Subcontractors - Total">{formatPrice(1000)}</Descriptions.Item>
                <Descriptions.Item label="Materials - Estimated">Materials - Estimated</Descriptions.Item>
                <Descriptions.Item label="Equipment - Estimated">Equipment - Estimated</Descriptions.Item>
                <Descriptions.Item label="Overhead">Overhead</Descriptions.Item>
                <Descriptions.Item label="Estimated Result">Estimated Result</Descriptions.Item>
              </Descriptions>
              <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                <Descriptions.Item label="Invoiced to date">INPUT</Descriptions.Item>
                <Descriptions.Item label="Materials">input</Descriptions.Item>
                <Descriptions.Item label="Tools">input</Descriptions.Item>
                <Descriptions.Item label="Labor">input</Descriptions.Item>
                <Descriptions.Item label="Subcontractors Paid to Date">input</Descriptions.Item>
                <Descriptions.Item label="Retainage">input</Descriptions.Item>
                <Descriptions.Item label="Supervision">input</Descriptions.Item>
                <Descriptions.Item label="Total Expenses">input</Descriptions.Item>
                <Descriptions.Item label="Total Received - Total Expenses:">input</Descriptions.Item>
              </Descriptions>
              <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                <Descriptions.Item label="Profit / Loss">input</Descriptions.Item>
              </Descriptions>
            </Space>
          </Col>
        </Row>
      </Box>
    </>
  );
}
