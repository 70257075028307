import React, { useState } from 'react';
import { Col, Modal, Spin, InputNumber } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import FormTxt from '~/components/Form/FormTxt';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { updateEstimateSubVendor } from '~/services/hooks/estimateSubVendors';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

const initialValues = {
  id: null,
  subVendor: {},
  quantity: 0.0,
  value: 0.0,
  dueDate: 0,
  note: '',
  arquive: null,
  responserName: '',
  responserJobTitle: '',
  status: 'NEW',
  winner: false,
  materialValue: 0.0,
  laborValue: 0.0,
  estimateItem: {
    id: null,
    area: {
      id: null,
      name: '',
    },
    laborType: {
      id: null,
      code: 0,
      name: '',
    },
    type: 'LABOR',
    markup: 0.0,
    markupType: 'DOUBLE_DASH',
    estimateDescription: {
      id: null,
      unit: 'SF',
      description: '',
      materialValue: 0.0,
      laborValue: 0.0,
    },
    quantity: 0.0,
    materialValue: 0.0,
    laborValue: 0.0,
    copyQtdToVendor: false,
  },
};

export default function RequestForQuotationForm({ selectedRecord, onClose, visible, responser, onUpdateItem }) {

  if (selectedRecord.responseName === null && responser !== null) {
    selectedRecord.responseName = responser.name;
  }

  if (selectedRecord.responseJobTitle === null && responser !== null) {
    selectedRecord.responseJobTitle = responser.jobTitle;
  }

  const recordData = { ...initialValues, ...selectedRecord };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadList, setUploadList] = useState([]);

  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.arquive = data[0];
    }
    setLoading(true);
    const data = await updateEstimateSubVendor(values);
    onUpdateItem(data);
    setLoading(false);
    onClose();
  };

  const estimateSchema = Yup.object().shape({
    quantity: Yup.number().required(t('screens:estimate.quantityRequired')),
    materialValue: Yup.number().required(t('screens:estimate.materialValueRequired')),
    laborValue: Yup.number().required(t('screens:estimate.laborValueRequired')),
    responserName: Yup.string().required(t('screens:estimate.responserNameRequired')),
    responserJobTitle: Yup.string().required(t('screens:estimate.responserJobTitleRequired')),
    dueDate: Yup.number().required(t('screens:estimate.dueDateRequired')),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={recordData}
      onSubmit={handleSave}
      validationSchema={estimateSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          width="600px"
          title={t('screens:estimate.edit')}
          description={t('screens:estimate.btnEdit')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  {/* DESCRIPTION */}
                  <FormTxt label={t('screens:estimate.data.description')}>
                    <>{values.estimateItem.estimateDescription.description}</>
                  </FormTxt>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {/* AREA */}
                  <FormTxt label={t('screens:estimate.data.area')}><>{values.estimateItem.area.name}</></FormTxt>
                </Col>
                <Col span={12}>
                  {/* SERVICE TYPE */}
                  <FormTxt label={t('screens:estimate.data.laborType')}>
                    <>{values.estimateItem.laborType.name}</>
                  </FormTxt>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  {/* QUANTITY */}
                  <FormControl
                    field="quantity"
                    label={t('screens:estimate.data.quantity')}
                    // required
                    error={(touched.quantity && errors.quantity) || errors.quantity}
                  >
                    <InputNumber
                      addonBefore={values.estimateItem.estimateDescription.unit}
                      name="quantity"
                      id="quantity"
                      placeholder="Enter quantity"
                      value={values.quantity}
                      onChange={value => {
                        setFieldValue('quantity', value);
                      }}
                    />
                  </FormControl>
                </Col>

                <Col span={6}>
                  {/* MATERIAL UNIT PRICE */}
                  <FormControl
                    field="materialValue"
                    label={t('screens:estimate.data.materialValue')}
                    // required
                    error={(touched.materialValue && errors.materialValue) || errors.materialValue}
                  >
                    <InputNumber
                      addonBefore="$"
                      name="materialValue"
                      id="materialValue"
                      placeholder="Enter material unit cost"
                      value={values.materialValue}
                      onChange={value => {
                        setFieldValue('materialValue', value);
                      }}
                    />
                  </FormControl>
                </Col>
                <Col span={6}>
                  {/* LABOR UNIT PRICE */}
                  <FormControl
                    field="laborValue"
                    label={t('screens:estimate.data.laborValue')}
                    // required
                    error={(touched.laborValue && errors.laborValue) || errors.laborValue}
                  >
                    <InputNumber
                      addonBefore="$"
                      name="laborValue"
                      id="laborValue"
                      placeholder="Enter labor unit cost"
                      value={values.laborValue}
                      onChange={value => {
                        setFieldValue('laborValue', value);
                      }}
                    />
                  </FormControl>
                </Col>
                <Col span={6}>
                  {/* DUE DATE */}
                  <FormControl
                    field="dueDate"
                    label={t('screens:estimate.data.dueDate')}
                    required
                    error={(touched.dueDate && errors.dueDate) || errors.dueDate}
                  >
                    <InputNumber
                      addonAfter="B.Days"
                      name="dueDate"
                      id="dueDate"
                      placeholder="Enter due date"
                      value={values.dueDate}
                      onChange={value => {
                        setFieldValue('dueDate', value);
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <FormControl label={t('screens:estimate.data.responserName')} field="responserName" required error={errors.responserName}>
                    <Input name="responserName" id="responserName" value={values.responserName} />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl label={t('screens:estimate.data.responserJobTitle')} field="responserJobTitle" required error={errors.responserJobTitle}>
                    <Input name="responserJobTitle" id="responserJobTitle" value={values.responserJobTitle} />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {/* NOTE */}
                  <FormControl
                    field="note"
                    label={t('screens:estimate.data.note')}
                    error={(touched.note && errors.note) || errors.note}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={1000}
                      name="note"
                      id="note"
                      placeholder="Enter note"
                      value={values.note}
                      onChange={e => {
                        setFieldValue('note', e.target.value);
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl field="subcontractor_file">
                  <UploadFile
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                      file={values?.arquive}
                      accept=".pdf"
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
