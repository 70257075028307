import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ projectStatus, isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    if (values.descriptionFilter === '0') {
      values.descriptionFilter = '';
    }
    const filter = [];
    try {
      if (
        values.descriptionFilter !== undefined &&
        values.descriptionFilter !== null &&
        values.descriptionFilter !== ''
      ) {
        filter.push({
          field: 'description',
          value: values.descriptionFilter,
          restriction: 'LIKE',
        });
      }
      if (values.statusFilter !== undefined && values.statusFilter !== null && values.statusFilter !== '' && values.statusFilter !== 'All') {
        filter.push({
          field: 'status',
          value: values.statusFilter,
          restriction: 'EQUAL',
        });
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik initialValues={{ descriptionFilter: '', statusFilter: 'All' }} enableReinitialize onSubmit={handleSearch}>
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24, sm: 12, md: 12, lg: 7, xl: 9 }}
                  error={errors.descriptionFilter}
                  field="descriptionFilter"
                  label={t('screens:bids.data.description')}
                >
                  <Input name="descriptionFilter" placeholder={t('fields:projects.description.placeholder')} />
                </FormControl>
                <FormControl
                  cols={{ xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }}
                  error={errors.statusFilter}
                  field="statusFilter"
                  label={t('screens:bids.status')}
                >
                  <Select name="statusFilter">
                    {projectStatus.map(item => {
                      return (
                        <Option key={item.id} value={item.code}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
