import React from 'react';
import { Modal } from 'antd';
import Row from '~/components/Row';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';

export default function ObsModal({ visible, onCancel, selectedRecord }) {
  const { t } = useTranslation();
  return (
    <Modal
      width="600px"
      title={t('screens:price_quotes.items.note')}
      onCancel={onCancel}
      open={visible}
      footer={
        <ModalFooter onCancel={onCancel} cancelText="Close" showOk={false}  />
    }
    >
      <Row>
        {selectedRecord !== null ? selectedRecord?.note : ''}
      </Row>
    </Modal>
  );
}
