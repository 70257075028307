import { Table } from 'antd';
import styled from 'styled-components';

export const TableProjects = styled(Table)`
  padding-top: 20 !important;
  .ant-table-tbody {
    padding: 2 !important;
  }
  .ant-table-tbody > tr > td {
    padding: 2 !important;
  }
`;
